import faker from 'faker';
import { Dialogues, Definitions, Objects, Properties, Relations, Values } from '@pitaman71/omniglot-live-data';
import { Domains, Modules, WrapFaker } from '@pitaman71/omniglot-live-domains';
import * as Elevated from '@pitaman71/omniglot-introspect';

import { Domain as Model__Events__TimeAndLocation__Domain} from '../Events/TimeAndLocation';
import { Value as Model__Events__TimeAndLocation__Value} from '../Events/TimeAndLocation';
export const directory = new Definitions.Directory();
// property Events.HasDetails

const Domain: Elevated.Domain<Model__Events__TimeAndLocation__Value> = Model__Events__TimeAndLocation__Domain;
export type TypeParams = {
    Binding: { event: Objects.Binding<string> },
    Value: Model__Events__TimeAndLocation__Value,
    Domain: typeof Domain
}
export const Descriptor = new class _Descriptor extends Properties.Descriptor<TypeParams> {
    canonicalName = 'generated.Events.HasDetails';
    build(builder: Properties.Builder<TypeParams>): void {
        builder.object('event');
        builder.measure(Model__Events__TimeAndLocation__Domain);
        builder.scalar();
    }
};

directory.descriptors.properties.set(Descriptor.canonicalName, Descriptor);
        
