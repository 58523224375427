import faker from 'faker';

import { Clients, Controller, Objects, Stores, Streams, Values } from '@pitaman71/omniglot-live-data';
import { Domains } from '@pitaman71/omniglot-live-domains';

import { Access } from '.';

import * as Model from '../../autosrc/swivell/talent_marketplace';

import * as InviteAll from '../Tasks/InviteAll';

const delay = (ms: number, val: any) => {
    return new Promise(resolve => setTimeout(resolve, ms, val));
}

export function useProject(zone: Stores.Zone, binding: { project: Objects.Binding<string> }) {
    const community = Objects.Binding.from_bound("the");
    return {
        hasProject: Model.Community.HasProject.Descriptor.stream(zone, { community }),
        hasProvenance: Model.Identity.Provenance.Descriptor.stream(zone, { the: binding.project }).scalar,
        hasMembers: Model.Projects.HasMembers.Descriptor.stream(zone, {project: binding.project}),
        hasTitle: Model.Projects.HasTitle.Descriptor.stream(zone, { project: binding.project}).scalar,
        hasBrandName: Model.Projects.HasBrandName.Descriptor.stream(zone, { project: binding.project}).scalar,
        hasDescription: Model.Projects.HasDescription.Descriptor.stream(zone, { project: binding.project}).scalar,
        hasStatus: Model.Projects.HasStatus.Descriptor.stream(zone, { project: binding.project}).scalar,
        hasRoles: Model.Projects.HasRoles.Descriptor.stream(zone, { project: binding.project}),
        hasAvatar: Model.Projects.HasAvatar.Descriptor.stream(zone, { project: binding.project}).scalar,
        hasHomeLocation: Model.Projects.HasHomeLocation.Descriptor.stream(zone, { project: binding.project }).scalar,
        hasEvents: Model.Projects.HasEvents.Descriptor.stream(zone, { project: binding.project }),
        includes: Model.Content.Includes.Descriptor.stream(zone, { topic: binding.project })

    }
}

export function createProject(zone: Stores.Zone, binding: { owner: Objects.Binding<string>, project: Objects.Binding<string> }) {
    const community = Objects.Binding.from_bound('the');
    return {
        allow: Clients.ScalarClient.Eager<boolean>('allow'),
        exec: () => {
            const { hasProvenance, hasMembers, hasStatus } = useProject(zone, binding);
            const byMember = Model.Projects.ByMember.Descriptor.stream(zone, {person: binding.owner});
            const hasProject = Model.Community.HasProject.Descriptor.stream(zone, { community });
            hasProvenance.stateful().assign({ claimed: true, owner: binding.owner });
            hasMembers.stateful().insert({ project: binding.project, person: binding.owner });
            byMember.stateful().insert({ project: binding.project, person: binding.owner });
            hasProject.stateful().insert({ community, project: binding.project });
            hasStatus.stateful().assign('Draft');
        }
    }
}

export function activateProject(zone: Stores.Zone, binding: { me: Objects.Binding<string>, project: Objects.Binding<string> }) {
    const community = Objects.Binding.from_bound('the');
    return {
        allow: Access.allowEdit(zone, { me: binding.me, project: binding.project }),
        exec: () => {
            const { hasStatus } = useProject(zone, binding);
            hasStatus.stateful().assign('Active');
            return InviteAll.Logic.create(zone, binding.me, InviteAll.HasState.Direction.Invite, undefined, binding.project);
        }
    }
}

export function deactivateProject(zone: Stores.Zone, binding: { me: Objects.Binding<string>, project: Objects.Binding<string> }) {
    const community = Objects.Binding.from_bound('the');
    return {
        allow: Access.allowEdit(zone, { me: binding.me, project: binding.project }),
        exec: () => {
            const { hasStatus } = useProject(zone, binding);
            this.clients().hasStatus?.assign('Draft');
            return InviteAll.Logic.create(zone, binding.me, InviteAll.HasState.Direction.Uninvite, undefined, binding.project);
        }
    }
}

export function deleteProject(zone: Stores.Zone, binding: { me: Objects.Binding<string>, project: Objects.Binding<string> }) {
    const community = Objects.Binding.from_bound('the');
    return {
        allow: Access.allowEdit(zone, { me: binding.me, project: binding.project }),
        exec: () => {
            const byMember = Model.Projects.ByMember.Descriptor.stream(zone, {person: binding.me});
            const hasProject = Model.Community.HasProject.Descriptor.stream(zone, { community });
            byMember.stateful().remove({ project: binding.project, person: binding.me });
            hasProject.stateful().remove({ community, project: binding.project });
        }
    }
}

export function useRoles(zone: Stores.Zone, binding: { me: Objects.Binding<string>, project: Objects.Binding<string> }) {
    return { hasRoles: Model.Projects.HasRoles.Descriptor.stream(zone, {project: binding.project}) };
}

export function useCandidates(zone: Stores.Zone, binding: { me: Objects.Binding<string>, project: Objects.Binding<string>, role: Objects.Binding<string> }) {
    return { hasCandidates: Model.Roles.HasCandidates.Descriptor.stream(zone, {role: binding.role}) };
}

export function useRole(zone: Stores.Zone, binding: { role: Objects.Binding<string> }) {
    const hasProvenance = Model.Identity.Provenance.Descriptor.stream(zone, { the: binding.role }).scalar;
    const hasSkills = Model.Roles.HasSkills.Descriptor.stream(zone, binding).set;
    const hasCount = Model.Roles.HasCount.Descriptor.stream(zone, binding).scalar;
    const hasTitle = Model.Roles.HasTitle.Descriptor.stream(zone, { role: binding.role }).scalar;
    const hasRate = Model.Roles.HasRate.Descriptor.stream(zone, { role: binding.role }).scalar;
    const hasCandidates = Model.Roles.HasCandidates.Descriptor.stream(zone, { role: binding.role });

    return { hasProvenance, hasSkills, hasCount, hasTitle, hasRate, hasCandidates };
}

export function useCandidate(zone: Stores.Zone, binding: { candidate: Objects.Binding<string> }) {
    const hasPerson = Model.Candidates.HasPerson.Descriptor.stream(zone, { candidate: binding.candidate });
    const hasProject = Model.Candidates.HasProject.Descriptor.stream(zone, { candidate: binding.candidate });
    const hasRole = Model.Candidates.HasRole.Descriptor.stream(zone, { candidate: binding.candidate });
    const hasScore = Model.Candidates.HasScore.Descriptor.stream(zone, { candidate: binding.candidate }).scalar;
    return { hasPerson, hasProject, hasRole, hasScore };
}

export function addRole(zone: Stores.Zone, binding: { me: Objects.Binding<string>, project: Objects.Binding<string> },options: {
    skill?: string,
    count?: number
}) {
    const { hasRoles } = useRoles(zone, binding);
    return {
        allow: !!options.skill,
        exec: () => {
            const role = Objects.Binding.from_bound(faker.datatype.uuid());
            hasRoles.stateful().insert({ project: binding.project, role });
            if(options.skill) {
                Model.Roles.HasSkills.Descriptor.stream(zone, { role }).set?.stateful().assign(new Set([ options.skill ]));
                Model.Roles.HasCount.Descriptor.stream(zone, { role }).scalar?.stateful().assign(options.count || 1);
            }
            return { role };
        }
    };
}

export function removeRole(zone: Stores.Zone, binding: { me: Objects.Binding<string>, project: Objects.Binding<string>, role: Objects.Binding<string> }
) {
    const { hasRoles } = useRoles(zone, binding);
    return {
        allow: true,
        exec: () => {
            hasRoles.stateful().remove(binding);
        }
    }
}

function useOffer (
    zone: Stores.Zone, 
    binding: { candidate: Objects.Binding<string> }
) {
    return {
        hasPerson: Model.Candidates.HasPerson.Descriptor.stream(zone, binding),
        hasProject: Model.Candidates.HasProject.Descriptor.stream(zone, binding),
        hasRole: Model.Candidates.HasRole.Descriptor.stream(zone, binding),
        hasBrandStatus: Model.Candidates.HasBrandStatus.Descriptor.stream(zone, binding).scalar,
        hasTalentStatus: Model.Candidates.HasTalentStatus.Descriptor.stream(zone, binding).scalar,
        hasOfferRate: Model.Candidates.HasOfferRate.Descriptor.stream(zone, binding).scalar
    }
}

export function extend(zone: Stores.Zone, binding: { me: Objects.Binding<string>, project: Objects.Binding<string>, role: Objects.Binding<string> },
    options: { person?: Objects.Binding<string>, candidate?: Objects.Binding<string>, rate?: Domains.Base.Parseable<Domains.Financial._PayRange> }
) {
    const { hasCandidates } = useCandidates(zone, binding);
    return {
        allow: true,
        exec: () => {
            const candidate = options.candidate || Objects.Binding.from_bound(faker.datatype.uuid());
            const { hasPerson, hasProject, hasRole, hasBrandStatus, hasOfferRate } = useOffer(zone, { candidate });
            if(!options.candidate) {
                hasCandidates.stateful().insert({ role: binding.role, candidate });
                hasPerson.stateful().assign([{ candidate, person: options.person }]);
                hasProject.stateful().assign([{ candidate, project: binding.project }]);
                hasRole.stateful().assign([{ candidate, role: binding.role }]);
            }
            hasBrandStatus.stateful().assign('Accept');
            options.rate && hasOfferRate.stateful().assign(options.rate);
        }
    }
}

export function retract(zone: Stores.Zone, binding: { me: Objects.Binding<string>, project: Objects.Binding<string>, role: Objects.Binding<string> },
    options: { person?: Objects.Binding<string>, candidate?: Objects.Binding<string> }
) {
    const { hasCandidates } = useCandidates(zone, binding);
    return {
        allow: true,
        exec: () => {
            const candidate = options.candidate || Objects.Binding.from_bound(faker.datatype.uuid());
            const { hasProject, hasPerson, hasBrandStatus, hasOfferRate } = useOffer(zone, { candidate });
            if(!options.candidate) {
                hasCandidates.stateful().insert({ role: binding.role, candidate });
                hasProject.stateful().assign([{ candidate, project: binding.project }]);
                hasPerson.stateful().assign([{ candidate, person: options.person }]);
            }
            hasBrandStatus.stateful().assign('Reject');
        }
    }
}

export function accept(zone: Stores.Zone, binding: { me: Objects.Binding<string>, project: Objects.Binding<string>, role: Objects.Binding<string> },
    options: { person?: Objects.Binding<string>, candidate?: Objects.Binding<string> }
) {
    const { hasCandidates } = useCandidates(zone, binding);
    return {
        allow: true,
        exec: () => {
            const candidate = options.candidate || Objects.Binding.from_bound(faker.datatype.uuid());
            const { hasProject, hasPerson, hasTalentStatus, hasOfferRate } = useOffer(zone, { candidate });
            if(!options.candidate) {
                hasCandidates.stateful().insert({ role: binding.role, candidate });
                hasProject.stateful().assign([{ candidate, project: binding.project }]);
                hasPerson.stateful().assign([{ candidate, person: options.person }]);
            }
            hasTalentStatus.stateful().assign('Accept');
        }
    }
}

export function reject(zone: Stores.Zone, binding: { me: Objects.Binding<string>, project: Objects.Binding<string>, role: Objects.Binding<string> },
    options: { person?: Objects.Binding<string>, candidate?: Objects.Binding<string> }
) {
    const { hasCandidates } = useCandidates(zone, binding);
    return {
        allow: true,
        exec: () => {
            const candidate = options.candidate || Objects.Binding.from_bound(faker.datatype.uuid());
            const { hasProject, hasPerson, hasTalentStatus, hasOfferRate } = useOffer(zone, { candidate });
            if(!options.candidate) {
                hasCandidates.stateful().insert({ role: binding.role, candidate });
                hasProject.stateful().assign([{ candidate, project: binding.project }]);
                hasPerson.stateful().assign([{ candidate, person: options.person }]);
            }
            hasTalentStatus.stateful().assign('Reject');
        }
    }
}


