import faker from 'faker';
import { Dialogues, Definitions, Objects, Properties, Relations, Values } from '@pitaman71/omniglot-live-data';
import { Domains, Modules, WrapFaker } from '@pitaman71/omniglot-live-domains';
import * as Elevated from '@pitaman71/omniglot-introspect';

export const directory = new Definitions.Directory();
// noun Locations.Location.Noun

export interface BindingType extends Objects.BindingType<string> {
    the: Objects.Binding<string>
}
export class Descriptor {
    canonicalName: string = `generated.Locations.Location.Noun`;
    build(builder: Relations.Builder<BindingType>): void {
        builder.object('the');
        builder.set();
    }
}
        
