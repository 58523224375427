import React from 'react';
import { kilomega } from 'kilomega';
import * as HeroIcons from '../../HeroIcons';

import { Dot } from 'react-animated-dots';

import {  makeStyles, Button, Tab, Tabs  } from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import * as NounProject from '../../NounProject';

import * as XLayout from '@pitaman71/react-explicit-layout';
import { Objects, Stores } from '@pitaman71/omniglot-live-data';
import { Database, MaterialUi, Navigation, Propose, Styler, Views } from '@pitaman71/omniglot-live-react';

import * as Model from 'models/autosrc/swivell/talent_marketplace';
import * as Inspectors from '../../Inspectors';
import * as Modes from '../../Modes';
import * as Themes from '../../Themes';
import * as Whoami from '../../Whoami';

import { ViewIncludes } from '../Content';
import { ViewSubject } from '../Discussion';

import * as ViewHasRoles from './ViewHasRoles';
import { ViewMoodBoard } from '.';
import { Authorize, AsActions, AsDelete, AsHeader, AsOverview, useAccess } from './ViewProject';

import config from '../../config.json'

const baseURI = process.env.SWIVELL_SERVICES || ( process.env.NODE_ENV !== 'production' ? 'http://localhost:8080/dev' : config.services['app.swivell'].backend.baseURI );

export interface Binding extends Objects.BindingType<string> {
    project: Objects.Binding<string>
}
export let pollInterval = { ms: 30000 };

function _Count(props: {
    value: undefined|number
}) {
    if(props.value === undefined) return <Styler.Body><Dot>.</Dot><Dot>.</Dot><Dot>.</Dot></Styler.Body>;
    const asKM = kilomega(props.value);
    return <div>{Math.floor((asKM.amount * 10)) / 10}{asKM.symbol}</div>
}

export type ProfileTabValues = 'qualifications'|'portfolio'|'availability';

function _Sections(props: {
    binding: Binding,
    zone: Stores.Zone,
    allowGalleryEdit: boolean,
    isInvitation: boolean,
    mode?: ProfileTabValues
}) {
    const navigation = React.useContext(Navigation.Context);
    const whoami = React.useContext(Whoami.Context);
    const me = whoami?.person;
    if(!me) throw new Error('Missing Context');
    const isMe = me?.objectId === props.binding.project.objectId;
    const access = useAccess(props.binding);

    const hasAvatar = Propose.useScalarProperty(Model.Projects.HasAvatar.Descriptor.stream(props.zone, { project: props.binding.project }).scalar);
    const hasDescription = Propose.useScalarProperty(Model.Projects.HasDescription.Descriptor.stream(props.zone, { project: props.binding.project }).scalar);
    const hasRoles = Propose.useRelation(Model.Projects.HasRoles.Descriptor.stream(props.zone, { project: props.binding.project }));

    const toGallery = () => navigation.forward(ViewMoodBoard.AsMoodBoard.asPath().to({ project: props.binding.project }));
    
    return (<Authorize access={access} mode='view'>
        <XLayout.Stack.South style={{ height: '100%'}}>
            <div style={{ flex: '0 0 auto', padding: '1em'}}>
                <AsHeader binding={props.binding} title="Profile"/>
                <AsActions binding={props.binding}/>
            </div>
            <XLayout.Stack.South id="project-profile" style={{ flex: '1', padding: '1em'}} scroll={true}>
                <MaterialUi.Media.ViewAsset.AsDownloader
                    baseURI={baseURI}
                    style={{ width: "100%" }} 
                    value={hasAvatar.value || {}} 
                />
                <Styler.Section prefix="projects-viewprofile-description" style={{ padding: '1em' }}>
                    <Styler.Body align='left'>
                        <p>{ hasDescription.value }</p>
                    </Styler.Body>
                </Styler.Section>
                <div style={{ width: '100%', height: '60%'}}>
                    <ViewIncludes.AsGrid
                        binding={{ topic: props.binding.project }}
                        shape={{ rows: 1, columns: 2 }}
                        major='horizontal'
                        onClick={toGallery}
                    />
                </div>
                <Styler.Section prefix="projects-viewprofile-skills" style={{ display: hasRoles.entries.length === 0 ? 'none' : undefined, padding: '1em' }}>
                    <Styler.Heading align="left">Roles</Styler.Heading>
                    <Styler.Body align="left">
                        <ViewHasRoles.AsChips binding={{ project: props.binding.project}} />
                    </Styler.Body>
                </Styler.Section>
                <div style={{ width: '100%', height: '60%'}}>
                    <ViewIncludes.AsGrid
                        binding={{ topic: props.binding.project }}
                        shape={{ rows: 1, columns: 2 }}
                        skip={2}
                    />
                </div>
            </XLayout.Stack.South>
        </XLayout.Stack.South>
        </Authorize>
    );
}

function _Card(props: {
    prefix: string,
    binding: Binding,
    zone: Stores.Zone,
    allowGalleryEdit: boolean,
    isInvitation: boolean,
    mode?: ProfileTabValues
}) {
    return <div style={{height: '100%', width: '100%', padding: '1em' }}>
        <_Sections {...props}/>
    </div>;
}

export const AsProfile = new Views.Factory<Binding, Model.Authority.Tags.ValueType>(['projects', 'profile'], ['project'], ((binding, factory) => new class implements Views.OfGoals.Plugin {
    key(separator: string) { return factory.asPath().key(separator) }
    route() { return factory.asPath().to(binding) }    
    controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin, options?: { ref?: React.RefObject<HTMLButtonElement> }) => JSX.Element) { return <React.Fragment></React.Fragment> }
    icon(props: {mode: Views.OfControls.Modes }): JSX.Element { return <HeroIcons.Identification variant={ props.mode === Views.OfControls.Modes.Selected ? "solid" : "outline" }/> }
    image(pixelDimensions: { width: number, height: number }, imageProps: React.CSSProperties) { return undefined; }
    label(truncate?: { maxChars?: number }): JSX.Element { return  (<span>Profile</span>) }
    summary(options: { onClick: (editMode: boolean) => void, zeroPlaceholder?: () => JSX.Element }): JSX.Element { return  (<span>Preview Profile</span>) }
    fields(): Views.OfFields.Plugin[] { return [{
        render: () => <Database.AsEditor peerId="Projects.ViewProfile.AsProfile" pollInterval={pollInterval} render={zone => <_Card prefix="project-profile" zone={zone} binding={binding} allowGalleryEdit={true} isInvitation={false} mode={'portfolio'}/>} />
    }] }
}), Modes.AnyMode);


Views.All.push(AsProfile);
