import faker from 'faker';
import { Dialogues, Definitions, Objects, Properties, Relations, Values } from '@pitaman71/omniglot-live-data';
import { Domains, Modules, WrapFaker } from '@pitaman71/omniglot-live-domains';
import * as Elevated from '@pitaman71/omniglot-introspect';

export const directory = new Definitions.Directory();
// property Persons.HasTalentStats

const Domain: Elevated.Domain<{ views: number, projects: number, experience: number }> = WrapFaker.OfJSON('Content.HasTalentStats', () => { return { views: faker.datatype.number(), projects: faker.datatype.number(), experience: faker.datatype.number() } }).domain();
export type TypeParams = {
    Binding: { person: Objects.Binding<string> },
    Value: { views: number, projects: number, experience: number },
    Domain: typeof Domain
}
export const Descriptor = new class _Descriptor extends Properties.Descriptor<TypeParams> {
    canonicalName = 'generated.Persons.HasTalentStats';
    build(builder: Properties.Builder<TypeParams>): void {
        builder.object('person');
        builder.measure(WrapFaker.OfJSON('Content.HasTalentStats', () => { return { views: faker.datatype.number(), projects: faker.datatype.number(), experience: faker.datatype.number() } }).domain());
        builder.scalar();
    }
};

directory.descriptors.properties.set(Descriptor.canonicalName, Descriptor);
        
