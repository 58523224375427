import React from 'react';

import { Typography } from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';

import { Dot } from 'react-animated-dots';

import * as XLayout from '@pitaman71/react-explicit-layout';
import { Objects, Stores } from '@pitaman71/omniglot-live-data';
import { Database, MaterialUi, Navigation, Propose, Styler, Views } from '@pitaman71/omniglot-live-react';

import * as Modes from '../../Modes';
import * as Themes from '../../Themes';
import * as Whoami from '../../Whoami';
import { ViewPerson } from '../Persons';
import * as ViewPersonProfile from '../Persons/ViewProfile';
import { ViewProject } from '../Projects';
import { ViewSubject } from '.';
import * as Model from 'models/autosrc/swivell/talent_marketplace';
import { Access, Controller } from 'models/src/Social';

interface BindPerson extends Objects.BindingType<string> { person: Objects.Binding<string> };
interface BindProject extends Objects.BindingType<string> { project: Objects.Binding<string> };

export function _CheckPerson(props: {
    binding: BindPerson
}) {
    const whoami = React.useContext(Whoami.Context);
    const { zone } = React.useContext(Database.Context);
    const navigation = React.useContext(Navigation.Context);
    const [ busy, setBusy ] = React.useState(true);
    const me = whoami.person;
    if(!zone || !me) throw new Error('Missing context');

    const hasConnectionStatus = Propose.useSetProperty(React.useMemo(() => Access.hasStatus(zone, { from: me, to: props.binding.person}), [ me, props.binding.person ]));

    const status = {
        connected: hasConnectionStatus.values.has('connected'),
        rejected: hasConnectionStatus.values.has('rejected'),
        rejecting: hasConnectionStatus.values.has('rejecting'),
        invited: hasConnectionStatus.values.has('invited'),
        inviting: hasConnectionStatus.values.has('inviting'),
        possible: !hasConnectionStatus.values.has('connected')
            && !hasConnectionStatus.values.has('rejected')
            && !hasConnectionStatus.values.has('rejecting')
            && !hasConnectionStatus.values.has('invited')
            && !hasConnectionStatus.values.has('inviting')
    };
    return <Styler.Card prefix="connection-confirm" style={{ width: '100%', height: '100%', display: 'flex' }}>
        <XLayout.Center.Both>
            <XLayout.Stack.South scroll={true}>
                <Styler.Heading style={{ margin: '0.5em' }}>Status: { 
                    hasConnectionStatus.values.size === 0 ? 'Checking Connection'
                    : status.connected ? 'Connected'
                    : status.rejected ? 'Rejected'
                    : status.rejecting ? 'Rejected'
                    : status.invited ? 'They Invited You'
                    : status.inviting ? 'You Invited Them'
                    : 'Not Connected'
                } </Styler.Heading>
                <XLayout.Center.Horizontal style={{ margin: '0.5em' }}>
                    <XLayout.Stack.East>
                        <ViewPerson.AsThumbnail zone={zone} binding={{ person: me }} pixelDimensions={{ width: 160, height: 240 }} style={{ marginRight: '0.5em', border: 'solid 2px' }}/>
                        <ViewPerson.AsThumbnail zone={zone} binding={{ person: props.binding.person }} pixelDimensions={{ width: 160, height: 240 }} style={{ marginLeft: '0.5em', border: 'solid 2px' }}/>
                    </XLayout.Stack.East>
                </XLayout.Center.Horizontal>
                <Styler.Body style={{ margin: '0.5em', display: status.rejecting ? undefined : 'none' }}>
                    You passed on their invitation to connect.
                </Styler.Body>
                <Styler.Body style={{ margin: '0.5em', display: status.rejected ? undefined : 'none' }}>
                    They passed on your invitation to connect.
                </Styler.Body>
                <Styler.Body style={{ margin: '0.5em', display: status.inviting ? undefined : 'none' }}>
                    You have invited them, but they have not yet reponded.
                    Any messages you send will be queued until they accept.
                </Styler.Body>
                <Styler.Body style={{ margin: '0.5em', display: status.invited ? undefined : 'none' }}>
                    They have invited you, but you have not responded.
                    Use the buttons below to view their profile and accept or reject their invitation.
                </Styler.Body>
                <div style={{ margin: '0.5em', display: 'inline-block' }}>
                        <Styler.Button
                            variant='contained' 
                            mode={busy ? "disabled" : "forward"} 
                            style={{ display: status.invited ? undefined : 'none', margin: '0.5em', minWidth: '30%' }}
                            onClick={() => navigation.forward(AsConfirmPerson.asPath().to(props.binding))}
                        >
                            Accept Invitation
                        </Styler.Button>
                        <Styler.Button
                            variant='contained' 
                            mode={busy ? "disabled" : "forward"} 
                            style={{ display: status.invited ? undefined : 'none', margin: '0.5em', minWidth: '30%' }}
                            onClick={() => navigation.forward(AsRejectPerson.asPath().to(props.binding))}
                        >
                            Reject Invitation
                        </Styler.Button>
                        <Styler.Button
                            variant='contained' 
                            mode={busy ? "disabled" : "forward"} 
                            style={{ display: status.possible ? undefined : 'none', margin: '0.5em', minWidth: '30%' }}
                            onClick={() => navigation.forward(AsRejectPerson.asPath().to(props.binding))}
                        >
                            Send Invitation
                        </Styler.Button>
                        <Styler.Button
                            variant='contained' 
                            mode={busy ? "disabled" : "forward"} 
                            style={{ display: status.inviting ? undefined : 'none', margin: '0.5em', minWidth: '30%' }}
                            onClick={() => navigation.forward(AsRejectPerson.asPath().to(props.binding))}
                        >
                            Retract Invitation
                        </Styler.Button>
                        <Styler.Button
                            variant='contained' 
                            mode={busy ? "disabled" : "forward"} 
                            style={{ margin: '0.5em', minWidth: '30%' }}
                            onClick={() => navigation.forward(ViewSubject.AsHistory.asPath().to({ subject: ViewSubject.groupToSubject(me, props.binding.person )}))}
                        >
                            Message
                        </Styler.Button>
                        <Styler.Button
                            variant='contained' 
                            mode={busy ? "disabled" : "forward"} 
                            style={{ margin: '0.5em', minWidth: '30%' }}
                            onClick={() => navigation.forward(ViewPersonProfile.AsProfile.asPath().to(props.binding))}
                        >
                            View Profile
                        </Styler.Button>
                </div>
            </XLayout.Stack.South>
        </XLayout.Center.Both>
    </Styler.Card>
}

export function _ConfirmPerson(props: {
    binding: BindPerson,
    sense: 'invite'|'uninvite'
}) {
    const whoami = React.useContext(Whoami.Context);
    const { zone } = React.useContext(Database.Context);
    const navigation = React.useContext(Navigation.Context);
    const [ busy, setBusy ] = React.useState(true);
    const me = whoami.person;
    if(!zone || !me) throw new Error('Missing context');

    React.useEffect(() => {
        const invite = props.sense === 'invite' 
        ? Controller.Invite(zone, { ...props.binding, me, from: me })
        : Controller.Reject(zone, { ...props.binding, me, from: me });
        invite.reset();
        zone.commitAll().then(() => {
            setBusy(false);
        }).catch(err => {
            console.error(err);
            setBusy(false);
        })
    }, []);
    const opacity = /* props.sense === 'uninvite' ? '0.5' : */ undefined;
    return <Styler.Card prefix="connection-confirm" theme={Themes.Red} style={{ width: '100%', height: '100%', display: 'flex' }}>
        <XLayout.Center.Both>
            <XLayout.Stack.South>
                <Styler.Heading style={{ margin: '1em' }}>{ props.sense === 'invite' ? "A perfect fit." : 'No, thanks' } </Styler.Heading>
                <XLayout.Center.Horizontal style={{ margin: '1em' }}>
                    <XLayout.Stack.East>
                        <ViewPerson.AsThumbnail zone={zone} binding={{ person: me }} pixelDimensions={{ width: 160, height: 240 }} style={{ margin: '1em', border: 'solid 2px' }}/>
                        <ViewPerson.AsThumbnail zone={zone} binding={{ person: props.binding.person }} pixelDimensions={{ width: 160, height: 240 }} style={{ margin: '1em', border: 'solid 2px', opacity }}/>
                    </XLayout.Stack.East>
                </XLayout.Center.Horizontal>
                <XLayout.Center.Horizontal style={{ margin: '1em', display: props.sense === 'invite' ? undefined : 'none' }}>
                    <Styler.Control prefix="connect-message" style={{ display: 'inline' }}>
                        <Styler.Button variant='contained' mode={busy ? "disabled" : "forward"} onClick={() => navigation.forward(ViewSubject.AsHistory.asPath().to({ subject: ViewSubject.groupToSubject(me, props.binding.person )}))}><div style={{ display: 'inline'}}>Message <ViewPerson.AsName zone={zone} binding={{ person: props.binding.person }}/></div></Styler.Button>
                    </Styler.Control>
                </XLayout.Center.Horizontal>
                <XLayout.Center.Horizontal style={{ margin: '1em' }}>
                    {
                        busy
                        ? <Styler.Button mode={busy ? "disabled" : "forward"}><Dot>.</Dot><Dot>.</Dot><Dot>.</Dot></Styler.Button>
                        : <Styler.Button mode={busy ? "disabled" : "forward"} onClick={() => navigation.forward('/')}>Back to Discovery</Styler.Button>
                    }
                    
                </XLayout.Center.Horizontal>
            </XLayout.Stack.South>
        </XLayout.Center.Both>
    </Styler.Card>
}

export function _ConfirmProject(props: {
    binding: BindProject,
    sense: 'invite'|'uninvite'
}) {
    const whoami = React.useContext(Whoami.Context);
    const { zone } = React.useContext(Database.Context);
    const navigation = React.useContext(Navigation.Context);
    const [ busy, setBusy ] = React.useState(true);
    const me = whoami.person;
    if(!zone || !me) throw new Error('Missing context');

    React.useEffect(() => {
        const invite = props.sense === 'invite' 
        ? Controller.Invite(zone, { ...props.binding, me, from: me })
        : Controller.Reject(zone, { ...props.binding, me, from: me });
        invite.reset();
        zone.commitAll().then(() => {
            setBusy(false);
        }).catch(err => {
            console.error(err);
            setBusy(false);
        })
    }, []);
    const opacity = /* props.sense === 'uninvite' ? '0.5' : */ undefined;
    return <Styler.Card prefix="connection-confirm" theme={Themes.Red} style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
        <XLayout.Center.Both>
            <XLayout.Stack.South>
                <Styler.Heading style={{ margin: '1em' }}>{ props.sense === 'invite' ? "A perfect fit." : 'No, thanks' } </Styler.Heading>
                <XLayout.Center.Horizontal>
                    <XLayout.Stack.East>
                        <ViewPerson.AsThumbnail zone={zone} binding={{ person: me }} pixelDimensions={{ width: 160, height: 240 }} style={{ margin: '1em', border: 'solid 2px' }}/>
                        <ViewProject.AsCover zone={zone} binding={{ project: props.binding.project }} pixelDimensions={{ width: 160, height: 240 }} style={{ margin: '1em', border: 'solid 2px', opacity }}/>
                    </XLayout.Stack.East>
                </XLayout.Center.Horizontal>
                <XLayout.Center.Horizontal style={{ margin: '1em' }}>
                    <Styler.Button mode={busy ? "disabled" : "forward"} onClick={() => navigation.forward('/')}>Back to Discovery</Styler.Button>
                </XLayout.Center.Horizontal>
            </XLayout.Stack.South>
        </XLayout.Center.Both>
    </Styler.Card>
}

export const AsCheckPerson = new Views.Factory<BindPerson, Model.Authority.Tags.ValueType>(['discussion', 'check', 'person'], ['person'], ((binding, factory) => new class implements Views.OfGoals.Plugin {
    key(separator: string) { return factory.asPath().key(separator) }
    route() { return factory.asPath().to(binding) }    
    controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin, options?: { ref?: React.RefObject<HTMLButtonElement> }) => JSX.Element) { return <React.Fragment></React.Fragment> }
    icon(): JSX.Element { return <MuiIcons.ThumbUpOutlined/> }
    image(pixelDimensions: { width: number, height: number }, imageProps: React.CSSProperties) { return undefined; }
    label(truncate?: { maxChars?: number }): JSX.Element { return  (<span>Delete</span>) }
    summary(options: { onClick: (editMode: boolean) => void, zeroPlaceholder?: () => JSX.Element }): JSX.Element { return  (<span>Invite Person</span>) }
    fields(): Views.OfFields.Plugin[] { return [{
        render: () => 
          <Database.AsEditor peerId="ViewConnections.AsCheckPerson" pollInterval={{ ms: 15000 }} render={zone => <_CheckPerson binding={binding}/>}/>
    }] }
}), Modes.NormalMode);
Views.All.push(AsCheckPerson);

export const AsConfirmPerson = new Views.Factory<BindPerson, Model.Authority.Tags.ValueType>(['discussion', 'confirm', 'person'], ['person'], ((binding, factory) => new class implements Views.OfGoals.Plugin {
    key(separator: string) { return factory.asPath().key(separator) }
    route() { return factory.asPath().to(binding) }    
    controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin, options?: { ref?: React.RefObject<HTMLButtonElement> }) => JSX.Element) { return <React.Fragment></React.Fragment> }
    icon(): JSX.Element { return <MuiIcons.ThumbUpOutlined/> }
    image(pixelDimensions: { width: number, height: number }, imageProps: React.CSSProperties) { return undefined; }
    label(truncate?: { maxChars?: number }): JSX.Element { return  (<span>Delete</span>) }
    summary(options: { onClick: (editMode: boolean) => void, zeroPlaceholder?: () => JSX.Element }): JSX.Element { return  (<span>Invite Person</span>) }
    fields(): Views.OfFields.Plugin[] { return [{
        render: () => 
          <Database.AsEditor peerId="ViewConnections.AsConfirmPerson" pollInterval={{ ms: 15000 }} render={zone => <_ConfirmPerson binding={binding} sense='invite'/>}/>
    }] }
}), Modes.NormalMode);
Views.All.push(AsConfirmPerson);

export const AsRejectPerson = new Views.Factory<BindPerson, Model.Authority.Tags.ValueType>(['discussion', 'reject', 'person'], ['person'], ((binding, factory) => new class implements Views.OfGoals.Plugin {
    key(separator: string) { return factory.asPath().key(separator) }
    route() { return factory.asPath().to(binding) }    
    controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin, options?: { ref?: React.RefObject<HTMLButtonElement> }) => JSX.Element) { return <React.Fragment></React.Fragment> }
    icon(): JSX.Element { return <MuiIcons.ThumbUpOutlined/> }
    image(pixelDimensions: { width: number, height: number }, imageProps: React.CSSProperties) { return undefined; }
    label(truncate?: { maxChars?: number }): JSX.Element { return  (<span>Delete</span>) }
    summary(options: { onClick: (editMode: boolean) => void, zeroPlaceholder?: () => JSX.Element }): JSX.Element { return  (<span>Reject Person</span>) }
    fields(): Views.OfFields.Plugin[] { return [{
        render: () => 
          <Database.AsEditor peerId="ViewConnections.AsRejectPerson" pollInterval={{ ms: 15000 }} render={zone => <_ConfirmPerson binding={binding} sense='uninvite'/>}/>
    }] }
}), Modes.NormalMode);
Views.All.push(AsRejectPerson);

export const AsConfirmProject = new Views.Factory<BindProject, Model.Authority.Tags.ValueType>(['discussion', 'confirm', 'project'], ['project'], ((binding, factory) => new class implements Views.OfGoals.Plugin {
    key(separator: string) { return factory.asPath().key(separator) }
    route() { return factory.asPath().to(binding) }    
    controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin, options?: { ref?: React.RefObject<HTMLButtonElement> }) => JSX.Element) { return <React.Fragment></React.Fragment> }
    icon(): JSX.Element { return <MuiIcons.ThumbUpOutlined/> }
    image(pixelDimensions: { width: number, height: number }, imageProps: React.CSSProperties) { return undefined; }
    label(truncate?: { maxChars?: number }): JSX.Element { return  (<span>Delete</span>) }
    summary(options: { onClick: (editMode: boolean) => void, zeroPlaceholder?: () => JSX.Element }): JSX.Element { return  (<span>Invite Project</span>) }
    fields(): Views.OfFields.Plugin[] { return [{
        render: () => 
          <Database.AsEditor peerId="ViewConnections.AsConfirmProject" pollInterval={{ ms: 15000 }} render={zone => <_ConfirmProject binding={binding} sense='invite'/>}/>
    }] }
}), Modes.NormalMode);
Views.All.push(AsConfirmProject);

export const AsRejectProject = new Views.Factory<BindProject, Model.Authority.Tags.ValueType>(['discussion', 'reject', 'project'], ['project'], ((binding, factory) => new class implements Views.OfGoals.Plugin {
    key(separator: string) { return factory.asPath().key(separator) }
    route() { return factory.asPath().to(binding) }    
    controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin, options?: { ref?: React.RefObject<HTMLButtonElement> }) => JSX.Element) { return <React.Fragment></React.Fragment> }
    icon(): JSX.Element { return <MuiIcons.ThumbUpOutlined/> }
    image(pixelDimensions: { width: number, height: number }, imageProps: React.CSSProperties) { return undefined; }
    label(truncate?: { maxChars?: number }): JSX.Element { return  (<span>Delete</span>) }
    summary(options: { onClick: (editMode: boolean) => void, zeroPlaceholder?: () => JSX.Element }): JSX.Element { return  (<span>Reject Person</span>) }
    fields(): Views.OfFields.Plugin[] { return [{
        render: () => 
          <Database.AsEditor peerId="ViewConnections.AsRejectProject" pollInterval={{ ms: 15000 }} render={zone => <_ConfirmProject binding={binding} sense='uninvite'/>}/>
    }] }
}), Modes.NormalMode);
Views.All.push(AsRejectProject);

export function AsTile(props: {
    zone: Stores.Zone,
    binding: BindPerson,
    size?: 'large'|'medium'|'small',
    style?: React.CSSProperties,
    onClick?: () => void,
}) {
    const whoami = React.useContext(Whoami.Context);
    const { zone } = React.useContext(Database.Context);
    const me = whoami.person;
    if(!zone || !me) throw new Error('Missing context');

    const hasConnectionStatus = Propose.useSetProperty(React.useMemo(() => Access.hasStatus(zone, { from: me, to: props.binding.person }), [me, props.binding.person ]))
    if(hasConnectionStatus.values.has('rejecting')) return <React.Fragment></React.Fragment>;
    const pixelSize = props.size === 'large' ? 300 : props.size === 'small' ? 48 : 125;    

    const textStyle:React.CSSProperties = {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 2,
        display: '-webkit-box'
    };
    return <div onClick={props.onClick} style={{
            ...props.style,
            width: pixelSize,
            height: 'auto',
            display: 'inline-block',
            verticalAlign: 'top'
        }}>
        <ViewPerson.AsAvatar zone={props.zone} binding={{ person: props.binding.person }} pixelDimensions={{ width: pixelSize, height: pixelSize }}/>
        <Styler.Control prefix="viewconnections-astile">
            <Styler.Heading style={textStyle}><ViewPerson.AsBrand zone={props.zone} binding={{ person: props.binding.person }} /></Styler.Heading>
            <Styler.Body style={textStyle}><ViewPerson.AsName zone={props.zone} binding={{ person: props.binding.person }} /></Styler.Body>
        </Styler.Control>
    </div>;
}

export function AsAvatarBrowser(props: {
    zone: Stores.Zone,
    binding: { person: Objects.Binding<string>}
}) {
    const navigation = React.useContext(Navigation.Context);
    const whoami = React.useContext(Whoami.Context);
    const setSelected = (person: Objects.Binding<string>) => {
        whoami?.person && navigation.forward(ViewSubject.AsHistory.asPath().to({ subject: ViewSubject.groupToSubject(whoami?.person, person )}))
    }
    const hasConnections = Propose.useRelation(Model.Social.HasConnection.Descriptor.stream(props.zone, { from: props.binding.person }));

    if(hasConnections.entries.length === 0) {
        return (
            <MaterialUi.Styler.Section prefix="connections-avatar-browser" theme={Themes.Dark}>
                <MaterialUi.Styler.Heading paragraph={true}>No Connections</MaterialUi.Styler.Heading>
                <MaterialUi.Styler.Body paragraph={true}>You are not currently connected to anyone else.</MaterialUi.Styler.Body>
                { 
                    whoami.mode !== 'normal' ? <React.Fragment></React.Fragment> :
                        <MaterialUi.Styler.Body>
                            <p>Your profile has been admitted, you just don't have any connections (yet). </p>
                            <p>Click on the Discover icon below to find new brands and talent and connect.</p>
                        </MaterialUi.Styler.Body>
                    
                }
                { 
                    whoami.mode !== 'lobby' ? <React.Fragment></React.Fragment> : 
                        <MaterialUi.Styler.Body>
                            <p>You are also in Lobby mode, a place where you can build your profile before you have access to the full app. </p>
                            <p>As you add details to your profile, it will be reviewed by Swivell admins.</p>
                            <p>Swivell admins may reach out to you here to provide feedback on your profile to help you obtain approval. </p>
                            <p>No other connections will appear here until your profile is approved to access the full app. </p>
                        </MaterialUi.Styler.Body>
                }
            </MaterialUi.Styler.Section>
        );
    }
    return (
        <MaterialUi.Styler.Section prefix="connections-avatar-browser" theme={Themes.Dark} style={{ maxWidth: '100%', maxHeight: '100%'}}>
            <XLayout.Stack.South id="discussion-connections" scroll={true} style={{ display: 'inline-block'}}>
                { hasConnections.entries.map(entry => <AsTile zone={props.zone} binding={{ from: props.binding.person, person: entry.to }} onClick={() => setSelected(entry.to)} style={{ margin: '2em'}}/>) }
            </XLayout.Stack.South>
        </MaterialUi.Styler.Section>
    )            
}
