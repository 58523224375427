import { Definitions } from '@pitaman71/omniglot-live-data';

import * as HasPreferenceType from './HasPreferenceType';
import * as HasHeritagePopulation from './HasHeritagePopulation';
import * as AudienceTargeting from './AudienceTargeting';
import * as OffersContentTypes from './OffersContentTypes';
import * as HasGenderType from './HasGenderType';
import * as HasSkills from './HasSkills';
import * as OffersProductCategories from './OffersProductCategories';
import * as HasPastBrandAssociations from './HasPastBrandAssociations';
import * as OffersAudienceTargeting from './OffersAudienceTargeting';
import * as OffersBlendedRate from './OffersBlendedRate';
const directory = Definitions.Directory.from(HasPreferenceType.directory,HasHeritagePopulation.directory,AudienceTargeting.directory,OffersContentTypes.directory,HasGenderType.directory,HasSkills.directory,OffersProductCategories.directory,HasPastBrandAssociations.directory,OffersAudienceTargeting.directory,OffersBlendedRate.directory)
export { directory, HasPreferenceType, HasHeritagePopulation, AudienceTargeting, OffersContentTypes, HasGenderType, HasSkills, OffersProductCategories, HasPastBrandAssociations, OffersAudienceTargeting, OffersBlendedRate }; 
