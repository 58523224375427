import faker from 'faker';
import { Dialogues, Definitions, Objects, Properties, Relations, Values } from '@pitaman71/omniglot-live-data';
import { Domains, Modules, WrapFaker } from '@pitaman71/omniglot-live-domains';
import * as Elevated from '@pitaman71/omniglot-introspect';

export const directory = new Definitions.Directory();
// property Projects.HasAvatar

const Domain: Elevated.Domain<Domains.Media._Asset> = WrapFaker.OfJSON('Projects.HasAvatar', () => { return { pixelDimensions: { width: 180, height: 180 }, uri: faker.image.business(180, 180)} }).domain();
export type TypeParams = {
    Binding: { project: Objects.Binding<string> },
    Value: Domains.Media._Asset,
    Domain: typeof Domain
}
export const Descriptor = new class _Descriptor extends Properties.Descriptor<TypeParams> {
    canonicalName = 'generated.Projects.HasAvatar';
    build(builder: Properties.Builder<TypeParams>): void {
        builder.object('project');
        builder.measure(WrapFaker.OfJSON('Projects.HasAvatar', () => { return { pixelDimensions: { width: 180, height: 180 }, uri: faker.image.business(180, 180)} }).domain());
        builder.scalar();
    }
};

directory.descriptors.properties.set(Descriptor.canonicalName, Descriptor);
        
