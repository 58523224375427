import React from 'react';

import { makeStyles, BottomNavigation, BottomNavigationAction, ImageListItem } from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';

import { Clients, Stores, Streams } from '@pitaman71/omniglot-live-data';
import { Navigation, Propose, Views } from '@pitaman71/omniglot-live-react';
import * as XLayout from '@pitaman71/react-explicit-layout'

import config from '../../config.json'

import * as Model from 'models/autosrc/swivell/talent_marketplace';
import { Media } from '../../Controls';

export let pollInterval = { ms: 15000 };

export type ImageGenerator = (height?: number, width?: number, randomize?: boolean) => string;

export function AsThumbnail(props: {
    index: number,
    numColumns: number,
    gallery: {
        pixelDimensions: {
            height: number,
            width: number
        }
    },
    image?: {
        pixelDimensions: {
            height: number,
            width: number
        },
        uri?: string
    },
    
}) {
    if(!props.image) return <React.Fragment></React.Fragment>;
    const gap = 1;
    const scale = !props.image ? undefined : (props.gallery.pixelDimensions.width - ((3 * props.numColumns) * gap)) / (props.numColumns * props.image.pixelDimensions.width)
    const maxHeight = scale === undefined || !props.image ? undefined : scale * props.image?.pixelDimensions.height;
    return (
        <ImageListItem key={props.index} cols={1}>
            <Media.AsDownloader value={props.image} pixelDimensions={props.image?.pixelDimensions} style={{ maxHeight, marginLeft: gap, marginRight: gap }}/>
        </ImageListItem>
    );
}

export class View implements Views.OfGoals.Plugin {
    _zone: Stores.Zone;
    _hasMedia: Streams.PropertyStream<Model.Content.HasMedia.TypeParams>;
    _controls: Views.OfControls.Plugin[];
    constructor(
        zone: Stores.Zone,
        hasMedia: Streams.PropertyStream<Model.Content.HasMedia.TypeParams>,
        controls: Views.OfControls.Plugin[] = []
    ) {
        this._zone = zone;
        this._hasMedia = hasMedia;
        this._controls = [ ... controls ];
    }

    key(separator: string) { return ['content', 'hasMedia' ].join(separator) }
    route() { return '/content/hasMedia' }
    controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin) => JSX.Element) { return (
        <React.Fragment>{this._controls.filter(filter).map(render)}</React.Fragment>
    ) }
    icon(): JSX.Element { return <MuiIcons.PhotoOutlined/> }
    image(pixelDimensions: { width: number, height: number }, style: React.CSSProperties) {
        return <Propose.PropertyData
            stream={this._hasMedia}
            
            render={{
                scalar: (value, client) => {
                    return (
                        <Media.AsDownloader pixelDimensions={pixelDimensions} style={style} value={value}/>
                    );
                }
            }}
        />;
    }
    label(truncate?: { maxChars?: number }): JSX.Element { 
        return  (<span>Media Board</span>);
    }
    summary(options: { onClick: (editMode:boolean) => void, zeroPlaceholder?: () => JSX.Element }): JSX.Element { 
        return <React.Fragment></React.Fragment>
    }
    fields(): Views.OfFields.Plugin[] { 
        return [ ]
    }
}

export class Edit extends View {
    image(pixelDimensions: { width: number, height: number }, style: React.CSSProperties) {
        return <Propose.PropertyData
            stream={this._hasMedia}
            render={{
                scalar: (value, client) => {
                    return (
                        <Media.AsDownloader pixelDimensions={pixelDimensions} style={style} value={value}/>
                    );
                }
            }}
        />;
    }
}

export function Assign(props: {
    view: Views.OfGoals.Plugin,
    zone: Stores.Zone,
    binding: Model.Content.HasMedia.TypeParams['Binding'],
    pixelDimensions: { width: number, height: number }, 
    on: {
        confirm: () => Promise<any>,
        cancel: () => Promise<any>
    };
}) {
    const controller = React.useContext(Navigation.Context);
    const hasMedia = props.zone.streams().property(Model.Content.HasMedia.Descriptor.bind(props.binding));
    return (<Propose.PropertyData
        stream={hasMedia}
        render={{
            scalar: (value, client) => {
                const on = {
                    assign: (value_: Model.Content.HasMedia.TypeParams['Value']) => {
                        client?.assign(value_);
                        return Promise.resolve();
                    },
                    clear: () => {
                        client?.clear();
                        return Promise.resolve();
                    },
                    ...props.on
                };
                return <Media.AsUploader view={props.view} pixelDimensions={props.pixelDimensions} style={{}} on={on}/>    
            }
        }}
    />);
}

export function Clear(props: {
    view: Views.OfGoals.Plugin,
    zone: Stores.Zone,
    binding: Model.Content.HasMedia.TypeParams['Binding'],
    pixelDimensions: { width: number, height: number }, 
    on: {
        confirm: () => Promise<any>,
        cancel: () => Promise<any>
    };
}) {
    const controller = React.useContext(Navigation.Context);
    const hasMedia = props.zone.streams().property(Model.Content.HasMedia.Descriptor.bind(props.binding));
    return (<Propose.PropertyData
        stream={hasMedia}
        render={{
            scalar: (value, client) => {
                const on = {
                    ...props.on,
                    confirm: () => {
                        client?.clear();
                        props.on.confirm();
                    }
                };
                return <Media.AsDeleter view={props.view} pixelDimensions={props.pixelDimensions} value={value} on={props.on}/>    
            }
        }}
    />);
}
