import { Definitions } from '@pitaman71/omniglot-live-data';

import * as Aesthetics from './Aesthetics';
import * as Organizations from './Organizations';
import * as Products from './Products';
import * as Content from './Content';
import * as Sexuality from './Sexuality';
import * as Owns from './Owns';
import * as Creator from './Creator';
import * as Authority from './Authority';
import * as Affiliations from './Affiliations';
import * as Assignment from './Assignment';
import * as Markets from './Markets';
import * as Discussion from './Discussion';
import * as Candidates from './Candidates';
import * as Ratings from './Ratings';
import * as Projects from './Projects';
import * as Social from './Social';
import * as Locations from './Locations';
import * as ForClient from './ForClient';
import * as Titles from './Titles';
import * as Talents from './Talents';
import * as Trades from './Trades';
import * as Heritage from './Heritage';
import * as Requires from './Requires';
import * as Markup from './Markup';
import * as Agents from './Agents';
import * as Community from './Community';
import * as HasMood from './HasMood';
import * as Employment from './Employment';
import * as PushNotifications from './PushNotifications';
import * as Events from './Events';
import * as HasRole from './HasRole';
import * as Roles from './Roles';
import * as SocialMedia from './SocialMedia';
import * as Identity from './Identity';
import * as Persons from './Persons';
import * as Assigns from './Assigns';
const directory = Definitions.Directory.from(Aesthetics.directory,Organizations.directory,Products.directory,Content.directory,Sexuality.directory,Owns.directory,Creator.directory,Authority.directory,Affiliations.directory,Assignment.directory,Markets.directory,Discussion.directory,Candidates.directory,Ratings.directory,Projects.directory,Social.directory,Locations.directory,ForClient.directory,Titles.directory,Talents.directory,Trades.directory,Heritage.directory,Requires.directory,Markup.directory,Agents.directory,Community.directory,HasMood.directory,Employment.directory,PushNotifications.directory,Events.directory,HasRole.directory,Roles.directory,SocialMedia.directory,Identity.directory,Persons.directory,Assigns.directory)
export { directory, Aesthetics, Organizations, Products, Content, Sexuality, Owns, Creator, Authority, Affiliations, Assignment, Markets, Discussion, Candidates, Ratings, Projects, Social, Locations, ForClient, Titles, Talents, Trades, Heritage, Requires, Markup, Agents, Community, HasMood, Employment, PushNotifications, Events, HasRole, Roles, SocialMedia, Identity, Persons, Assigns }; 
