import faker from 'faker';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactDOMServer from "react-dom/server";

import { makeStyles, Avatar, Chip, Table, TableBody, TableCell, TableRow, TextField } from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { Clients, Objects, Stores, Streams } from '@pitaman71/omniglot-live-data';
import { Database, Navigation, Propose, Styler, Views } from '@pitaman71/omniglot-live-react';
import * as XLayout from '@pitaman71/react-explicit-layout';

import * as Model from 'models/autosrc/swivell/talent_marketplace';

import * as ProjectController from 'models/src/Projects/Controller';

import * as ViewProject from './ViewProject';
import * as ViewRole from '../Roles/ViewRole';
import { Media } from '..';
import * as NounProject from '../../NounProject';
import * as Themes from '../../Themes';
import * as Whoami from '../../Whoami';

import * as d3 from 'd3';
import { OrgChart } from 'd3-org-chart';

export let pollInterval = { ms: 30000 };

const useStyles = makeStyles((theme) => ({
  d3DiagramContainer: {
      display: "flex",
      overflowY: "auto",
      flex: "1",
      '& div': { 
          height: 'auto'
      },
      '& .node-button-g': {
          'display': 'none'
      }
  }, title: {
    display: "flex",
    flex: "0 0 auto"
  }
}));

export interface From extends Objects.BindingType<string> {
  project: Objects.Binding<string>
};
export interface To extends From {
  role: Objects.Binding<string>
}

const classes = {
    content: {
      display: 'flex',
      justifyContent: 'center',
      '& > *': {
        margin: "0.5em",
      },
    },
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: "3em",
      },
    },
    chip: {
      margin: "0.25em 0.5em 0.25em 0.5em",
    }
};

export function AsChips(props: {
    binding: From,
    zeroPlaceholder?: () => JSX.Element,
    onClick?: () => void
}) {
  const { zone } = React.useContext(Database.Context);
  if(!zone) throw new Error('Missing Context');
    const hasRoles = Propose.useRelation(Model.Projects.HasRoles.Descriptor.stream(zone, props.binding));
    if(!!props.zeroPlaceholder && hasRoles.entries.length == 0) return <div onClick={props.onClick}><Styler.Chips style={classes.chip} size="small">{props.zeroPlaceholder()}</Styler.Chips></div>;
    return <Styler.Section prefix="project-preview-roles" theme={Themes.Gold}><div onClick={props.onClick}><Styler.Chips style={classes.chip} size="small">{hasRoles.entries.map(entry => <ViewRole.AsSummary zone={zone} binding={{ role: entry.role }}/> )}</Styler.Chips></div></Styler.Section>;
}

function _makeDOMID(prefix: string, orig: undefined|string) {
    const suffix = orig === undefined ? "?" : orig.replace(new RegExp(/\./g), '-')||'?';
    return prefix+'-'+suffix;
}

function _AsAdd(props: {
    zone: Stores.Zone,
    binding: From,
    pixelDimensions: { height: number, width: number },
  }) {
    const navigation = React.useContext(Navigation.Context);
    const pixelDimensions = { width: props.pixelDimensions.width * 0.8, height: props.pixelDimensions.width * 0.8 };
    return (
        <XLayout.Stack.South>
            <XLayout.Center.Horizontal><Avatar style={pixelDimensions}><MuiIcons.PersonOutlined/></Avatar></XLayout.Center.Horizontal>            
            <span style={{ fontSize: '3em' }}><b>+</b></span>
        </XLayout.Stack.South>
    );
}

function _AsTeamDiagram(props: {
  zone: Stores.Zone;
  binding: From,
  entries: Model.Projects.HasRoles.BindingType[]
}) {
  const navigation = React.useContext(Navigation.Context);
  const d3Container = React.useRef<HTMLDivElement>(null);
  let chart:null|OrgChart<{ id: string, parentId?: string, project?: Objects.Binding<string>, role?: Objects.Binding<string>, add?: boolean}> = null;
  
  const classes = useStyles();

  const onClick=() => {};
  const viewRole=(role: Objects.Binding<string>) => {
    navigation.forward(ViewRole.Inspect.asPath().to({project: props.binding.project, role}));
  }
  const addRole=() => {
    const binding = { ...props.binding, role: Objects.Binding.from_bound(faker.datatype.uuid())};
    navigation.forward(Add.asPath().to(binding))
  };

  const tileDimensions = { width: 150, height: 200 };
  // We need to manipulate DOM
  React.useLayoutEffect(() => {
    if (d3Container.current) {
      if (!chart) {
        chart = new OrgChart();
      }
      chart
      .container(d3Container.current as any)
      .data([
        { 
          id: props.binding.project.objectId || "?",
          project: props.binding.project
        },
        ...props.entries.map((entry,index) => ({
          id: entry.role.objectId || "?",
          role: entry.role,
          parentId: props.binding.project.objectId || "?"
        })) /*, 
        { 
          id: 'add-role',
          add: true,
          parentId: props.binding.project.objectId || "?"
        }*/
      ])
      .nodeWidth((d:any) => tileDimensions.width)
      .nodeHeight((d:any) => tileDimensions.height)
      .nodeContent((node) => {
          return ReactDOMServer.renderToStaticMarkup(<div id={_makeDOMID(!!node.data.role ? 'role' : !!node.data.project ? 'project' : 'add', node.data.id)} style={{ height: "100%", width: "100%", overflow: "hidden", position: "fixed"}}>
          </div>);
      })
      .render();
      chart.expandAll();
      d3.selectAll('svg').on("mousedown.zoom", null).on("wheel.zoom", null);
      d3.select('svg').selectAll('rect').on('click', onClick);
      let node = d3.select('#'+_makeDOMID('project', props.binding.project?.objectId));
      ReactDOM.render(
        <Media.Provide>
        <ViewProject.AsTile {...props} pixelDimensions={tileDimensions}/>
        </Media.Provide>,
        node.node() as any
      );
      props.entries.forEach(entry => {
        node = d3.select('#'+_makeDOMID('role', entry.role.objectId));
        node.on("click", () => viewRole(entry.role))
        ReactDOM.render(
            <Media.Provide>
            <ViewRole.AsTile zone={props.zone} binding={{ ...props.binding, role: entry.role }} pixelDimensions={tileDimensions}/> 
            </Media.Provide>
            , node.node() as any
        )
      });
      /*node = d3.select('#'+_makeDOMID('add', 'add-role'));
      node.on("click", () => addRole())
      ReactDOM.render(
        <_AsAdd {...props} pixelDimensions={{ width: 120, height: 170 }}/>,
        node.node() as any
      );*/
    }
  }, [d3Container.current, props.entries]);

  return (
      <div className={classes.d3DiagramContainer}>
          <div style={{ width: '100%' }} ref={d3Container}/>
      </div>
  );
}

export const AsTeamDiagram = new Views.Factory<From, Model.Authority.Tags.ValueType>([ 'projects', 'hasRoles', 'teamDiagram' ], ['project'], (binding, factory) => new class implements Views.OfGoals.Plugin {
  key(separator: string) { return factory.asPath().key(separator) }
  route() { return factory.asPath().to(binding) }    
  controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin, options?: { ref?: React.RefObject<HTMLButtonElement> }) => JSX.Element) { return <React.Fragment></React.Fragment> }
  icon() { return <NounProject.Team/> }
  image(pixelDimensions: { width: number, height: number }, imageProps: React.CSSProperties) {
      return undefined
  }
  label(truncate?: { maxChars?: number }) {
      return <span>Team Diagram</span>
  }
  summary(options: { onClick: (editMode:boolean) => void, zeroPlaceholder?: () => JSX.Element }) {
      return <div onClick={() => options.onClick(false)}>{this.label()}</div>
  }
  fields() { return [ { 
      render: () =>  (
          <Database.AsEditor peerId="ViewHasRoles.AsTeamDiagram" pollInterval={pollInterval} render={zone => {
            const hasProjects = zone.streams().relation(Model.Projects.HasRoles.Descriptor.bindAnchor(binding));
            return <Propose.RelationEntries stream={hasProjects} render={
              (entries, client) => <_AsTeamDiagram zone={zone} binding={binding} entries={entries}/>
            }/>;          
          }}/>
      )
  }] }
});

function _Add(props: {
  zone: Stores.Zone,
  binding: To
}) {
  const navigation = React.useContext(Navigation.Context);
  return <ViewRole.AsProfile {...props} on={{
    add: () => {
      const hasRoles = props.zone.streams().relation(Model.Projects.HasRoles.Descriptor.bindAnchor(props.binding));
      hasRoles.stateful()?.insert({...props.binding})
      return props.zone.commitAll().then(() => navigation.forward(AsTeamDiagram.asPath().to(props.binding)));
    }, cancel: () => {
      navigation.forward(AsTeamDiagram.asPath().to(props.binding))
      return Promise.resolve();
    }
  }}/>
}

export const Add = new Views.Factory<To, Model.Authority.Tags.ValueType>([ 'role', 'add' ], ['role', 'project'], (binding, factory) => new class implements Views.OfGoals.Plugin {
  key(separator: string) { return factory.asPath().key(separator) }
  route() { return factory.asPath().to(binding) }    
  controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin, options?: { ref?: React.RefObject<HTMLButtonElement> }) => JSX.Element) { return <React.Fragment></React.Fragment> }
  icon() { return <NounProject.Availability/> }
  image(pixelDimensions: { width: number, height: number }, imageProps: React.CSSProperties) {
      return undefined
  }
  label(truncate?: { maxChars?: number }) {
      return <span>View Project Role</span>
  }
  summary(options: { onClick: (editMode:boolean) => void, zeroPlaceholder?: () => JSX.Element }) {
      return <div onClick={() => options.onClick(false)}>{this.label()}</div>
  }
  fields() { return [ { 
      render: () =>  (
          <Database.AsEditor peerId="ViewHasRoles.Add" pollInterval={pollInterval} render={zone => <_Add zone={zone} binding={binding}/>} />
      )
  }] }
});

function _Delete(props: {
  zone: Stores.Zone,
  binding: To
}) {
  const navigation = React.useContext(Navigation.Context);
  const on = {
    delete: () => {
      const hasRoles = props.zone.streams().relation(Model.Projects.HasRoles.Descriptor.bindAnchor(props.binding));
      hasRoles.stateful()?.remove({...props.binding})
      return props.zone.commitAll().then(() => navigation.forward(AsTeamDiagram.asPath().to(props.binding)));
    }, cancel: () => {
      navigation.forward(AsTeamDiagram.asPath().to(props.binding))
      return Promise.resolve();
    }
  };

  return <React.Fragment>
      <XLayout.Stack.South style={{ alignItems: 'center' }}>
        <ViewRole.AsTile {...props} pixelDimensions={{ width: 140, height: 170 }}/>
        <XLayout.Stack.East>
          <Views.OfControls.AsRow controls={[
                  {
                      class: Views.OfControls.Direction.Backward,
                      id: 'cancel',
                      label: () => 'Cancel',
                      icon: () => <MuiIcons.ArrowLeftOutlined/>,
                      onClick: on.cancel
                  },{
                      class: Views.OfControls.Direction.Forward,
                      id: 'delete',
                      label: () => 'Delete Role',
                      icon: () => <MuiIcons.DeleteOutlined/>,
                      onClick: on.delete
                  }
              ]} render={controlProps => <Views.OfControls.AsButton {...controlProps} variant="outlined"/>} />
        </XLayout.Stack.East>
      </XLayout.Stack.South>
  </React.Fragment>
}

export const Delete = new Views.Factory<To, Model.Authority.Tags.ValueType>([ 'role', 'delete' ], ['role', 'project'], (binding, factory) => new class implements Views.OfGoals.Plugin {
  key(separator: string) { return factory.asPath().key(separator) }
  route() { return factory.asPath().to(binding) }    
  controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin, options?: { ref?: React.RefObject<HTMLButtonElement> }) => JSX.Element) { return <React.Fragment></React.Fragment> }
  icon() { return <NounProject.Availability/> }
  image(pixelDimensions: { width: number, height: number }, imageProps: React.CSSProperties) {
      return undefined
  }
  label(truncate?: { maxChars?: number }) {
      return <span>Delete this role?</span>
  }
  summary(options: { onClick: (editMode:boolean) => void, zeroPlaceholder?: () => JSX.Element }) {
      return <div onClick={() => options.onClick(false)}>{this.label()}</div>
  }
  fields() { return [ { 
      render: () =>  (
          <Database.AsEditor peerId="ViewHasRoles.Delete" pollInterval={pollInterval} render={zone => <_Delete zone={zone} binding={binding}/>} />
      )
  }] }
});

Views.All.push( AsTeamDiagram, Add, Delete );

export function AsTable(props: {
  binding: { project: Objects.Binding<string> }
}) {
  const { zone } = React.useContext(Database.Context);
  const whoami = React.useContext(Whoami.Context);
  if(zone === undefined || whoami.person === undefined) throw new Error('Missing Context');
  const me = whoami.person;
  const [ value, setValue ] = React.useState<string|null>(null);

  const hasRoles = Propose.useRelation(Model.Projects.HasRoles.Descriptor.stream(zone, { project: props.binding.project }));
  return (<XLayout.Stack.South>
    <div style={{ flex: '0 0 auto'}}>
      <Autocomplete
          fullWidth={true}
          id="add-role-with-skill"
          options={Array.from(Model.Roles.HasSkills.Descriptor.getDomain().asEnumeration(100)?.forward() || [])}
          value={value}
          onChange={(event, value_) => {
            if(value_) {
              ProjectController.addRole(zone, { me, project: props.binding.project }, { skill: value_ }).exec();
            }
            setValue(null);
          }}
          freeSolo
          renderInput={(params) => (
              <TextField {...params} variant="filled" placeholder="Add roles to hire..." />
          )}
      />
     </div>
    <div style={{ flex: '1'}}>
      <XLayout.Stack.South scroll={true} style={{ minHeight: '0', width: '100%' }}>
          <Table>
            <TableBody>
              {
                hasRoles.entries.map(entry => (
                  <ViewRole.AsTableRow binding={entry}/>
                ))
              }
            </TableBody>
          </Table>
      </XLayout.Stack.South>
    </div>
  </XLayout.Stack.South>)
}
