import React from 'react';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';

import { makeStyles, useTheme, BottomNavigation, BottomNavigationAction, IconButton } from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';

import { Navigation, Styler, Views } from '@pitaman71/omniglot-live-react';
import * as XLayout from '@pitaman71/react-explicit-layout'

import { AsControlBox, AsDialog, AsFields, AsImage } from './Inspectors';
import * as HeroIcons from './HeroIcons';
import * as Logos from './Logos';
import * as Themes from './Themes';
import * as Whoami from './Whoami';

export function MobileTop(props: {
    view: Views.OfGoals.Plugin
  }) {
    const theme = useTheme();
    const controller = React.useContext(Navigation.Context);
    const location = useLocation();
    const whoami = React.useContext(Whoami.Context);
    return (
        <XLayout.Fill style={{ backgroundColor: theme.palette.background.paper }}>
            <XLayout.Stack.East>
            <div style={{ flex: '1' }}>
                {
                    <IconButton style={{ height: "100%", padding: "0 0 0 1em" }} color="secondary" onClick={() => controller.backward(1)}>
                        <HeroIcons.ArrowCircle variant="solid" color="secondary" direction="left" style={{ width: "1.3em", height: "1.3em" }}/>
                    </IconButton>
                }
            </div>
            <div style={{ flex: '0 0 auto', alignContent: 'center', height: '48px', marginLeft: '2em' }}>
                <Logos.Wordmark style={{ verticalAlign: 'middle' }}/>
                <Styler.Chip theme={Themes.Gray} size='small' label={'Beta'}/>
            </div>
            <div style={{ flex: '1' }}>
                {
                    props.view.controls(control => control.class === Views.OfControls.Direction.Settings, (control, options) => <Views.OfControls.AsIconButton control={control} refObject={options?.ref}/>)
                }
                {
                    Views.All.filter(factory => {
                        if(factory.position() !== Views.Position.Settings) return false;
                        return factory.relevant(location.pathname, whoami?.hasTag) && factory.permitted(location.pathname, whoami?.hasTag);
                    }).map((factory, index) => {
                        const position = factory.position();
                        const view = factory.make({});
                        return <IconButton key={index} id={`nav-${position}-${factory._prefix.join('-')}`} color="primary" style={{ height: "100%" }} onClick={() => {
                            controller.forward(factory.asPath().to({}));
                        }}>
                            <Views.OfGoals.AsIcon view={view}/>
                        </IconButton>
                    })
                }
            </div>
            </XLayout.Stack.East>
        </XLayout.Fill>
    )
  }
  
  export function MobileBottom(props: {
    view: Views.OfGoals.Plugin
  }): JSX.Element {
    const controller = React.useContext(Navigation.Context);
    const whoami = React.useContext(Whoami.Context);
    const location = useLocation();
    return (
        <BottomNavigation 
            value={ location.pathname }
            onChange={(event:any, pathname_: string) => {
                controller.forward(pathname_);
            }}
        >
            {
                Views.All.filter(factory => {
                    if(factory.position() !== Views.Position.Primary) return false;
                    return factory.relevant(location.pathname, whoami?.hasTag) && factory.permitted(location.pathname, whoami?.hasTag);;
                }).map((factory, index) => {
                    const position = factory.position();
                    const view = factory.make({});
                    return <BottomNavigationAction 
                        key={index} 
                        value={factory.asPath().pattern} 
                        id={`nav-${position}-${factory._prefix.join('-')}`} 
                        icon={<Views.OfGoals.AsIcon view={view}/>}
                        label={view.label({ maxChars: 35 })}
                        showLabel={true}
                    />
                })
            }
        </BottomNavigation>
    );
}
 

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    content: {
        flexGrow: 1,
        overflow: 'hidden',
    }
}));

export function AsCover(props: {
    view: Views.OfGoals.Plugin
}) {
    const navigation = React.useContext(Navigation.Context);
    const fields = [ ... props.view.fields() ].reverse();
    const classes = useStyles();
    const provide = !props.view.provide ? (children: JSX.Element[]|JSX.Element) => (<React.Fragment>{children}</React.Fragment>) : props.view.provide;

    return provide(
        <React.Fragment>
            <aside className="top-nav-skinny">
                <MobileTop view={props.view}/>
            </aside>
            <main
            className={clsx(classes.content)}
            >
            <AsDialog>
                <AsImage view={props.view} pixelDimensions={{ width: 400, height: 400 }} style={{ width: '100%', height: '100%'}}/>
                <React.Fragment>
                {
                    fields.map((field, index) => (
                        <XLayout.Partition top={0.9 - 0.05*(index+1)} bottom={0.9 - 0.05*index} left={0.05} right={0.5}>
                            { field.render(navigation) }
                        </XLayout.Partition>    
                    ))
                }
                </React.Fragment>
                <XLayout.Partition top={0.7} bottom={0.9} left={0.80} right={0.97}>
                    <AsControlBox view={props.view}/>
                </XLayout.Partition>
            </AsDialog>
            </main>
        </React.Fragment>
    );
}

export function AsRegular(props: {
    view: Views.OfGoals.Plugin
}) {
    const classes = useStyles();
    const provide = !props.view.provide ? (children: JSX.Element[]|JSX.Element) => (<React.Fragment>{children}</React.Fragment>) : props.view.provide;
    return provide(
        <React.Fragment>
            <aside className="top-nav">
                <MobileTop view={props.view}/>
            </aside>
            <main
                className={clsx(classes.content)}
            >
            <AsDialog>
                <XLayout.Fill maxWidth={480}>
                    <AsFields prefix="Pages.AsRegular" view={props.view}/>
                </XLayout.Fill>
            </AsDialog>
            </main>
            <aside className="bottom-nav">
                <MobileBottom view={props.view}/>
            </aside>
        </React.Fragment>
    );
}
