import React from 'react';

import { makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import * as MuiIcons from '@material-ui/icons';

import { Clients, Objects, Stores, Streams } from '@pitaman71/omniglot-live-data';
import { Carousel, Database, Navigation, Propose, Styler, Views } from '@pitaman71/omniglot-live-react';
import * as XLayout from '@pitaman71/react-explicit-layout'

import * as Model from 'models/autosrc/swivell/talent_marketplace';
import { Controller as ContentController } from 'models/src/Content';

import { ViewAsset, ViewHasMedia } from '.';
import * as Media from '../Media';
import * as HeroIcons from '../../HeroIcons';
import * as Modes from '../../Modes';
import * as Whoami from '../../Whoami';

import config from '../../config.json';

const baseURI = process.env.SWIVELL_SERVICES || ( process.env.NODE_ENV !== 'production' ? 'http://localhost:8080/dev' : config.services['app.swivell'].backend.baseURI );

export let pollInterval = { ms: 30000 };

const postDimensions = { width: 1080, height: 1350 };

const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: 'transparent',
      flexDirection: 'column'
    },
}));

function _UploadButton(props: {
    binding: Partial<Model.Content.Includes.BindingType>,
    client?: Clients.RelationClient<Model.Content.Includes.BindingType>
}) {
    const { zone } = React.useContext(Database.Context);
    const whoami = React.useContext(Whoami.Context);
    if(!zone || !whoami.person || props.binding.topic === undefined) {
        throw new Error('Missing Context');
    }
    const me = whoami.person;
    const styles= useStyles();
    if(!props.client) return <React.Fragment></React.Fragment>;
    const controller = ContentController.Manage(zone, { topic: props.binding.topic }, baseURI);
    return (
        <XLayout.Center.Horizontal style={{
            position: 'absolute',
            top: '80%',
            left: '0%',
            right: '100%'
        }}>
            <Media.AsUploadButton style={{ 
                backdropFilter: 'blur(8px)',
                WebkitBackdropFilter: 'blur(8px)',
                backgroundColor: 'rgba(45, 45, 45, 0.1)'
            }} startIcon={<MuiIcons.CloudUpload/>} prefix={'includes'} multiple={true} onChange={files_ => {
                controller.add(files_).forEach(mgr => mgr.upload.watch({
                    next: mgr => console.log(mgr.toString()),
                    error: err => console.log(err),
                    complete: () => {
                        zone.commitAll()
                    }
                }));
            }}>Upload</Media.AsUploadButton>
        </XLayout.Center.Horizontal>
    )
}

function _DeleteButton(props: {
    binding: Model.Content.Includes.BindingType,
    client?: Clients.RelationClient<Model.Content.Includes.BindingType>
}) {
    const navigation = React.useContext(Navigation.Context);
    const { zone } = React.useContext(Database.Context);
    const whoami = React.useContext(Whoami.Context);
    if(!navigation || !zone || !whoami.person || props.binding.topic === undefined) {
        throw new Error('Missing Context');
    }
    const me = whoami.person;
    if(!props.client) return <React.Fragment></React.Fragment>;
    return (
        <XLayout.Center.Horizontal style={{
            position: 'absolute',
            top: '80%'
        }}>
            <Styler.IconButton style={{ 
                backdropFilter: 'blur(8px)',
                WebkitBackdropFilter: 'blur(8px)',
                backgroundColor: 'rgba(45, 45, 45, 0.1)'
            }} onClick={event => {
                navigation.forward(RemoveAsset.asPath().to(props.binding));
            }}><MuiIcons.DeleteOutlined/></Styler.IconButton>
        </XLayout.Center.Horizontal>
    )
}

function _isLegal(hasMedia: { mime?: string, uri?: string }) {
    return hasMedia.mime?.startsWith('video/') ||
    hasMedia.mime === 'image/jpeg' || hasMedia.mime === 'image/png' || hasMedia.mime === 'image/svg'
    || hasMedia.uri?.endsWith('.jpg') || hasMedia.uri?.endsWith('.jpeg') 
    || hasMedia.uri?.endsWith('.png') || hasMedia.uri?.endsWith('.svg');
}

function _GalleryItem(props: {
    pixelDimensions: { height: number, width: number },
    entry: Model.Content.Includes.BindingType,
    id?: string,
    onClick?: (props: { asset: Objects.Binding<string> }) => void
}) {
    const { zone } = React.useContext(Database.Context);
    if(!zone || !props.entry.asset || !props.entry.topic) {
        throw new Error('Missing Context');
    }

    const onClick = props.onClick === undefined ? undefined : () => props.onClick && props.onClick(props.entry);
    const hasMedia = Propose.useScalarProperty(Model.Content.HasMedia.Descriptor.stream(zone, { asset: props.entry.asset }).scalar);
    if(!!hasMedia.value && !_isLegal(hasMedia.value)) {
        console.error(`Skipping media with mime type ${hasMedia.value?.mime}`)
        return <React.Fragment></React.Fragment>;
    }
    // consider using optimized versions if pressent
    return (
        <Media.AsDownloader pixelDimensions={props.pixelDimensions} onClick={onClick} style={{}} id={props.id} value={hasMedia.value}/>
    );
}

export function _AsGrid(props: React.PropsWithChildren<{
    binding: { topic: Objects.Binding<string> },
    shape: { rows?: number, columns?: number },
    major?: 'horizontal'|'vertical',
    scroll?: boolean,
    show?: { controls?: boolean, fill?: boolean },
    frameDimensions: { width: number, height: number },
    skip?: number,
    prefix?: string,
    onClick?: (props: { asset: Objects.Binding<string> }) => void
}>) {
    const { zone } = React.useContext(Database.Context);
    if(zone === undefined) throw new Error("Missing Context");
    const includes = Propose.useRelation(Model.Content.Includes.Descriptor.stream(zone, props.binding));
    const minElements = (props.shape.rows || 1) * (props.shape.columns || 1);
    const maxElements = (props.shape.rows === undefined || props.shape.columns === undefined || props.scroll) ? undefined : props.shape.rows * props.shape.columns;
    const pixelDimensions = props.show?.fill && includes.entries.length < minElements ? props.frameDimensions : { 
        width: props.frameDimensions.width / (props.shape.columns || 1),
        height: props.frameDimensions.height / (props.shape.rows || 1),
    };
    const entries = props.skip === undefined ? includes.entries : includes.entries.slice(props.skip);
    const extras = props.show?.fill === true || props.shape.rows === undefined || props.shape.columns === undefined || entries.length >= (props.shape.rows * props.shape.columns) ? 0 : props.shape.rows * props.shape.columns - entries.length
    const range = (start: number, stop: number, step: number) =>
        Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);
      
    return <React.Fragment>
        {
            props.show?.fill && entries.length < minElements ? (
                props.children
            ) : props.major === 'horizontal' ? (
                <XLayout.Grid.Horizontal id='view-includes-asgrid' shape={props.shape} scroll={props.scroll}>
                    { entries.map((entry, index) => maxElements !== undefined && index >= maxElements ? <React.Fragment></React.Fragment>  : <_GalleryItem {...props} key={entry.asset.objectId} entry={entry} pixelDimensions={pixelDimensions} id={!props.prefix ? undefined : `${props.prefix}-${index}`}/> )}
                    { range(0, extras - 1, 1).map(() => <Styler.Skeleton animation="wave" variant="rect" {...pixelDimensions}/> )}
                </XLayout.Grid.Horizontal>
            ) : (
                <XLayout.Grid.Vertical id='view-includes-asgrid' shape={props.shape} scroll={props.scroll}>
                    { entries.map((entry, index) => maxElements !== undefined && index >= maxElements ? <React.Fragment></React.Fragment>  : <_GalleryItem {...props} key={entry.asset.objectId} entry={entry} pixelDimensions={pixelDimensions} id={!props.prefix ? undefined : `${props.prefix}-${index}`}/> )}
                    { range(0, extras - 1, 1).map(() => <Styler.Skeleton animation="wave" variant="rect" {...pixelDimensions}/> )}
                </XLayout.Grid.Vertical>
            )
        }
        { props.show?.controls !== true ? <React.Fragment/> : <_UploadButton {...props} {...includes}/> }
    </React.Fragment>
}

export function AsGrid(props: React.PropsWithChildren<{
    binding: { topic: Objects.Binding<string> },
    shape: { rows?: number, columns?: number },
    major?: 'horizontal'|'vertical',
    scroll?: boolean,
    show?: { controls?: boolean, fill?: boolean },
    skip?: number,
    style?: React.CSSProperties,
    prefix?: string,
    onClick?: (props: { asset: Objects.Binding<string> }) => void
}>) {
    const render = (frameDimensions: { width: number, height: number }) => <_AsGrid {...props} 
        shape={props.shape}
        frameDimensions={frameDimensions} 
    />;
    return (
        <XLayout.Fill style={props.style}>
            <XLayout.WithDimensions render={render}/>
        </XLayout.Fill>
    );
}

export function AsAsset(props: {
    pixelDimensions: { width: number, height: number },
    show?: { controls?: boolean },
    binding: Model.Content.Includes.BindingType
}) {
    const { zone } = React.useContext(Database.Context);
    if(!zone) throw new Error('Missing Context');

    const includes = Propose.useRelation(Model.Content.Includes.Descriptor.stream(zone, props.binding));
    const hasMedia = Propose.useScalarProperty(Model.Content.HasMedia.Descriptor.stream(zone, { asset: props.binding.asset }).scalar);
    return (
        <React.Fragment>
            <Media.AsDownloader pixelDimensions={props.pixelDimensions} style={{ width: '100%', height: '100%', objectFit: 'contain' }} {...hasMedia} allowOptimize={false}/>
            { props.show?.controls !== true ? <React.Fragment/> : <_DeleteButton binding={props.binding} client={includes.client}/> }
        </React.Fragment>
    )
}

export function AsCarousel(props: {
    pixelDimensions: { width: number, height: number },
    show?: { controls?: boolean },
    binding: Model.Content.Includes.BindingType
}) {
    const { zone } = React.useContext(Database.Context);
    const [ selected, setSelected ] = React.useState<number|undefined>();
    if(!zone) throw new Error('Missing Context');

    const includes = Propose.useRelation(Model.Content.Includes.Descriptor.stream(zone, props.binding));

    React.useEffect(() => {
        if(selected === undefined) {
            const index = includes.entries.findIndex(entry => Objects.Binding.cmp(props.binding.asset, entry.asset) === 0);
            if(index >= 0)
                setSelected(index)
        }
    }, [ includes.entries ]);
    if(includes.entries.length === 0 || selected === undefined) {
        return <AsAsset {...props}/>
    }
    return <Carousel selected={selected} setSelected={setSelected}>
        { includes.entries.map(entry => <AsAsset {...props} key={entry.asset.objectId} binding={entry}/>)}
    </Carousel>
}

export class View implements Views.OfGoals.Plugin {
    _zone: Stores.Zone;
    _binding: Model.Content.Includes.BindingType;
    _prev: string
    _inspectors: {
        asset: ViewAsset.View
    }
    _streams: {
        includes: Streams.RelationStream<Model.Content.Includes.BindingType>
    }

    constructor(
        zone: Stores.Zone,
        binding: Model.Content.Includes.BindingType,
        prev: string
    ) {
        this._zone = zone;
        this._binding = { ... binding };  
        this._prev = prev;
        this._inspectors = {
            asset: new ViewAsset.View(zone, binding)
        };
        this._streams = {
            includes: zone.streams().relation(Model.Content.Includes.Descriptor.bindAnchor({ topic: binding.topic }))
        }
    }

    key(separator: string) { return [ 'content', 'includes' ].join(separator) }
    route() { return '/content/includes/'+this._binding.topic.objectId?.toString() || "?" }
    controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin, options?: { ref?: React.RefObject<HTMLButtonElement> }) => JSX.Element) { return <React.Fragment></React.Fragment> }
    icon(): JSX.Element { return <MuiIcons.DynamicFeed/> }
    image(pixelDimensions: { width: number, height: number }, imageProps: React.CSSProperties) {
        return this._inspectors.asset.image(pixelDimensions, imageProps);
    }
    label(truncate?: { maxChars?: number }): JSX.Element { 
        return this._inspectors.asset.label(truncate);
    }
    summary(options: { onClick: (editMode:boolean) => void, zeroPlaceholder?: () => JSX.Element }): JSX.Element { 
        return this._inspectors.asset.summary(options);
    }
    fields(): Views.OfFields.Plugin[] { 
        return this._inspectors.asset.fields();
    }
}

function _Remove(props: {
    view: Views.OfGoals.Plugin,
    zone: Stores.Zone,
    binding: Model.Content.Includes.BindingType,
    pixelDimensions: { width: number, height: number }, 
    on: {
        confirm: () => Promise<any>,
        cancel: () => Promise<any>
    };
}) {
    const controller = React.useContext(Navigation.Context);
    const includes = props.zone.streams().relation(Model.Content.Includes.Descriptor.bindAnchor({ topic: props.binding.topic }));
    return (<Propose.RelationEntries
        stream={includes}
        render={(values, client) => {
            const on = {
                confirm: () => {
                    const remainder = values.filter(entry => entry.asset.objectId != props.binding.asset.objectId);
                    if(remainder.length == 0) client?.clear();
                    else client?.assign(remainder);
                    return props.on.confirm();
                }, cancel: props.on.cancel
            };
            return <ViewHasMedia.Clear view={props.view} zone={props.zone} binding={{ asset: props.binding.asset }} pixelDimensions={props.pixelDimensions} on={on}/>    
        }}
    />);
}

function _RemoveAsset(props: {
    view: Views.OfGoals.Plugin,
    zone: Stores.Zone,
    binding: Model.Content.Includes.BindingType
}) {
    const controller = React.useContext(Navigation.Context);
    const whoami = React.useContext(Whoami.Context);
    const finish = () => {
        controller.backward(2);
        return Promise.resolve();
    }
    const on = {
        confirm: () => {
            return props.zone.commitAll()
            .then(finish);
        }, cancel: finish
    }
    return <_Remove {...props} pixelDimensions={postDimensions} binding={props.binding} on={on}/>    
}


export const RemoveAsset = new Views.Factory<Model.Content.Includes.BindingType, Model.Authority.Tags.ValueType>(['content', 'remove'], ['topic', 'asset'], ((binding, factory) => new class implements Views.OfGoals.Plugin {
    key(separator: string) { return factory.asPath().key(separator) }
    route() { return factory.asPath().to(binding) }    
    label(truncate?: { maxChars?: number }): JSX.Element { 
        return <span>Remove</span>
    }
    icon() { return <MuiIcons.DeleteOutlined/> }
    summary(options: { onClick: (editMode:boolean) => void, zeroPlaceholder?: () => JSX.Element }) {
        return <span>Remove Media</span>
    }
    fields(): Views.OfFields.Plugin[] { 
        return [{
            render: () => <Database.AsEditor peerId="ViewIncludes.RemoveAsset" pollInterval={{ ms: 30000 }} render={(zone) => <_RemoveAsset view={this} zone={zone} binding={binding}/> }/>
        }]
    }
    controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin, options?: { ref?: React.RefObject<HTMLButtonElement> }) => JSX.Element) { return <React.Fragment></React.Fragment> }
    image(pixelDimensions: { width: number, height: number }, imageProps: React.CSSProperties) {
        return undefined;
    }
}))

Views.All.push(RemoveAsset);
