import React from 'react';
import { Button, List, ListItem, ListItemIcon, ListItemText, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';

import * as XLayout from '@pitaman71/react-explicit-layout';

import { Database, MaterialUi, Propose, Styler } from '@pitaman71/omniglot-live-react';
import config from './config.json'

import { Carousel } from './NewCarousel';
import * as HeroIcons from './HeroIcons';
import * as Payments from './Payments';
import * as Stripe from './Stripe';
import * as Themes from './Themes';
import * as Whoami from './Whoami';

import * as Model from 'models/autosrc/swivell/talent_marketplace';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
    partialVisibilityGutter: 10
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    partialVisibilityGutter: 10
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    partialVisibilityGutter: 10
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    partialVisibilityGutter: 10
  }
};

function _Plan(props: React.PropsWithChildren<{
    plan: { id: string, name: string, description: string, marketing_features?: { name: string }[] },
    price: { unit_amount: number },
    onClick?: () => void,
    style?: React.CSSProperties
}>) {
    return <Styler.Card prefix={props.plan.id} theme={Themes.Light} style={{ ...props.style, height: '100%', display: 'flex', flexDirection: 'column' }}>
    <Styler.Button variant="contained" style={{ margin: '1em', color: '#000000', backgroundColor: '#FF0016', fontWeight: '700'  }} onClick={props.onClick}>{props.plan.name}</Styler.Button>
    <Styler.Section style={{ flex: '1', display: 'flex', flexDirection: 'column' }} prefix="price">
            <XLayout.Stack.South>
            <Styler.Body style={{ fontWeight: '500' }}>No minimum commitment. Cancel at any time.</Styler.Body>
            <Styler.Heading style={{ fontSize: '14pt', textWrap: 'nowrap' }}>USD ${props.price.unit_amount / 100}/month</Styler.Heading>
            <hr style={{ color:'#FF0016', width:'80%', borderWidth: '0.1px'}}></hr>
            <List>
                {
                    ( props.plan.marketing_features || []).map(marketing_feature => (
                        <ListItem>
                            <ListItemIcon><HeroIcons.CheckCircle variant="solid" style={{ fill: "#000000", stroke: "#FF0016" }}/></ListItemIcon>
                            <ListItemText>{marketing_feature.name}</ListItemText>
                        </ListItem>
                    ) )
                }
            </List>
            </XLayout.Stack.South>
    </Styler.Section>
    <XLayout.Center.Horizontal style={{ flex: '0 0 auto', padding: '1em'}}>
        { props.children }
    </XLayout.Center.Horizontal>
</Styler.Card>
}

function _Subscription(props: {
    plans: any[],
    subscription: any,
    style?: React.CSSProperties
}) {
    const found = props.plans.filter(({ plan, price }) => price.id === props.subscription.plan.id);
    if(found.length > 0) {
        return <_Plan plan={found[0].plan} price={found[0].price} style={props.style}>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell><Styler.Heading>Status</Styler.Heading></TableCell>
                        <TableCell>{props.subscription.status}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell><Styler.Heading>Subscribed Since</Styler.Heading></TableCell>
                        <TableCell>{new Date(props.subscription.created * 1000).toLocaleDateString()}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell><Styler.Heading>Trial Ends</Styler.Heading></TableCell>
                        <TableCell>{new Date(props.subscription.trial_end * 1000).toLocaleDateString()}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell><Styler.Heading>Paid Through</Styler.Heading></TableCell>
                        <TableCell>{new Date(props.subscription.current_period_end * 1000).toLocaleDateString()}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </_Plan>;
    } else{
        return <React.Fragment></React.Fragment>
    }
}

export function CheckSubscription(props: React.PropsWithChildren<{
    skip?: boolean,
    why?: JSX.Element
}>) {
    const authContext = React.useContext(MaterialUi.ViewAuthentication.AuthContext);
    const [ error, setError ] = React.useState<unknown|string>();;
    const [ plans, setPlans ] = React.useState<any>();
    const [ plan, setPlan ] = React.useState<any>();
    const [ price, setPrice ] = React.useState<any>();
    const [ subscriptions, setSubscriptions ] = React.useState<any>();
    const [ selected, setSelected ] = React.useState<number>(0);

    const name = authContext?.credentials().common?.name;
    const email = authContext?.credentials().common?.email;
    React.useEffect(() => {
        if(!name || !email) {
            console.log(`Waiting for login to complete`);
        } else {
            const body = JSON.stringify({ request: { email, capabilities: ['post-project'] } });
            console.log(`Checking subscriptions request = ${body}`);
            fetch(config.services['app.swivell'].backend.baseURI+'/payment/check', {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body 
            })
            .then(check_ => {
                if(check_.status != 200) {
                    console.log(`Checking subscriptions response status = ${check_.status}`);
                    return check_.json().then(error_ => {
                        console.error(`While checking subsriptions: ${error_}`);
                        setError(error_.toString())
                    });
                } else {
                    return check_.json().then(success_ => {
                        console.log(`While checking subsriptions: ${success_}`);
                        setPlans(success_.plans);
                        if(success_.subscriptions?.length > 0) {
                            setSubscriptions(success_.subscriptions);
                        } else {
                            setSelected(Math.floor(success_.plans.length / 2));
                            setSubscriptions([]);
                        }
                    });
                }
            }).catch(error => setError(error.toString()))
        }
    }, [ name, email, plan ])
    React.useEffect(() => {
        if(subscriptions?.length === 0 && plans && !plan) {
            setSelected(plans.length >= 2 ? Math.floor(plans.length / 2) : 0);
        }
    }, [ subscriptions?.length, plans, plan ])
    if(props.skip === true) {
        return <React.Fragment>{props.children}</React.Fragment>
    }
    if(!name || !email) {
        console.log(`Waiting for login to complete`);
        return <XLayout.Center.Both style={{ flex: '1' }}><Styler.Heading>Waiting for login to complete</Styler.Heading></XLayout.Center.Both>
    } else if(error) {
        console.log(`An error occurred while checking your subscription status: ${error}`);
        return <XLayout.Center.Both style={{ flex: '1' }}><Styler.Heading>An error occurred while checking your subscription status: <i>{error.toString()}</i></Styler.Heading></XLayout.Center.Both>
    } else if(subscriptions === undefined && !plans) {
        console.log(`Checking for active subscriptions ...`);
        return <XLayout.Center.Both style={{ flex: '1' }}><Styler.Heading>Checking for active subscriptions ...</Styler.Heading></XLayout.Center.Both>
    } else if(subscriptions?.length === 0 && plans && !plan) {
        console.log(`You do not have an active subscription. Pick a Plan.`);
        return (
            <XLayout.Center.Vertical style={{ height: '100%', width: '100%' }}>
                <XLayout.Stack.South>
                <Styler.Heading style={{ flex: '0 0 auto'}}>
                    Brand Subscriptions
                </Styler.Heading>
                <Carousel
                    prefix="payment-plan"
                    direction="east"
                    selected={selected}
                    setSelected={setSelected}
                    peek={25}
                    gap={1}
                >
                {
                    (plans || []).toSorted((a:any,b:any) => a.price.unit_amount - b.price.unit_amount).map((upgrade:any, index:any) => 
                        <_Plan {...upgrade} index={index} onClick={() => {
                            setPlan(upgrade.plan);
                            setPrice(upgrade.price);
                        }}/>
                    )
                }
                </Carousel>
                </XLayout.Stack.South>
            </XLayout.Center.Vertical>
        );
    } else if(subscriptions?.length === 0 && plan) {
        return (<React.Fragment>
            <Styler.Heading style={{ flex: '0 0 auto'}}>Subscribe: {plan.name}</Styler.Heading>
            <Styler.Heading>USD ${price.unit_amount / 100}/month</Styler.Heading>
            <Styler.Control prefix="subscribe" style={{ flex: '1' }}>
                <XLayout.Center.Both>
                    <Stripe.Subscribe plan={plan} price={price} name={name} email={email}/>
                </XLayout.Center.Both>
            </Styler.Control>
        </React.Fragment>);
    }
    if(React.Children.count(props.children) === 0 && subscriptions?.length > 0) {
        return (<XLayout.Center.Vertical style={{ height: '100%', width: '100%' }}>
            <XLayout.Stack.South>
                <Styler.Heading style={{ flex: '0 0 auto'}}>{subscriptions.length} Active Subscriptions:</Styler.Heading>
                <Carousel
                    prefix="check-subscription"
                    direction="east"
                    selected={selected}
                    setSelected={setSelected}
                    peek={25}
                    gap={1}
                >
                    { subscriptions.map((subscription: any) => <Styler.Control prefix="subscription-preview"><_Subscription subscription={subscription} plans={plans}/></Styler.Control>) }
                </Carousel>
            </XLayout.Stack.South>
        </XLayout.Center.Vertical>
        );
    }
    return (<React.Fragment>{props.children}</React.Fragment>);
}

export function BrandPaywall(props: React.PropsWithChildren<{}>) {
    const { zone } = React.useContext(Database.Context);
    const whoami = React.useContext(Whoami.Context);
    if(!whoami.person || !zone) throw new Error('Missing Context');

    const hasAuthority = Propose.useSetProperty(zone.streams().property(Model.Persons.HasAuthority.Descriptor.bind({ person: whoami.person })).set);
    return (<Payments.CheckSubscription
        skip={ hasAuthority.values.has('admin') ||  hasAuthority.values.has('creator') }
        why={<span>A subscription is required to accept or reject connections</span>}
    >
        { props.children }
    </Payments.CheckSubscription>);
}
