import React from 'react';

import * as XLayout from '@pitaman71/react-explicit-layout';
import { Clients, Objects, Stores, Streams } from '@pitaman71/omniglot-live-data';
import { Database, Propose, Styler } from '@pitaman71/omniglot-live-react';

import * as Model from 'models/autosrc/swivell/talent_marketplace';

import * as ViewProject from '../Projects/ViewProject';
import * as ViewRole from '../Roles/ViewRole';
import * as ViewCandidate from '../Candidates/ViewCandidate';
import * as NounProject from '../../NounProject';

import * as d3 from 'd3';
import { OrgChart } from 'd3-org-chart';

export let pollInterval = { ms: 30000 };

const classes = {
    content: {
      display: 'flex',
      justifyContent: 'center',
      '& > *': {
        margin: "0.5em",
      },
    },
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: "3em",
      },
    },
    chip: {
      margin: "0.25em 0.5em 0.25em 0.5em",
    }
};

export function AsTable(props: {
    zone: Stores.Zone,
    binding: { role: Objects.Binding<string> }
}) {
    const role = props.binding.role;
    const hasCandidates = Propose.useRelation(Model.Roles.HasCandidates.Descriptor.stream(props.zone, props.binding))
    if(hasCandidates.entries.length === 0) {
      return <XLayout.Center.Both>
        <XLayout.Stack.South>
        <Styler.Heading>This role has no candidates (yet)</Styler.Heading>
        <Styler.Body>Click the "Discover button to search for talent for this role.</Styler.Body>
        </XLayout.Stack.South>
      </XLayout.Center.Both>
    }
    return (
        <XLayout.Center.Both>
            <table><tbody>
                { hasCandidates.entries.map(entry => <ViewCandidate.AsTableRow zone={props.zone} binding={{ ...props.binding, candidate: entry.candidate }}/>) }
            </tbody></table>
        </XLayout.Center.Both>
    );
}
