import React from 'react';

import { Dot } from 'react-animated-dots';

import {  FormControlLabel, Radio, RadioGroup, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';

import * as XLayout from '@pitaman71/react-explicit-layout';
import { Domains } from '@pitaman71/omniglot-live-domains';
import { Database, Interview, MaterialUi, Navigation, Propose, Styler, Views } from '@pitaman71/omniglot-live-react';

import * as Model from 'models/autosrc/swivell/talent_marketplace';
import * as Inspectors from '../../Inspectors';
import * as Modes from '../../Modes';
import * as NounProject from '../../NounProject';
import * as Themes from '../../Themes';
import * as Whoami from '../../Whoami';

import * as Payments from '../../Payments';
import { ViewBrandOnboarding, ViewCreatorOnboarding, ViewCommonOnboarding, ViewGallery, ViewOnboarding, ViewPerson, ViewProfile } from '.';

function _useUpdateViews(props: {
    language: string,
    binding: ViewPerson.Binding
}) {
    const { zone } = React.useContext(Database.Context);
    if(!zone) throw new Error("Missing Context");
    const hasAuthority = Propose.useSetProperty(Model.Persons.HasAuthority.Descriptor.stream(zone, { person: props.binding.person }).set);
    const whoami = React.useContext(Whoami.Context);
    const isAdmin = whoami.hasTag('admin');
    const isBrand = hasAuthority?.values.has('brand');
    const isCreator = hasAuthority?.values.has('creator');

    const result:Views.OfGoals.Plugin[] = [];
    result.push(ViewCommonOnboarding.AsWizard.make(props.binding));
    result.push(ViewGallery.AsGallery.make(props.binding));
    if(!!isBrand) result.push(ViewBrandOnboarding.AsWizard.make(props.binding));
    if(!!isBrand) result.push(ViewOnboarding.AsSubscription.make(props.binding));
    if(!!isCreator) result.push(ViewCreatorOnboarding.AsWizard.make(props.binding));
    return result;
}

export function Mine(props: {
    language: string
}) {
    const { zone } = React.useContext(Database.Context);
    const whoami = React.useContext(Whoami.Context);
    const navigation = React.useContext(Navigation.Context);
    if(!zone || !whoami.person) throw new Error("Missing Context");
    const me = whoami.person;

    const updateViews = _useUpdateViews({ ...props, binding: { person:whoami.person }});

    return (
        <Styler.Section prefix="my-account" theme={Themes.Dark} style={{ maxHeight: '100%' }}>
            <XLayout.Center.Horizontal style={{ maxHeight: '100%' }}>
                <XLayout.Stack.South style={{ width: '80%', maxHeight: '100%'}}>
                    <p><Styler.Heading>Update Your Profile</Styler.Heading></p>
                    <p><Styler.Body>The table below shows profile sections which are relevant to your account.</Styler.Body></p>
                    { whoami.mode === 'lobby' ? <p><Styler.Body>Completing your profile is required for your account to be admitted to the full app.</Styler.Body></p> 
                    : whoami.mode === 'normal' ? <p><Styler.Body>Keeping your profile fresh ensures you get the best exposure on this app.</Styler.Body></p>
                    : <React.Fragment></React.Fragment>
                    }
                    <Styler.Control prefix={`my-account-views`} style={{ flex: '1', minHeight: "0" }}>
                    <XLayout.Stack.South id="my-account" scroll={true}>
                        <Table>
                            <TableBody>
                                { updateViews.map(view =>  <Inspectors.AsTableRow view={view}/>) }
                            </TableBody>
                        </Table>
                        <Styler.Button style={{ marginTop: '1em' }} onClick={() => navigation.forward(ViewProfile.AsProfile.asPath().to({ person: me }))}>Preview Your Profile</Styler.Button>
                    </XLayout.Stack.South>
                    </Styler.Control>
                </XLayout.Stack.South>
            </XLayout.Center.Horizontal>
        </Styler.Section>
    );    
}

export function Other(props: {
    language: string,
    binding: ViewPerson.Binding
}) {
    const { zone } = React.useContext(Database.Context);
    const whoami = React.useContext(Whoami.Context);
    const navigation = React.useContext(Navigation.Context);
    if(!zone || !whoami.person) throw new Error("Missing Context");
    const me = whoami.person;
    
    const hasName = Propose.useScalarProperty(Model.Persons.HasName.Descriptor.stream(zone, { person: props.binding.person }).scalar);
    const updateViews = _useUpdateViews(props);
    return (
        <Styler.Section prefix="profile-overview" theme={Themes.Dark} style={{ maxHeight: '100%', maxWidth: '100%' }}>
            <XLayout.Center.Horizontal style={{ maxHeight: '100%' }}>
                <XLayout.Stack.South style={{ width: '80%'}}>
                    <p><Styler.Heading>Update Profile : {hasName.value}</Styler.Heading></p>
                    <p><Styler.Body>The table below shows profile sections which are relevant to this account.</Styler.Body></p>
                    <XLayout.Stack.South scroll={true}>
                        <Table>
                            <TableBody>
                                { updateViews.map(view => <Inspectors.AsTableRow view={view}/>) }
                            </TableBody>
                        </Table>
                        <Styler.Button style={{ marginTop: '1em' }} onClick={() => navigation.forward(ViewProfile.AsProfile.asPath().to({ person: me }))}>Preview Profile {hasName.value}</Styler.Button>
                    </XLayout.Stack.South>
                </XLayout.Stack.South>
            </XLayout.Center.Horizontal>
        </Styler.Section>
    );    
}

export const AsMine = new Views.Factory<ViewPerson.Binding, Model.Authority.Tags.ValueType>(['persons', 'onboarding' ], [], ((binding, factory) => new class implements Views.OfGoals.Plugin {
    key(separator: string) { return factory.asPath().key(separator) }
    route() { return factory.asPath().to(binding) }    
    controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin, options?: { ref?: React.RefObject<HTMLButtonElement> }) => JSX.Element) { return <React.Fragment></React.Fragment> }
    icon(): JSX.Element { return <MuiIcons.Edit/> }
    image(pixelDimensions: { width: number, height: number }, imageProps: React.CSSProperties) { return undefined; }
    label(truncate?: { maxChars?: number }): JSX.Element { return  (<span>Revise</span>) }
    summary(options: { onClick: (editMode: boolean) => void, zeroPlaceholder?: () => JSX.Element }): JSX.Element { return  (<span>My Account</span>) }
    fields(): Views.OfFields.Plugin[] { return [{
        render: () => 
          <Database.AsEditor peerId="ViewAccount.AsMyOnboarding" pollInterval={{ ms: 15000 }} render={zone => <Mine language="en_US"/>}/>
    }] }
}), Modes.AnyMode);
Views.All.push(AsMine);

export const AsOther = new Views.Factory<ViewPerson.Binding, Model.Authority.Tags.ValueType>(['persons', 'onboarding' ], ['person'], ((binding, factory) => new class implements Views.OfGoals.Plugin {
    key(separator: string) { return factory.asPath().key(separator) }
    route() { return factory.asPath().to(binding) }    
    controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin, options?: { ref?: React.RefObject<HTMLButtonElement> }) => JSX.Element) { return <React.Fragment></React.Fragment> }
    icon(): JSX.Element { return <MuiIcons.Edit/> }
    image(pixelDimensions: { width: number, height: number }, imageProps: React.CSSProperties) { return undefined; }
    label(truncate?: { maxChars?: number }): JSX.Element { return  (<span>Revise</span>) }
    summary(options: { onClick: (editMode: boolean) => void, zeroPlaceholder?: () => JSX.Element }): JSX.Element { return  (<span>My Account</span>) }
    fields(): Views.OfFields.Plugin[] { return [{
        render: () => 
          <Database.AsEditor peerId="ViewAccount.AsMyOnboarding" pollInterval={{ ms: 15000 }} render={zone => <Other language="en_US" binding={binding}/>}/>
    }] }
}), Modes.AnyMode);
Views.All.push(AsOther);


export function _Talent2(props: {
    language: string,
    binding: ViewPerson.Binding
}) {
    const navigation = React.useContext(Navigation.Context);
    const whoami = React.useContext(Whoami.Context);
    const { zone } = React.useContext(Database.Context);
    if(zone === undefined) throw new Error("Missing Context");
    if(whoami.person === undefined) throw new Error("Missing Context");
    const me = whoami.person;

    const access = ViewPerson.useAccess(props.binding);

    const hasGenderType = Propose.useScalarProperty(Model.Creator.HasGenderType.Descriptor.stream(zone, { creator: props.binding.person }).scalar);
    const hasPreferenceType = Propose.useScalarProperty(Model.Creator.HasPreferenceType.Descriptor.stream(zone, { creator: props.binding.person }).scalar);

    const questions: Interview.Question[] = [{
        key: 'personal-gender',
        pre: (language) => [ "Which best describes your gender identity?" ],
        ask: (language, focus,) => {
            return <MaterialUi.ViewScalar.AsCheckboxes
                id="personal-gender-input"
                domain={Model.Sexuality.GenderTypes.Tags.Domain}
                value={hasGenderType.value} 
                client={hasGenderType.client}
            />
        },
        tell: (language) => {
            if(hasGenderType.value === undefined) {
                return <span>Your gender identity is undisclosed.</span>
            } else {
                return <span>Your gender identity is {hasGenderType.value}</span>
            }
        },
        post: (language) => [ 
            "You are not required to specify a gender identity.",
            "The information you provide here, if you choose to do so, will not be visible on your profile.",
            "It will be searchable for matching purposes"
        ]
    },{
        key: 'personal-preference',
        pre: (language) => [ "Which best describes your gender preference?" ],
        ask: (language, focus,) => {
            return <MaterialUi.ViewScalar.AsCheckboxes
                id="personal-preference-input"
                domain={Model.Sexuality.PreferenceTypes.Tags.Domain}
                value={hasPreferenceType.value} 
                client={hasPreferenceType.client}
            />
        },
        tell: (language) => {
            if(hasPreferenceType.value === undefined) {
                return <span>Your gender preference is undisclosed.</span>
            } else {
                return <span>Your gender preference is {hasPreferenceType.value}</span>
            }
        },
        post: (language) => [ 
            "You are not required to specify a gender identity.",
            "The information you provide here, if you choose to do so, will not be visible on your profile.",
            "It will be searchable for matching purposes"
        ]
    }];

    return (
        <Interview.Wizard language={props.language} questions={questions} allow={{ view: access.view, edit: access.edit }} label={{
            checkingAuth: () => <span>Confirming your authorization to edit this profile <Dot>.</Dot><Dot>.</Dot><Dot>.</Dot></span>,
            failedAuth: () => <span>You don't have authorization to edit this profile.</span>,
            cancel: () => <span>Discard Changes</span>,
            confirm: () => <span>Save Changes</span>
        }} on={{ 
            cancel: () => { navigation.backward(); return Promise.resolve() },
            commit: () => {
                return zone.commitAll().then(() => {
                    navigation.backward();
                })                    
            },
        }} theme={{ card: Themes.Interview, buttons: Themes.Gold }}/>
    )
}

export const AsTalent2 = new Views.Factory<ViewPerson.Binding, Model.Authority.Tags.ValueType>(['persons', 'onboarding', 'talent2'], ['person'], ((binding, factory) => new class implements Views.OfGoals.Plugin {
    key(separator: string) { return factory.asPath().key(separator) }
    route() { return factory.asPath().to(binding) }    
    controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin, options?: { ref?: React.RefObject<HTMLButtonElement> }) => JSX.Element) { return <React.Fragment></React.Fragment> }
    icon(): JSX.Element { return <MuiIcons.Edit/> }
    image(pixelDimensions: { width: number, height: number }, imageProps: React.CSSProperties) { return undefined; }
    label(truncate?: { maxChars?: number }): JSX.Element { return  (<span>Revise</span>) }
    summary(options: { onClick: (editMode: boolean) => void, zeroPlaceholder?: () => JSX.Element }): JSX.Element { return  (<span>Personal Details</span>) }
    fields(): Views.OfFields.Plugin[] { return [{
        render: () => 
          <Database.AsEditor peerId="ViewPerson.AsTalent2" pollInterval={{ ms: 15000 }} render={zone => <_Talent2 language="en_US" binding={binding}/>}/>
    }] }
}), Modes.AnyMode);
Views.All.push(AsTalent2);

export const AsSubscription = new Views.Factory<ViewPerson.Binding, Model.Authority.Tags.ValueType>(['persons', 'onboarding', 'subscription'], ['person'], ((binding, factory) => new class implements Views.OfGoals.Plugin {
    key(separator: string) { return factory.asPath().key(separator) }
    route() { return factory.asPath().to(binding) }    
    controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin, options?: { ref?: React.RefObject<HTMLButtonElement> }) => JSX.Element) { return <React.Fragment></React.Fragment> }
    icon(): JSX.Element { return <NounProject.Pay/> }
    image(pixelDimensions: { width: number, height: number }, imageProps: React.CSSProperties) { return undefined; }
    label(truncate?: { maxChars?: number }): JSX.Element { return  (<span>Subscribe</span>) }
    summary(options: { onClick: (editMode: boolean) => void, zeroPlaceholder?: () => JSX.Element }): JSX.Element { return  (<span>Check Subscription</span>) }
    fields(): Views.OfFields.Plugin[] { return [{
        render: () => 
          <Database.AsEditor peerId="ViewPerson.AsSubscription" pollInterval={{ ms: 15000 }} render={zone => (
            <Payments.CheckSubscription/>
        )}/>
    }] }
}), Modes.AnyMode);
Views.All.push(AsSubscription);

