import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Navigation, Styler } from '@pitaman71/omniglot-live-react';

import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";

import * as raw_config from './config.json';

interface Config {
    services: {
        "com.stripe": {
            publishable: string
        }, "app.swivell": {
            backend: {
                baseURI: string
            }
        }
    }
}

const config: Config = raw_config as any;

const baseURI = process.env.SWIVELL_SERVICES || ( process.env.NODE_ENV !== 'production' ? 'http://localhost:8080/dev' : config.services['app.swivell'].backend.baseURI );

function _Submit(props: React.PropsWithChildren<{
    setError: (error: string) => void
}>) {
    const stripe = useStripe();
    const elements = useElements();
    const navigation = React.useContext(Navigation.Context);
    return <Styler.Button variant="outlined" onClick={!elements || !stripe ? undefined : (() => {
        stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
              return_url: navigation.location.pathname,
            }
        }).then(({error}) => props.setError(error.toString()));
    })}>{props.children}</Styler.Button>
}
export function Subscribe(props: {
    plan: any,
    price: any,
    name: string,
    email: string
}) {
    const navigation = React.useContext(Navigation.Context);
    const [ clientSecret, setClientSecret ]= React.useState<string|undefined>();
    const [ intentId, setIntentId ] = React.useState<string|undefined>();
    const [ error, setError ] = React.useState<string|undefined>();
    const [ stripePromise ] = React.useState(() => loadStripe(config.services['com.stripe'].publishable));

    if(!navigation) throw new Error('Missing Context');

    React.useEffect(() => {
        fetch(baseURI+'/payment/session', {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ request: {  
                plan: props.plan.id,
                name: props.name,
                email: props.email,
                success: window.location.href,
                cancel: window.location.href,
            }})
        })
        .then(response => response.json())
        .then(response => {
            window.location = response.url
        }).catch(error => setError(error.toString()))
    }, [])

    if(!clientSecret || !intentId) {
        return <Styler.Heading>One moment please...</Styler.Heading>
}
    return (
        <div>
            <Elements stripe={stripePromise} options={{
                clientSecret,
                appearance: { 
                    theme: 'night',
                    variables: {
                        colorPrimary: '#feedbe',
                        colorText: '#ffffff',
                        colorDanger: '#df1b41',
                    }
                }
            }}>
                <PaymentElement/>
                <p>{error}</p>
                <_Submit setError={setError}>Subscribe</_Submit>
           </Elements>
        </div>
    );
}