import faker from 'faker';
import { Dialogues, Definitions, Objects, Properties, Relations, Values } from '@pitaman71/omniglot-live-data';
import { Domains, Modules, WrapFaker } from '@pitaman71/omniglot-live-domains';
import * as Elevated from '@pitaman71/omniglot-introspect';

export const directory = new Definitions.Directory();
// property Ratings.HasFactor

const Domain: Elevated.Domain<number> = Values.Fraction;
export type TypeParams = {
    Binding: { assignment: Objects.Binding<string> },
    Value: number,
    Domain: typeof Domain
}
export const Descriptor = new class _Descriptor extends Properties.Descriptor<TypeParams> {
    canonicalName = 'generated.Ratings.HasFactor';
    build(builder: Properties.Builder<TypeParams>): void {
        builder.object('assignment');
        builder.measure(Values.Fraction);
        builder.scalar();
    }
};

directory.descriptors.properties.set(Descriptor.canonicalName, Descriptor);
        
