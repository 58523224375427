import faker from 'faker';
import { Dialogues, Definitions, Objects, Properties, Relations, Values } from '@pitaman71/omniglot-live-data';
import { Domains, Modules, WrapFaker } from '@pitaman71/omniglot-live-domains';
import * as Elevated from '@pitaman71/omniglot-introspect';

export const directory = new Definitions.Directory();
// tag Creator.AudienceTargeting.Tags

export type ValueType = string|'infant'|'child'|'tween'|'teen'|'adult'|'mature'|'senior'|'retired'|'18-24'|'25-34'|'35-44'|'45-54'|'55-64'|'65 and older'|'gay'|'lesbian'|'lgbtq+'|'male'|'female'|'nonbinary'|'trans'|'jewish'|'christian'|'muslim'|'agnostic'|'atheist'|'academic'|'athletic'|'student';
export const Domain = new Values.TagDomain('infant','child','tween','teen','adult','mature','senior','retired','18-24','25-34','35-44','45-54','55-64','65 and older','gay','lesbian','lgbtq+','male','female','nonbinary','trans','jewish','christian','muslim','agnostic','atheist','academic','athletic','student');
        
