import * as Model from 'models/autosrc/swivell/talent_marketplace';

export const AnyMode = {
    permitted: (route: string, hasTag: (tag: Model.Authority.Tags.ValueType) => boolean) => true
};

export const LobbyMode = {
    permitted: (route: string, hasTag: (tag: Model.Authority.Tags.ValueType) => boolean) => { return !hasTag('admitted') && !hasTag('ambassador') && !hasTag('admin'); }
};

export const NormalMode = {
    permitted: (route: string, hasTag: (tag: Model.Authority.Tags.ValueType) => boolean) => { return hasTag('admitted') || hasTag('ambassador') || hasTag('admin'); }
};

export const AdminMode = {
    permitted: (route: string, hasTag: (tag: Model.Authority.Tags.ValueType) => boolean) => { hasTag('ambassador') || hasTag('admin'); }
}
