import faker from 'faker';
import { Dialogues, Definitions, Objects, Properties, Relations, Values } from '@pitaman71/omniglot-live-data';
import { Domains, Modules, WrapFaker } from '@pitaman71/omniglot-live-domains';
import * as Elevated from '@pitaman71/omniglot-introspect';

export const directory = new Definitions.Directory();
// tag SocialMedia.ContentTypes.Tags

export type ValueType = string|'social media posts'|'blog posts'|'ratings and reviews'|'hashtag campaigns'|'surveys'|'zero party data';
export const Domain = new Values.TagDomain('social media posts','blog posts','ratings and reviews','hashtag campaigns','surveys','zero party data');
        
