import faker from 'faker';

import * as Elevated from '@pitaman71/omniglot-introspect';
import { Clients, Definitions, Objects, Properties, Stores } from '@pitaman71/omniglot-live-data';
import { Domains } from '@pitaman71/omniglot-live-domains';

import * as Model from '../../autosrc/swivell/talent_marketplace';
import * as ByOwner from './ByOwner';

export const directory = new Definitions.Directory();

const delay = (ms: number, val: any) => {
    return new Promise(resolve => setTimeout(resolve, ms, val));
}

export namespace HasState {
    export enum Direction {
        Unknown = 'unknown',
        Invite = 'invite',
        Uninvite = 'uninvite'
    }

    export interface _Config {
        owner: Objects.Binding<string>,
        direction: Direction,
        person?: Objects.Binding<string>,
        project?: Objects.Binding<string>,
        queued?: Objects.Binding<string>[],
        done?: Objects.Binding<string>[]
    }
    export const Domain = Domains.Tasks.StateDomain(new class extends Elevated.Domain<Domains.Base.Parseable<_Config>> {
        asJSON() {
            const domain = this;
            return {
                from(json: any): Domains.Base.Parseable<_Config> {
                    const error: undefined|string = undefined;
                    const owner = json.owner === undefined ? undefined : Objects.Binding.from_bound(json.owner);
                    const direction = 
                        json.direction === Direction.Unknown ? Direction.Unknown
                        : json.direction === Direction.Invite ? Direction.Invite
                        : json.direction === Direction.Uninvite ? Direction.Uninvite
                        : Direction.Unknown;
                    const person = json.person === undefined ? undefined : Objects.Binding.asJSON<string>().from(json.person);
                    const project = json.project === undefined ? undefined : Objects.Binding.asJSON<string>().from(json.project);
                    const queued = !Array.isArray(json.queued) ? undefined : json.queued.map(queued_ => Objects.Binding.asJSON<string>().from(queued_));
                    const done = !Array.isArray(json.done) ? undefined : json.done.map(done_ => Objects.Binding.asJSON<string>().from(done_));
                    return {
                        parsed: { owner, direction, person, project, queued, done },
                        error
                    }
                },
                to(value: Domains.Base.Parseable<_Config>) {
                    return value.parsed ? JSON.stringify(value.parsed) : value.text || "";
                }
            }
        }
        asString(format?: string) { 
            const domain = this;
            return {
                from(text: string): Domains.Base.Parseable<_Config> { 
                    try {
                        const parsed = JSON.parse(text);
                        return { text, ...domain.asJSON().from(parsed) };
                    } catch(e) {
                        return {
                            text,
                            error: 'unable to parse JSON as Tasks.Progress'
                        };
                    }
                },
                to(value: Domains.Base.Parseable<_Config>) { return domain.asJSON().to(value) }
            };
        }
        asEnumeration(maxCount: number) { return undefined }
        asColumns() { return undefined }
        cmp(a: Domains.Base.Parseable<_Config>, b: Domains.Base.Parseable<_Config>) {
            let code: -1|0|1|undefined = undefined;
            if(a.parsed === undefined || b.parsed === undefined) return undefined;
            code = Objects.Binding.cmp(a.parsed.owner, b.parsed.owner);
            if(code !== 0) return code;
            code = Objects.Binding.cmp(a.parsed.person, b.parsed.person);
            if(code !== 0) return code;
            code = Objects.Binding.cmp(a.parsed.project, b.parsed.project);
            if(code !== 0) return code;
            code = Objects.Binding.cmp<string>(a.parsed.queued, b.parsed.project);
        }    
    }());

    export type TypeParams = {
        Binding: { task: Objects.Binding<string> },
        Value: Domains.Tasks._State<_Config>,
        Domain: typeof Domain
    }
    export const Descriptor = new class _Descriptor extends Properties.Descriptor<TypeParams> {
        canonicalName = 'Tasks.InviteAll.HasState';
        build(builder: Properties.Builder<TypeParams>): void {
            builder.object('task');
            builder.measure(Domain);
            builder.scalar();
        }
    };

    directory.descriptors.properties.set(Descriptor.canonicalName, Descriptor);
}

export const Logic = {
    binding: (state: Domains.Tasks._State<HasState._Config>) => {
        const nonce = state.descriptor.nonce;
        if(state.descriptor.nonce === undefined) state.descriptor.nonce = nonce;
        const task = Objects.Binding.from_bound(nonce);
        return {
            task,
            owner: state.config.owner,
            person: state.config.person,
            project: state.config.project
        }
    },
    streams: (zone: Stores.Zone, binding: { task: Objects.Binding<string>, owner: Objects.Binding<string>}) => {
        const hasTasks = zone.streams().relation(ByOwner.Descriptor.bindAnchor({ owner: binding.owner }));
        const hasState = zone.streams().property(HasState.Descriptor.bind(binding));
        const hasProvenance = zone.streams().property(Model.Identity.Provenance.Descriptor.bind({ the: binding.task }));
        return { hasTasks, hasState, hasProvenance };
    },
    statefuls: (zone: Stores.Zone, binding: { task: Objects.Binding<string>, owner: Objects.Binding<string>}) => {
        const streams = Logic.streams(zone, binding);
        const hasTasks = streams.hasTasks.stateful();
        const hasState = streams.hasState.scalar?.stateful();
        const hasProvenance = streams.hasProvenance.scalar?.stateful();
        return { hasTasks, hasState, hasProvenance };
    },
    create: (zone: Stores.Zone, owner: Objects.Binding<string>, direction: HasState.Direction, person?: Objects.Binding<string>, project?: Objects.Binding<string>): Promise<Domains.Tasks.Status> => {
        const nonce = faker.datatype.uuid();
        const task = Objects.Binding.from_bound(nonce);
        const statefuls = Logic.statefuls(zone, { task, owner });
        if(!statefuls.hasTasks || !statefuls.hasState || !statefuls.hasProvenance) return Promise.reject(`Database connection zone does not allow push`);
        statefuls.hasTasks.insert({ owner, task });
        statefuls.hasProvenance.assign({
            owner: person
        });
        statefuls.hasState.assign({
            config: {
                owner,
                direction,
                person,
                project                
            }, descriptor: {
                nonce,
                session: "?",
                principal: person?.objectId || project?.objectId
            }, progress: {}
        });
        return Promise.resolve(Domains.Tasks.Status.Configuring);
    },
    tick: (
        zone: Stores.Zone, 
        state: HasState.TypeParams['Value'], 
        client: Clients.ScalarClient<HasState.TypeParams['Value']>,
        itemCount: number = 10
    ): Promise<Domains.Tasks.Status> => {
        const binding = Logic.binding(state);
        return Promise.resolve().then(() => {
            const result = Domains.Tasks.Status.Unknown;
            if(state.config.owner === undefined) {
                console.log(`Tasks.InviteAll.tick : state.config is not configured yet, needs props { owner }`);
                return { state, status: Domains.Tasks.Status.Configuring };
            } else if(state.config.queued === undefined) {
                const community = Objects.Binding.from_bound('the');
                const hasPersons = Model.Community.HasPerson.Descriptor.eager(zone, { community });
                console.log(`Tasks.InviteAll.tick : querying db for list of persons`);
                return delay(5000, undefined).then(() => {
                    state.config.queued = [ ...hasPersons.entries().map(entry => entry.person) ].filter(person_ => Objects.Binding.cmp(person_, state.config.person) !== 0);
                    console.log(`Tasks.InviteAll.tick : queueing add to discovery feed for ${state.config.queued.length} persons`);
                    return { state, status: Domains.Tasks.Status.Running };
                });
            } else if(state.config.queued.length > 0) {
                const head = state.config.queued.slice(0,itemCount);
                state.config.queued = state.config.queued.slice(itemCount);
                head.forEach(person => {
                    const hasDiscoveryFeed = zone.streams().property(Model.Persons.HasDiscoveryFeed.Descriptor.bind({person}));
                    if(state.config.direction === HasState.Direction.Invite) {
                        console.log(`Tasks.InviteAll.tick : add to discovery feed ${JSON.stringify(person)}`);
                        hasDiscoveryFeed.sequence?.stateful()?.append(undefined, { person: state.config.person, project: state.config.project });
                    } else {
                        console.log(`Tasks.InviteAll.tick : remove from discovery feed ${JSON.stringify(person)}`);
                        hasDiscoveryFeed.sequence?.stateful()?.remove({ person: state.config.person, project: state.config.project });
                    }
                    state.config.done = [ ...(state.config.done || []), person ];
                })
                return { state, status: state.config.queued.length === 0 ? Domains.Tasks.Status.Done : Domains.Tasks.Status.Running };
            } else {
                return { state, status: Domains.Tasks.Status.Done };
            }
        }).then(({ state, status }) => {
            if(Array.isArray(state.config.queued)) {
                state.progress.units = {
                    expected: state.config.queued.length + (state.config.done || []).length,
                    processing: 0,
                    completed: (state.config.done || []).length
                }
            }
            client.assign(state);
            return status;
        })
    }
}
