import faker from 'faker';
import { Dialogues, Definitions, Objects, Properties, Relations, Values } from '@pitaman71/omniglot-live-data';
import { Domains, Modules, WrapFaker } from '@pitaman71/omniglot-live-domains';
import * as Elevated from '@pitaman71/omniglot-introspect';

export const directory = new Definitions.Directory();
// property Locations.HasRadius

const Domain: Elevated.Domain<string> = WrapFaker.OfString('Locations.HasRadiusMiles', () => faker.helpers.randomize(['+10km', '+20km', '+ 50km', '+100km', '+200km', '+5mi', '+10mi', '+25mi', '+50mi', '+100mi', 'any distance'])).domain();
export type TypeParams = {
    Binding: { location: Objects.Binding<string> },
    Value: string,
    Domain: typeof Domain
}
export const Descriptor = new class _Descriptor extends Properties.Descriptor<TypeParams> {
    canonicalName = 'generated.Locations.HasRadius';
    build(builder: Properties.Builder<TypeParams>): void {
        builder.object('location');
        builder.measure(WrapFaker.OfString('Locations.HasRadiusMiles', () => faker.helpers.randomize(['+10km', '+20km', '+ 50km', '+100km', '+200km', '+5mi', '+10mi', '+25mi', '+50mi', '+100mi', 'any distance'])).domain());
        builder.scalar();
    }
};

directory.descriptors.properties.set(Descriptor.canonicalName, Descriptor);
        
