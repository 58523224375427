import faker from 'faker';
import { Dialogues, Definitions, Objects, Properties, Relations, Values } from '@pitaman71/omniglot-live-data';
import { Domains, Modules, WrapFaker } from '@pitaman71/omniglot-live-domains';
import * as Elevated from '@pitaman71/omniglot-introspect';

import { Domain as Model__Heritage__Populations__Tags__Domain} from '../Heritage/Populations/Tags';
import { ValueType as Model__Heritage__Populations__Tags__ValueType} from '../Heritage/Populations/Tags';
export const directory = new Definitions.Directory();
// property Creator.HasHeritagePopulation

const Domain: Elevated.Domain<Model__Heritage__Populations__Tags__ValueType> = Model__Heritage__Populations__Tags__Domain;
export type TypeParams = {
    Binding: { creator: Objects.Binding<string> },
    Value: Model__Heritage__Populations__Tags__ValueType,
    Domain: typeof Domain
}
export const Descriptor = new class _Descriptor extends Properties.Descriptor<TypeParams> {
    canonicalName = 'generated.Creator.HasHeritagePopulation';
    build(builder: Properties.Builder<TypeParams>): void {
        builder.object('creator');
        builder.measure(Model__Heritage__Populations__Tags__Domain);
        builder.set();
    }
};

directory.descriptors.properties.set(Descriptor.canonicalName, Descriptor);
        
