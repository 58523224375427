import faker from 'faker';
import { Dialogues, Definitions, Objects, Properties, Relations, Values } from '@pitaman71/omniglot-live-data';
import { Domains, Modules, WrapFaker } from '@pitaman71/omniglot-live-domains';
import * as Elevated from '@pitaman71/omniglot-introspect';

export const directory = new Definitions.Directory();
// property Events.Occurs

const Domain: Elevated.Domain<Domains.Temporal._Interval> = Domains.Temporal.IntervalDomain;
export type TypeParams = {
    Binding: { event: Objects.Binding<string> },
    Value: Domains.Temporal._Interval,
    Domain: typeof Domain
}
export const Descriptor = new class _Descriptor extends Properties.Descriptor<TypeParams> {
    canonicalName = 'generated.Events.Occurs';
    build(builder: Properties.Builder<TypeParams>): void {
        builder.object('event');
        builder.measure(Domains.Temporal.IntervalDomain);
        builder.scalar();
    }
};

directory.descriptors.properties.set(Descriptor.canonicalName, Descriptor);
        
