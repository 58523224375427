import { Definitions } from '@pitaman71/omniglot-live-data';

import * as HasHomeLocation from './HasHomeLocation';
import * as HasEvents from './HasEvents';
import * as HasStatus from './HasStatus';
import * as HasAvatar from './HasAvatar';
import * as Assignment from './Assignment';
import * as Project from './Project';
import * as AssignsWhom from './AssignsWhom';
import * as Status from './Status';
import * as HasDescription from './HasDescription';
import * as ByMember from './ByMember';
import * as Role from './Role';
import * as HasRoles from './HasRoles';
import * as HasMembers from './HasMembers';
import * as HasTitle from './HasTitle';
import * as HasBrandName from './HasBrandName';
import * as ForClient from './ForClient';
import * as Action from './Action';
const directory = Definitions.Directory.from(HasHomeLocation.directory,HasEvents.directory,HasStatus.directory,HasAvatar.directory,Assignment.directory,Project.directory,AssignsWhom.directory,Status.directory,HasDescription.directory,ByMember.directory,Role.directory,HasRoles.directory,HasMembers.directory,HasTitle.directory,HasBrandName.directory,ForClient.directory,Action.directory)
export { directory, HasHomeLocation, HasEvents, HasStatus, HasAvatar, Assignment, Project, AssignsWhom, Status, HasDescription, ByMember, Role, HasRoles, HasMembers, HasTitle, HasBrandName, ForClient, Action }; 
