import React from 'react';

import { makeStyles, Chip, IconButton, TextField, Typography } from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';

import { Dot } from 'react-animated-dots';
import { VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import * as XLayout from '@pitaman71/react-explicit-layout';
import { Stores } from '@pitaman71/omniglot-live-data';
import { Domains } from '@pitaman71/omniglot-live-domains';
import { MaterialUi, Navigation, Propose } from '@pitaman71/omniglot-live-react';

import * as Model from 'models/autosrc/swivell/talent_marketplace';

export let pollInterval = { ms: 15000 };

const useStyles = makeStyles((theme) => ({
    content: {
      display: 'flex',
      justifyContent: 'center',
      '& > *': {
        margin: "0.5em",
      },
    },
    root: {
      width: 500,
      '& > * + *': {
        marginTop: "3em",
      },
    },
    chip: {
      margin: "0.25em 0.5em 0.25em 0.5em"
    }
}));

export function AsSortable<BindingType>(props: {
    zone: Stores.Zone,
    entry: BindingType,
    binding: Model.Events.HasDetails.TypeParams["Binding"],
    updateSortKey: (binding_: BindingType, sortKey_: Propose.Sortable) => void
}) {
    const hasDetails = props.zone.streams().property(Model.Events.HasDetails.Descriptor.bind(props.binding));
    return (<Propose.PropertyData stream={hasDetails} render={{
        scalar: (value, client) => {
            if(value === undefined) props.updateSortKey(props.entry, undefined);
            else props.updateSortKey(props.entry, [value?.when?.dates?.from.iso8601, value?.when?.dates?.to.iso8601]);
            return <div key={JSON.stringify(props.entry)}></div>
        }}
    }/>)
}

  
export function AsChip(props: {
    zone: Stores.Zone,
    binding: Model.Events.HasDetails.TypeParams["Binding"],
    icon?: JSX.Element
}) {
    const classes = useStyles();
    const hasDetails = props.zone.streams().property(Model.Events.HasDetails.Descriptor.bind(props.binding));
    return (<Propose.PropertyData stream={hasDetails} render={{
        scalar: (value, client) => {
            if(value === undefined) return <div key={JSON.stringify(props.binding)}><Dot>.</Dot><Dot>.</Dot><Dot>.</Dot></div>;
            const when = value.when === undefined ? undefined : Domains.Logistics.WhenDomain.asString().to(value.when);
            const where = value.where === undefined ? undefined : Domains.Logistics.WhereDomain.asString().to(value.where);
            return <Chip color="primary" key={JSON.stringify(props.binding)} className={classes.chip} icon={props.icon} label={`${when} ${where}`}/>;
        }}
    }/>)
}

function _EditButton(props: {
    zone: Stores.Zone,
    binding: Model.Events.HasDetails.TypeParams['Binding'],
    onClick: (binding: Model.Events.HasDetails.TypeParams['Binding']) => void
}) {
    const navigation = React.useContext(Navigation.Context);
    return <IconButton onClick={() => props.onClick(props.binding)}><MuiIcons.EditOutlined/></IconButton>;
}

function _DeleteButton(props: {
    zone: Stores.Zone,
    binding: Model.Events.HasDetails.TypeParams['Binding'],
    onClick: (binding: Model.Events.HasDetails.TypeParams['Binding']) => void
}) {
    const navigation = React.useContext(Navigation.Context);
    return <IconButton onClick={() => props.onClick(props.binding)}><MuiIcons.DeleteOutlined/></IconButton>;
}

export function AsTimelineElement(props: {
    zone: Stores.Zone,
    binding: Model.Events.HasDetails.TypeParams['Binding'],
    on: {
        edit: (binding: Model.Events.HasDetails.TypeParams['Binding']) => void,
        delete: (binding: Model.Events.HasDetails.TypeParams['Binding']) => void
    },
    icon?: JSX.Element
}) {
    const hasDetails = props.zone.streams().property(Model.Events.HasDetails.Descriptor.bind(props.binding));
    return (
        <Propose.PropertyData stream={hasDetails} render={{
            scalar: (value, client) => {
                if(value === undefined) return <React.Fragment></React.Fragment>;
                const when = value.when === undefined ? undefined : Domains.Logistics.WhenDomain.asString().to(value.when);
                const where = value.where === undefined ? undefined : Domains.Logistics.WhereDomain.asString().to(value.where);
                return <VerticalTimelineElement
                    key={JSON.stringify(props.binding)}
                    contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                    date={value.when.text}
                    //style= {{ height: "24em" }}
                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    icon={props.icon}
                    // icon={<StatusIcon project={status}/>}
                    //onTimelineElementClick={() => props.inspect(value)}
                >
                    <XLayout.Stack.East>
                        <div style={{ display: 'flex', alignItems: 'center', minHeight: '100%', width: '70%'}}>
                            <MaterialUi.Styler.Heading>{where}</MaterialUi.Styler.Heading>
                        </div>
                        <_EditButton {...props} onClick={props.on.edit}/>
                        <_DeleteButton {...props} onClick={props.on.delete}/>
                    </XLayout.Stack.East>
                </VerticalTimelineElement>;
            }
        }}/>
    )
}

export function AsForm(props: {
    zone: Stores.Zone,
    binding: Model.Events.HasDetails.TypeParams['Binding'],
    editMode: boolean
}) {
    const useStyles = makeStyles((theme) => ({
        root: {
          '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: 200,
          },
        },
    }));
    const classes = useStyles();
      
    const hasDetails = props.zone.streams().property(Model.Events.HasDetails.Descriptor.bind(props.binding));
    return (
        <Propose.PropertyData stream={hasDetails} render={{
            scalar: (value, client) => {
                return <div className={classes.root}>
                    <TextField type="date"
                        autoFocus={true}
                        fullWidth={true}
                        InputLabelProps={{
                            shrink: true,
                          }}
                        value={!value?.when?.dates ? "" : value.when.dates.from.iso8601.replace(new RegExp(/T.*$/), '')}
                        label="From Date"
                        helperText={value?.when?.error}
                        onChange={!props.editMode || !client?.assign ? undefined : (event:any) => { 
                            client.assign({
                                ... value,
                                when: Domains.Logistics.WhenDomain.asDates().from({ 
                                    from: event.target.value,
                                    to: value?.when?.dates?.to?.iso8601 || event.target.value
                                }, value?.when)
                            })
                        }}
                    />
                    <TextField type="date"
                        fullWidth={true}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={!value?.when?.dates ? "" : value.when.dates.to.iso8601.replace(new RegExp(/T.*$/), '')}
                        label="To Date"
                        helperText={value?.when?.error}
                        onChange={!props.editMode || !client?.assign ? undefined : (event:any) => { 
                            client.assign({
                                ... value,
                                when: Domains.Logistics.WhenDomain.asDates().from({ 
                                    from: value?.when?.dates?.from?.iso8601 || event.target.value,
                                    to: event.target.value
                                }, value?.when)
                            })
                        }}
                    />
                    <TextField type="text"
                        fullWidth={true}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={!value?.where ? undefined : Domains.Logistics.WhereDomain.asString().to(value.where)} 
                        label="Where"
                        placeholder="Type location here"
                        onChange={!props.editMode || !client?.assign ? undefined : (event:any) => { 
                            client.assign({
                                ... value,
                                where: Domains.Logistics.WhereDomain.asString().from(event.target.value || "")
                            })
                        }}
                    />
                    <TextField type="text"
                        fullWidth={true}
                        InputLabelProps={{
                            shrink: true,
                          }}
                        value={!value?.when?.times ? "" : Domains.Logistics.TimeOfDayDomain.asString().to(value.when.times.from)}
                        label="From Time"
                        placeholder="Start of workday HH:MM[am|pm]"
                        helperText={value?.when?.error}
                        onChange={!props.editMode || !client?.assign ? undefined : (event:any) => { 
                            client.assign({
                                ... value,
                                when: Domains.Logistics.WhenDomain.asTimes().from({ 
                                    from: event.target.value,
                                    to: value?.when?.times?.to.text || event.target.value
                                }, value?.when)
                            })
                        }}
                    />
                    <TextField type="text"
                        fullWidth={true}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={!value?.when?.times ? "" : Domains.Logistics.TimeOfDayDomain.asString().to(value?.when.times.to)}
                        label="To Time"
                        placeholder="End of workday HH:MM[am|pm]"
                        helperText={value?.when?.error}
                        onChange={!props.editMode || !client?.assign ? undefined : (event:any) => { 
                            client.assign({
                                ... value,
                                when: Domains.Logistics.WhenDomain.asTimes().from({ 
                                    from: value?.when?.times?.from?.text || event.target.value,
                                    to: event.target.value
                                }, value?.when)
                            })
                        }}
                    />
                </div>
            }
        }}/>
    )
}