import faker from 'faker';
import React from 'react';

import {  Table, TableBody } from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';

import * as XLayout from '@pitaman71/react-explicit-layout';
import { Objects } from '@pitaman71/omniglot-live-data';
import { Database, Propose, Styler, Views } from '@pitaman71/omniglot-live-react';

import * as Inspectors from '../../Inspectors';
import * as Modes from '../../Modes';
import * as Themes from '../../Themes';
import * as Whoami from '../../Whoami';

import { ViewOnboarding, ViewPerson } from '.';
import * as Manage from './Manage';

import * as Model from 'models/autosrc/swivell/talent_marketplace';

function _useUpdateViews(props: {
    language: string,
    binding: ViewPerson.Binding
}) {
    const { zone } = React.useContext(Database.Context);
    if(!zone) throw new Error("Missing Context");
    const hasAuthority = Propose.useSetProperty(Model.Persons.HasAuthority.Descriptor.stream(zone, { person: props.binding.person }).set);
    const whoami = React.useContext(Whoami.Context);
    const isAdmin = whoami.hasTag('admin');
    const isBrand = hasAuthority?.values.has('brand');

    const result:Views.OfGoals.Plugin[] = [];
    if(isAdmin) result.push(Manage.AsMembership.make(props.binding));
    if(!!isBrand) result.push(ViewOnboarding.AsSubscription.make(props.binding));
    if(isAdmin) result.push(Manage.AsConnections.make(props.binding));
    if(isAdmin) result.push(Manage.AsDiscovery.make(props.binding));
    result.push(ViewPerson.AsDelete.make(props.binding));
    return result;
}

export function Mine(props: {
    language: string
}) {
    const { zone } = React.useContext(Database.Context);
    const whoami = React.useContext(Whoami.Context);
    if(!zone || !whoami.person) throw new Error("Missing Context");

    const updateViews = [
        ..._useUpdateViews({ ...props, binding: { person:whoami.person }}),
        ViewPerson.AsInvitation.make({ person: Objects.Binding.from_bound(faker.datatype.uuid())})
    ];

    return (
        <Styler.Section prefix="my-account" theme={Themes.Dark} style={{ maxHeight: '100%' }}>
            <XLayout.Center.Horizontal style={{ maxHeight: '100%' }}>
                <XLayout.Stack.South style={{ width: '80%', maxHeight: '100%'}}>
                    <p><Styler.Heading>Manage Your Account</Styler.Heading></p>
                    <Styler.Control prefix={`my-account-views`} style={{ flex: '1', minHeight: "0" }}>
                    <XLayout.Stack.South id="my-account" scroll={true}>
                        <Table>
                            <TableBody>
                                { updateViews.map(view =>  <Inspectors.AsTableRow view={view}/>) }
                            </TableBody>
                        </Table>
                    </XLayout.Stack.South>
                    </Styler.Control>
                </XLayout.Stack.South>
            </XLayout.Center.Horizontal>
        </Styler.Section>
    );    
}

export function Other(props: {
    language: string,
    binding: ViewPerson.Binding
}) {
    const { zone } = React.useContext(Database.Context);
    const whoami = React.useContext(Whoami.Context);
    if(!zone || !whoami.person) throw new Error("Missing Context");
    
    const updateViews = _useUpdateViews(props);
    return (
        <Styler.Section prefix="profile-overview" theme={Themes.Dark} style={{ maxHeight: '100%', maxWidth: '100%' }}>
            <XLayout.Center.Horizontal style={{ maxHeight: '100%' }}>
                <XLayout.Stack.South style={{ width: '80%'}}>
                    <p><Styler.Heading>Manage Other Account</Styler.Heading></p>
                    <XLayout.Stack.South scroll={true}>
                    <Table>
                        <TableBody>
                            { updateViews.map(view => <Inspectors.AsTableRow view={view}/>) }
                        </TableBody>
                    </Table>
                    </XLayout.Stack.South>
                </XLayout.Stack.South>
            </XLayout.Center.Horizontal>
        </Styler.Section>
    );    
}

export const AsMine = new Views.Factory<ViewPerson.Binding, Model.Authority.Tags.ValueType>(['persons', 'account' ], [], ((binding, factory) => new class implements Views.OfGoals.Plugin {
    key(separator: string) { return factory.asPath().key(separator) }
    route() { return factory.asPath().to(binding) }    
    controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin, options?: { ref?: React.RefObject<HTMLButtonElement> }) => JSX.Element) { return <React.Fragment></React.Fragment> }
    icon(): JSX.Element { return <MuiIcons.Edit/> }
    image(pixelDimensions: { width: number, height: number }, imageProps: React.CSSProperties) { return undefined; }
    label(truncate?: { maxChars?: number }): JSX.Element { return  (<span>Revise</span>) }
    summary(options: { onClick: (editMode: boolean) => void, zeroPlaceholder?: () => JSX.Element }): JSX.Element { return  (<span>My Account</span>) }
    fields(): Views.OfFields.Plugin[] { return [{
        render: () => 
          <Database.AsEditor peerId="ViewAccount.AsMyAccount" pollInterval={{ ms: 15000 }} render={zone => <Mine language="en_US"/>}/>
    }] }
}), Modes.AnyMode);
Views.All.push(AsMine);

export const AsOther = new Views.Factory<ViewPerson.Binding, Model.Authority.Tags.ValueType>(['persons', 'account' ], ['person'], ((binding, factory) => new class implements Views.OfGoals.Plugin {
    key(separator: string) { return factory.asPath().key(separator) }
    route() { return factory.asPath().to(binding) }    
    controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin, options?: { ref?: React.RefObject<HTMLButtonElement> }) => JSX.Element) { return <React.Fragment></React.Fragment> }
    icon(): JSX.Element { return <MuiIcons.Edit/> }
    image(pixelDimensions: { width: number, height: number }, imageProps: React.CSSProperties) { return undefined; }
    label(truncate?: { maxChars?: number }): JSX.Element { return  (<span>Revise</span>) }
    summary(options: { onClick: (editMode: boolean) => void, zeroPlaceholder?: () => JSX.Element }): JSX.Element { return  (<span>My Account</span>) }
    fields(): Views.OfFields.Plugin[] { return [{
        render: () => 
          <Database.AsEditor peerId="ViewAccount.AsMyAccount" pollInterval={{ ms: 15000 }} render={zone => <Other language="en_US" binding={binding}/>}/>
    }] }
}), Modes.AnyMode);
Views.All.push(AsOther);
