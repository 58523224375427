import * as rxjs from 'rxjs';
import React from 'react';
import faker from 'faker';

import { makeStyles, Button, ButtonProps, Step, Stepper, StepContent, StepLabel, Typography } from '@material-ui/core';
import { Skeleton  } from '@material-ui/lab';
import * as MuiIcons from '@material-ui/icons';

import * as XLayout from '@pitaman71/react-explicit-layout';
import { Debug, MaterialUi, Styler, Views } from '@pitaman71/omniglot-live-react';

import { getCroppedImg } from './MediaUtils';

import config from '../config.json'

const baseURI = process.env.SWIVELL_SERVICES || ( process.env.NODE_ENV !== 'production' ? 'http://localhost:8080/dev' : config.services['app.swivell'].backend.baseURI );

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    }, button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    }, actionsContainer: {
      marginBottom: theme.spacing(2),
    }, resetContainer: {
      padding: theme.spacing(3),
    },
}));

interface _Media {
    pixelDimensions?: { width: number, height: number}, 
    uri?: string,
    mime?: string,
    optimize?: {
        [format:string]: string    
    }
};

class Service {
    _presignedUrls: Map<string, string>;

    constructor() {
        this._presignedUrls = new Map();
    }

    lookupDataURL(media: _Media, pixelDimensions?: { width: number, height: number}): Promise<_Media> {
        if(!media.uri) return Promise.reject('Media has no uri');
        const use = { ...media, uri: media.uri };
        const optimize = media.optimize;
        if(optimize !== undefined && pixelDimensions !== undefined) {
            if(pixelDimensions.width > pixelDimensions.height) {
                // landscape
                if(pixelDimensions.width < 128 && optimize[128]) {
                    use.uri = optimize[128].replace(/^\/media\//,'');
                } else if(pixelDimensions.width < 512 && optimize[512]) {
                    use.uri = optimize[512].replace(/^\/media\//,'');
                } else if(optimize[2048]) {
                    use.uri = optimize[2048].replace(/^\/media\//,'');
                }
            } else {
                // portrait
                if(pixelDimensions.height < 128 && optimize[128]) {
                    use.uri = optimize[128].replace(/^\/media\//,'');
                } else if(pixelDimensions.height < 512 && optimize[512]) {
                    use.uri = optimize[512].replace(/^\/media\//,'');
                } else if(optimize[2048]) {
                    use.uri = optimize[2048].replace(/^\/media\//,'');
                }    
            }
        }
        if(false /*this._presignedUrls.has(use.uri)*/) {
            return Promise.resolve({
                ...media,
                uri: this._presignedUrls.get(use.uri)
            });
        }
        return this._getDownloadURL(use)
        .then(({ signedUrl }) => {
            this._presignedUrls.set(use.uri, signedUrl);
            if(media.mime) {
                return {
                    ...media,
                    uri: signedUrl
                }
            } else {
                return this._downloadBlob(signedUrl)
                .then(blob => this._makeDataUrl(blob))
                .then(dataUrl_ => {
                    const mime = !dataUrl_ ? undefined : dataUrl_.substring(dataUrl_.indexOf(":")+1, dataUrl_.indexOf(";")).replace('quicktime', 'mp4');
                    if(!mime) return Promise.reject('No mime type in data URL');
                    
                    return {
                        ...media,
                        uri: signedUrl,
                        mime
                    }
                })
        }});
    }

    readAsDataURL(file: Blob) {
        return new Promise<string|ArrayBuffer|null>((resolve, reject) => {
            const fr = new FileReader();
            fr.onload = () => {
                resolve(fr.result);
            }
            fr.onerror = () => {
                reject(fr.error);
            }
            fr.readAsDataURL(file)
        });
    }

    getUploadURL(path: string, size: number) {
        return fetch(baseURI+'/media/upload', {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ path, size })
        })
        .then(response => response.json())
    }

    _getDownloadURL(media: _Media) {
        const mime = media.mime;
        return fetch(baseURI+'/media/download', {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ path: media.uri, mime })
        })
        .then(response => response.json())
    }

    uploadBuffer(signedURL: string, mime: string, buffer: ArrayBuffer) {
        return fetch(signedURL, { 
            method: 'PUT',
            headers: {
                "Content-Type": mime
            },
            body: buffer
        });
    }

    _makeDataUrl(blob: Blob) {
        return new Promise<string>((resolve, reject) =>{
            let reader = new FileReader();
            reader.onload = () => { resolve(reader.result as string) } ;
            reader.readAsDataURL(blob) ;
        });
    }

    _downloadBlob(signedURL: string) {
        return fetch(signedURL, { 
            method: 'GET',
            headers: {
                "Content-Type": "image/jpeg"
            }
        }).then(response => response.blob());
    }
};

const Context = React.createContext<Service|undefined>(undefined);

export function Provide(props: React.PropsWithChildren<{}>) {
    const service = new Service();
    return <Context.Provider value={service}>{props.children}</Context.Provider>
}

export function AsDownloader(props: {
    pixelDimensions: { width: number, height: number},
    style: React.CSSProperties,
    allowOptimize?: boolean,
    value?: _Media,
    render?: (pixelDimensions: { width: number, height: number}, style: React.CSSProperties, url?: string, mime?: string) => JSX.Element,
    id?: string,
    onClick?: () => void
}) {
    const context = React.useContext(Context);
    const [ media, setMedia ] = React.useState<_Media>();

    if(!context) throw new Error('Missing Context');

    React.useEffect(() => {
        const words = props.value?.uri?.split('/') || [];

        if(words.length == 0) {
            // skip
        } else if(words.length > 2 && words[0] === '' && words[1] === 'images')
            context.lookupDataURL({
                ...props.value,
                uri: words.slice(1).join('/')
            }, props.allowOptimize === false ? undefined : props.pixelDimensions).then(media_ => setMedia(media_));
        else if(words[0] == '' && words[1] == 'media')
            context.lookupDataURL({
                ...props.value,
                uri: words.slice(2).join('/')
            }, props.allowOptimize === false ? undefined : props.pixelDimensions).then(media_ => setMedia(media_));
    }, [props.value]);

    
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if(props.value === undefined || !media?.uri || !media?.mime) {
        return <Skeleton id={props.id} variant="rect" {...props.pixelDimensions} style={props.style} onClick={props.onClick}/>
    }
    if(props.render) {
        return props.render(props.pixelDimensions, props.style, media.uri, media.mime);
    }
    return (
        <Debug.Boundary name='Controls.Media._AsDownloader'>
            <React.Fragment>
                { !isSafari && media.mime.split('/')[0] !== 'image' ? [] : <img onClick={props.onClick} id={props.id} style={{ ...props.pixelDimensions, display: 'block', maxWidth: '100%', objectFit: 'cover', objectPosition: 'center', ...props.style }} src={media.uri}/> }
                { isSafari || media.mime.split('/')[0] !== 'video' ? [] : <video onClick={props.onClick} id={props.id} style={{ ...props.pixelDimensions, display: 'block', maxWidth: '100%', objectFit: 'cover', objectPosition: 'center', ...props.style }} src={media.uri} autoPlay={true}/> }
            </React.Fragment>
        </Debug.Boundary>
    )
}

export function AsUploadButton(props: Omit<ButtonProps, 'onChange'> & React.PropsWithChildren<{
    prefix: string,
    multiple?: boolean,
    inputRef?: React.RefObject<HTMLButtonElement>,
    onChange: (blobs_: Blob[]) => void
}>) {
    const id = `${props.prefix}-upload`;
    return (
        <React.Fragment>
            <input type="file" accept="image/*, video/mp4, .mp4" id={id} multiple={props.multiple} style={{ display: 'none' }} onChange={event => {
                if(!event.target.files?.length) {
                    props.onChange([]);
                } else {
                    props.onChange(Array.from(event.target.files))
                }
            }}/>
            <label htmlFor={id}>
                <Styler.Button variant="outlined" {...props} onChange={undefined} component="span" inputRef={props.inputRef}>
                    {props.children}
                </Styler.Button>
            </label> 
        </React.Fragment>
    )
}

export function AsUploader(props: {
    view: Views.OfGoals.Plugin,
    pixelDimensions: { width: number, height: number},
    style: React.CSSProperties,
    render?: (pixelDimensions: { width: number, height: number}, style: React.CSSProperties, url: string) => JSX.Element,
    on: { assign: (value_: _Media) => Promise<any>, clear: () => Promise<any>, cancel: () => Promise<any>, confirm: () => Promise<any> }
}) {
    const context = React.useContext(Context);
    if(!context) throw new Error('Missing Context');
    const classes = useStyles();
    const [ suffix, ] = React.useState(faker.datatype.uuid()+'.jpeg');
    const [ imageFile, setImageFile ] = React.useState<Blob>();
    const [ raw, setRaw ] = React.useState<string>();
    const [ busy, setBusy ] = React.useState(false);
    const inputRef = React.useRef<HTMLInputElement|null>(null);
    
    React.useEffect(() => {
        if(inputRef.current) {
            setTimeout(() => {
                inputRef.current?.click();
            }, 1000);
        }
    }, [inputRef]);

    const activeStep = 
        !!raw ? 1
        : 0;
    const mime = !raw ? undefined : raw.substring(raw.indexOf(":")+1, raw.indexOf(";"));
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    return (
        <div className="scroll-y" style={{ position: 'absolute', height: '100%'}}>
        <Stepper activeStep={activeStep} orientation="vertical">
          <Step key="step-upload">
            <StepLabel>Upload media from your device</StepLabel>
            <StepContent>
                <p><MaterialUi.Styler.Heading>Click the button below to select media from your device.</MaterialUi.Styler.Heading></p>
                <div className={classes.actionsContainer}>
                    <AsUploadButton prefix={props.view.key('-')} multiple={false} onChange={files_ => {
                        if(!files_.length) {
                            props.on.clear();
                        } else {
                            const imageFile = files_[0];
                            setImageFile(imageFile);
                            context.readAsDataURL(imageFile)
                            .then(dataURL => {
                                if(dataURL !== null)
                                    setRaw(dataURL instanceof ArrayBuffer ? new TextDecoder().decode(dataURL) : dataURL);
                            })                    
                        }
                    }}>Upload Media</AsUploadButton>
                </div>
            </StepContent>
          </Step>
          <Step key="step-add">
            <StepLabel>{props.view.label()}</StepLabel>
            <StepContent>
                <p><MaterialUi.Styler.Heading>{props.view.summary({ onClick: () => {} })}?</MaterialUi.Styler.Heading></p>
                <XLayout.Center.Horizontal>
                <Debug.Boundary name='Controls.Media.AsUploader'>
                    <React.Fragment>
                        { !isSafari && !!mime && mime.split('/')[0] !== 'image' ? [] : <img src={raw} style={{ display: 'block', objectFit: 'cover', objectPosition: 'center', ...props.style, width: props.pixelDimensions.width / 4, height: props.pixelDimensions.height/ 4 }}/> }
                        { isSafari || !mime || mime.split('/')[0] !== 'video' ? [] : <video src={raw} style={{ display: 'block', objectFit: 'cover', objectPosition: 'center', ...props.style, width: props.pixelDimensions.width / 4, height: props.pixelDimensions.height/ 4 }} autoPlay={true}/> }
                    </React.Fragment>
                </Debug.Boundary>
                </XLayout.Center.Horizontal>
                <div className={classes.actionsContainer}>
                    <Views.OfControls.AsRow  controls={[
                            {
                                class: Views.OfControls.Direction.Backward,
                                id: 'back',
                                label: () => 'Back',
                                icon: () => <MuiIcons.ArrowLeftOutlined/>,
                                onClick: props.on.cancel
                            },{
                                class: Views.OfControls.Direction.Forward,
                                id: 'add-to-gallery',
                                label: props.view.label,
                                icon: () => <MuiIcons.CheckOutlined/>,
                                onClick: () => { 
                                    if(!raw || !mime) { return Promise.reject() }
                                    setBusy(true);
                                    return context.getUploadURL(suffix, raw.length)
                                    .then(({ signedUrl }) => {
                                        return fetch(raw)
                                        .then(response => response.arrayBuffer())
                                        .then(buffer => context.uploadBuffer(signedUrl, mime, buffer))
                                    }).then(() => {
                                        props.on.assign({ pixelDimensions: props.pixelDimensions, uri: '/media/'+ suffix, mime });
                                        props.on.confirm();
                                    }).then(() => setBusy(false))
                                    .catch(err => {
                                        console.error(err);
                                        setBusy(false);
                                    })
                                 }
                            }
                        ]} render={(controlProps) => <Views.OfControls.AsButton {...controlProps} variant="outlined" className={classes.button} busy={busy}/>} />
                </div>
            </StepContent>
          </Step>
        </Stepper>
        </div>
    )
}


export function AsDeleter(props: {
    view: Views.OfGoals.Plugin,
    pixelDimensions: { width: number, height: number},
    value: _Media,
    on: { cancel: () => Promise<any>, confirm: () => Promise<any> }
}) {
    const classes = useStyles();
  
    return (
        <Debug.Boundary name='Controls.Media.AsDeleter'>
        <div className="scroll-y" style={{ position: 'absolute', height: '100%' }}>
            <p><Styler.Heading>{props.view.summary({ onClick: () => {} })}?</Styler.Heading></p>
            <XLayout.Center.Horizontal>
                <AsDownloader 
                    pixelDimensions={{width: props.pixelDimensions.width / 4, height: props.pixelDimensions.height/ 4}}
                    style={{ display: 'block' }}
                    value={props.value}
                />
            </XLayout.Center.Horizontal>
            <div className={classes.actionsContainer}>
                <Views.OfControls.AsRow  controls={[
                    {
                        class: Views.OfControls.Direction.Backward,
                        id: 'cancel',
                        label: () => 'Cancel',
                        icon: () => <MuiIcons.CancelOutlined/>,
                        onClick: props.on.cancel
                    },{
                        class: Views.OfControls.Direction.Forward,
                        id: 'remove',
                        label: () => 'Remove',
                        icon: () => <MuiIcons.CheckOutlined/>,
                        onClick: props.on.confirm
                    }
                ]} render={(controlProps) => <Views.OfControls.AsButton {...controlProps} variant="outlined" className={classes.button}/>} />
            </div>
        </div>
        </Debug.Boundary>
    )
}
