import React from 'react';

import {  Typography  } from '@material-ui/core';

import * as MuiIcons from '@material-ui/icons';

import { Objects, Stores } from '@pitaman71/omniglot-live-data';
import { MaterialUi, Propose, Views } from '@pitaman71/omniglot-live-react';
import * as XLayout from '@pitaman71/react-explicit-layout';

import * as Model from 'models/autosrc/swivell/talent_marketplace';
import * as NounProject from '../../NounProject';
import * as Whoami from '../../Whoami';

import { Controller as ProjectController } from 'models/src/Projects';

export let pollInterval = { ms: 60000 };

export interface Binding extends Objects.BindingType<string> { candidate: Objects.Binding<string> };

function _AsThumb(props: {
    value: undefined|string 
}) {
    return (
        <div style={{ margin: '1em', display: 'flex', flexDirection: 'row' }}>
            {
                props.value === undefined
                ? <MaterialUi.Styler.Heading>?</MaterialUi.Styler.Heading>
                : props.value === 'Accept' 
                ? <MuiIcons.ThumbUpOutlined/>
                : <MuiIcons.ThumbDownOutlined/>
            }
        </div>
    )
}

export function If(props: {
    zone: Stores.Zone,
    binding: Binding,
    predicate: (
        brandStatus: undefined|Model.Candidates.HasBrandStatus.TypeParams['Value'],
        talentStatus: undefined|Model.Candidates.HasBrandStatus.TypeParams['Value']
    ) => boolean,
    children: JSX.Element | JSX.Element[]
}) {
    const hasBrandStatus = Propose.useScalarProperty(Model.Candidates.HasBrandStatus.Descriptor.stream(props.zone, props.binding).scalar);
    const hasTalentStatus = Propose.useScalarProperty(Model.Candidates.HasTalentStatus.Descriptor.stream(props.zone, props.binding).scalar);

    return (
        <React.Fragment>
            { props.predicate(hasBrandStatus.value, hasTalentStatus.value) ? props.children : [] }
        </React.Fragment>
    );
}

export function AsSummary(props: {
    zone: Stores.Zone,
    binding: Binding
}) {
    const hasBrandStatus = Propose.useScalarProperty(Model.Candidates.HasBrandStatus.Descriptor.stream(props.zone, props.binding).scalar);
    const hasTalentStatus = Propose.useScalarProperty(Model.Candidates.HasTalentStatus.Descriptor.stream(props.zone, props.binding).scalar);

    return (
        <XLayout.Center.Horizontal>
            <XLayout.Stack.East>
            <_AsThumb value={hasBrandStatus.value}/>
            <div style={{ transform: "scale(2.0)", display: "flex", margin: "0 1em", alignItems: "center" }}><NounProject.Handshake/></div>
            <_AsThumb value={hasTalentStatus.value}/>
            </XLayout.Stack.East>
        </XLayout.Center.Horizontal>
    );
}

export function AsControls(props: {
    zone: Stores.Zone,
    binding: Binding
}) {
    const whoami = React.useContext(Whoami.Context);
    if(!whoami.person) throw new Error('Missing Context');
    const me = whoami.person;

    const hasBrandStatus = Propose.useScalarProperty(Model.Candidates.HasBrandStatus.Descriptor.stream(props.zone, props.binding).scalar);
    const hasTalentStatus = Propose.useScalarProperty(Model.Candidates.HasTalentStatus.Descriptor.stream(props.zone, props.binding).scalar);
    const hasProject = Propose.useRelation(Model.Candidates.HasProject.Descriptor.stream(props.zone, props.binding));
    const hasRole = Propose.useRelation(Model.Candidates.HasRole.Descriptor.stream(props.zone, props.binding));
    const project = hasProject.entries.length === 0 ? undefined : hasProject.entries[0].project;
    const role = hasRole.entries.length === 0 ? undefined : hasRole.entries[0].role;

    return (
        <Views.OfControls.AsRow controls={[
            {
                class: Views.OfControls.Direction.Forward,
                id: 'accept',
                label: () => 'Accept Offer',
                icon: () => <MuiIcons.ThumbUpOutlined/>,
                onClick: !project || !role || hasBrandStatus.value !== 'Accept' || hasTalentStatus.value === 'Accept' ? undefined : () => {
                    ProjectController.accept(props.zone, { me, project, role }, { candidate: props.binding.candidate }).exec();
                    return props.zone.commitAll();
                }
            },{
                class: Views.OfControls.Direction.Backward,
                id: 'reject',
                label: () => 'Reject Offer',
                icon: () => <MuiIcons.ThumbDownOutlined/>,
                onClick: !project || !role || hasBrandStatus.value !== 'Accept' || hasTalentStatus.value === 'Reject' ? undefined : () => {
                    ProjectController.reject(props.zone, { me, project, role }, { candidate: props.binding.candidate }).exec();
                    return props.zone.commitAll();
                }
            }
        ]} render={controlProps => <Views.OfControls.AsButton {...controlProps} variant="outlined"/>} />
    )
}
