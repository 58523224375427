import faker from 'faker';
import { Dialogues, Definitions, Objects, Properties, Relations, Values } from '@pitaman71/omniglot-live-data';
import { Domains, Modules, WrapFaker } from '@pitaman71/omniglot-live-domains';
import * as Elevated from '@pitaman71/omniglot-introspect';

export const directory = new Definitions.Directory();
// enumeration Authority.Tags

export type ValueType = 'admin'|'ambassador'|'admitted'|'brand'|'creator';
export const Domain = new Values.EnumerationDomain('admin','ambassador','admitted','brand','creator');
        
