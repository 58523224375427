import faker from 'faker';
import { Dialogues, Definitions, Objects, Properties, Relations, Values } from '@pitaman71/omniglot-live-data';
import { Domains, Modules, WrapFaker } from '@pitaman71/omniglot-live-domains';
import * as Elevated from '@pitaman71/omniglot-introspect';

export const directory = new Definitions.Directory();
// relation Content.Includes

export interface BindingType extends Objects.BindingType<string> { topic: Objects.Binding<string>; asset: Objects.Binding<string> }

export const Descriptor = new class _Descriptor extends Relations.Descriptor<BindingType> {
    canonicalName: string = `generated.Content.Includes`;
    build(builder: Relations.Builder<BindingType>): void {
        builder.object('topic');
        builder.set();
        builder.object('asset');
    }
};

directory.descriptors.relations.set(Descriptor.canonicalName, Descriptor);
        
