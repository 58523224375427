import React from 'react';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import * as NounProject from '../../NounProject';

import * as XLayout from '@pitaman71/react-explicit-layout';
import { Objects } from '@pitaman71/omniglot-live-data';
import { Database, MaterialUi, Propose, Styler, Views } from '@pitaman71/omniglot-live-react';
import * as ViewPerson from './ViewPerson';

import * as Modes from '../../Modes';
import * as Whoami from '../../Whoami';

import * as Model from 'models/autosrc/swivell/talent_marketplace';

import * as SocialAccess from 'models/src/Social/Access';
import * as SocialController from 'models/src/Social/Controller';

function _AsStatus(props: {
    binding: { from: Objects.Binding<string>, to: Objects.Binding<string> }
}) {
    const { zone } = React.useContext(Database.Context);
    const whoami = React.useContext(Whoami.Context);
    if(!zone || !whoami.person) throw new Error('Missing Context');
    const me = whoami.person;
    const status = Propose.useSetProperty(React.useMemo(() => SocialAccess.hasStatus(zone, { ...props.binding }), []));
    return status.values.has('new') ? <span>New</span> 
    : status.values.has('rejected') || status.values.has('rejecting') ? <span>Rejected</span> 
    : status.values.has('invited') || status.values.has('inviting') ? <span>Invited</span> 
    : status.values.has('connected') ? <span>Connected</span> 
    : <span>Undecided</span>;
}

function _AsControls(props: {
    binding: { from: Objects.Binding<string>, to: Objects.Binding<string> }
}) {
    const { zone } = React.useContext(Database.Context);
    const whoami = React.useContext(Whoami.Context);
    if(!zone || !whoami.person) throw new Error('Missing Context');
    const me = whoami.person;
    const connect = SocialController.Connect(zone, { me, ...props.binding });
    const disconnect = SocialController.Disconnect(zone, { me, ...props.binding });
    return (
        <XLayout.Stack.East>
            <Styler.Button onClick={() => {
                connect.reset();
                zone.commitAll();
            }}>Connect</Styler.Button>
            <Styler.Button onClick={() => {
                disconnect.reset();
                zone.commitAll();
            }}>Disconnect</Styler.Button>
        </XLayout.Stack.East>
    )
}

export function AsTable(props: {
    language: string,
    binding: { person: Objects.Binding<string> }
}) {
    const { zone } = React.useContext(Database.Context);
    if(zone === undefined) throw new Error('Missing Context');
    return (
        <XLayout.Stack.East style={{ maxHeight: '100%', maxWidth: '100%' }}>
            <Styler.Section prefix="persons-manage-connections-me" style={{ flex: '1', minWidth: 0 }}>
                <XLayout.Center.Both>
                    <XLayout.Stack.South style={{ maxWidth: '100%' }}>
                        <Styler.Heading>Manage Connections :</Styler.Heading>
                        <ViewPerson.AsName zone={zone} binding={props.binding}/>
                    </XLayout.Stack.South>
                </XLayout.Center.Both>
            </Styler.Section>
            <Styler.Section prefix="persons-manage-connections-others"  style={{ flex: '4', minWidth: 0 }}>
                <XLayout.Stack.South id="persons-viewconnections" scroll={true} style={{ height: '100%', width: "100%" }}>
                    <Table style={{ tableLayout: 'fixed' }}>
                        <TableBody>
                            <ViewPerson.AsTableRows
                                prefix="person-manage-connections" 
                                except={[props.binding.person]} 
                                show={{ avatar: true, header: true, email: false }}
                                extras={[
                                    binding => <_AsStatus binding={{ from: props.binding.person, to: binding.person }}/>,
                                    binding => <_AsControls binding={{ from: props.binding.person, to: binding.person }}/>
                                ]}
                            />
                        </TableBody>
                    </Table>
                </XLayout.Stack.South>
            </Styler.Section>
        </XLayout.Stack.East>
    )
}
