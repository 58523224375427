import React from 'react';

import { Avatar } from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';

import { Clients, Objects, Stores, Streams } from '@pitaman71/omniglot-live-data';
import { Database, Navigation, Propose, Views } from '@pitaman71/omniglot-live-react';

import * as Model from 'models/autosrc/swivell/talent_marketplace';
import * as Controls from '../../Controls';
import * as Whoami from '../../Whoami';

export type ImageGenerator = (height?: number, width?: number, randomize?: boolean) => string;

export let pollInterval = { ms: 60000 };

type Binding = Model.Persons.HasAvatar.TypeParams['Binding'];
class Inspect {
    _zone: Stores.Zone;
    _binding: Binding;
    _streams: {
        hasAvatar: Streams.PropertyStream<Model.Persons.HasAvatar.TypeParams>
    }

    constructor(
        zone: Stores.Zone,
        binding: Binding,
    ) {
        this._zone = zone;
        this._binding = { ... binding };  
        this._streams = {
            hasAvatar: zone.streams().property(Model.Persons.HasAvatar.Descriptor.bind(binding))
        }
    }

    icon(): JSX.Element { return <MuiIcons.DynamicFeed/> }
}

function _SwivellArc(props: {
    binding: {
        person: Objects.Binding<string>
    },
    pixelDimensions: {
        width: number,
        height: number
    },
    stroke: number
}) {
    const { zone } = React.useContext(Database.Context);
    const center = {
        x: props.pixelDimensions.width / 2,
        y: props.pixelDimensions.height / 2
    };
    const radius = {
        x: (props.pixelDimensions.width - (1 + props.stroke)) / 2,
        y: (props.pixelDimensions.height - (1 + props.stroke)) / 2
    }
    const hasAuthority = zone?.streams().property(Model.Persons.HasAuthority.Descriptor.bind(props.binding));
    return !hasAuthority ? <React.Fragment></React.Fragment> : <Propose.PropertyData
        stream={hasAuthority}
        render={{
            set: (value, client) => (
                !value?.has('admin') ? <React.Fragment></React.Fragment> : <div style={{ display: 'flex', position: 'absolute', height: 'auto', width: 'auto' }}>
                    <svg width={props.pixelDimensions.width} height={props.pixelDimensions.height} version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <g transform={`rotate(-10 ${center.x} ${center.y})`}>
                            <path stroke="red" stroke-width={props.stroke} fill="transparent" d={`M ${center.x + radius.x} ${center.y} A ${radius.x} ${radius.y} 0 0 0 ${center.x} ${center.y - radius.y}`}/>
                            <path stroke="red" stroke-width={props.stroke} fill="transparent" d={`M ${center.x - radius.x} ${center.y} A ${radius.x} ${radius.y} 0 0 0 ${center.x} ${center.y + radius.y}`}/>
                        </g>
                    </svg>
                </div>                                
            )
        }}
    />
}
export class View extends Inspect {
    key(separator: string) { return ['persons', 'hasAvatar', 'view'].join(separator) }
    route() { return '/persons/hasAvatar/view/'+(this._binding.person.objectId?.toString() || "?") }
    controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin) => JSX.Element) { return <Views.OfControls.Generic filter={filter} render={render} on={{
        upload: (controller) => {
            controller.forward('/persons/hasAvatar/upload/'+(this._binding.person.objectId?.toString()||'?'));
            return Promise.resolve();
        },
        cancel: (controller) => {
            !!controller.backward ? controller.backward() : controller.forward(Controls.Persons.ViewProfile.AsProfile.asPath().to({ person: this._binding.person }));
            return Promise.resolve();
        }
    }}/>}
    icon(): JSX.Element { return <MuiIcons.PhotoOutlined/> }
    image(pixelDimensions: { width: number, height: number }, style: React.CSSProperties) {
        return <Propose.PropertyData
            stream={this._streams.hasAvatar}
            render={{
                scalar: (value, client) => {
                    const diameter = Math.min(pixelDimensions.width, pixelDimensions.height);
                    const stroke = Math.ceil(diameter * 0.02); // stroke width and gap width
                    return !value ? <Avatar style={{
                        width: diameter,
                        height: diameter,
                        ...style
                    }}><MuiIcons.PersonOutlined/></Avatar> 
                        : <Controls.Media.AsDownloader pixelDimensions={{ width: diameter, height: diameter }} style={style} value={value} render={(pixelDimensions, style, src) => (
                            <div style={{ ...pixelDimensions, display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                                <Avatar src={src} style={{ width: pixelDimensions.width - (1 + stroke * 4), height: pixelDimensions.height - (1 + stroke * 4), objectFit: 'cover', backgroundColor: 'transparent' }}/>
                                <_SwivellArc binding={this._binding} stroke={stroke} pixelDimensions={pixelDimensions}/>
                            </div>
                        )
                    }/>
                }
            }}
        />;
    }
    label(truncate?: { maxChars?: number }): JSX.Element { 
        return  (<span>View Avatar</span>);
    }
    summary(options: { onClick: (editMode:boolean) => void, zeroPlaceholder?: () => JSX.Element }): JSX.Element { 
        return <span>View Avatar Image</span>
    }
    fields(): Views.OfFields.Plugin[] { 
        return [ ]
    }
}

function _AsUploader(props: {
    view: Views.OfGoals.Plugin,
    zone: Stores.Zone,
    binding: Model.Persons.HasAvatar.TypeParams['Binding'],
    client: Clients.ScalarClient<Model.Persons.HasAvatar.TypeParams['Value']>,
    pixelDimensions: { width: number, height: number }, 
    style: React.CSSProperties
}) {
    const controller = React.useContext(Navigation.Context);
    const whoami = React.useContext(Whoami.Context);
    const finish = () => {
        if(controller.backward)
            controller.backward()
        else
            controller.forward(Controls.Persons.ViewProfile.AsProfile.asPath().to({ person: props.binding.person }));
        return Promise.resolve();
    }
    const on = {
        assign: (value_: Model.Persons.HasAvatar.TypeParams['Value']) => {
            props.client.assign(value_);
            return Promise.resolve();
        },
        clear: () => {
            props.client.clear();
            return Promise.resolve();
        },
        confirm: () => { 
            const promise = new Promise((resolve, reject) => {
                props.zone.commitAll().then(resolve).catch(reject);
            });
            return promise.then(finish);
        }, cancel: finish
    };
    return <Controls.Media.AsUploader {...props} style={props.style} on={on}/>    
}

export class Upload extends View {
    key(separator: string) { return ['persons', 'hasAvatar', 'upload'].join(separator) }
    route() { return '/persons/hasAvatar/upload/'+(this._binding.person.objectId?.toString() || "?") }
    controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin, options?: { ref?: React.RefObject<HTMLButtonElement> }) => JSX.Element) { return <React.Fragment></React.Fragment> }
    label(truncate?: { maxChars?: number }): JSX.Element { 
        return  (<span>Upload Avatar</span>);
    }
    summary(options: { onClick: (editMode:boolean) => void, zeroPlaceholder?: () => JSX.Element }): JSX.Element { 
        return <span>Upload Avatar Image</span>
    }
    image(pixelDimensions: { width: number, height: number }, style: React.CSSProperties) {
        return <Propose.PropertyData
            stream={this._streams.hasAvatar}
            render={{
                scalar: (value, client) => {
                    if(!client) return <React.Fragment></React.Fragment>;
                    return <div style={style}><_AsUploader view={this} pixelDimensions={pixelDimensions} style={style} zone={this._zone} binding={this._binding} client={client}/></div>
                }
            }}
        />;
    }
}

