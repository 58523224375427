import React from 'react';

import { Objects } from '@pitaman71/omniglot-live-data';
import { Interview, Styler, Views } from '@pitaman71/omniglot-live-react';
import * as XLayout from '@pitaman71/react-explicit-layout';

import config from './config.json';
import * as HeroIcons from './HeroIcons';
import * as MuiIcons from '@material-ui/icons';
import * as Whoami from './Whoami';

const baseURI = process.env.SWIVELL_SERVICES || ( process.env.NODE_ENV !== 'production' ? 'http://localhost:8080/dev' : config.services['app.swivell'].backend.baseURI );

function urlBase64ToUint8Array(base64String: string) {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
      .replace(/\-/g, "+")
      .replace(/_/g, "/");
  
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
  
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}
  
export function Provide(props: React.PropsWithChildren<{}>) {
    const [ error, setError ] = React.useState<{
        swNotSupported?: boolean
    }>({});
    const [ status, setStatus ] = React.useState<'checking'|'askInstall'|'askEnable'|'done'>('done');
    const [ registration, setRegistration ] = React.useState<ServiceWorkerRegistration|undefined>(undefined);
    const whoami = React.useContext(Whoami.Context);

    const checkSubscription = (registration: ServiceWorkerRegistration) => {
        if(!registration.pushManager) {
            console.log(`PushManager missing ... this device is probably iOS Safari`);
            setStatus('askInstall');
            return;
        }
        console.log(`Service worker is active, subscribing to push messages`);
        return registration.pushManager.getSubscription().then((subscription: PushSubscription|null) => {
            if(subscription) {
                console.log('Push subscription already exists');
                setStatus('done');
                return;
            } else {
                setRegistration(registration);
                setStatus('askEnable');
            }
        });
    }

    const askSubscription = () => {
        if(!registration) return Promise.reject('registration not set');
        return registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array(config.services['app.swivell'].vapid.publicKey),
        }).then(subscription => {
            return fetch(`${baseURI}/notification/subscribe`, {
                method: "POST",
                body: JSON.stringify({ request: { person: whoami.person, subscription } }),
                headers: {
                "content-type": "application/json",
                },
            });
        }).then(() => fetch(`${baseURI}/notification/send`, {
            method: "POST",
            body: JSON.stringify({ request: { person: whoami.person, payload: {
                title: 'Swivell Notifications Signup',
                body: 'Notification Signup Successful'
            } } }),
            headers: {
            "content-type": "application/json",
            },
        })).then(() => setStatus('done'))
        .catch(err => setStatus('done'));
    };

    /* React.useEffect(() => {
        if(!whoami.person) {
            // do nothing, wait for authentication to provide person object
        } else if(!("serviceWorker" in navigator)) {
            setError({ swNotSupported: true });
        } else {
            navigator.serviceWorker.register("/service-worker.js", {
                scope: "/",
            }).then(register => {
                console.log(`Service Worker registration active = ${register.active?.state}`)
                if(register.active) checkSubscription(register);
                else {
                    const target = (register.installing || register.waiting);
                    if(!target) {
                        setStatus('askInstall')
                    } else {
                        target.addEventListener('statechange',(event) => {
                            const serviceWorker = event.target as ServiceWorker;
                            if(serviceWorker.state === 'activated') checkSubscription(register);
                        });
                    }
                }
            });
        }
    }, [whoami.person]); */
    if(status === 'checking') {
        return <XLayout.Center.Both>
            <Styler.Heading>Checking Notification Setup</Styler.Heading>
        </XLayout.Center.Both>
    } else if(status === 'askInstall') {
        return <XLayout.Center.Both><Interview.Waypoint buttonStyle={{ width: '100%' }} progress={{
                key: 'push-ask-install',
                status: () => [
                    <span>Add to Home Screen</span>,
                ],
                details: () => [
                    <Styler.Body>It is recommended that you add the Swivell App to your home screen.</Styler.Body>,
                    <Styler.Body>This will allow you to enable and disable receiving push notifications from Swivell.</Styler.Body>,
                    <Styler.Body>Click on the "Share" icon:</Styler.Body>,
                    <XLayout.Center.Horizontal>
                        <img src="/ios-share-button.jpg" style={{ maxWidth: '80%' }}/>,
                    </XLayout.Center.Horizontal>,
                    <Styler.Body>Scroll down and click "Add to Home Screen":</Styler.Body>,
                    <XLayout.Center.Horizontal>
                        <img src="/ios-addto-home.jpg" style={{ maxWidth: '80%' }}/>,
                    </XLayout.Center.Horizontal>
                ],
                options: [
                    {
                        class: Views.OfControls.Direction.Lateral,
                        id: 'install-decline',
                        label: () => <span>Skip</span>,
                        icon: ({mode}) => <MuiIcons.CancelOutlined/>,
                        onClick: () => {
                            setStatus('done');
                            return Promise.resolve();
                        }
                    }
                ]
        }}/></XLayout.Center.Both>
    } else if(status === 'askEnable') {
        return <XLayout.Center.Both><Interview.Waypoint progress={{
                key: 'push-ask-enable',
                status: () => [
                    <span>Not Subscribed to Push Notifications</span>,
                ],
                details: () => [
                    <span>Please click the button below and grant permission for Swivell to deliver push notifications to you on this device.</span>
                ],
                options: [
                    {
                        class: Views.OfControls.Direction.Forward,
                        id: 'push-enable',
                        label: () => <span>Enable Push Notifications</span>,
                        icon: ({mode}) => <HeroIcons.ArrowCircle variant='outline' direction='right'/>,
                        onClick: askSubscription                            
                    },{
                        class: Views.OfControls.Direction.Lateral,
                        id: 'push-decline',
                        label: () => <span>Skip</span>,
                        icon: ({mode}) => <MuiIcons.CancelOutlined/>,
                        onClick: () => {
                            setStatus('done');
                            return Promise.resolve();
                        }
                    }
                ]
        }}/></XLayout.Center.Both>
    }
    return <React.Fragment>{props.children}</React.Fragment>;
}

