import { Definitions } from '@pitaman71/omniglot-live-data';

import * as Mood from './Mood';
import * as HasMedia from './HasMedia';
import * as HasCaption from './HasCaption';
import * as Topic from './Topic';
import * as HasAuthor from './HasAuthor';
import * as Asset from './Asset';
import * as Includes from './Includes';
const directory = Definitions.Directory.from(Mood.directory,HasMedia.directory,HasCaption.directory,Topic.directory,HasAuthor.directory,Asset.directory,Includes.directory)
export { directory, Mood, HasMedia, HasCaption, Topic, HasAuthor, Asset, Includes }; 
