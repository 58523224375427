import { Definitions } from '@pitaman71/omniglot-live-data';

import * as CreativeDirector from './CreativeDirector';
import * as Photographer from './Photographer';
import * as ArtDirector from './ArtDirector';
import * as Noun from './Noun';
import * as Influencer from './Influencer';
import * as Videographer from './Videographer';
import * as Model from './Model';
import * as Stylists from './Stylists';
const directory = Definitions.Directory.from(CreativeDirector.directory,Photographer.directory,ArtDirector.directory,Noun.directory,Influencer.directory,Videographer.directory,Model.directory,Stylists.directory)
export { directory, CreativeDirector, Photographer, ArtDirector, Noun, Influencer, Videographer, Model, Stylists }; 
