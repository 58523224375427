import faker from 'faker';
import React from 'react';

import {  InputAdornment, TextField, useMediaQuery  } from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';

import * as Model from 'models/autosrc/swivell/talent_marketplace';
import * as DiscussionController from 'models/src/Discussion/Controller';

import { Objects, Stores } from '@pitaman71/omniglot-live-data';
import { MaterialUi, Propose, Styler } from '@pitaman71/omniglot-live-react';
import * as XLayout from '@pitaman71/react-explicit-layout';

import { ViewCandidate } from '../Candidates';
import { ViewPerson } from '../Persons';
import * as Whoami from '../../Whoami';

export interface Binding extends Objects.BindingType<string> {
    comment: Objects.Binding<string>
}

function _AsAuthor(props: {
    zone: Stores.Zone,
    binding: Binding
}) {
    const hasAuthor = Propose.useRelation(Model.Discussion.HasAuthor.Descriptor.stream(props.zone, props.binding));    
    return hasAuthor.entries.length === 0 ? <React.Fragment></React.Fragment> : <ViewPerson.AsName zone={props.zone} binding={{ person: hasAuthor.entries[0].author }}/>
}

function _AsAtTime(props: {
    zone: Stores.Zone,
    binding: Binding
}) {
    const atTime = Propose.useScalarProperty(Model.Discussion.AtTime.Descriptor.stream(props.zone, props.binding).scalar);
    return <span>{atTime.value === undefined ? "" : new Date(atTime.value).toLocaleString()}</span>;
}

function _AsBody(props: {
    zone: Stores.Zone,
    binding: Binding
}) {
    const hasBody = Propose.useScalarProperty(Model.Discussion.HasBody.Descriptor.stream(props.zone, props.binding).scalar);
    return hasBody.value === undefined ? <React.Fragment></React.Fragment> : <span>{hasBody.value}</span>;
}

function _AsOffer(props: {
    zone: Stores.Zone,
    binding: Binding
}) {
    const hasOffer = Propose.useRelation(Model.Discussion.HasOffer.Descriptor.stream(props.zone, props.binding));
    return (
        hasOffer.entries.length === 0 ? <React.Fragment></React.Fragment>
        : <React.Fragment>
            <MaterialUi.Styler.Heading align="center" style={{ width: "100%" }}>OFFER</MaterialUi.Styler.Heading>
            <XLayout.Center.Horizontal><ViewCandidate.AsCard zone={props.zone} binding={hasOffer.entries[0]} pixelDimensions={{ width: 150, height: 200 }}/></XLayout.Center.Horizontal> 
        </React.Fragment>
    );
}

export function AsHistoryEntry(props: {
    zone: Stores.Zone,
    binding: Binding,
    viewport?: React.RefObject<HTMLDivElement>
}) {
    const whoami = React.useContext(Whoami.Context);
    if(!whoami.person) throw new Error('Missing Context');
    const me = whoami.person;

    const ref = React.useRef<HTMLDivElement>(null);
    const hasAuthor = Propose.useRelation(Model.Discussion.HasAuthor.Descriptor.stream(props.zone, props.binding));
    const author = hasAuthor.entries.length === 0 ? undefined : hasAuthor.entries[0].author;
    const side = author?.objectId === whoami.person?.objectId ? "right" : "left";
    const seenAtTime = Propose.useScalarProperty(Model.Discussion.SeenAtTime.Descriptor.stream(props.zone, { comment: props.binding.comment, person: me }).scalar);

    React.useEffect(() => {
        if(seenAtTime.value || !seenAtTime.client) {
            // skip
        } else if(props.viewport?.current && ref.current) {
            const observer_ = (new IntersectionObserver(entries => {
                let inView_ = false;
                entries.forEach(entry => {
                    if(entry.intersectionRatio > 0.5) {
                        inView_ = true;
                    }
                })
                if(inView_) {
                    seenAtTime.client?.assign(new Date().toISOString());
                    props.zone.commitAll();
                }
            }, {
                root: props.viewport.current,
                rootMargin: "50%",
                threshold: [
                    0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1
                ],
            }));
            observer_.observe(ref.current);
            return () => observer_.disconnect();
        }
    }, [ props.viewport?.current, ref.current, seenAtTime.value ]);

    return (
        <Styler.Control prefix="viewcomment-ashistoryentry" style={{ width: "100%", marginBottom: "1em" }} key={props.binding.comment.objectId||"?"}>
            <div style={{ width: '100%', display: 'flex', justifyContent: "center" }}>
                { /* when */ }
                <_AsAtTime {...props}/>
            </div>
            <div style={{ width: '70%', justifyContent: side, float: side, display: side === 'right' ? "none" : "fiex", textAlign: side }}>
                { /* author */ }
                <MaterialUi.Styler.Heading><b>@<_AsAuthor {...props}/></b></MaterialUi.Styler.Heading>
            </div>
            { /* body */ }                
            <div ref={ref} style={{ width: '70%', display: 'flex', flexDirection: 'column', justifyContent: side, float: side, border: "1px solid white", borderRadius: "5px", padding: "0.5em" }}>
                <MaterialUi.Styler.Body align="left" style={{ width: "100%" }}><_AsBody {...props}/></MaterialUi.Styler.Body>
                <_AsOffer {...props}/>
            </div>
        </Styler.Control>
    )
}

export function AsAppend(props: {
    zone: Stores.Zone,
    binding: { subject: Objects.Binding<string> },
    recipients: Objects.Binding<string>[]
}) {
    const whoami = React.useContext(Whoami.Context);
    if(!whoami.person) throw new Error('Missing Context');
    const me = whoami.person;
    
    const [ busy, setBusy ] = React.useState(false);
    const [ body, setBody ] = React.useState<string|null>(null);

    const send = () => {
        const controller = DiscussionController.appendComment(props.zone, {
            subject: props.binding.subject,
            author: me
        }, props.recipients.map(recipient => ({ person: recipient })), { body });

        setBusy(true);
        controller.exec();
        props.zone.commitAll().then(() => {
            setBody(null);
            setBusy(false);
        })
    };

    const format = {
        wide: useMediaQuery('(min-width: 768px)')
    };
    return <Styler.Input
        type="text"
        autoFocus={true} 
        fullWidth={true} 
        multiline={format.wide ? false : true}
        maxRows={4}            
        placeholder="Type your message here" 
        value={body || ""} 
        disabled={busy}
        onKeyDown={(ev) => {
            if (ev.key === 'Enter') {
                ev.preventDefault();
                send();
            }
        }}            
        onChange={(event) => {
            if(event.target.value === null || event.target.value === undefined)
                setBody(null);
            else
                setBody(event.target.value)
        }} 
        endAdornment={<InputAdornment position="end">
            <Styler.IconButton style={{ padding: '0 0.5em' }} onClick={(e) => {
                e.preventDefault();
                send();
            }}>
                <MuiIcons.Send/>
            </Styler.IconButton>
        </InputAdornment>}
    />
}

