import React from 'react';

import * as XLayout from '@pitaman71/react-explicit-layout';
import { Objects, Stores } from '@pitaman71/omniglot-live-data';
import { Database, MaterialUi, Navigation, Propose, Styler, Views } from '@pitaman71/omniglot-live-react';

import * as Model from 'models/autosrc/swivell/talent_marketplace';
import { Controller as ContentController } from 'models/src/Content';

import * as HeroIcons from '../../HeroIcons';
import * as Modes from '../../Modes';
import { Media } from '../'

import { ViewIncludes } from '../Content';

import { ViewCreatorOnboarding, ViewPerson } from '.';

import config from '../../config.json';

const baseURI = process.env.SWIVELL_SERVICES || ( process.env.NODE_ENV !== 'production' ? 'http://localhost:8080/dev' : config.services['app.swivell'].backend.baseURI );

const quota = 4;

export interface Binding extends Objects.BindingType<string> {
    person: Objects.Binding<string>
}

function _AsAsset(props: {
    binding: Binding & { asset: Objects.Binding<string> }
    pixelDimensions: { width:number, height:number }
}) {
    const access = ViewPerson.useAccess(props.binding);

    return (
        <XLayout.Stack.South style={{ height: '100%', width: '100%'}}>
            <div style={{ flex: '0 0 auto', padding: '1em'}}>
                <ViewPerson.AsHeader binding={props.binding} title="Gallery"/>
            </div>
            <XLayout.Stack.South id="person-asset" style={{ flex: '1', padding: '1em'}} scroll={true}>
                <ViewIncludes.AsCarousel
                    binding={{ topic: props.binding.person, asset: props.binding.asset }}
                    show={{ controls: access.edit.value }}
                    pixelDimensions={props.pixelDimensions}
                />
            </XLayout.Stack.South>
        </XLayout.Stack.South>
    )
}

function _AsGallery(props: {
    binding: Binding
}) {
    const navigation = React.useContext(Navigation.Context);
    const access = ViewPerson.useAccess(props.binding);
    
    return (
        <XLayout.Stack.South style={{ height: '100%', width: '100%'}}>
            <div style={{ flex: '0 0 auto', padding: '1em'}}>
                <ViewPerson.AsHeader binding={props.binding} title="Gallery"/>
            </div>
            <XLayout.Stack.South id="person-gallery" style={{ flex: '1', padding: '1em'}} scroll={true}>
                <ViewIncludes.AsGrid 
                    binding={{ topic: props.binding.person }}
                    prefix="person-gallery"
                    shape={{ rows: 4, columns: 3 }}
                    major='horizontal'
                    scroll={true}
                    show={{ controls: access.edit.value, fill: false }}
                    onClick={({ asset }) => navigation.forward(AsGalleryItem.asPath().to({ person: props.binding.person, asset }))}
                />
            </XLayout.Stack.South>
        </XLayout.Stack.South>
    )
}

export const AsGalleryItem = new Views.Factory<Binding & { asset: Objects.Binding<string> }, Model.Authority.Tags.ValueType>(['persons', 'gallery', 'item'], ['person', 'asset'], ((binding, factory) => new class implements Views.OfGoals.Plugin {
    key(separator: string) { return factory.asPath().key(separator) }
    route() { return factory.asPath().to(binding) }    
    provide(children: JSX.Element[]|JSX.Element) { return <Database.AsEditor peerId="content-asset" pollInterval={{ ms: 15000 }} render={zone => <React.Fragment>{children}</React.Fragment>}/> }
    controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin, options?: { ref?: React.RefObject<HTMLButtonElement> }) => JSX.Element) { return <React.Fragment></React.Fragment> }
    icon(props: { mode: Views.OfControls.Modes }): JSX.Element { return <HeroIcons.Photo variant={ props.mode === Views.OfControls.Modes.Selected ? "solid" : "outline" }/> }
    image(pixelDimensions: { width: number, height: number }, imageProps: React.CSSProperties) { return <_AsAsset binding={binding} pixelDimensions={pixelDimensions}/>; }
    label(truncate?: { maxChars?: number }): JSX.Element { return  (<span>Asset</span>) }
    summary(options: { onClick: (editMode: boolean) => void, zeroPlaceholder?: () => JSX.Element }): JSX.Element { return  (<span>View Media Asset</span>) }
    fields(): Views.OfFields.Plugin[] { return [] }
}), Modes.AnyMode);
Views.All.push(AsGalleryItem);

export const AsGallery = new Views.Factory<Binding, Model.Authority.Tags.ValueType>(['persons', 'gallery'], ['person'], ((binding, factory) => new class implements Views.OfGoals.Plugin {
    key(separator: string) { return factory.asPath().key(separator) }
    route() { return factory.asPath().to(binding) }    
    controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin, options?: { ref?: React.RefObject<HTMLButtonElement> }) => JSX.Element) { return <React.Fragment></React.Fragment> }
    icon(props: { mode: Views.OfControls.Modes }): JSX.Element { return <HeroIcons.Photo variant={ props.mode === Views.OfControls.Modes.Selected ? "solid" : "outline" }/> }
    image(pixelDimensions: { width: number, height: number }, imageProps: React.CSSProperties) { return undefined; }
    label(truncate?: { maxChars?: number }): JSX.Element { return  (<span>Gallery</span>) }
    summary(options: { onClick: (editMode: boolean) => void, zeroPlaceholder?: () => JSX.Element }): JSX.Element { return  (<span>Review My Gallery</span>) }
    fields(): Views.OfFields.Plugin[] { return [{
        render: () =>  <Database.AsEditor peerId="ViewPerson.AsGallery" pollInterval={{ ms: 15000 }} render={zone => 
            <_AsGallery binding={binding}/>
        }/>
    }] }
}), Modes.AnyMode);
Views.All.push(AsGallery);

function _AsUploader(props: {
    binding: { person: Objects.Binding<string> }
}) {
    const navigation = React.useContext(Navigation.Context);
    const { zone } = React.useContext(Database.Context);
    if(!zone) throw new Error('Missing Context');
    const controller = ContentController.Manage(zone, { topic: props.binding.person }, baseURI);
    const includes = Propose.useRelation(Model.Content.Includes.Descriptor.stream(zone, { topic: props.binding.person }));

    return (
        <XLayout.Stack.South>
            <div style={{ flex: '1' }}>
                <Styler.Heading>Create a Portfolio</Styler.Heading>
            </div>
            <div style={{ flex: '1' }}>
                <XLayout.Center.Both>
                    <XLayout.Stack.South>
                        <Styler.Body>
                            Upload at least {quota} images (or short-form videos) to your Swivell portfolio.
                        </Styler.Body>
                        <div style={{ height: '300px', width: '100%'}}>
                            <ViewIncludes.AsGrid prefix="person-gallery-uploader" shape={{ rows: 2, columns: 2 }} binding={{topic: props.binding.person}} major='horizontal' scroll={true}/>
                        </div>
                        <Media.AsUploadButton style={{ 
                            backdropFilter: 'blur(8px)',
                            WebkitBackdropFilter: 'blur(8px)',
                            backgroundColor: 'rgba(45, 45, 45, 0.1)'
                        }} prefix="person-gallery" multiple={true} onChange={files_ => {
                            controller.add(files_).forEach(mgr => mgr.upload.watch({
                                next: mgr => console.log(mgr.toString()),
                                error: err => console.log(err),
                                complete: () => {
                                    zone.commitAll()
                                }
                            }));
                        }}>Upload Media</Media.AsUploadButton>
                    </XLayout.Stack.South>
                </XLayout.Center.Both>
            </div>
            <div style={{ flex: '1' }}>
                <XLayout.Center.Both>
                    <XLayout.Stack.South>
                        <Styler.Body>{includes.entries.length} / {quota} required</Styler.Body>                    
                        <Styler.Button variant='contained' mode={includes.entries.length < quota ? 'disabled' : 'forward' } onClick={includes.entries.length < quota ? undefined : () => navigation.forward(ViewCreatorOnboarding.AsWizard.asPath().to(props.binding))}>Next</Styler.Button>
                    </XLayout.Stack.South>
                </XLayout.Center.Both>
            </div>
        </XLayout.Stack.South>
    )
}

export const AsUploader = new Views.Factory<Binding, Model.Authority.Tags.ValueType>(['persons', 'gallery', 'upload'], ['person'], ((binding, factory) => new class implements Views.OfGoals.Plugin {
    key(separator: string) { return factory.asPath().key(separator) }
    route() { return factory.asPath().to(binding) }    
    controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin, options?: { ref?: React.RefObject<HTMLButtonElement> }) => JSX.Element) { return <React.Fragment></React.Fragment> }
    icon(props: { mode: Views.OfControls.Modes }): JSX.Element { return <HeroIcons.Photo variant={ props.mode === Views.OfControls.Modes.Selected ? "solid" : "outline" }/> }
    image(pixelDimensions: { width: number, height: number }, imageProps: React.CSSProperties) { return undefined; }
    label(truncate?: { maxChars?: number }): JSX.Element { return  (<span>Gallery</span>) }
    summary(options: { onClick: (editMode: boolean) => void, zeroPlaceholder?: () => JSX.Element }): JSX.Element { return  (<span>Review My Gallery</span>) }
    fields(): Views.OfFields.Plugin[] { return [{
        render: () =>  <Database.AsEditor peerId="ViewGallery.AsUploader" pollInterval={{ ms: 15000 }} render={zone => 
            <_AsUploader binding={binding}/>
        }/>
    }] }
}), Modes.AnyMode);
Views.All.push(AsUploader);
