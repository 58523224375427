import { Clients, Objects, Operators, Stores, Streams, Values } from '@pitaman71/omniglot-live-data';

import * as Model from '../../autosrc/swivell/talent_marketplace';

import * as Access from '../Social/Access';

//Operators.logEnable = true;

export function allowView(
    before: Stores.Zone, 
    binding: { me: Objects.Binding<string>, person: Objects.Binding<string> }
): Streams.ScalarStream<boolean> {
    const isSelf = Objects.Binding.cmp(binding.me, binding.person) === 0;

    const meHasAuthority = Model.Persons.HasAuthority.Descriptor.stream(before, { person: binding.me });
    const hasProvenance = Model.Identity.Provenance.Descriptor.stream(before, { the: binding.person });
    const hasConnectionStatus = Access.hasStatus(before, { from: binding.me, to: binding.person});

    const isOwner = Operators.ApplyScalars(
        'isOwner', 
        Values.TheBooleanDomain, 
        (hasProvenance_) => Objects.Binding.cmp(hasProvenance_?.owner, binding.me) === 0, 
        hasProvenance
    );
    const isAdmin = Operators.ApplySet('isAdmin', Values.TheBooleanDomain, meHasAuthority_ => meHasAuthority_.has('admin'), meHasAuthority);
    const isAmbassador = Operators.ApplySet('isAmbassador', Values.TheBooleanDomain, meHasAuthority_ => meHasAuthority_.has('ambassador'), meHasAuthority);
    const isConnected = Operators.ApplySet('isConnected', Values.TheBooleanDomain, hasConnectionStatus_ => hasConnectionStatus_.has('connected'), { set: hasConnectionStatus });

    const result = Operators.ApplyScalars('Persons.allowView', Values.TheBooleanDomain, (isOwner, isAdmin, isAmbassador, isConnected) => 
        isSelf || isOwner || isAdmin || isAmbassador || isConnected, isOwner, isAdmin, isAmbassador, isConnected);
    return result.scalar;
}

export function allowEdit(
    before: Stores.Zone, 
    binding: { me: Objects.Binding<string>, person: Objects.Binding<string> }
): Streams.ScalarStream<boolean> {
    const isSelf = Objects.Binding.cmp(binding.me, binding.person) === 0;

    const meHasAuthority = Model.Persons.HasAuthority.Descriptor.stream(before, { person: binding.me });
    const hasProvenance = Model.Identity.Provenance.Descriptor.stream(before, { the: binding.person });

    const isOwner = Operators.ApplyScalars(
        'isOwner', 
        Values.TheBooleanDomain, 
        (hasProvenance_) => Objects.Binding.cmp(hasProvenance_?.owner, binding.me) === 0, 
        hasProvenance
    );
    const isAdmin = Operators.ApplySet('isAdmin', Values.TheBooleanDomain, meHasAuthority_ => meHasAuthority_.has('admin'), meHasAuthority);

    const result = Operators.ApplyScalars('Persons.allowEdit', Values.TheBooleanDomain, (isOwner, isAdmin) => 
        isSelf || isOwner || isAdmin, isOwner, isAdmin);

    return result.scalar;
}

export function allowRemove(
    before: Stores.Zone, 
    binding: { me: Objects.Binding<string>, person: Objects.Binding<string> }
): Streams.ScalarStream<boolean> {
    const isSelf = Objects.Binding.cmp(binding.me, binding.person) === 0;

    const meHasAuthority = Model.Persons.HasAuthority.Descriptor.stream(before, { person: binding.me });

    const isAdmin = Operators.ApplySet('persons.allowRemove', Values.TheBooleanDomain, meHasAuthority_ => meHasAuthority_.has('admin'), meHasAuthority);

    return isAdmin.scalar;
}
