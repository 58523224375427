import React from 'react';
import faker from 'faker';

import {  makeStyles, Avatar, Step, Stepper, StepLabel, StepContent, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@material-ui/core';

import * as MuiIcons from '@material-ui/icons';
import { Rating } from '@material-ui/lab';

import { Objects, Stores } from '@pitaman71/omniglot-live-data';
import { Domains } from '@pitaman71/omniglot-live-domains';
import { Database, MaterialUi, Navigation, Propose, Views } from '@pitaman71/omniglot-live-react';
import * as XLayout from '@pitaman71/react-explicit-layout';

import * as Model from 'models/autosrc/swivell/talent_marketplace';
import * as DiscussionController from 'models/src/Discussion/Controller';

import * as NounProject from '../../NounProject';

import * as Whoami from '../../Whoami';
import { ViewPerson } from '../Persons';
import { ViewProject } from '../Projects';
import { ViewRole } from '../Roles';
import { ViewSubject } from '../Discussion';
import { ViewHasOfferStatus } from '.';

import { Controller as ProjectController} from 'models/src/Projects';

import * as Controls from '../../Controls';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
}));

export interface Binding extends Objects.BindingType<string>{ candidate: Objects.Binding<string> };

export function AsName(props: {
    zone: Stores.Zone,
    binding: Binding
}) {
    const candidate = props.binding.candidate;
    const hasPerson = props.zone.streams().relation(Model.Candidates.HasPerson.Descriptor.bindAnchor({ candidate }));
    return (
        <Propose.RelationEntries stream={hasPerson} render={ (entries, client) => {
            const person = entries.length > 0 ? entries[0].person : undefined;
            return !person ? <React.Fragment></React.Fragment> : <ViewPerson.AsName zone={props.zone} binding={{ person }}/>
        } }/>
    );
}

export function AsAvatarImage(props: {
    zone: Stores.Zone,
    binding: Binding,
    pixelDimensions: {
        width: number,
        height: number
    }
}) {
    const candidate = props.binding.candidate;
    const hasPerson = props.zone.streams().relation(Model.Candidates.HasPerson.Descriptor.bindAnchor({ candidate }));
    return (
        <Propose.RelationEntries stream={hasPerson} render={ (entries, client) => {
            const person = entries.length > 0 ? entries[0].person : undefined;
            const view = !person ? undefined : new Controls.Persons.ViewHasAvatar.View(props.zone, { person });
            return !view ? <React.Fragment></React.Fragment> : view.image(props.pixelDimensions, {})
        } }/>
    );
}

export function AsCard(props: {
    zone: Stores.Zone,
    binding: Binding,
    pixelDimensions: { height: number, width: number }
}) {
    const navigation = React.useContext(Navigation.Context);
    const hasProject = props.zone.streams().relation(Model.Candidates.HasProject.Descriptor.bindAnchor({ candidate: props.binding.candidate }));
    const hasRole = props.zone.streams().relation(Model.Candidates.HasRole.Descriptor.bindAnchor({ candidate: props.binding.candidate }));
    const hasOfferRate = props.zone.streams().property(Model.Candidates.HasOfferRate.Descriptor.bind(props.binding))
    return <Propose.RelationEntries stream={hasRole} render={ ( entries, client) => {
        if(entries.length === 0) return <React.Fragment></React.Fragment>;
        const role = entries[0].role;
        return <Propose.RelationEntries stream={hasProject} render={ ( entries, client) => {
            if(entries.length === 0) return <React.Fragment></React.Fragment>;
            const project = entries[0].project;    
            return <div style={{ maxWidth: "100%" }} onClick={(e) => {
                e.preventDefault();
                navigation.forward(ReviewOffer.asPath().to(props.binding))
            }}>
                <ViewRole.AsOfferCard {...props } binding={{...props.binding, role, project }}/>
                <XLayout.Center.Horizontal>
                    <_Rate {...props} mode='view'/>
                </XLayout.Center.Horizontal>
                <ViewHasOfferStatus.AsSummary zone={props.zone} binding={{ candidate: props.binding.candidate }}/>
            </div>
        } }/>
    } }/>;
}

export function AsTableRow(props: {
    zone: Stores.Zone,
    binding: Binding
}) {
    const navigation = React.useContext(Navigation.Context);
    const candidate = props.binding.candidate;
    const hasPerson = Propose.useRelation(Model.Candidates.HasPerson.Descriptor.stream(props.zone, { candidate }));
    const hasScore = Propose.useScalarProperty(Model.Candidates.HasScore.Descriptor.stream(props.zone, { candidate }).scalar);
    const hasBrandStatus = Propose.useScalarProperty(Model.Candidates.HasBrandStatus.Descriptor.stream(props.zone, { candidate }).scalar);
    const hasTalentStatus = Propose.useScalarProperty(Model.Candidates.HasTalentStatus.Descriptor.stream(props.zone, { candidate }).scalar);

    const person = hasPerson.entries.length > 0 ? hasPerson.entries[0].person : undefined;
    const hasAvatar = !person ? undefined : new Controls.Persons.ViewHasAvatar.View(
        props.zone, { person }
    );

    return <tr>
        <td style={{ verticalAlign: 'middle' }}><Avatar style={{ width: '4em', height: '4em' }}>{ hasAvatar?.image({ width: 105, height: 105 }, {}) || <MuiIcons.PersonOutlined/> }</Avatar></td>
        <th style={{ verticalAlign: 'middle' }}><MaterialUi.Styler.Heading>{
            !person ? <React.Fragment></React.Fragment> :
            <ViewPerson.AsName zone={props.zone} binding={{ person }}/>
        }</MaterialUi.Styler.Heading></th>
        <td style={{ verticalAlign: 'middle' }}>
            <Rating name="read-only" size="small" value={hasScore.value || 0}/>                        
            <Views.OfControls.AsRow controls={[
                {
                    class: Views.OfControls.Direction.Forward,
                    id: 'make-offer',
                    label: () => 'Make Offer',
                    icon: () => <NounProject.Handshake/>,
                    onClick: () => { return Promise.resolve(); }
                },{
                    class: Views.OfControls.Direction.Forward,
                    id: 'retract-offer',
                    label: () => 'Retract Offer',
                    icon: () => <MuiIcons.CancelOutlined/>,
                    onClick: () => { return Promise.resolve(); }
                },{
                    class: Views.OfControls.Direction.Forward,
                    id: 'message',
                    label: () => 'Send Reminder',
                    icon: () => <MuiIcons.EmailOutlined/>,
                    onClick: () => { return Promise.resolve(); }
                }
            ]} render={controlProps => <Views.OfControls.AsIconButton {...controlProps}/>}/>
        </td>
    </tr>;
}

function _Rate(props: {
    mode:'view'|'edit',
    zone: Stores.Zone,
    binding: { candidate: Objects.Binding<string> }
}) {
    const hasOfferRate = Propose.useScalarProperty(Model.Candidates.HasOfferRate.Descriptor.stream(props.zone, props.binding).scalar)
    if(props.mode === 'view') {
        return hasOfferRate.value === undefined ? (<span></span>) : (<span>{hasOfferRate.value.text}</span>)
    }
    return (
        <TextField type="text"
            value={hasOfferRate.value === undefined ? "$" : Domains.Financial.PayRangeDomain.asString().to(hasOfferRate.value)} 
            placeholder="Set offer rate ..."
            helperText={hasOfferRate.value?.error}
            onChange={!hasOfferRate.client ? undefined : (event:any) => { 
                let value_ = hasOfferRate.value;
                if(!event.target.value)
                    hasOfferRate.client?.clear();
                else {
                    value_ = Domains.Financial.PayRangeDomain.asString().from(`${event.target.value}`);
                    hasOfferRate.client?.assign(value_);
                }
            }}
        />
    )
}

function _AsWizard(props: {
    zone: Stores.Zone,
    binding: { person: Objects.Binding<string> }
}) {
    const classes = useStyles();

    const whoami = React.useContext(Whoami.Context);
    const navigation = React.useContext(Navigation.Context);
    const subject = !whoami?.person ? undefined : ViewSubject.groupToSubject(whoami.person, props.binding.person );
    
    const [ candidate, ] = React.useState(Objects.Binding.from_bound(faker.datatype.uuid()));
    const [ activeStep, setActiveStep ] = React.useState(0);
    const [ project, setProject ] = React.useState<Objects.Binding<string>>();
    const [ role, setRole ] = React.useState<Objects.Binding<string>>();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        return Promise.resolve();
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        return Promise.resolve();
    };

    const makeOffer = () => {
        const me = whoami?.person;
        if(me && subject && project && role) {
            const discussion = DiscussionController.appendComment(props.zone, {
                subject,
                author: me
            }, [ { person: props.binding.person } ], { offer: candidate });
        
            ProjectController.extend(props.zone, {
                me, project, role
            }, {
                candidate,
                person: props.binding.person
            }).exec();
            return props.zone.commitAll().then(() => navigation.backward());
        }
        return Promise.resolve();
    };
    const cancel = () => {
        navigation.backward();
        return Promise.resolve();
    };

    return (
        <div className={classes.root}>
            <Stepper activeStep={activeStep} orientation="vertical">
                <Step key="step-project">
                    <StepLabel>Select a project</StepLabel>
                    <StepContent>
                        <XLayout.Stack.South>
                            <ViewProject.AsPicker zone={props.zone} project={project} setProject={setProject} />
                            <div className={classes.actionsContainer}>
                                <Views.OfControls.AsRow controls={[
                                    {
                                    class: Views.OfControls.Direction.Forward,
                                    id: 'next',
                                    label: () => 'Next',
                                    icon: () => <MuiIcons.CheckOutlined/>,
                                    onClick: handleNext
                                },{
                                    class: Views.OfControls.Direction.Backward,
                                    id: 'cancel',
                                    label: () => 'Cancel',
                                    icon: () => <MuiIcons.CancelOutlined/>,
                                    onClick: cancel
                                }
                                ]} render={controlProps => <Views.OfControls.AsButton {...controlProps} variant="outlined" className={classes.button}/>} />
                            </div>
                        </XLayout.Stack.South>
                    </StepContent>
                </Step>
                <Step key="step-role">
                    <StepLabel>Select a role</StepLabel>
                    <StepContent>
                        <XLayout.Stack.South>
                            { !project ? <React.Fragment></React.Fragment> : <ViewRole.AsPicker zone={props.zone} binding={{project}} role={role} setRole={setRole} /> }
                            <div className={classes.actionsContainer}>
                                <Views.OfControls.AsRow controls={[
                                    {
                                    class: Views.OfControls.Direction.Forward,
                                    id: 'next',
                                    label: () => 'Next',
                                    icon: () => <MuiIcons.CheckOutlined/>,
                                    onClick: handleNext
                                },{
                                    class: Views.OfControls.Direction.Backward,
                                    id: 'back',
                                    label: () => 'Back',
                                    icon: () => <MuiIcons.ArrowBackOutlined/>,
                                    onClick: handleBack
                                }
                                ]} render={controlProps => <Views.OfControls.AsButton {...controlProps} variant="outlined" className={classes.button}/>} />
                            </div>
                        </XLayout.Stack.South>
                    </StepContent>
                </Step>
                <Step key="step-rate">
                    <StepLabel>Offer a rate</StepLabel>
                    <StepContent>
                        <XLayout.Stack.South>
                            <_Rate mode='edit' zone={props.zone} binding={{candidate}}/>
                            <div className={classes.actionsContainer}>
                                <Views.OfControls.AsRow controls={[
                                    {
                                    class: Views.OfControls.Direction.Forward,
                                    id: 'next',
                                    label: () => 'Next',
                                    icon: () => <MuiIcons.CheckOutlined/>,
                                    onClick: handleNext
                                },{
                                    class: Views.OfControls.Direction.Backward,
                                    id: 'back',
                                    label: () => 'Back',
                                    icon: () => <MuiIcons.ArrowBackOutlined/>,
                                    onClick: handleBack
                                }
                                ]} render={controlProps => <Views.OfControls.AsButton {...controlProps} variant="outlined" className={classes.button}/>} />
                            </div>
                        </XLayout.Stack.South>
                    </StepContent>
                </Step>
                <Step key="step-confirm">
                    <StepLabel>Confirmation</StepLabel>
                    <StepContent>
                        <XLayout.Stack.South>
                            <MaterialUi.Styler.Heading>
                                <p>You are about to make the following offer:</p>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableHead>
                                                Project
                                            </TableHead>
                                            <TableCell>
                                                { !project ? "?" : <ViewProject.AsTitle zone={props.zone} binding={{project}}/> }
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableHead>
                                                Role
                                            </TableHead>
                                            <TableCell>
                                                { !role ? "?" : <ViewRole.AsTitle zone={props.zone} binding={{role}}/> }
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableHead>
                                                Rate
                                            </TableHead>
                                            <TableCell>
                                                <_Rate mode='view' zone={props.zone} binding={{candidate}}/>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableHead>
                                                Candidate
                                            </TableHead>
                                            <TableCell>
                                                { <ViewPerson.AsName zone={props.zone} binding={props.binding}/> }
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </MaterialUi.Styler.Heading>
                        </XLayout.Stack.South>
                        <div className={classes.actionsContainer}>
                            <Views.OfControls.AsRow controls={[
                                {
                                class: Views.OfControls.Direction.Forward,
                                id: 'confirm',
                                label: () => 'Make Offer',
                                icon: () => <MuiIcons.CheckOutlined/>,
                                onClick: makeOffer
                            },{
                                class: Views.OfControls.Direction.Backward,
                                id: 'back',
                                label: () => 'Back',
                                icon: () => <MuiIcons.ArrowBackOutlined/>,
                                onClick: handleBack
                            }
                            ]} render={controlProps => <Views.OfControls.AsButton {...controlProps} variant="outlined" className={classes.button}/>} />
                        </div>
                    </StepContent>
                </Step>
            </Stepper>
        </div>
    )
}

export const AsWizard = new Views.Factory<{ person: Objects.Binding<string> }, Model.Authority.Tags.ValueType>(['candidates', 'configureOffer' ], ['person'], ((binding, factory) => new class implements Views.OfGoals.Plugin {
    key(separator: string) { return factory.asPath().key(separator) }
    route() { return factory.asPath().to(binding) }    
    controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin, options?: { ref?: React.RefObject<HTMLButtonElement> }) => JSX.Element) { return <React.Fragment></React.Fragment> }
    icon(): JSX.Element { return <NounProject.Team/> }
    image(pixelDimensions: { width: number, height: number }, imageProps: React.CSSProperties) { return undefined; }
    label(truncate?: { maxChars?: number }): JSX.Element { return  (<span>Offer</span>) }
    summary(options: { onClick: (editMode: boolean) => void, zeroPlaceholder?: () => JSX.Element }): JSX.Element { return  (<span>Extend an offer</span>) }
    fields(): Views.OfFields.Plugin[] { return [{
        render: () => <Database.AsEditor peerId="ViewCandidate.AsWizard" pollInterval={{ ms: 15000 }} render={zone => <_AsWizard zone={zone} binding={binding}/>} />
    }] }
}));

function _ReviewOffer(props: {
    zone: Stores.Zone,
    binding: { candidate: Objects.Binding<string> }
}) {
    return (
        <React.Fragment>
            <XLayout.Center.Horizontal><AsCard zone={props.zone} binding={props.binding} pixelDimensions={{ width: 150, height: 200 }}/></XLayout.Center.Horizontal>
            <ViewHasOfferStatus.AsControls zone={props.zone} binding={props.binding}/>
        </React.Fragment>
    )
}

export const ReviewOffer = new Views.Factory<{ candidate: Objects.Binding<string> }, Model.Authority.Tags.ValueType>(['candidates', 'reviewOffer' ], ['candidate'], ((binding, factory) => new class implements Views.OfGoals.Plugin {
    key(separator: string) { return factory.asPath().key(separator) }
    route() { return factory.asPath().to(binding) }    
    controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin, options?: { ref?: React.RefObject<HTMLButtonElement> }) => JSX.Element) { return <React.Fragment></React.Fragment> }
    icon(): JSX.Element { return <NounProject.Team/> }
    image(pixelDimensions: { width: number, height: number }, imageProps: React.CSSProperties) { return undefined; }
    label(truncate?: { maxChars?: number }): JSX.Element { return  (<span>Offer</span>) }
    summary(options: { onClick: (editMode: boolean) => void, zeroPlaceholder?: () => JSX.Element }): JSX.Element { return  (<span>Review Offer</span>) }
    fields(): Views.OfFields.Plugin[] { return [{
        render: () => <Database.AsEditor peerId="ViewCandidate.ReviewOffer" pollInterval={{ ms: 15000 }} render={zone => <_ReviewOffer zone={zone} binding={binding}/>} />
    }] }
}));

Views.All.push(AsWizard, ReviewOffer);
