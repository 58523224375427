import React from 'react';

import { Dot } from 'react-animated-dots';

import { Objects, Stores } from '@pitaman71/omniglot-live-data';
import { Database, Navigation, Propose, Styler, Views } from '@pitaman71/omniglot-live-react';

import * as Model from 'models/autosrc/swivell/talent_marketplace';
import * as Media from '../Media';
import * as Themes from '../../Themes';

import { AsProfile } from './ViewProfile';
import { Authorize, useAccess } from './ViewProject';
import { ViewHasRoles } from '.';

import * as Whoami from '../../Whoami';

import { Controller as SocialController } from 'models/src/Social';

export interface Binding extends Objects.BindingType<string> {
    project: Objects.Binding<string>
}
export let pollInterval = { ms: 30000 };

export function Card(props: React.PropsWithChildren<{
    prefix: string,
    binding: Binding,
    zone: Stores.Zone,
    style?: React.CSSProperties
}>) {
    const access = useAccess(props.binding);
    const navigation = React.useContext(Navigation.Context);

    const hasBrandName = Propose.useScalarProperty(Model.Projects.HasBrandName.Descriptor.stream(props.zone, props.binding).scalar);
    const hasTitle = Propose.useScalarProperty(Model.Projects.HasTitle.Descriptor.stream(props.zone, props.binding).scalar);
    const hasAvatar = Propose.useScalarProperty(Model.Projects.HasAvatar.Descriptor.stream(props.zone, props.binding).scalar);
    const hasHomeLocation = Propose.useScalarProperty(Model.Projects.HasHomeLocation.Descriptor.stream(props.zone, props.binding).scalar);
    
    return <div style={{height: '100%', width: '100%', padding: '1em', display: "flex", flexDirection: "column", ...props.style }}>
        <Authorize access={access} mode='view'>
            <Styler.Card prefix={props.prefix} style={{ flex: "1", width: '100%', display: "flex", flexDirection: "column" }} theme={Themes.Light} onClick={event => {
                    event.defaultPrevented || navigation.forward(AsProfile.asPath().to(props.binding));
                }}>
                <Styler.CardMedia style={{ flex: "1", minHeight: "0" }}>
                    <Media.AsDownloader pixelDimensions={{ width: 300, height: 300}} style={{ width: '100%', height: '100%'}} value={hasAvatar.value}/>
                </Styler.CardMedia>
                <Styler.CardContent>
                    <Styler.Heading align='left'>
                        { hasBrandName.value ? hasBrandName.value : <React.Fragment>Loading <Dot>.</Dot><Dot>.</Dot><Dot>.</Dot></React.Fragment> }
                    </Styler.Heading>
                    <Styler.Control prefix="project-preview-summary">
                        <Styler.Heading align='left'>
                            { hasTitle.value ? hasTitle.value : <React.Fragment><Dot>.</Dot><Dot>.</Dot><Dot>.</Dot></React.Fragment> }
                            <Styler.Bullet/>
                            { [ hasHomeLocation.value?.text ].reduce<(JSX.Element|string)[]>((accum, value) => value === undefined ? accum : accum.length === 0 ? [value] : [...accum, <Styler.Bullet/>, value], []) }
                        </Styler.Heading>
                        <Styler.Body align='left'>                            
                            <ViewHasRoles.AsChips binding={props.binding}/>                                
                        </Styler.Body>
                    </Styler.Control>
                </Styler.CardContent>
                <Styler.CardActions>
                    { props.children }
                </Styler.CardActions>
            </Styler.Card>
        </Authorize>
    </div>;
}
