import faker from 'faker';
import { Dialogues, Definitions, Objects, Properties, Relations, Values } from '@pitaman71/omniglot-live-data';
import { Domains, Modules, WrapFaker } from '@pitaman71/omniglot-live-domains';
import * as Elevated from '@pitaman71/omniglot-introspect';

export const directory = new Definitions.Directory();
// property Agents.HasMessagesWaiting

const Domain: Elevated.Domain<{ subject: Objects.Binding<string> }> = WrapFaker.OfJSON('Notifications.HasMessagesWaiting', () => ({ subject: Objects.Binding.from_bound('?') })).domain();
export type TypeParams = {
    Binding: { person: Objects.Binding<string> },
    Value: { subject: Objects.Binding<string> },
    Domain: typeof Domain
}
export const Descriptor = new class _Descriptor extends Properties.Descriptor<TypeParams> {
    canonicalName = 'generated.Agents.HasMessagesWaiting';
    build(builder: Properties.Builder<TypeParams>): void {
        builder.object('person');
        builder.measure(WrapFaker.OfJSON('Notifications.HasMessagesWaiting', () => ({ subject: Objects.Binding.from_bound('?') })).domain());
        builder.set();
    }
};

directory.descriptors.properties.set(Descriptor.canonicalName, Descriptor);
        
