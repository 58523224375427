import faker from 'faker';
import { Dialogues, Definitions, Objects, Properties, Relations, Values } from '@pitaman71/omniglot-live-data';
import { Domains, Modules, WrapFaker } from '@pitaman71/omniglot-live-domains';
import * as Elevated from '@pitaman71/omniglot-introspect';

import { Domain as Model__Candidates__Status__Tags__Domain} from '../Candidates/Status/Tags';
import { ValueType as Model__Candidates__Status__Tags__ValueType} from '../Candidates/Status/Tags';
export const directory = new Definitions.Directory();
// property Candidates.HasTalentStatus

const Domain: Elevated.Domain<Model__Candidates__Status__Tags__ValueType> = Model__Candidates__Status__Tags__Domain;
export type TypeParams = {
    Binding: { candidate: Objects.Binding<string> },
    Value: Model__Candidates__Status__Tags__ValueType,
    Domain: typeof Domain
}
export const Descriptor = new class _Descriptor extends Properties.Descriptor<TypeParams> {
    canonicalName = 'generated.Candidates.HasTalentStatus';
    build(builder: Properties.Builder<TypeParams>): void {
        builder.object('candidate');
        builder.measure(Model__Candidates__Status__Tags__Domain);
        builder.scalar();
    }
};

directory.descriptors.properties.set(Descriptor.canonicalName, Descriptor);
        
