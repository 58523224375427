import React from 'react';
import { TextField } from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';

import { Database, Navigation, Propose, Views } from '@pitaman71/omniglot-live-react';
import { Properties } from '@pitaman71/omniglot-live-data';

import * as Model from 'models/autosrc/swivell/talent_marketplace';

export let pollInterval = { ms: 10000 };

export function makeFactories<PropertyTypeParams extends Properties.TypeParams>(route: string[], descriptor: Properties.Descriptor<PropertyTypeParams>) {
    const Edit = new Views.Factory<PropertyTypeParams['Binding'], Model.Authority.Tags.ValueType>([...route, 'edit' ], descriptor.getSymbols(), (binding, factory) => new class implements Views.OfGoals.Plugin {
        key(separator: string) { return factory.asPath().key(separator) }
        route() { return factory.asPath().to(binding) }    
        controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin, options?: { ref?: React.RefObject<HTMLButtonElement> }) => JSX.Element) { return <React.Fragment></React.Fragment> }
        icon(): JSX.Element { return <MuiIcons.DynamicFeed/> }
        image(pixelDimensions: { width: number, height: number }, imageProps: React.CSSProperties) { return undefined; }
        label(truncate?: { maxChars?: number }): JSX.Element { 
            return  (<span>Title</span>);
        }
        summary(options: { onClick: (editMode:boolean) => void, zeroPlaceholder?: () => JSX.Element }): JSX.Element { 
            return (<Database.AsViewer render={zone => (
                <Propose.PropertyData
                    stream={zone.streams().property(descriptor.bind(binding))}
                    render={{
                        scalar: (value, client) => {
                            const asString = descriptor.getDomain().asString();
                            const text = value === undefined ? undefined : !asString ? value : asString.to(value);
                            return <div onClick={() => options.onClick(!value && !!options.zeroPlaceholder)}>{!!value ? text : !!options.zeroPlaceholder ? options.zeroPlaceholder() : <i>Set Project Title ...</i> }</div>;
                        }
                    }}
                />)}
            />);
        }
        fields(): Views.OfFields.Plugin[] { 
            return [ {
                render: (controller: Navigation.Controller) => (<Database.AsEditor peerId="ViewProperty.makeFactories.Edit" pollInterval={pollInterval} render={zone => (
                    <React.Fragment>
                        <Propose.PropertyData
                            stream={zone.streams().property(descriptor.bind(binding))}
                            render={{
                                scalar: (value, client) => {
                                    const asString = descriptor.getDomain().asString();
                                    return (
                                        <TextField type="text"
                                            autoFocus={true}
                                            fullWidth={true}
                                            multiline={true}
                                            value={value === undefined ? undefined : !!asString ? asString.to(value) : value} 
                                            helperText={value?.error === undefined ? undefined : value.error }
                                            onChange={(event:any) => { 
                                                if(event.target.value === null || event.target.value === undefined || event.target.value === "") {
                                                    client?.clear();
                                                } else {
                                                    client?.assign(!!asString ? asString.from(event.target.value) : event.target.value);
                                                }
                                            }}
                                        />
                                    );
                                }
                            }}
                        />
                        <Views.OfControls.AsRow controls={[
                            {
                                class: Views.OfControls.Direction.Forward,
                                onClick: () => zone.commitAll().then(() => controller.closeDialog()),
                                id: 'save',
                                label: () => 'Save',
                                icon: () => <MuiIcons.CheckOutlined/>
                            }, {
                                class: Views.OfControls.Direction.Backward,
                                onClick: () => zone.revertAll(),
                                id: 'revert',
                                label: () => 'Revert',
                                icon: () => <MuiIcons.UndoOutlined/>
                            }, {
                                class: Views.OfControls.Direction.Backward,
                                onClick: () => {
                                    controller.closeDialog();
                                    return Promise.resolve()
                                },
                                id: 'cancel',
                                label: () => 'Cancel',
                                icon: () => <MuiIcons.CancelOutlined/>
                            }                            
                        ]} render={(controlProps) => <Views.OfControls.AsIconButton {...controlProps}/>}/>
                    </React.Fragment>
                )}/>)
            }]
        }
        });

    const View = new Views.Factory<PropertyTypeParams['Binding'], Model.Authority.Tags.ValueType>([...route, 'view' ], descriptor.getSymbols(), (binding, factory) => new class implements Views.OfGoals.Plugin {
        key(separator: string) { return factory.asPath().key(separator) }
        route() { return factory.asPath().to(binding) }    
        controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin) => JSX.Element) { return <Views.OfControls.Generic filter={filter} render={render} on={{
        edit: (controller) => {
            controller.openDialog(Edit.make(binding));
            return Promise.resolve();
        },
        cancel: (controller) => {
            controller.closeDialog();
            return Promise.resolve();
        }
        }}/>}
        icon(): JSX.Element { return <MuiIcons.DynamicFeed/> }
        image(pixelDimensions: { width: number, height: number }, imageProps: React.CSSProperties) { return undefined; }
        label(truncate?: { maxChars?: number }): JSX.Element { 
            return  (<span>Title</span>);
        }
        summary(options: { onClick: (editMode:boolean) => void, zeroPlaceholder?: () => JSX.Element }): JSX.Element { 
            return (<Database.AsViewer render={zone => (
                <Propose.PropertyData
                    stream={zone.streams().property(descriptor.bind(binding))}
                    render={{
                        scalar: (value, client) => {
                            const asString = descriptor.getDomain().asString();
                            const text = value === undefined ? undefined : !asString ? value : asString.to(value);
                            return <div onClick={() => options.onClick(!value && !!options.zeroPlaceholder)}>{!!value ? text : !!options.zeroPlaceholder ? options.zeroPlaceholder() : <i>Set Project Title ...</i> }</div>;
                        }
                    }}
                />)}
            />);
        }
        fields(): Views.OfFields.Plugin[] { 
            return [ {
                render: () => (<Database.AsViewer render={zone => (
                    <Propose.PropertyData
                        stream={zone.streams().property(descriptor.bind(binding))}
                        render={{
                            scalar: (value, client) => {
                                const asString = descriptor.getDomain().asString();
                                const text = value === undefined ? undefined : !asString ? value : asString.to(value);
                                return <div>{text || ""}</div>;
                            }
                        }}
                    />
                )}/>)
            }]
        }
    });
    return { Edit, View };
}
