import React from 'react';

import { Button, Card, CardContent, CardMedia, Table, TableBody, TableCell, TableRow  } from '@material-ui/core';
import { Dot } from 'react-animated-dots';

import * as Model from 'models/autosrc/swivell/talent_marketplace';
import * as Inspectors from '../../Inspectors';
import * as Themes from '../../Themes';
import * as Whoami from '../../Whoami';

import * as XLayout from '@pitaman71/react-explicit-layout';
import { Objects, Stores, Streams } from '@pitaman71/omniglot-live-data';
import { Domains } from '@pitaman71/omniglot-live-domains';
import { Database, MaterialUi, Navigation, Propose, Styler, Views } from '@pitaman71/omniglot-live-react';

import * as HeroIcons from '../../HeroIcons';
import * as MuiIcons from '@material-ui/icons';

import { ViewHasDetails } from '../Events';
import { ViewDetails, ViewHasAvatar, ViewHasEvents, ViewHasHomeLocation, ViewHasRoles, ViewMoodBoard, ViewStatus } from '.';
import * as Modes from '../../Modes';

import { Media } from '../';
import { AsProfile } from './ViewProfile';
import { Controller as ProjectController } from 'models/src/Projects';
import { Access } from 'models/src/Projects';
import { AsTeamDiagram } from './ViewHasRoles';

export interface Binding extends Objects.BindingType<string>{
    project: Objects.Binding<string>;
}

const postDimensions = { width: 1080, height: 1350 };
const coverDimensions = { width: 960, height: 960 };

export function useAccess(binding: Binding) {
    const whoami = React.useContext(Whoami.Context);
    const { zone } = React.useContext(Database.Context);
    if(zone === undefined) throw new Error("Missing Context");
    if(whoami.person === undefined) throw new Error("Missing Context");
    const me = whoami.person;

    const view = Propose.useScalarProperty(React.useMemo(() => Access.allowView(zone, { me, project: binding.project }), [zone, whoami.person, binding.project ]));
    const edit = Propose.useScalarProperty(React.useMemo(() => Access.allowEdit(zone, { me, project: binding.project }), [zone, whoami.person, binding.project ]));
    return { view, edit };
}

export function Authorize(props: {
    mode: 'view'|'edit',
    access: ReturnType<typeof useAccess>,
    children: JSX.Element|JSX.Element[]
}) {
    /* if(props.mode === 'view' && props.access.view.value === undefined) {
        return (
            <XLayout.Center.Both>
                <MaterialUi.Styler.Heading>Checking your authorization to view this project profile.</MaterialUi.Styler.Heading>
            </XLayout.Center.Both>
        )
    } else if(props.mode === 'view' && props.access.view.value === false) {
        return (
            <XLayout.Center.Both>
                <MaterialUi.Styler.Heading>You do not have authorization to view this project profile.</MaterialUi.Styler.Heading>
            </XLayout.Center.Both>
        )
    } else */ if(props.mode === 'edit' && props.access.edit.value === undefined) {
        return (
            <XLayout.Center.Both>
                <MaterialUi.Styler.Heading>Checking your authorization to modify this project profile.</MaterialUi.Styler.Heading>
            </XLayout.Center.Both>
        )
    } else if(props.mode === 'edit' && props.access.edit.value === false) {
        return (
            <XLayout.Center.Both>
                <MaterialUi.Styler.Heading>You do not have authorization to modify this project profile.</MaterialUi.Styler.Heading>
            </XLayout.Center.Both>
        )
    } else if(Array.isArray(props.children)) {
        return <React.Fragment>{props.children}</React.Fragment>;
    } else {
        return props.children;
    }
}

export function AsBrandName(props: {
    zone: Stores.Zone,
    binding: Binding
}) {
    const hasBrandName = props.zone.streams().property(Model.Projects.HasBrandName.Descriptor.bind({ project: props.binding.project }));
    return <Propose.PropertyData stream={hasBrandName} render={{ scalar: (value, client) => <span>{value || ""}</span> }}/>
}

export function AsTitle(props: {
    zone: Stores.Zone,
    binding: Binding
}) {
    const hasTitle = props.zone.streams().property(Model.Projects.HasTitle.Descriptor.bind({ project: props.binding.project }));
    return <Propose.PropertyData stream={hasTitle} render={{ scalar: (value, client) => <span>{value || ""}</span> }}/>
}

export function AsCover(props: {
    zone: Stores.Zone,
    binding: Binding,
    pixelDimensions: {
        width: number,
        height: number
    },
    style?: React.CSSProperties,
    onClick?: () => void
}) {
    const hasAvatar = Propose.useScalarProperty(Model.Projects.HasAvatar.Descriptor.stream(props.zone, { project: props.binding.project }).scalar);
    return !hasAvatar.value ? <div style={{
        ...props.pixelDimensions,
        ...props.style
    }}><MuiIcons.PersonOutlined/></div> 
        : <Media.AsDownloader pixelDimensions={props.pixelDimensions} style={props.style || {}} value={hasAvatar.value} render={(pixelDimensions, style, src) => (
            <img src={src} style={{ ...props.pixelDimensions, ...props.style, objectFit: 'cover', backgroundColor: 'transparent' }}/>
        )
    }/>
}

export function AsTile(props: {
    zone: Stores.Zone,
    binding: Binding,
    pixelDimensions: { width: number, height: number }
    viewport?: React.RefObject<HTMLDivElement>,
    onClick?: () => void
}): JSX.Element {
    return (
        <MaterialUi.Styler.Card prefix="project-card" viewport={props.viewport} theme={Themes.Light} style={{ ...props.pixelDimensions, display: 'inline-block', verticalAlign: 'top' }}
            onClick={props.onClick}
        >
        <XLayout.Stack.South>
            <AsCover {...props}/>
            <Styler.Control prefix="team-project-title"><Styler.Heading><AsTitle {...props}/></Styler.Heading></Styler.Control>
        </XLayout.Stack.South>
        </MaterialUi.Styler.Card>
    );
}

export function AsPicker(props: {
    zone: Stores.Zone,
    project: Objects.Binding<string>|undefined,
    setProject: React.Dispatch<React.SetStateAction<Objects.Binding<string> | undefined>>
}): JSX.Element {
    const whoami = React.useContext(Whoami.Context);
    const hasProjects = props.zone.streams().relation(Model.Community.HasProject.Descriptor.bindAnchor({ community: Objects.Binding.from_bound('the')}));
    return (
        <div style={{ width: '100%', height: '100%', position: 'relative', overflow: 'hidden'}}>
            <Propose.RelationEntries stream={hasProjects} render={(entries, client) => {
                return <XLayout.Stack.East style={{ overflowX: 'auto', display: 'flex', flex: '0 0 auto', height: 'auto' }}>

                    {
                        entries.map(entry => {
                            const hasProvenance = props.zone.streams().property(Model.Identity.Provenance.Descriptor.bind({ the: entry.project }));
                            return <Propose.PropertyData stream={hasProvenance} render={{ scalar: (value, client) => (
                                /* !whoami.hasTag('admin') && (!value?.owner || value?.owner.objectId !== whoami.person) ? <React.Fragment/> : */
                                <AsTile zone={props.zone} binding={entry} pixelDimensions={props.project === entry.project ? { height: 200, width: 125 } : { height: 160, width: 100 }} onClick={()=> {
                                    props.setProject(entry.project);
                                }}/>
                            )
                            }}/>;
                        })
                    }
                </XLayout.Stack.East>
            }}/>
        </div>
    );
}

export function AsPreview(props: {
    binding: Binding,
    zone: Stores.Zone
}) {
    const navigation = React.useContext(Navigation.Context);
    const access = useAccess(props.binding);
    const hasAvatar = new ViewHasAvatar.View(
        props.zone,
        props.binding
    );
    const hasHomeLocation = new ViewHasHomeLocation.View(
        props.zone,
        props.zone.streams().property(Model.Projects.HasTitle.Descriptor.bind(props.binding)),
        props.zone.streams().property(Model.Projects.HasHomeLocation.Descriptor.bind(props.binding))
    );

    return <Authorize access={access} mode='view'>
        <MaterialUi.Styler.Card prefix="project-profile" theme={Themes.Light}>
        <XLayout.Stack.South>
            <XLayout.Center.Horizontal>
                <Inspectors.AsThumbnail pixelDimensions={{ width: 400, height: 400 }} style={{}} view={hasAvatar}/>
            </XLayout.Center.Horizontal>
            <MaterialUi.Styler.Heading align="left" style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <AsTitle {...props}/>
            </MaterialUi.Styler.Heading>
            <MaterialUi.Styler.Heading>
                <Inspectors.AsSummary view={hasHomeLocation}/>
            </MaterialUi.Styler.Heading>
            <MaterialUi.Styler.Heading>
                <ViewHasRoles.AsChips binding={props.binding}/>
            </MaterialUi.Styler.Heading>
        </XLayout.Stack.South>
        </MaterialUi.Styler.Card>
    </Authorize>;
}

export function _Overview(props: {
    language: string,
    binding: Binding
}) {
    const { zone } = React.useContext(Database.Context);
    const whoami = React.useContext(Whoami.Context);
    if(!zone || !whoami.person) throw new Error("Missing Context");
    
    const updateViews = useUpdateViews(props);
    return (
        <Styler.Section prefix="project-overview" theme={Themes.Dark} style={{ maxHeight: '100%', maxWidth: '100%' }}>
            <XLayout.Center.Horizontal style={{ maxHeight: '100%' }}>
                <XLayout.Stack.South style={{ width: '80%'}}>
                    <p><Styler.Heading>Update Project</Styler.Heading></p>
                    <p><Styler.Body>The table below shows profile sections which are relevant to your account.</Styler.Body></p>
                    <XLayout.Stack.South id="project-overview" >
                    <Table>
                        <TableBody>
                            { updateViews.map(view => <Inspectors.AsTableRow view={view}/>) }
                        </TableBody>
                    </Table>
                    </XLayout.Stack.South>
                </XLayout.Stack.South>
            </XLayout.Center.Horizontal>
        </Styler.Section>
    );    
}

function _ProfileCard(props: {
    prefix: string,
    binding: Binding,
    zone: Stores.Zone
}) {
    return <div style={{height: '100%', width: '100%', padding: '1em' }}>
        <MaterialUi.Styler.Card prefix={props.prefix} style={{ height: '100%', width: '100%' }} theme={Themes.Light}>
            <MaterialUi.Styler.CardContent style={{ height: '100%', width: '100%' }} >
                <_ProfileSections {...props}/>
            </MaterialUi.Styler.CardContent>
    </MaterialUi.Styler.Card>
    </div>
}

function _Actions(props: {
    binding: Binding,
    zone: Stores.Zone
}) {
    const navigation = React.useContext(Navigation.Context);
    const whoami = React.useContext(Whoami.Context);
    const me = whoami?.person;
    if(!me) throw new Error('Missing Context');
    const access = useAccess(props.binding);

    const hasStatus = Propose.useScalarProperty(Model.Projects.HasStatus.Descriptor.stream(props.zone, props.binding).scalar);

    const toOverview = AsOverview.make({ project: props.binding.project });
;
    return (
        <XLayout.Stack.West>
            {
                access.edit.value === true && !!toOverview ?
                <Views.OfGoals.AsButton view={toOverview} variant="outlined"/>
                : <React.Fragment/>
            }
        </XLayout.Stack.West>
    )
}

function _ProfileSections(props: {
    binding: Binding,
    zone: Stores.Zone
}) {
    const navigation = React.useContext(Navigation.Context);
    const whoami = React.useContext(Whoami.Context);
    const access = useAccess(props.binding);

    const hasDescription = Propose.useScalarProperty(Model.Projects.HasDescription.Descriptor.stream(props.zone, { project: props.binding.project }).scalar);
    const hasAvatar = new ViewHasAvatar.View(
        props.zone,
        props.binding
    );
    const hasHomeLocation = new ViewHasHomeLocation.View(
        props.zone,
        props.zone.streams().property(Model.Projects.HasTitle.Descriptor.bind(props.binding)),
        props.zone.streams().property(Model.Projects.HasHomeLocation.Descriptor.bind(props.binding))
    );
    const hasEvents = props.zone.streams().relation(Model.Projects.HasEvents.Descriptor.bindAnchor({ project: props.binding.project }));

    return (
        <Authorize access={access} mode='view'>
            <div style={{ display: "flex", flexDirection: "column", maxHeight: "100%", width: "100%", overflow: "hidden" }}>
                <XLayout.Stack.South id="project-profile" scroll={true} style={{ flex: "1", minHeight: "0" }}>
                    <XLayout.Stack.South style={{ maxWidth: '100%'}}>
                        <XLayout.Center.Horizontal>
                            <Inspectors.AsThumbnail pixelDimensions={{ width: 480, height: 480 }} style={{ objectFit: 'cover', objectPosition: 'center' }} view={hasAvatar}/>
                        </XLayout.Center.Horizontal>
                        <XLayout.Center.Horizontal>
                            <_Actions binding={props.binding} zone={props.zone}/>
                        </XLayout.Center.Horizontal>
                        <MaterialUi.Styler.Heading>
                            <AsTitle {...props}/>
                        </MaterialUi.Styler.Heading>
                        <MaterialUi.Styler.Heading>
                            <Inspectors.AsSummary view={hasHomeLocation}/>
                        </MaterialUi.Styler.Heading>
                        <MaterialUi.Styler.Body>
                            {hasDescription.value}
                        </MaterialUi.Styler.Body>
                        <MaterialUi.Styler.Heading>
                            <ViewHasEvents.AsChips zone={props.zone} binding={props.binding}/>
                        </MaterialUi.Styler.Heading>
                        <MaterialUi.Styler.Heading>
                            <ViewHasRoles.AsChips binding={props.binding}/>
                        </MaterialUi.Styler.Heading>
                    </XLayout.Stack.South>
                </XLayout.Stack.South>
            </div>
        </Authorize>
    );
}

export const AsOverview = new Views.Factory<Binding, Model.Authority.Tags.ValueType>(['projects', 'overview'], ['project'], ((binding, factory) => new class implements Views.OfGoals.Plugin {
    key(separator: string) { return factory.asPath().key(separator) }
    route() { return factory.asPath().to(binding) }    
    controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin, options?: { ref?: React.RefObject<HTMLButtonElement> }) => JSX.Element) { return <React.Fragment></React.Fragment> }
    icon(): JSX.Element { return <MuiIcons.EditOutlined/> }
    image(pixelDimensions: { width: number, height: number }, imageProps: React.CSSProperties) { return undefined; }
    label(truncate?: { maxChars?: number }): JSX.Element { return  (<span>Overview</span>) }
    summary(options: { onClick: (editMode: boolean) => void, zeroPlaceholder?: () => JSX.Element }): JSX.Element { return  (<span>Project Overview</span>) }
    fields(): Views.OfFields.Plugin[] { return [{
        render: () => 
          <Database.AsEditor peerId="ViewProject.AsOverview" pollInterval={{ ms: 15000 }} render={zone => <_Overview language="en_US" binding={binding}/>}/>
    }] }
}), Modes.NormalMode);
Views.All.push(AsOverview);

function _Delete(props: {
    language: string,
    binding: Binding
}) {
    const navigation = React.useContext(Navigation.Context);
    const whoami = React.useContext(Whoami.Context);
    const { zone } = React.useContext(Database.Context);
    if(zone === undefined) throw new Error("Missing Context");
    if(whoami.person === undefined) throw new Error("Missing Context");
    const me = whoami.person;

    const access = useAccess(props.binding);

    return (
        <Authorize access={access} mode='edit'>
            <XLayout.Center.Both>
            <XLayout.Stack.South style={{ height: '50%' }}>
                <MaterialUi.Styler.Heading style={{ flex: '1' }}>Delete this project?</MaterialUi.Styler.Heading>
                <XLayout.Center.Horizontal style={{ flex: '1' }}>
                    <AsTile {...props} zone={zone} pixelDimensions={{ width: 105, height: 162 }}/>
                </XLayout.Center.Horizontal>
                <Views.OfControls.AsRow style={{ flex: '1' }} controls={[
                    {
                        class: Views.OfControls.Direction.Backward,
                        onClick: () => {
                            if(navigation.backward)
                                navigation.backward();
                            else
                                navigation.closeDialog();
                            return Promise.resolve();
                        },
                        id: 'keep',
                        label: () => 'Keep Project',
                        icon: () => <MuiIcons.CancelOutlined/>
                    }, {
                        class: Views.OfControls.Direction.Forward,
                        onClick: () => {
                            ProjectController.deleteProject(zone, { me, project: props.binding.project }).exec();
                            return zone.commitAll().then(() => navigation.backward ? navigation.backward() : navigation.closeDialog());
                        },
                        id: 'delete',
                        label: () => 'Delete Project',
                        icon: () => <MuiIcons.DeleteOutlined/>
                    }                            
                ]} render={controlProps => <Views.OfControls.AsButton {...controlProps} variant="outlined"/>}/>
            </XLayout.Stack.South>
            </XLayout.Center.Both>
        </Authorize>
    )
}

export const AsDelete = new Views.Factory<Binding, Model.Authority.Tags.ValueType>(['projects', 'delete'], ['project'], ((binding, factory) => new class implements Views.OfGoals.Plugin {
    key(separator: string) { return factory.asPath().key(separator) }
    route() { return factory.asPath().to(binding) }    
    controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin, options?: { ref?: React.RefObject<HTMLButtonElement> }) => JSX.Element) { return <React.Fragment></React.Fragment> }
    icon(): JSX.Element { return <MuiIcons.DeleteOutlined/> }
    image(pixelDimensions: { width: number, height: number }, imageProps: React.CSSProperties) { return undefined; }
    label(truncate?: { maxChars?: number }): JSX.Element { return  (<span>Delete</span>) }
    summary(options: { onClick: (editMode: boolean) => void, zeroPlaceholder?: () => JSX.Element }): JSX.Element { return  (<span>Delete Project</span>) }
    fields(): Views.OfFields.Plugin[] { return [{
        render: () => 
          <Database.AsEditor peerId="ViewProject.AsDelete" pollInterval={{ ms: 15000 }} render={zone => <_Delete language="en_US" binding={binding}/>}/>
    }] }
}), Modes.NormalMode);
Views.All.push(AsDelete);

export function useUpdateViews(props: {
    language: string,
    binding: Binding
}) {
    const result:Views.OfGoals.Plugin[] = [];
    result.push(ViewDetails.AsUpdate.make(props.binding));
    result.push(ViewMoodBoard.AsMoodBoard.make(props.binding));
    result.push(ViewHasRoles.AsTeamDiagram.make(props.binding));
    result.push(ViewStatus.AsCheck.make(props.binding));
    result.push(AsDelete.make(props.binding));
    return result;
}

export function AsGrid(props: {
    prefix: string,
    style?: React.CSSProperties
}) {
    const navigation = React.useContext(Navigation.Context);
    const { zone } = React.useContext(Database.Context);
    const whoami = React.useContext(Whoami.Context);
    const viewport = React.useRef<HTMLDivElement>(null);
    const community = Objects.Binding.from_bound("the");
    if(!navigation || !zone) throw new Error('Missing Context');
    const hasProject = Propose.useRelation(Model.Community.HasProject.Descriptor.stream(zone, { community }));
    return (
        <XLayout.Grid.Vertical id="project-grid" scroll={true} divRef={viewport} shape={{}} style={{ height: '100%', width: "100%" }}>
            { hasProject.entries.map(entry => 
                <AsTile 
                zone={zone} 
                binding={{project: entry.project }} 
                pixelDimensions={{ width: 160, height: 240 }}
                viewport={viewport}
                onClick={ 
                    whoami.hasTag('admin') 
                    ? () => navigation.forward(AsOverview.asPath().to({ project: entry.project }))
                    : () => navigation.forward(AsProfile.asPath().to({ project: entry.project }))
                }
            />) 
        }
        </XLayout.Grid.Vertical>
    );
}

export function AsTableRow(props: React.PropsWithChildren<{
    zone: Stores.Zone,
    binding: Binding,
    show?: {
        cover: boolean,
        header: boolean
    },
}>): JSX.Element {
    const whoami = React.useContext(Whoami.Context);
    return (
        <TableRow>            
            { props.show?.cover === false ? <React.Fragment/> : <TableCell><AsCover {...props} pixelDimensions={{ width: 160, height: 160 }}/></TableCell> }
            { props.show?.header === false ? <React.Fragment/> : <TableCell><AsHeader {...props}/></TableCell> }                        
            { React.Children.map(props.children, child => (<TableCell>{child}</TableCell>)) }
        </TableRow>
    )
}

export function AsHeader(props: {
    binding: Binding,
    title?: string
}) {
    const { zone } = React.useContext(Database.Context);
    const navigation = React.useContext(Navigation.Context);
    const whoami = React.useContext(Whoami.Context);
    if(!zone || !whoami.person) throw new Error('Missing Context');
    const me = whoami.person;

    const hasStatus = Propose.useScalarProperty(Model.Projects.HasStatus.Descriptor.stream(zone, props.binding).scalar);
    const hasBrandName = Propose.useScalarProperty(Model.Projects.HasBrandName.Descriptor.stream(zone, props.binding).scalar);
    const hasTitle = Propose.useScalarProperty(Model.Projects.HasTitle.Descriptor.stream(zone, props.binding).scalar);
    const hasHomeLocation = Propose.useScalarProperty(Model.Projects.HasHomeLocation.Descriptor.stream(zone, props.binding).scalar);

    return (
        <div style={{ position: 'relative', display: 'block' }}>
            <Styler.Heading align='left'>
                { props.title ? props.title + ': ' : ''}{ hasTitle.value ? hasTitle.value : "?" }
                { hasStatus.value === 'Draft' ? <Styler.Chip theme={Themes.Gray} size='small' label={'Draft'} onClick={() => navigation.forward(ViewStatus.AsCheck.asPath().to(props.binding))}/> : <React.Fragment></React.Fragment> }
            </Styler.Heading>
            <Styler.Body align='left'>
                { hasBrandName.value ? hasBrandName.value : "?" }
            </Styler.Body>
            <Styler.Body align='left'>
                { [ hasHomeLocation.value?.text ].reduce<(JSX.Element|string)[]>((accum, value) => value === undefined ? accum : accum.length === 0 ? [value] : [...accum, <Styler.Bullet/>, value], []) }
            </Styler.Body>
        </div>
    )
}

export function AsActions(props: {
    binding: Binding
}) {
    const { zone } = React.useContext(Database.Context);
    const navigation = React.useContext(Navigation.Context);
    const whoami = React.useContext(Whoami.Context);
    if(!zone || !whoami.person) throw new Error('Missing Context');
    const me = whoami.person;

    const allowEdit = Propose.useScalarProperty(React.useMemo(() => Access.allowEdit(zone, { me, ...props.binding }), [ me, props.binding.project ]));

    return (
        <XLayout.Center.Horizontal>
            <XLayout.Stack.East style={{ width: '100%' }}>
                { <Styler.Button variant='outlined' id="viewproject-team-button" style={{ 
                    flex: '1',
                    margin: '0.5rem'
                }} onClick={() => navigation.forward(AsTeamDiagram.asPath().to(props.binding))}>Team Diagram</Styler.Button>
                }
                { !allowEdit ? <React.Fragment></React.Fragment> : <Styler.Button variant='outlined' id="viewproject-edit-button" style={{ 
                    display: !allowEdit.value ? 'none' : undefined,
                    flex: '1',
                    margin: '0.5rem'
                }} onClick={() => navigation.forward(AsOverview.asPath().to(props.binding))}>Edit</Styler.Button>
                }
            </XLayout.Stack.East>
        </XLayout.Center.Horizontal>
    )
}

export function AsTableRows(props: {
    prefix: string,
    except?: Array<Objects.Binding<string>>,
    show?: {
        cover: boolean,
        header: boolean
    },
    extras?: ((binding: Binding) => JSX.Element)[]
}) {
    const navigation = React.useContext(Navigation.Context);
    const { zone } = React.useContext(Database.Context);
    const whoami = React.useContext(Whoami.Context);
    const community = Objects.Binding.from_bound("the");
    if(!navigation || !zone) throw new Error('Missing Context');
    const hasProject = Propose.useRelation(Model.Community.HasProject.Descriptor.stream(zone, { community }));
    return (<React.Fragment> {
        hasProject.entries.filter(entry => (
            !props.except || !Objects.contains(props.except.map(project => ({project})), { project: entry.project })
        )).map(entry => 
            <AsTableRow
                key={entry.project.objectId}
                zone={zone} 
                binding={{project: entry.project }} 
                show={props.show}
            >
                { props.extras?.map(cb => cb({project: entry.project })) }
            </AsTableRow>) 
    } </React.Fragment>);
}
