import React from 'react';

import { makeStyles, BottomNavigation, BottomNavigationAction, IconButton, TableCell, TableRow } from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';

import { Debug, MaterialUi, Navigation, Styler, Views } from '@pitaman71/omniglot-live-react';
import * as XLayout from '@pitaman71/react-explicit-layout'

import * as HeroIcons from './HeroIcons';

const postDimensions = {
    width: 1080,
    height: 1350
};

const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: 'transparent',
      flexDirection: 'column'
    },
}));

export function AsDialog(props: {
    children: JSX.Element | JSX.Element[]
}) {
    const navigation = React.useContext(Navigation.Context);
    const controls = () => navigation.dialog?.controls(
        control => control.class === Views.OfControls.Direction.Forward || control.class === Views.OfControls.Direction.Backward, 
        control => 
        <IconButton id={control.id} color="primary" disabled={!control.onClick} onClick={() => control.onClick && control.onClick()}>
            {<Views.OfControls.AsIcon view={control}/>}
            {control.label()}
        </IconButton>
    );

    if(!navigation.dialog) return <React.Fragment>{props.children}</React.Fragment>;
    return (
        <div style={{ display: "flex", flexDirection: "column", height: "100%", maxWidth: "100%", overflow: "hidden", margin: "0 1em" }}>
            <div style={{ display: "flex", flex: "0 0 auto", justifyContent:'center', flexDirection: 'column', marginTop: "1em" }}>
                <MaterialUi.Styler.Heading align="center">
                    <p>{ navigation.dialog?.label({ maxChars: 70 }) }</p>
                </MaterialUi.Styler.Heading>
            </div>
            <div className="scroll-y" style={{ display: "flex", flex: "0 1 auto", minHeight: 0, margin: "0.5em 0", flexDirection: "column" }}>
                <AsFields prefix="Inspectors.AsDialog" view={navigation.dialog}/>
            </div>
            <div style={{ display: "flex", flex: "0 0 auto" }}>
                <XLayout.Center.Horizontal>
                    {
                        controls() || <React.Fragment></React.Fragment>
                    }
                </XLayout.Center.Horizontal>
            </div>
        </div>
    );
}

export function AsFields(props: {
    prefix: string,
    view: Views.OfGoals.Plugin,
    style?: React.CSSProperties
}) {
    const navigation = React.useContext(Navigation.Context);
    return (
        <Debug.Boundary name={props.prefix}>
            <XLayout.Center.Both style={{ flexDirection: 'column', ...props.style }}>
                {props.view.fields().map(field => field.render(navigation))}
            </XLayout.Center.Both>
        </Debug.Boundary>
    );
}

export function AsThumbnail(props: {
    view: Views.OfGoals.Plugin,
    pixelDimensions: { width: number, height: number }, 
    style: React.CSSProperties
}) {
    const navigation = React.useContext(Navigation.Context);
    return (
        <div onClick={() => {
            navigation.forward(props.view.route())
        }} id="inspectors-asthumbnail" style={{ overflow: 'clip' }}>
            {props.view.image(props.pixelDimensions, props.style) || <MuiIcons.BrokenImage/>}
        </div>
    )
}

export function AsImage(props: {
    view: Views.OfGoals.Plugin,
    pixelDimensions: { width: number, height: number }, 
    style: React.CSSProperties
}) {
    return (
        props.view.image(props.pixelDimensions, { objectFit: 'contain', objectPosition: 'center', ...props.pixelDimensions, ...props.style }) || <MuiIcons.BrokenImage/>
    )
}

export function AsBackground(props: {
    view: Views.OfGoals.Plugin,
    pixelDimensions: { width: number, height: number }, 
    style: React.CSSProperties
}) {
    return (
        <div id="inspectors-asbackground" style={{ overflow: 'clip', maxHeight: "100%", display: "flex" }}>
            {props.view.image(props.pixelDimensions, { objectFit: 'cover', objectPosition: 'center', ...props.pixelDimensions, ...props.style }) || <MuiIcons.BrokenImage/>}
        </div>
    )
}

export function AsLabel(props: {
    view: Views.OfGoals.Plugin,
    maxLength: number
}) {
    return (
        <div>
            {props.view.label({ maxChars: props.maxLength })}
        </div>
    )
}

export function AsSummary(props: {
    view: Views.OfGoals.Plugin,
    zeroPlaceholder?: () => JSX.Element,
}) {
    const navigation = React.useContext(Navigation.Context);
    return props.view.summary({ ...props, onClick: (editMode: boolean) => navigation.openDialog(editMode && props.view.edit? props.view.edit() : props.view) });
}

export function AsControlBox(props: {
    view: Views.OfGoals.Plugin
}) {
    const navigation = React.useContext(Navigation.Context);
    const styles=useStyles();            
    return (
        <BottomNavigation className={styles.root} showLabels style={{ height: "auto" }}>
            {
                props.view.controls(control => true, control => {
                    return (
                        <BottomNavigationAction id={control.id}
                            label={control.label()}
                            icon={<Views.OfControls.AsIcon view={control}/>}
                            disabled={!control.onClick}
                            onClick={() => control.onClick && control.onClick()}
                        />        
                    )
                })
            }
        </BottomNavigation>
    );
}

export function AsTableRow(props: {
    view: Views.OfGoals.Plugin
}) {
    return (
        <TableRow>
            <TableCell>
                <Views.OfGoals.AsIcon view={props.view}/>
            </TableCell>
            <TableCell>
                <Styler.Heading>
                    <AsSummary view={props.view}/>
                </Styler.Heading>
            </TableCell>
            <TableCell style={{ textAlign: 'right' }}>
                <Views.OfGoals.AsButton view={props.view} size="large" show={{ label: false, icon: <HeroIcons.ArrowCircle variant="solid" direction="right" color="secondary"/> }} />
            </TableCell>
        </TableRow>
    )
}
