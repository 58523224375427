import faker from 'faker';
import { Dialogues, Definitions, Objects, Properties, Relations, Values } from '@pitaman71/omniglot-live-data';
import { Domains, Modules, WrapFaker } from '@pitaman71/omniglot-live-domains';
import * as Elevated from '@pitaman71/omniglot-introspect';

export const directory = new Definitions.Directory();
// tag Heritage.Populations.Tags

export type ValueType = string|'European (Broadly)'|'European (Northern + Western)'|'European (Eastern)'|'European (Southern)'|'African (Hunter-Gatherer)'|'African (Northern)'|'African (Northeastern)'|'African (Southeastern)'|'African (Western)'|'Asian (Western)'|'Asian (Central)'|'Asian (Southern)'|'Asian (Eastern)'|'American (Indigenous)'|'American (Anglo)'|'American (Latin)'|'American (Black)'|'Melanesian + Polynesian';
export const Domain = new Values.TagDomain('European (Broadly)','European (Northern + Western)','European (Eastern)','European (Southern)','African (Hunter-Gatherer)','African (Northern)','African (Northeastern)','African (Southeastern)','African (Western)','Asian (Western)','Asian (Central)','Asian (Southern)','Asian (Eastern)','American (Indigenous)','American (Anglo)','American (Latin)','American (Black)','Melanesian + Polynesian');
        
