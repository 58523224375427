import faker from 'faker';
import { Dialogues, Definitions, Objects, Properties, Relations, Values } from '@pitaman71/omniglot-live-data';
import { Domains, Modules, WrapFaker } from '@pitaman71/omniglot-live-domains';
import * as Elevated from '@pitaman71/omniglot-introspect';

export const directory = new Definitions.Directory();
// tag Titles.Tags

export type ValueType = string|'Brand Manager'|'Project Manager'|'Recruiter'|'Talent Rep'|'Photographer'|'Creative Director'|'Influencer'|'Set Designer'|'Graphic Designer'|'Makeup Artist'|'MUA'|'Hairstylist'|'Producer'|'Model'|'Videographer'|'Wardrobe Stylist'|'Content Creator'|'Artist'|'Editor'|'Production Assistant'|'Marketing Manager'|'Marketing Coordinator'|'Marketing Director'|'Copy Writer'|'Art Director'|'Campaign Manager'|'Event Planner'|'Musician';
export const Domain = new Values.TagDomain('Brand Manager','Project Manager','Recruiter','Talent Rep','Photographer','Creative Director','Influencer','Set Designer','Graphic Designer','Makeup Artist','MUA','Hairstylist','Producer','Model','Videographer','Wardrobe Stylist','Content Creator','Artist','Editor','Production Assistant','Marketing Manager','Marketing Coordinator','Marketing Director','Copy Writer','Art Director','Campaign Manager','Event Planner','Musician');
        
