import faker from 'faker';
import { Dialogues, Definitions, Objects, Properties, Relations, Values } from '@pitaman71/omniglot-live-data';
import { Domains, Modules, WrapFaker } from '@pitaman71/omniglot-live-domains';
import * as Elevated from '@pitaman71/omniglot-introspect';

export const directory = new Definitions.Directory();
// property Roles.HasCount

const Domain: Elevated.Domain<number> = WrapFaker.OfInteger('Roles.HasCount', () => faker.datatype.number(10), { min: 1, max: 10, unit: 1 }).domain();
export type TypeParams = {
    Binding: { role: Objects.Binding<string> },
    Value: number,
    Domain: typeof Domain
}
export const Descriptor = new class _Descriptor extends Properties.Descriptor<TypeParams> {
    canonicalName = 'generated.Roles.HasCount';
    build(builder: Properties.Builder<TypeParams>): void {
        builder.object('role');
        builder.measure(WrapFaker.OfInteger('Roles.HasCount', () => faker.datatype.number(10), { min: 1, max: 10, unit: 1 }).domain());
        builder.scalar();
    }
};

directory.descriptors.properties.set(Descriptor.canonicalName, Descriptor);
        
