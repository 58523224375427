import React from 'react';

import { Dot } from 'react-animated-dots';

import * as MuiIcons from '@material-ui/icons';
import { Stores } from '@pitaman71/omniglot-live-data';
import { Database, Interview, MaterialUi, Navigation, Propose, Styler, Views } from '@pitaman71/omniglot-live-react';
import * as XLayout from '@pitaman71/react-explicit-layout';

import * as Model from 'models/autosrc/swivell/talent_marketplace';

import * as HeroIcons from '../../HeroIcons';
import * as Modes from '../../Modes';
import * as NounProject from '../../NounProject';
import * as Payments from '../../Payments';
import * as Themes from '../../Themes';
import * as Whoami from '../../Whoami';

import { ViewHasRoles, ViewProject, ViewStatus } from '.';
import { ViewIncludes } from '../Content';
import { Controller as ProjectController } from 'models/src/Projects';
import config from '../../config.json'

const baseURI = process.env.SWIVELL_SERVICES || ( process.env.NODE_ENV !== 'production' ? 'http://localhost:8080/dev' : config.services['app.swivell'].backend.baseURI );

function useData(props: {
    zone: Stores.Zone,
    binding: ViewProject.Binding
}) {
    return{
        hasBrandName: Propose.useScalarProperty(Model.Projects.HasBrandName.Descriptor.stream(props.zone, { project: props.binding.project }).scalar),
        hasTitle: Propose.useScalarProperty(Model.Projects.HasTitle.Descriptor.stream(props.zone, { project: props.binding.project }).scalar),
        hasAvatar: Propose.useScalarProperty(Model.Projects.HasAvatar.Descriptor.stream(props.zone, { project: props.binding.project }).scalar),
        includes: Propose.useRelation(Model.Content.Includes.Descriptor.stream(props.zone, { topic: props.binding.project })),
        hasDescription: Propose.useScalarProperty(Model.Projects.HasDescription.Descriptor.stream(props.zone, { project: props.binding.project }).scalar),
        hasHomeLocation: Propose.useScalarProperty(Model.Projects.HasHomeLocation.Descriptor.stream(props.zone, { project: props.binding.project }).scalar)    
    }
}

export function _Wizard(props: {
    language: string,
    binding: ViewProject.Binding,
    mode: 'create'|'edit'
}) {
    const navigation = React.useContext(Navigation.Context);
    const whoami = React.useContext(Whoami.Context);
    const { zone } = React.useContext(Database.Context);
    if(zone === undefined) throw new Error("Missing Context");
    if(whoami.person === undefined) throw new Error("Missing Context");
    const me = whoami.person;

    const access = ViewProject.useAccess(props.binding);

    const { hasBrandName, hasTitle, hasAvatar, includes, hasDescription, hasHomeLocation } = useData({ zone, binding: props.binding });
    
    React.useEffect(() => {
        if(props.mode === 'create') {
            ProjectController.createProject(zone, { owner: me, project: props.binding.project }).exec();
        }
    }, [ props.binding.project, props.mode ])

    const questions: Interview.Question[] = [{
        key: 'project-brand',
        pre: (language) => [ "Which brand owns this project?" ],
        ask: (language, focus,) => {
            return <MaterialUi.ViewScalar.AsInput inputProps={{ 
                type: "text",
                autoFocus: focus,
                multiline: false,
                placeholder: "brand name" 
            }}
                domain={Model.Projects.HasBrandName.Descriptor.getDomain()}
                value={hasBrandName.value} 
                client={hasBrandName.client}
            />
        },
        tell: (language) => (hasBrandName.value === undefined ? <span>This project has no brand</span> : <span>The project is owned by {hasBrandName.value}</span> ),
        post: (language) => [ "This will be visible on the project profile to other users."]
    },{
        key: 'project-title',
        pre: (language) => [ "What shall we call this project?" ],
        ask: (language, focus,) => {
            return <MaterialUi.ViewScalar.AsInput inputProps={{ 
                type: "text",
                autoFocus: focus,
                multiline: false,
                placeholder: "project title" 
            }}
                domain={Model.Projects.HasTitle.Descriptor.getDomain()}
                value={hasTitle.value} 
                client={hasTitle.client}
            />
        },
        tell: (language) => (hasTitle.value === undefined ? <span>This project has no title</span> : <span>The project's title is {hasTitle.value}</span> ),
        post: (language) => [ "This will be visible on the project profile to other users."]
    }, {
        key: 'project-home',
        pre: (language) => [ "What is the location of this project?" ],
        ask: (language, focus,) => {
            return <MaterialUi.ViewLogistics.AsLocationInput 
                domain={Model.Projects.HasHomeLocation.Descriptor.getDomain()}
                value={hasHomeLocation.value} 
                client={hasHomeLocation.client}
            />
        },
        tell: (language) => (hasHomeLocation.value === undefined ? <span>This project has no location</span> : <span>Project location: {hasHomeLocation.value?.text}</span>),
        post: (language) => [ 
            "This will be visible on the project profile to other users.",
            "If you are staffing an on-site projects, it is important to provide this information so nearby talent will be matched.",
            "Please do not provide a physical or mailing address - only the city name (and state/province/country as applicable)",
            "You can return to this page and change your location to match your travel plans.",
        ]
    }, {
        key: 'project-team',
        pre: (language) => [ "What types of talent will you need for this project?" ],
        ask: (language, focus,) => {
            return <ViewHasRoles.AsTable
                binding={{ project: props.binding.project }}
            />
        },
        tell: (language) => <ViewHasRoles.AsChips
            binding={{ project: props.binding.project }}
        />,
        post: (language) => [ 
            "This will be visible on the project profile to other users.",
        ]
    }, {
        key: 'project-avatar',
        pre: (language) => [ "What cover photo should we use for this project?" ],
        ask: (language, focus,) => {
            return <MaterialUi.Media.ViewAsset.AsUploader
                baseURI={baseURI}
                style={{ width: "80% "}} 
                value={hasAvatar.value} 
                client={hasAvatar.client}
            />
        },
        tell: (language) => <span></span>,
        post: (language) => [
        ]
    }, {
        key: 'project-mood',
        pre: (language) => [ "Set the Mood for this project" ],
        ask: (language, focus,) => {
            return <ViewIncludes.AsGrid
                binding={{ topic: props.binding.project }}
                shape={{ rows: 2, columns: 2 }} 
                major='horizontal' scroll={true}
                style={{ height: '100%' }}
                show={{ controls: true, fill: true }}
            >
                <XLayout.Center.Both>
                    <p>Mood Board is empty</p>
                    </XLayout.Center.Both>
                </ViewIncludes.AsGrid>
        },
        tell: (language) => <span></span>,
        post: (language) => [
            `${includes.entries.length} / 4 required`
        ]
    }, {
        key: 'project-description',
        pre: (language) => [ "How would you describe this project?" ],
        ask: (language, focus,) => {
            return <MaterialUi.ViewScalar.AsInput inputProps={{ 
                type: "text",
                autoFocus: focus,
                multiline: true,
                placeholder: "about this project" 
            }}
                domain={Model.Projects.HasDescription.Descriptor.getDomain()}
                value={hasDescription.value} 
                client={hasDescription.client}
            />
        },
        tell: (language) => (hasDescription.value === undefined ? <span>This project has no description</span> : <span>Project description: {hasDescription.value}</span>),
        post: (language) => [ 
            "This will be visible on the project's profile to other users."
        ]
    }];

    return (
        <Interview.Wizard language={props.language} questions={questions} allow={{ view: access.view, edit: access.edit }} label={{
            checkingAuth: () => <span>Confirming your authorization to edit this profile <Dot>.</Dot><Dot>.</Dot><Dot>.</Dot></span>,
            failedAuth: () => <span>You don't have authorization to edit this profile.</span>,
            cancel: () => <span>Discard Changes</span>,
            confirm: () => <span>Save Changes</span>
        }} on={{ 
            cancel: () => { navigation.backward(); return Promise.resolve() },
            commit: () => {
                return zone.commitAll().then(() => {
                    navigation.forward(AsAfter.asPath().to(props.binding))
                })                    
            },
        }} theme={{ card: Themes.Interview, buttons: Themes.Gold }}/>
    )
}

export const AsCreate = new Views.Factory<ViewProject.Binding, Model.Authority.Tags.ValueType>(['projects', 'create'], ['project'], ((binding, factory) => new class implements Views.OfGoals.Plugin {
    key(separator: string) { return factory.asPath().key(separator) }
    route() { return factory.asPath().to(binding) }    
    controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin, options?: { ref?: React.RefObject<HTMLButtonElement> }) => JSX.Element) { return <React.Fragment></React.Fragment> }
    icon(): JSX.Element { return <NounProject.Team/> }
    image(pixelDimensions: { width: number, height: number }, imageProps: React.CSSProperties) { return undefined; }
    label(truncate?: { maxChars?: number }): JSX.Element { return  (<span>Create</span>) }
    summary(options: { onClick: (editMode: boolean) => void, zeroPlaceholder?: () => JSX.Element }): JSX.Element { return  (<span>Create a Project</span>) }
    fields(): Views.OfFields.Plugin[] { return [{
        render: () => 
          <Database.AsEditor peerId="ViewProject.AsCreate" pollInterval={{ ms: 15000 }} render={zone => <Payments.BrandPaywall><_Wizard language="en_US" binding={binding} mode='create'/></Payments.BrandPaywall>}/>
    }] }
}), Modes.NormalMode);
Views.All.push(AsCreate);

export const AsUpdate = new Views.Factory<ViewProject.Binding, Model.Authority.Tags.ValueType>(['projects', 'update'], ['project'], ((binding, factory) => new class implements Views.OfGoals.Plugin {
    key(separator: string) { return factory.asPath().key(separator) }
    route() { return factory.asPath().to(binding) }    
    controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin, options?: { ref?: React.RefObject<HTMLButtonElement> }) => JSX.Element) { return <React.Fragment></React.Fragment> }
    icon(): JSX.Element { return <NounProject.Team/> }
    image(pixelDimensions: { width: number, height: number }, imageProps: React.CSSProperties) { return undefined; }
    label(truncate?: { maxChars?: number }): JSX.Element { return  (<span>Revise</span>) }
    summary(options: { onClick: (editMode: boolean) => void, zeroPlaceholder?: () => JSX.Element }): JSX.Element { return  (<span>Revise Details + Team</span>) }
    fields(): Views.OfFields.Plugin[] { return [{
        render: () => 
          <Database.AsEditor peerId="ViewProject.AsUpdate" pollInterval={{ ms: 15000 }} render={zone => <_Wizard language="en_US" binding={binding} mode='edit'/>}/>
    }] }
}), Modes.AnyMode);
Views.All.push(AsUpdate);

function _After(props: {
    language: string,
    binding: ViewProject.Binding,
}) {
    const navigation = React.useContext(Navigation.Context);
    const { zone } = React.useContext(Database.Context);
    if(zone === undefined) throw new Error('Missing Context');

    const { hasBrandName, hasTitle, hasAvatar, includes, hasDescription, hasHomeLocation } = useData({ zone, binding: props.binding });
    const errors = {
        missingBrandName: !hasBrandName.value,
        missingTitle: !hasTitle.value,
        missingAvatar: !hasAvatar.value,
        missingMood: includes.entries.length < 4,
        missingDescription: !hasDescription.value
    };
    if(errors.missingBrandName || errors.missingTitle || errors.missingAvatar || errors.missingMood || errors.missingDescription ) {
        return <Interview.Waypoint buttonStyle={{ width: '100%' }} progress={{
            key: "after-common-fail",
            status: () => <span>Project Setup Incomplete</span>,
            details: () => [
                <Styler.Body style={{ display: errors.missingBrandName ? undefined : 'none' }}>You did not state the name of the brand which owns this project.</Styler.Body>,
                <Styler.Body style={{ display: errors.missingTitle ? undefined : 'none' }}>You did not provide a title for the project.</Styler.Body>,
                <Styler.Body style={{ display: errors.missingAvatar ? undefined : 'none' }}>You did not upload an avatar photo.</Styler.Body>,
                <Styler.Body style={{ display: errors.missingMood ? undefined : 'none' }}>You did not upload enough photos/videos to setup the project mood board (4 required).</Styler.Body>,
                <Styler.Body style={{ display: errors.missingDescription ? undefined : 'none' }}>You did not provide a project description.</Styler.Body>,
                <Styler.Body>Your Project setup is incomplete, so your account cannot be admitted to the app.</Styler.Body>,
                <Styler.Body>Please click on the "Back to About Me" button to go back and complete any missing sections.</Styler.Body>,
            ],
            options: [
                {
                    class: Views.OfControls.Direction.Forward,
                    id: 'return-project',
                    label: () => <span>Back to Project Setup</span>,
                    icon: ({mode}) => <HeroIcons.ArrowCircle variant='outline' direction='left'/>,
                    onClick: () => {
                        navigation.forward(AsUpdate.asPath().to(props.binding))
                        return Promise.resolve();
                    }
                }
            ]
        }}/>
    } else {
        return <Interview.Waypoint buttonStyle={{ width: '100%' }} progress={{
            key: "after-project-pass",
            status: () => <span>Project Setup Complete</span>,
            details: () => [
                <Styler.Body>You have completed Project Setup. All of your answers have been saved securely to the cloud.</Styler.Body>,
                <Styler.Body>Please click on the "Next" button to activate your project so others can see it.</Styler.Body>,
            ],
            options: [
                {
                    class: Views.OfControls.Direction.Forward,
                    id: 'next',
                    label: () => <span>Next</span>,
                    icon: ({mode}) => <HeroIcons.ArrowCircle variant='outline' direction='right'/>,
                    onClick: () => {
                        navigation.forward(ViewStatus.AsCheck.asPath().to(props.binding))
                        return Promise.resolve();
                    }
                },
                {
                    class: Views.OfControls.Direction.Backward,
                    id: 'return-project',
                    label: () => <span>Back to Project Setup</span>,
                    icon: ({mode}) => <HeroIcons.ArrowCircle variant='outline' direction='left'/>,
                    onClick: () => {
                        navigation.forward(AsUpdate.asPath().to(props.binding))
                        return Promise.resolve();
                    }
                }
            ]
        }}/>        
    }
}

export const AsAfter = new Views.Factory<ViewProject.Binding, Model.Authority.Tags.ValueType>(['projects', 'after', 'update'], ['project'], ((binding, factory) => new class implements Views.OfGoals.Plugin {
    key(separator: string) { return factory.asPath().key(separator) }
    route() { return factory.asPath().to(binding) }    
    controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin, options?: { ref?: React.RefObject<HTMLButtonElement> }) => JSX.Element) { return <React.Fragment></React.Fragment> }
    icon(): JSX.Element { return <MuiIcons.Person/> }
    image(pixelDimensions: { width: number, height: number }, imageProps: React.CSSProperties) { return undefined; }
    label(truncate?: { maxChars?: number }): JSX.Element { return  (<span>Revise</span>) }
    summary(options: { onClick: (editMode: boolean) => void, zeroPlaceholder?: () => JSX.Element }): JSX.Element { return  (<span>About Me</span>) }
    fields(): Views.OfFields.Plugin[] { return [{
        render: () => 
          <Database.AsEditor peerId="ViewProject.AsAfter" pollInterval={{ ms: 15000 }} render={zone => <_After language="en_US" binding={binding}/>}/>
    }] }
}), Modes.AnyMode);
Views.All.push(AsAfter);
