import faker from 'faker';

import { Clients, Controller, Objects, Stores, Values } from '@pitaman71/omniglot-live-data';

import { Access } from '.';

import * as Model from '../../autosrc/swivell/talent_marketplace';

export function Invite(zone: Stores.Zone, binding: { 
    me: Objects.Binding<string>, 
    from: Objects.Binding<string>, 
    person?: Objects.Binding<string>,
    project?: Objects.Binding<string>
}): Controller.OfObject<any> {
    return new class _Invite {
        _neighbors = {
            community: Objects.Binding.from_bound('the')
        }
        _aspects = {
            allow: Clients.ScalarClient.Eager<boolean>('allow'),
            toInvitationStatus: Model.Social.HasInvitationStatus.Descriptor.bind({
                from: binding.from,
                to: binding.person || binding.project
            }),
            out: Model.Social.HasConnection.Descriptor.bindAnchor({ from: binding.from }),
            in: Model.Social.HasConnection.Descriptor.bindAnchor({ from: binding.project || binding.person }),
            fromFeed: Model.Persons.HasDiscoveryFeed.Descriptor.bind({ person: binding.from }),
            toFeed: binding.person === undefined ? undefined : Model.Persons.HasDiscoveryFeed.Descriptor.bind({ person: binding.person })
        }
        allow() {
            return this._aspects.allow.stream(Values.TheBooleanDomain)
        }
        streams() {
            return {
                toInvitationStatus: zone.streams().property(this._aspects.toInvitationStatus).scalar,
                out: zone.streams().relation(this._aspects.out),
                in: zone.streams().relation(this._aspects.in),
                fromFeed: zone.streams().property(this._aspects.fromFeed).sequence,
                toFeed: this._aspects.toFeed === undefined ? undefined : zone.streams().property(this._aspects.toFeed).sequence,
            };
        }
        clients()  {
            return {
                toInvitationStatus: this.streams().toInvitationStatus?.stateful(),
                out: this.streams().out?.stateful(),
                in: this.streams().in?.stateful(),
                fromFeed: this.streams().fromFeed?.stateful(),
                toFeed: this.streams().toFeed?.stateful(),
            }
        }
        reset() {
            const forward = {
                from: binding.from,
                to: binding.person || binding.project
            };
            const backward = {
                to: binding.from,
                from: binding.person || binding.project
            };
            this._aspects.allow.upstream(Access.allowInvite(zone, { ...forward, me: binding.me }));
            this.clients().toInvitationStatus.assign({ acceptCount: 1, rejectCount: 0 });
            this.clients().out.insert(forward);
            this.clients().in.insert(backward);
            const toClient = this.clients().toFeed;
            const fromClient = this.clients().fromFeed;
            if(toClient) {
                binding.project && toClient.append(null, { project: binding.project });
                binding.person && toClient.append(null, { person: binding.person });
            }
            binding.project && fromClient.remove({ project: binding.project });
            binding.person && fromClient.remove({ person: binding.person });
    }
        pre() {
        }
        post() {
        }
    }();
}

export function Add(zone: Stores.Zone, binding: { 
    me: Objects.Binding<string>, 
    from: Objects.Binding<string>, 
    person?: Objects.Binding<string>,
    project?: Objects.Binding<string>
}): Controller.OfObject<any> {
    return new class _Invite {
        _neighbors = {
            community: Objects.Binding.from_bound('the')
        }
        _aspects = {
            allow: Clients.ScalarClient.Eager<boolean>('allow'),
            toInvitationStatus: Model.Social.HasInvitationStatus.Descriptor.bind({
                from: binding.from,
                to: binding.person || binding.project
            }),
            fromInvitationStatus: Model.Social.HasInvitationStatus.Descriptor.bind({
                from: binding.person || binding.project,
                to: binding.from
            }),
            out: Model.Social.HasConnection.Descriptor.bindAnchor({ from: binding.from }),
            in: Model.Social.HasConnection.Descriptor.bindAnchor({ from: binding.project || binding.person }),
            fromFeed: Model.Persons.HasDiscoveryFeed.Descriptor.bind({ person: binding.from })
        }
        allow() {
            return this._aspects.allow.stream(Values.TheBooleanDomain)
        }
        streams() {
            return {
                toInvitationStatus: zone.streams().property(this._aspects.toInvitationStatus).scalar,
                fromInvitationStatus: zone.streams().property(this._aspects.fromInvitationStatus).scalar,
                out: zone.streams().relation(this._aspects.out),
                in: zone.streams().relation(this._aspects.in),
                fromFeed: zone.streams().property(this._aspects.fromFeed).sequence,
            };
        }
        clients()  {
            return {
                toInvitationStatus: this.streams().toInvitationStatus?.stateful(),
                fromInvitationStatus: this.streams().fromInvitationStatus?.stateful(),
                out: this.streams().out?.stateful(),
                in: this.streams().in?.stateful(),
                fromFeed: this.streams().fromFeed?.stateful(),
            }
        }
        reset() {
            const forward = {
                from: binding.from,
                to: binding.person || binding.project
            };
            const backward = {
                to: binding.from,
                from: binding.person || binding.project
            };
            this._aspects.allow.upstream(Access.allowInvite(zone, { ...forward, me: binding.me }));
            this.clients().toInvitationStatus.assign({ acceptCount: 1, rejectCount: 0 });
            this.clients().fromInvitationStatus.assign({ acceptCount: 1, rejectCount: 0 });
            this.clients().out.insert(forward);
            this.clients().in.insert(backward);
            const fromClient = this.clients().fromFeed;
            binding.project && fromClient.append(null, { project: binding.project });
            binding.person && fromClient.append(null, { person: binding.person });
    }
        pre() {
        }
        post() {
        }
    }();
}

export function Remove(zone: Stores.Zone, binding: { 
    me: Objects.Binding<string>, 
    from: Objects.Binding<string>, 
    person?: Objects.Binding<string>,
    project?: Objects.Binding<string>
}): Controller.OfObject<any> {
    return new class _Uninvite {
        _neighbors = {
            community: Objects.Binding.from_bound('the')
        }
        _aspects = {
            allow: Clients.ScalarClient.Eager<boolean>('allow'),
            toInvitationStatus: Model.Social.HasInvitationStatus.Descriptor.bind({
                from: binding.from,
                to: binding.person || binding.project
            }),
            fromInvitationStatus: Model.Social.HasInvitationStatus.Descriptor.bind({
                from: binding.person || binding.project,
                to: binding.from
            }),
            hasDiscoveryFeed: Model.Persons.HasDiscoveryFeed.Descriptor.bind({ person: binding.from })
        }
        allow() {
            return this._aspects.allow.stream(Values.TheBooleanDomain)
        }
        streams() {
            return {
                toInvitationStatus: zone.streams().property(this._aspects.toInvitationStatus).scalar,
                fromInvitationStatus: zone.streams().property(this._aspects.fromInvitationStatus).scalar,
                hasDiscoveryFeed: zone.streams().property(this._aspects.hasDiscoveryFeed).sequence
            };
        }
        clients()  {
            return {
                toInvitationStatus: this.streams().toInvitationStatus?.stateful(),
                fromInvitationStatus: this.streams().fromInvitationStatus?.stateful(),
                hasDiscoveryFeed: this.streams().hasDiscoveryFeed?.stateful()
            }
        }
        reset() {
            const forward = {
                me: binding.me, 
                from: binding.from,
                to: binding.person || binding.project
            };
            const backward = {
                me: binding.me, 
                to: binding.from,
                from: binding.person || binding.project
            };
            this._aspects.allow.upstream(Access.allowInvite(zone, forward));
            this.clients().toInvitationStatus.assign({ acceptCount: 0, rejectCount: 0 });
            this.clients().fromInvitationStatus.assign({ acceptCount: 0, rejectCount: 0 });
            binding.project && this.clients().hasDiscoveryFeed.remove({ project: binding.project });
            binding.person && this.clients().hasDiscoveryFeed.remove({ person: binding.person });
        }
        pre() {
        }
        post() {
        }
    }();
}

export function Reject(zone: Stores.Zone, binding: { 
    me: Objects.Binding<string>, 
    from: Objects.Binding<string>, 
    person?: Objects.Binding<string>,
    project?: Objects.Binding<string>
}): Controller.OfObject<any> {
    return new class _Uninvite {
        _neighbors = {
            community: Objects.Binding.from_bound('the')
        }
        _aspects = {
            allow: Clients.ScalarClient.Eager<boolean>('allow'),
            toInvitationStatus: Model.Social.HasInvitationStatus.Descriptor.bind({
                from: binding.from,
                to: binding.person || binding.project
            }),
            out: Model.Social.HasConnection.Descriptor.bindAnchor({ from: binding.from }),
            in: Model.Social.HasConnection.Descriptor.bindAnchor({ from: binding.person || binding.project }),
            hasDiscoveryFeed: Model.Persons.HasDiscoveryFeed.Descriptor.bind({ person: binding.from })
        }
        allow() {
            return this._aspects.allow.stream(Values.TheBooleanDomain)
        }
        streams() {
            return {
                toInvitationStatus: zone.streams().property(this._aspects.toInvitationStatus).scalar,
                out: zone.streams().relation(this._aspects.out),
                in: zone.streams().relation(this._aspects.in),
                hasDiscoveryFeed: zone.streams().property(this._aspects.hasDiscoveryFeed).sequence
            };
        }
        clients()  {
            return {
                toInvitationStatus: this.streams().toInvitationStatus?.stateful(),
                out: this.streams().out?.stateful(),
                in: this.streams().in?.stateful(),
                hasDiscoveryFeed: this.streams().hasDiscoveryFeed?.stateful()
            }
        }
        reset() {
            const forward = {                
                from: binding.from,
                to: binding.person || binding.project
            };
            const backward = {
                to: binding.from,
                from: binding.person || binding.project
            };
            this._aspects.allow.upstream(Access.allowInvite(zone, { ...forward, me: binding.me }))
            this.clients().toInvitationStatus.assign({ acceptCount: 0, rejectCount: 1 });
            this.clients().out.insert(forward);
            this.clients().in.insert(backward);
            binding.project && this.clients().hasDiscoveryFeed.remove({ project: binding.project });
            binding.person && this.clients().hasDiscoveryFeed.remove({ person: binding.person });
        }
        pre() {
        }
        post() {
        }
    }();
}

export function Suggest(zone: Stores.Zone, binding: { me: Objects.Binding<string>, from: Objects.Binding<string> }): Controller.OfObject<any> {
    return new class _Suggest {
        _neighbors = {
            community: Objects.Binding.from_bound('the')
        }
        _aspects = {
            allow: Clients.ScalarClient.Eager<boolean>('allow'),
            hasDiscoveryFeed: Model.Persons.HasDiscoveryFeed.Descriptor.bind({ person: binding.from })
        }
        allow() {
            return this._aspects.allow.stream(Values.TheBooleanDomain)
        }
        streams() {
            return {
                hasPersons: Model.Community.HasPerson.Descriptor.stream(zone, this._neighbors),
                hasProjects: Model.Community.HasProject.Descriptor.stream(zone, this._neighbors),
                hasDiscoveryFeed: zone.streams().property(this._aspects.hasDiscoveryFeed).sequence
            };
        }
        clients()  {
            return {
                hasDiscoveryFeed: this.streams().hasDiscoveryFeed?.stateful()
            }
        }
        reset() {
            this._aspects.allow.set(true);
            const hasPersons = Clients.RelationClient.Eager<Model.Community.HasPerson.BindingType>('hasPersons');
            hasPersons.upstream(this.streams().hasPersons);
            const hasProjects = Clients.RelationClient.Eager<Model.Community.HasProject.BindingType>('hasProject');
            hasProjects.upstream(this.streams().hasProjects);
            const onAnyUpdate = {
                next: () => {
                    const hasDiscoveryFeed = this.clients().hasDiscoveryFeed;
                    if(hasDiscoveryFeed) {
                        const candidates = [ ...hasPersons.entries().map(entry => {
                            return {
                                person: entry.person
                            }
                        }), ...hasProjects.entries().map(entry => {
                            return {
                                project: entry.project
                            }
                        }) ];
                        hasDiscoveryFeed.assign(faker.helpers.shuffle(candidates).slice(0,5));
                    }
                },
                error: (err) => { console.error(err) },
                complete: () => {}
            };
            onAnyUpdate.next();        
            hasPersons.watch(onAnyUpdate);
            hasProjects.watch(onAnyUpdate);
        }
        pre() {
        }
        post() {
        }
    }();
}

export function groupToSubject(...peers: Objects.Binding<string>[]) {
    const objectIds = peers.map(peer => peer.objectId || "?").sort((a,b) => a < b ? -1 : a > b ? 1 : 0);
    return Objects.Binding.from_bound(objectIds.join(","));
}

export function Connect(zone: Stores.Zone, binding: { me: Objects.Binding<string>, from: Objects.Binding<string>, to: Objects.Binding<string> }): Controller.OfObject<any> {
    return new class _Invite {
        _neighbors = {
            community: Objects.Binding.from_bound('the')
        }
        _aspects = {
            allow: Clients.ScalarClient.Eager<boolean>('allow'),
            toInvitationStatus: Model.Social.HasInvitationStatus.Descriptor.bind({ to: binding.to, from: binding.from }),
            fromInvitationStatus: Model.Social.HasInvitationStatus.Descriptor.bind({ from: binding.to, to: binding.from }),
            out: Model.Social.HasConnection.Descriptor.bindAnchor({ from: binding.from }),
            in: Model.Social.HasConnection.Descriptor.bindAnchor({ from: binding.to }),
        }
        allow() {
            return this._aspects.allow.stream(Values.TheBooleanDomain)
        }
        streams() {
            return {
                toInvitationStatus: zone.streams().property(this._aspects.toInvitationStatus).scalar,
                fromInvitationStatus: zone.streams().property(this._aspects.fromInvitationStatus).scalar,
                out: zone.streams().relation(this._aspects.out),
                in: zone.streams().relation(this._aspects.in)
            };
        }
        clients()  {
            return {
                toInvitationStatus: this.streams().toInvitationStatus?.stateful(),
                fromInvitationStatus: this.streams().fromInvitationStatus?.stateful(),
                out: this.streams().out?.stateful(),
                in: this.streams().in?.stateful()
            }
        }
        reset() {
            this._aspects.allow.upstream(Access.allowInvite(zone, binding));
            this.clients().toInvitationStatus.assign({ acceptCount: 1, rejectCount: 0 });
            this.clients().fromInvitationStatus.assign({ acceptCount: 1, rejectCount: 0 });
            this.clients().out.insert({ from: binding.from, to: binding.to });
            this.clients().in.insert({ from: binding.to, to: binding.from });
        }
        pre() {
        }
        post() {
        }
    }();
}

export function Disconnect(zone: Stores.Zone, binding: { me: Objects.Binding<string>, from: Objects.Binding<string>, to: Objects.Binding<string> }): Controller.OfObject<any> {
    return new class _Invite {
        _neighbors = {
            community: Objects.Binding.from_bound('the')
        }
        _aspects = {
            allow: Clients.ScalarClient.Eager<boolean>('allow'),
            toInvitationStatus: Model.Social.HasInvitationStatus.Descriptor.bind({ to: binding.to, from: binding.from }),
            fromInvitationStatus: Model.Social.HasInvitationStatus.Descriptor.bind({ from: binding.to, to: binding.from }),
            out: Model.Social.HasConnection.Descriptor.bindAnchor({ from: binding.from }),
            in: Model.Social.HasConnection.Descriptor.bindAnchor({ from: binding.to }),
        }
        allow() {
            return this._aspects.allow.stream(Values.TheBooleanDomain)
        }
        streams() {
            return {
                toInvitationStatus: zone.streams().property(this._aspects.toInvitationStatus).scalar,
                fromInvitationStatus: zone.streams().property(this._aspects.fromInvitationStatus).scalar,
                out: zone.streams().relation(this._aspects.out),
                in: zone.streams().relation(this._aspects.in)
            };
        }
        clients()  {
            return {
                toInvitationStatus: this.streams().toInvitationStatus?.stateful(),
                fromInvitationStatus: this.streams().fromInvitationStatus?.stateful(),
                out: this.streams().out?.stateful(),
                in: this.streams().in?.stateful()
            }
        }
        reset() {
            this._aspects.allow.upstream(Access.allowInvite(zone, binding));
            this.clients().toInvitationStatus.assign({ acceptCount: 0, rejectCount: 0 });
            this.clients().fromInvitationStatus.assign({ acceptCount: 0, rejectCount: 0 });
            this.clients().out.insert({ from: binding.from, to: binding.to });
            this.clients().in.insert({ from: binding.to, to: binding.from });
        }
        pre() {
        }
        post() {
        }
    }();
}
