import faker from 'faker';

import { Clients, Controller, Objects, Stores, Streams, Values } from '@pitaman71/omniglot-live-data';
import { Domains } from '@pitaman71/omniglot-live-domains';

import * as Model from '../../autosrc/swivell/talent_marketplace';

import * as InviteAll from '../Tasks/InviteAll';

const delay = (ms: number, val: any) => {
    return new Promise(resolve => setTimeout(resolve, ms, val));
}

export function useDiscussion(zone: Stores.Zone, binding: { subject: Objects.Binding<string> }) {
    return {
        hasComments: Model.Discussion.HasComment.Descriptor.stream(zone, binding)
    }
}

export function useComment(zone: Stores.Zone, binding: { comment: Objects.Binding<string> }) {
    return {
        hasAuthor: Model.Discussion.HasAuthor.Descriptor.stream(zone, binding),
        atTime: Model.Discussion.AtTime.Descriptor.stream(zone, binding).scalar,
        seenAtTime: Model.Discussion.AtTime.Descriptor.stream(zone, binding).scalar,
        hasBody: Model.Discussion.HasBody.Descriptor.stream(zone, binding).scalar,
        hasOffer: Model.Discussion.HasOffer.Descriptor.stream(zone, binding)
    };
}

export function usePerson(zone: Stores.Zone, binding: { person: Objects.Binding<string> }) {
    return {
        hasMessagesWaiting: Model.Agents.HasMessagesWaiting.Descriptor.stream(zone, binding)
    }
}

export function appendComment(zone: Stores.Zone, binding: { 
    subject: Objects.Binding<string>, 
    author: Objects.Binding<string> 
}, recipients: {
    person: Objects.Binding<string>
}[], options: {
    body?: string|null,
    offer?: Objects.Binding<string>
}) {
    const comment = Objects.Binding.from_bound(faker.datatype.uuid());
    const { hasComments } = useDiscussion(zone, binding);
    const { hasBody, hasOffer, hasAuthor, atTime } = useComment(zone, { comment });
    
    return {
        allow: Clients.ScalarClient.Eager<boolean>('allow'),
        binding: {
            ...binding,
            comment
        },
        streams: () => useComment(zone, { comment }),
        exec: () => {
            hasComments.stateful().insert({ subject: binding.subject, comment });
            atTime.stateful().assign(new Date().toISOString());
            if(options.body) hasBody.stateful().assign(options.body);
            if(options.offer) hasOffer.stateful().assign([{ comment, candidate: options.offer }]);
            hasAuthor.stateful().assign([{ comment, author: binding.author }]);
            recipients.forEach(recipient => {
                const { hasMessagesWaiting } = usePerson(zone, recipient);
                hasMessagesWaiting.set.stateful().insert({ subject: binding.subject });
            })
        }
    };
}

export function viewSubject(zone: Stores.Zone, binding: { 
    subject: Objects.Binding<string>, 
    viewer: Objects.Binding<string> 
}) {
    return {
        allow: Clients.ScalarClient.Eager<boolean>('allow'),
        binding: {
            ...binding
        },
        exec: () => {
            const { hasMessagesWaiting } = usePerson(zone, { person: binding.viewer });
            hasMessagesWaiting.set.stateful().remove({ subject: binding.subject });
        }
    };
}
