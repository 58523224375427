import { Definitions } from '@pitaman71/omniglot-live-data';

import * as Comment from './Comment';
import * as Likes from './Likes';
import * as AtTime from './AtTime';
import * as HasOffer from './HasOffer';
import * as HasAuthor from './HasAuthor';
import * as SeenAtTime from './SeenAtTime';
import * as HasBody from './HasBody';
import * as HasComment from './HasComment';
const directory = Definitions.Directory.from(Comment.directory,Likes.directory,AtTime.directory,HasOffer.directory,HasAuthor.directory,SeenAtTime.directory,HasBody.directory,HasComment.directory)
export { directory, Comment, Likes, AtTime, HasOffer, HasAuthor, SeenAtTime, HasBody, HasComment }; 
