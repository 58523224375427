import faker from 'faker';
import { Dialogues, Definitions, Objects, Properties, Relations, Values } from '@pitaman71/omniglot-live-data';
import { Domains, Modules, WrapFaker } from '@pitaman71/omniglot-live-domains';
import * as Elevated from '@pitaman71/omniglot-introspect';

export const directory = new Definitions.Directory();
// tag Projects.Status.Tags

export type ValueType = string|'Draft'|'Active'|'Nominated'|'Staffed'|'Finished';
export const Domain = new Values.TagDomain('Draft','Active','Nominated','Staffed','Finished');
        
