import faker from 'faker';
import { Dialogues, Definitions, Objects, Properties, Relations, Values } from '@pitaman71/omniglot-live-data';
import { Domains, Modules, WrapFaker } from '@pitaman71/omniglot-live-domains';
import * as Elevated from '@pitaman71/omniglot-introspect';

export const directory = new Definitions.Directory();
// property Social.HasInvitationStatus

const Domain: Elevated.Domain<{ acceptCount: number, rejectCount:number }> = WrapFaker.OfJSON('Social.HasInvitationStatus', () => { return { acceptCount: 0, rejectCount: 0 } }).domain();
export type TypeParams = {
    Binding: { from: Objects.Binding<string>; to: Objects.Binding<string> },
    Value: { acceptCount: number, rejectCount:number },
    Domain: typeof Domain
}
export const Descriptor = new class _Descriptor extends Properties.Descriptor<TypeParams> {
    canonicalName = 'generated.Social.HasInvitationStatus';
    build(builder: Properties.Builder<TypeParams>): void {
        builder.object('from');
builder.object('to');
        builder.measure(WrapFaker.OfJSON('Social.HasInvitationStatus', () => { return { acceptCount: 0, rejectCount: 0 } }).domain());
        builder.scalar();
    }
};

directory.descriptors.properties.set(Descriptor.canonicalName, Descriptor);
        
