import React from 'react';

import * as XLayout from '@pitaman71/react-explicit-layout';

export function Carousel(props: React.PropsWithChildren<{
    prefix: string,
    direction: 'east'|'south',
    selected: number,
    setSelected: (selected_: number) => void,
    peek : number,
    gap : number,
    style?: React.CSSProperties
}>) {
    const itemWidth = 100 - props.peek - props.gap;
    const [ mode, setMode ] = React.useState<"init"|"idle">("init");
    const ref = React.useRef<HTMLDivElement>(null);
    React.useEffect(() => {
        if(ref.current) {
            if(mode === 'init') {
                const element = props.selected >= ref.current.children.length ? undefined : ref.current.children[props.selected + 1];
                if(element instanceof HTMLElement) {                    
                    const offsetCenter = element.offsetLeft + (element.offsetWidth / 2);
                    const scrollLeft = offsetCenter - (ref.current.clientWidth / 2);
                    ref.current.scrollLeft = scrollLeft;
                }
                setMode('idle');
            }
        }
    }, [ ref.current ])
    if(props.direction === 'east') {
        return <XLayout.Stack.East id={`${props.prefix}-carousel`} style={{ ...props.style, flex: '1', scrollSnapType: 'x mandatory',  }} scroll={true} divRef={ref}>
            {
                props.peek === 0 ? [] : <div style={{ minHeight: "100%", minWidth: `${props.peek}%`, marginRight: `${props.gap}%`}}>&nbsp;</div>
            }
            { React.Children.map(props.children, child => (
                <div style={{ minWidth: `${itemWidth}%`, height: '100%', margin: `0 ${props.gap}%`, scrollSnapAlign: 'center', scrollSnapStop: 'always' }}>
                    {child}
                </div>
            )) }
            {
                props.peek === 0 ? [] : <div style={{ minHeight: "100%", minWidth: `${props.peek}%`, marginLeft: `${props.gap}%`}}>&nbsp;</div>
            }
        </XLayout.Stack.East>;
    } else {
        return <XLayout.Stack.South id={`${props.prefix}-carousel`} style={{ ...props.style, flex: '1', scrollSnapType: 'y mandatory',  }} scroll={true} divRef={ref}>
            {
                props.peek === 0 ? [] : <div style={{ minWidth: "100%", minHeight: `${props.peek}%`, marginBottom: `${props.gap}%`}}>&nbsp;</div>
            }
            { React.Children.map(props.children, child => (
                <div style={{ minHeight: `${itemWidth}%`, width: '100%', margin: `0 ${props.gap}%`, scrollSnapAlign: 'center', scrollSnapStop: 'always' }}>
                    {child}
                </div>
            )) }
            {
                props.peek === 0 ? [] : <div style={{ minWidth: "100%", minHeight: `${props.peek}%`, marginTop: `${props.gap}%`}}>&nbsp;</div>
            }
        </XLayout.Stack.South>;
        
    }
}