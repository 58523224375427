import faker from 'faker';
import { Dialogues, Definitions, Objects, Properties, Relations, Values } from '@pitaman71/omniglot-live-data';
import { Domains, Modules, WrapFaker } from '@pitaman71/omniglot-live-domains';
import * as Elevated from '@pitaman71/omniglot-introspect';

export const directory = new Definitions.Directory();
// property PushNotifications.HasSubscription

const Domain: Elevated.Domain<string> = WrapFaker.OfString('PushNotifications.HasSubscription', () => "").domain();
export type TypeParams = {
    Binding: { the: Objects.Binding<string> },
    Value: string,
    Domain: typeof Domain
}
export const Descriptor = new class _Descriptor extends Properties.Descriptor<TypeParams> {
    canonicalName = 'generated.PushNotifications.HasSubscription';
    build(builder: Properties.Builder<TypeParams>): void {
        builder.object('the');
        builder.measure(WrapFaker.OfString('PushNotifications.HasSubscription', () => "").domain());
        builder.set();
    }
};

directory.descriptors.properties.set(Descriptor.canonicalName, Descriptor);
        
