import faker from 'faker';
import { Dialogues, Definitions, Objects, Properties, Relations, Values } from '@pitaman71/omniglot-live-data';
import { Domains, Modules, WrapFaker } from '@pitaman71/omniglot-live-domains';
import * as Elevated from '@pitaman71/omniglot-introspect';

export const directory = new Definitions.Directory();
// property Projects.HasHomeLocation

const Domain: Elevated.Domain<Domains.Base.Parseable & Domains.Logistics._Where> = WrapFaker.OfJSON('Projects.HasHomeLocation', () => ({ text: faker.address.city() })).domain();
export type TypeParams = {
    Binding: { project: Objects.Binding<string> },
    Value: Domains.Base.Parseable & Domains.Logistics._Where,
    Domain: typeof Domain
}
export const Descriptor = new class _Descriptor extends Properties.Descriptor<TypeParams> {
    canonicalName = 'generated.Projects.HasHomeLocation';
    build(builder: Properties.Builder<TypeParams>): void {
        builder.object('project');
        builder.measure(WrapFaker.OfJSON('Projects.HasHomeLocation', () => ({ text: faker.address.city() })).domain());
        builder.scalar();
    }
};

directory.descriptors.properties.set(Descriptor.canonicalName, Descriptor);
        
