import faker from 'faker';
import { Dialogues, Definitions, Objects, Properties, Relations, Values } from '@pitaman71/omniglot-live-data';
import { Domains, Modules, WrapFaker } from '@pitaman71/omniglot-live-domains';
import * as Elevated from '@pitaman71/omniglot-introspect';

export const directory = new Definitions.Directory();
// tag Sexuality.PreferenceTypes.Tags

export type ValueType = string|'asexual'|'bi'|'demi'|'gay'|'lesbian'|'mono'|'pan'|'straight'|'poly';
export const Domain = new Values.TagDomain('asexual','bi','demi','gay','lesbian','mono','pan','straight','poly');
        
