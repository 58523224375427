import faker from 'faker';
import rxjs from 'rxjs';

import { Clients, Controller, Dependencies, Objects, Stores, Values } from '@pitaman71/omniglot-live-data';
import { Domains } from '@pitaman71/omniglot-live-domains';

import * as Model from '../../autosrc/swivell/talent_marketplace';

export class Upload {
    _subject: rxjs.Subject<this>;
    _mediaServiceURI: string;
    _blob: Blob;
    _key: string;
    _data?: string;
    _signedUrl?: string;
    _sent?: number;
    _cloud?: Domains.Media._Asset;

    constructor(blob: Blob, mediaServiceURI: string) {
        this._subject = Dependencies.makeSubject();
        this._mediaServiceURI = mediaServiceURI;
        this._key = faker.datatype.uuid();
        this._blob = blob;
    }

    watch(observer: rxjs.Observer<this>) {
        return this._subject.subscribe(observer);
    }

    toString() {
        const status = this._sent === this._blob.size ? 'DONE'
        : this._blob.size !== undefined && this._sent !== undefined ? `${this._sent}/${this._blob.size}`
        : !!this._signedUrl ? 'WAITING'
        : 'READING';
        return `UPLOAD ${this._key} ${status}`;
    }

    _read(): Promise<this> {
        return new Promise<this>((resolve, reject) => {
            const fr = new FileReader();
            fr.onload = () => {
                this._data = fr.result as string,
                this._cloud = {
                    ...this._cloud,
                    mime: this._data.substring(this._data.indexOf(":")+1, this._data.indexOf(";"))
                };
                this._subject.next(this);
                resolve(this);
            }
            fr.onerror = () => {
                this._subject.error(this);
                reject(fr.error);
            }
            fr.readAsDataURL(this._blob)
        });
    }

    _metadata(): Promise<this> {
        return new Promise((resolve, reject) => {
            const img = new Image();
            if(!this._data) {
                reject('no data to upload');
            } else {
                img.src = this._data;
                img.onload = event => {
                    this._cloud = { 
                        ...this._cloud, 
                        pixelDimensions: {
                            height: img.naturalHeight,
                            width: img.naturalWidth
                        }
                    };
                    this._subject.next(this);
                    resolve(this);
                };
                img.onerror = err => {
                    this._subject.error(this);
                    reject(err)
                };
            }
        });
    }

    _allocate(): Promise<this> {
        return fetch(this._mediaServiceURI+'/media/upload',
        {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }, body: JSON.stringify({ 
                path: this._key+'.jpeg', 
                size: this._blob.size 
            })
        })
        .then(response => response.json())
        .then(({ signedUrl }) => {
            this._signedUrl = signedUrl;
            this._subject.next(this);
            return this;
        });
    }

    _send(): Promise<this> {
        return this._blob.arrayBuffer()
        .then(arrayBuffer => !this._signedUrl ? Promise.reject('_signedUrl was not set')
        : !this._cloud?.mime ? Promise.reject('_cloud was not set')
        : fetch(this._signedUrl, { 
            method: 'PUT',
            headers: {
                "Content-Type": this._cloud.mime,
            },
            body: arrayBuffer
        })).then(() => {
            this._cloud = {
                ...this._cloud,
                uri: '/media/'+ this._key+'.jpeg'
            }
            this._sent = this._blob.size;
            this._subject.next(this);
            this._subject.complete();
            return this;
        });
    }
    
    go(): Promise<this> {
        return this._read()
        .then(() => this._metadata())
        .then(() => this._allocate())
        .then(() => this._send());
    }
};

export function Manage(zone: Stores.Zone, binding: { topic: Objects.Binding<string> }, mediaServiceURI: string) {
    return new class _Manage implements Controller.OfObject<any> {
        _aspects = {
            allow: Clients.ScalarClient.Eager<boolean>('allow')
        }
        allow() {
            return this._aspects.allow.stream(Values.TheBooleanDomain)
        }
        streams() {
            return {
                includes: Model.Content.Includes.Descriptor.stream(zone, { topic: binding.topic })
            };
        }
        clients()  {
            return {
                includes: this.streams().includes?.stateful()
            }
        }
        reset() {
            this._aspects.allow.set(true);
        }
        pre() {
        }
        post() {
        }
        add(blobs_: Blob[]) {
            const result = blobs_.map(blob_ => {
                const upload = new Upload(blob_, mediaServiceURI);
                const asset = Objects.Binding.from_bound(upload._key);
                const hasMedia = Model.Content.HasMedia.Descriptor.stream(zone, { asset }).scalar?.stateful();
                upload.watch({
                    next: mgr => {
                        if(mgr._cloud && hasMedia) {
                            console.log(`Content.HasMedia(asset: ${asset.objectId}) = ${JSON.stringify(mgr._cloud)}`);
                            hasMedia.assign(mgr._cloud);
                        }
                    }, 
                    error: err => {},
                    complete: () => {}
                });
                upload.go()
                .catch(err => {
                    console.error(err);
                });
                return { upload, asset };
            });
            this.clients().includes?.insert(...result.map(({ upload, asset }) => ({ topic: binding.topic, asset})) );
            return result;
        }
    }();
}
