import faker from 'faker';
import { Dialogues, Definitions, Objects, Properties, Relations, Values } from '@pitaman71/omniglot-live-data';
import { Domains, Modules, WrapFaker } from '@pitaman71/omniglot-live-domains';
import * as Elevated from '@pitaman71/omniglot-introspect';

export const directory = new Definitions.Directory();
// property Projects.HasTitle

const Domain: Elevated.Domain<string> = WrapFaker.OfString('Projects.HasTitle', () => faker.lorem.words(3)).domain();
export type TypeParams = {
    Binding: { project: Objects.Binding<string> },
    Value: string,
    Domain: typeof Domain
}
export const Descriptor = new class _Descriptor extends Properties.Descriptor<TypeParams> {
    canonicalName = 'generated.Projects.HasTitle';
    build(builder: Properties.Builder<TypeParams>): void {
        builder.object('project');
        builder.measure(WrapFaker.OfString('Projects.HasTitle', () => faker.lorem.words(3)).domain());
        builder.scalar();
    }
};

directory.descriptors.properties.set(Descriptor.canonicalName, Descriptor);
        
