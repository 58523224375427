import { Clients, Objects, Operators, Stores, Streams, Values } from '@pitaman71/omniglot-live-data';

import * as Model from '../../autosrc/swivell/talent_marketplace';

export function allowView(
    before: Stores.Zone, 
    binding: { me: Objects.Binding<string>, project: Objects.Binding<string> }
): Streams.ScalarStream<boolean> {
    const meHasAuthority = Model.Persons.HasAuthority.Descriptor.stream(before, { person: binding.me });
    const hasProvenance = Model.Identity.Provenance.Descriptor.stream(before, { the: binding.project });
    const hasMembers = Model.Projects.HasMembers.Descriptor.stream(before, { project: binding.project });

    const isOwner = Operators.ApplyScalars(
        'isOwner', 
        Values.TheBooleanDomain, 
        (hasProvenance_) => Objects.Binding.cmp(hasProvenance_?.owner, binding.me) === 0, 
        hasProvenance
    );
    const isAdmin = Operators.ApplySet('isAdmin', Values.TheBooleanDomain, meHasAuthority_ => meHasAuthority_.has('admin'), meHasAuthority);
    const isAmbassador = Operators.ApplySet('isAmbassador', Values.TheBooleanDomain, meHasAuthority_ => meHasAuthority_.has('ambassador'), meHasAuthority);
    const isMember = Operators.ApplyRelation('isMember', Values.TheBooleanDomain, hasMembers_ => hasMembers_.filter(entry => Objects.Binding.cmp(entry.person, binding.me) === 0).length > 0, hasMembers);

    const result = Operators.ApplyScalars('result', Values.TheBooleanDomain, (isOwner, isMember, isAdmin, isAmbassador) => 
        isOwner || isMember || isAdmin || isAmbassador, isOwner, isMember, isAdmin, isAmbassador);
    return result.scalar;
}

export function allowEdit(
    before: Stores.Zone, 
    binding: { me: Objects.Binding<string>, project: Objects.Binding<string> }
): Streams.ScalarStream<boolean> {
    const meHasAuthority = Model.Persons.HasAuthority.Descriptor.stream(before, { person: binding.me });
    const hasProvenance = Model.Identity.Provenance.Descriptor.stream(before, { the: binding.project });

    const isOwner = Operators.ApplyScalars(
        'isOwner', 
        Values.TheBooleanDomain, 
        (hasProvenance_) => Objects.Binding.cmp(hasProvenance_?.owner, binding.me) === 0, 
        hasProvenance
    );
    const isAdmin = Operators.ApplySet('isAdmin', Values.TheBooleanDomain, meHasAuthority_ => meHasAuthority_.has('admin'), meHasAuthority);

    const result = Operators.ApplyScalars('result', Values.TheBooleanDomain, (isOwner, isAdmin) => 
        isOwner  || isAdmin, isOwner, isAdmin);
    return result.scalar;
}

export function allowRemove(
    before: Stores.Zone, 
    binding: { me: Objects.Binding<string>, project: Objects.Binding<string> }
): Streams.ScalarStream<boolean> {
    const meHasAuthority = Model.Persons.HasAuthority.Descriptor.stream(before, { person: binding.me });

    const isAdmin = Operators.ApplySet('isAdmin', Values.TheBooleanDomain, meHasAuthority_ => meHasAuthority_.has('admin'), meHasAuthority);

    return isAdmin.scalar;
}
