import { Definitions } from '@pitaman71/omniglot-live-data';

import * as HasHomeLocation from './HasHomeLocation';
import * as HasWebLink from './HasWebLink';
import * as HasAvatar from './HasAvatar';
import * as Manager from './Manager';
import * as Freelancer from './Freelancer';
import * as Person from './Person';
import * as HasAuthority from './HasAuthority';
import * as HasTalentStats from './HasTalentStats';
import * as HasOpenToTravel from './HasOpenToTravel';
import * as HasName from './HasName';
import * as HasTitle from './HasTitle';
import * as HasAvailability from './HasAvailability';
import * as HasEmail from './HasEmail';
import * as HasDiscoveryFeed from './HasDiscoveryFeed';
import * as HasAdmissionDate from './HasAdmissionDate';
import * as HasAbout from './HasAbout';
import * as ByEmail from './ByEmail';
import * as HasTasks from './HasTasks';
const directory = Definitions.Directory.from(HasHomeLocation.directory,HasWebLink.directory,HasAvatar.directory,Manager.directory,Freelancer.directory,Person.directory,HasAuthority.directory,HasTalentStats.directory,HasOpenToTravel.directory,HasName.directory,HasTitle.directory,HasAvailability.directory,HasEmail.directory,HasDiscoveryFeed.directory,HasAdmissionDate.directory,HasAbout.directory,ByEmail.directory,HasTasks.directory)
export { directory, HasHomeLocation, HasWebLink, HasAvatar, Manager, Freelancer, Person, HasAuthority, HasTalentStats, HasOpenToTravel, HasName, HasTitle, HasAvailability, HasEmail, HasDiscoveryFeed, HasAdmissionDate, HasAbout, ByEmail, HasTasks }; 
