import faker from 'faker';
import React from 'react';

import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Objects, Stores, Streams } from '@pitaman71/omniglot-live-data';
import { Database, Navigation, Propose, Views } from '@pitaman71/omniglot-live-react';
import * as XLayout from '@pitaman71/react-explicit-layout';

import * as Model from 'models/autosrc/swivell/talent_marketplace';

import { ViewDetails, ViewPreview, ViewProject } from '.';

import * as NounProject from '../../NounProject';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '6px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
}));
  
export function AsTimeline(props: {
  zone: Stores.Zone,
  binding: { person: Objects.Binding<string> }
}) {
  const classes = useStyles();
  const navigation = React.useContext(Navigation.Context);
  const stream = props.zone.streams().relation(Model.Projects.ByMember.Descriptor.bindAnchor({ person: props.binding.person }));
  return (
    <React.Fragment>
    <XLayout.Stack.South scroll={true} id="projects-viewbymember-astimeline" style={{ position: 'absolute', height: '90%', width: '100%'}}>
        <Propose.RelationEntries stream={stream} render={
          (entries, client) => <React.Fragment>
              {
                entries.length > 0 ? [] : <img src="fullres/upcoming.png" style={{ objectFit: 'contain', maxWidth: "100%", maxHeight: "100%"}}/>
              }
              { entries.map(entry => (
                  <ViewPreview.Card
                    prefix={entry.project.objectId || 'preview'}
                    zone={props.zone} 
                    binding={{ project: entry.project }}
                    style={{ minHeight: '90%'}}
                  />
            )) }
            </React.Fragment>
        }/>
    </XLayout.Stack.South>
      <XLayout.Center.Horizontal style={{
            position: 'absolute',
            top: '90%',
            left: '0%',
            right: '100%'
        }}>
            <Button variant="outlined" id="projects-viewbymember-add-button" style={{ 
                borderColor: 'currentColor',
                backdropFilter: 'blur(8px)',
                WebkitBackdropFilter: 'blur(8px)',
                backgroundColor: 'rgba(45, 45, 45, 0.1)'
            }} onClick={() => navigation.forward(ViewDetails.AsCreate.asPath().to({ project: Objects.Binding.from_bound(faker.datatype.uuid()) }))}>New Project</Button>
        </XLayout.Center.Horizontal>
    </React.Fragment>
  );
}

const BrowseProjects = new Views.Factory<{ person: Objects.Binding<string>}, Model.Authority.Tags.ValueType>([ 'projects', 'byMember', 'browse'], ['person'], (binding, factory) => new class implements Views.OfGoals.Plugin {
  key(separator: string) { return factory.asPath().key(separator) }
  route() { return factory.asPath().to(binding) }    
  controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin, options?: { ref?: React.RefObject<HTMLButtonElement> }) => JSX.Element) { return <React.Fragment></React.Fragment> }
  icon() { return <NounProject.Availability/> }
  image(pixelDimensions: { width: number, height: number }, imageProps: React.CSSProperties) {
      return undefined
  }
  label(truncate?: { maxChars?: number }) {
      return <span>Browse Projects</span>
  }
  summary(options: { onClick: (editMode:boolean) => void, zeroPlaceholder?: () => JSX.Element }) {
      return <div onClick={() => options.onClick(false)}>{this.label()}</div>
  }
  fields() {
    return [ { 
      render: () =>  (
        <Database.AsViewer render={(zone) => <AsTimeline zone={zone} binding={binding}/>}/>
      )
    } ];
  }
});

Views.All.push(BrowseProjects);
