import { Definitions, Objects, Relations } from '@pitaman71/omniglot-live-data';

export const directory = new Definitions.Directory();

export interface BindingType extends Objects.BindingType<string> { owner: Objects.Binding<string>, task: Objects.Binding<string> }

export const Descriptor = new class _Descriptor extends Relations.Descriptor<BindingType> {
    canonicalName: string = `Tasks.ByOwner`;
    build(builder: Relations.Builder<BindingType>): void {
        builder.object('owner');
        builder.set();
        builder.object('task');
    }
};    
directory.descriptors.relations.set(Descriptor.canonicalName, Descriptor)    
