import faker from 'faker';
import { Dialogues, Definitions, Objects, Properties, Relations, Values } from '@pitaman71/omniglot-live-data';
import { Domains, Modules, WrapFaker } from '@pitaman71/omniglot-live-domains';
import * as Elevated from '@pitaman71/omniglot-introspect';

export const directory = new Definitions.Directory();
// relation Discussion.HasOffer

export interface BindingType extends Objects.BindingType<string> { comment: Objects.Binding<string>; candidate: Objects.Binding<string> }

export const Descriptor = new class _Descriptor extends Relations.Descriptor<BindingType> {
    canonicalName: string = `generated.Discussion.HasOffer`;
    build(builder: Relations.Builder<BindingType>): void {
        builder.object('comment');
        builder.set();
        builder.object('candidate');
    }
};

directory.descriptors.relations.set(Descriptor.canonicalName, Descriptor);
        
