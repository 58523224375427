import React from 'react';

import { Dot } from 'react-animated-dots';

import * as XLayout from '@pitaman71/react-explicit-layout';
import { Objects, Stores } from '@pitaman71/omniglot-live-data';
import { Database, Navigation, Propose, Styler, Views } from '@pitaman71/omniglot-live-react';

import * as Model from 'models/autosrc/swivell/talent_marketplace';
import { ViewIncludes } from '../Content';
import * as Themes from '../../Themes';

import { Media } from '..';
import { Authorize, useAccess } from './ViewPerson';
import { AsProfile } from './ViewProfile';

import * as Whoami from '../../Whoami';

import { Controller as SocialController } from 'models/src/Social';

export interface Binding extends Objects.BindingType<string> {
    person: Objects.Binding<string>
}
export let pollInterval = { ms: 30000 };

export function Card(props: React.PropsWithChildren<{
    prefix: string,
    binding: Binding,
    zone: Stores.Zone,
    style?: React.CSSProperties
}>) {
    const access = useAccess(props.binding);
    const navigation = React.useContext(Navigation.Context);

    const hasName = Propose.useScalarProperty(Model.Persons.HasName.Descriptor.stream(props.zone, props.binding).scalar);
    const hasAvatar = Propose.useScalarProperty(Model.Persons.HasAvatar.Descriptor.stream(props.zone, props.binding).scalar);
    const hasHomeLocation = Propose.useScalarProperty(Model.Persons.HasHomeLocation.Descriptor.stream(props.zone, props.binding).scalar);
    const offersAudienceTargeting = Propose.useSetProperty(Model.Creator.OffersAudienceTargeting.Descriptor.stream(props.zone, { creator: props.binding.person }).set);
    const offersBlendedRate = Propose.useScalarProperty(Model.Creator.OffersBlendedRate.Descriptor.stream(props.zone, { creator: props.binding.person }).scalar);
    const hasSkills = Propose.useSetProperty(Model.Creator.HasSkills.Descriptor.stream(props.zone, { creator: props.binding.person }).set);
    const offersContentTypes = Propose.useSetProperty(Model.Creator.OffersContentTypes.Descriptor.stream(props.zone, { creator: props.binding.person }).set);
    const offersProductCategories = Propose.useSetProperty(Model.Creator.OffersProductCategories.Descriptor.stream(props.zone, { creator: props.binding.person }).set);

    const qualifications = [
        ...Array.from(offersContentTypes.values),
        ...Array.from(offersProductCategories.values)
    ];
    
    return <div style={{height: '100%', width: '100%', padding: '1em', display: "flex", flexDirection: "column", ...props.style }}>
        <Authorize access={access} mode='view'>
            <Styler.Card prefix={props.prefix} style={{ flex: "1", width: '100%', display: "flex", flexDirection: "column" }} theme={Themes.Light} onClick={event =>
                event.defaultPrevented || navigation.forward(AsProfile.asPath().to(props.binding))
            }>
                <Styler.CardMedia style={{ flex: "1", minHeight: "0" }}>
                    <ViewIncludes.AsGrid
                        binding={{ topic: props.binding.person }}
                        shape={{ rows: 2, columns: 2 }}
                        major='horizontal'
                        show={{ fill: true }}
                    >
                        <Media.AsDownloader {...hasAvatar} pixelDimensions={{ width: 300, height: 300 }} style={{ width: '100%', height: '100%' }}/>
                    </ViewIncludes.AsGrid>
                </Styler.CardMedia>
                <Styler.CardContent>
                    <Styler.Control prefix="person-preview-summary">
                        <Styler.Heading align='left'>
                            { hasName.value ? hasName.value : <React.Fragment>Loading <Dot>.</Dot><Dot>.</Dot><Dot>.</Dot></React.Fragment> }
                        </Styler.Heading>
                        <Styler.Body align='left'>
                            { [ hasHomeLocation.value?.text ].reduce<(JSX.Element|string)[]>((accum, value) => value === undefined ? accum : accum.length === 0 ? [value] : [...accum, <Styler.Bullet/>, value], []) }
                            <Styler.Chips theme={Themes.Gold} size='small' limit={2}>{Array.from(hasSkills.values).map(item => <span>{item}</span>)}</Styler.Chips>
                        </Styler.Body>
                        <Styler.Body align='left'>
                            <Styler.Chips theme={Themes.Gold} size='small' limit={2}>{qualifications.map(item => <span>{item}</span>)}</Styler.Chips>
                            { offersBlendedRate.value ? <React.Fragment><Styler.Bullet/> from {offersBlendedRate.value?.text} </React.Fragment> : <React.Fragment/> }
                        </Styler.Body>
                    </Styler.Control>
                </Styler.CardContent>
                { props.children }
            </Styler.Card>
        </Authorize>
    </div>;
}
