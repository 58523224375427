import React from 'react';

import { Dot } from 'react-animated-dots';

import { Stores } from '@pitaman71/omniglot-live-data';
import { Domains } from '@pitaman71/omniglot-live-domains';
import { Database, Interview, MaterialUi, Navigation, Propose, Styler, Views } from '@pitaman71/omniglot-live-react';

import * as Model from 'models/autosrc/swivell/talent_marketplace';
import * as Modes from '../../Modes';
import * as NounProject from '../../NounProject';
import * as Themes from '../../Themes';
import * as Whoami from '../../Whoami';

import * as HeroIcons from '../../HeroIcons';

import { ViewPerson } from '.';

import config from '../../config.json'

const baseURI = process.env.SWIVELL_SERVICES || ( process.env.NODE_ENV !== 'production' ? 'http://localhost:8080/dev' : config.services['app.swivell'].backend.baseURI );

function useData(props: {
    zone: Stores.Zone,
    binding: ViewPerson.Binding
}) {
    return {
        hasName: Propose.useScalarProperty(Model.Affiliations.HasName.Descriptor.stream(props.zone, { affiliated: props.binding.person }).scalar),
        hasURL: Propose.useScalarProperty(Model.Affiliations.HasURL.Descriptor.stream(props.zone, { affiliated: props.binding.person }).scalar),
        hasAvatar: Propose.useScalarProperty(Model.Affiliations.HasAvatar.Descriptor.stream(props.zone, { affiliated: props.binding.person }).scalar),
        hasSocialMedia: Propose.useSetProperty(Model.SocialMedia.HasAccount.Descriptor.stream(props.zone, { the: props.binding.person }).set)
    };
}

function _Wizard(props: {
    language: string,
    binding: ViewPerson.Binding
}) {
    const navigation = React.useContext(Navigation.Context);
    const whoami = React.useContext(Whoami.Context);
    const { zone } = React.useContext(Database.Context);
    if(zone === undefined) throw new Error("Missing Context");
    if(whoami.person === undefined) throw new Error("Missing Context");
    const me = whoami.person;

    const access = ViewPerson.useAccess(props.binding);

    const { hasName, hasURL, hasAvatar, hasSocialMedia } = useData({ zone, binding: props.binding });
    const questions: Interview.Question[] = [{
        key: 'brand-name',
        pre: (language) => [ "What is the name of the brand you represent?" ],
        ask: (language, focus,) => {
            return <MaterialUi.ViewScalar.AsInput inputProps={{
                id: "brand-name-input",
                autoFocus: focus,
                multiline: false,
                placeholder: "brand name",
            }}
                domain={Model.Affiliations.HasName.Descriptor.getDomain()}
                value={hasName.value} 
                client={hasName.client}
            />
        },
        tell: (language) => <span>You represent this brand: {hasName.value}</span>,
        post: (language) => [ "This will be visible on your profile to other users."]
    }, {
        key: 'brand-logo',
        pre: (language) => [ "What logo should we use for your brand?" ],
        ask: (language, focus,) => {
            return <MaterialUi.Media.ViewAsset.AsUploader
                baseURI={baseURI}
                style={{ width: "80% "}} 
                value={hasAvatar.value} 
                client={hasAvatar.client}
            />
        },
        tell: (language) => !!hasAvatar.value ? <span>You have uploaded a logo image</span> : <span>You have not uploaded a logo image</span>,
        post: (language) => [
        ]
    }, {
        key: 'brand-website',
        pre: (language) => [ "What is your brand's website?" ],
        ask: (language, focus,) => {
            return <MaterialUi.ViewScalar.AsInput inputProps={{
                id: "brand-website-input",
                type: "text",
                autoFocus: focus,
                multiline: false,
                placeholder: "brand website",
            }}
                domain={Model.Affiliations.HasURL.Descriptor.getDomain()}
                value={hasURL.value} 
                client={hasURL.client}
            />
        },
        tell: (language) => <span>The brand's website is {hasURL.value}</span>,
        post: (language) => [ "This will be visible on your profile to other users."]
    }, {
        key: 'brand-socials',
        pre: (language) => [ "Which social media accounts does your brand use?" ],
        ask: (language, focus,) => {
            return <MaterialUi.ViewSocialMedia.AsTable
                prefix="brand"
                serviceNames={[
                    Domains.SocialMedia.ServiceNames.FACEBOOK,
                    Domains.SocialMedia.ServiceNames.INSTAGRAM,
                    Domains.SocialMedia.ServiceNames.LINKEDIN,
                    Domains.SocialMedia.ServiceNames.TIKTOK,
                ]}
                style={{ width: "80% "}} 
                values={hasSocialMedia.values} 
                client={hasSocialMedia.client}
            />
        },
        tell: (language) => <span>The brand's social media handles are: {Array.from(hasSocialMedia.values).join(', ')}</span>,
        post: (language) => [ "Social media stats (e.g. follower counts) are visible to other users in your brand profile" ]
    }];

    return (
        <Interview.Wizard language={props.language} questions={questions} allow={{ view: access.view, edit: access.edit }} label={{
            checkingAuth: () => <span>Confirming your authorization to edit this profile <Dot>.</Dot><Dot>.</Dot><Dot>.</Dot></span>,
            failedAuth: () => <span>You don't have authorization to edit this profile.</span>,
            cancel: () => <span>Discard Changes</span>,
            confirm: () => <span>Save Changes</span>
        }} on={{ 
            cancel: () => { navigation.backward(); return Promise.resolve() },
            commit: () => {
                return zone.commitAll().then(() => {
                    navigation.forward(AsAfter.asPath().to(props.binding))
                })                    
            },
        }} theme={{ card: Themes.Interview, buttons: Themes.Gold }}/>
    )
}

export const AsWizard = new Views.Factory<ViewPerson.Binding, Model.Authority.Tags.ValueType>(['persons', 'onboarding', 'brand'], ['person'], ((binding, factory) => new class implements Views.OfGoals.Plugin {
    key(separator: string) { return factory.asPath().key(separator) }
    route() { return factory.asPath().to(binding) }    
    controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin, options?: { ref?: React.RefObject<HTMLButtonElement> }) => JSX.Element) { return <React.Fragment></React.Fragment> }
    icon(): JSX.Element { return <NounProject.Brand/> }
    image(pixelDimensions: { width: number, height: number }, imageProps: React.CSSProperties) { return undefined; }
    label(truncate?: { maxChars?: number }): JSX.Element { return  (<span>Revise</span>) }
    summary(options: { onClick: (editMode: boolean) => void, zeroPlaceholder?: () => JSX.Element }): JSX.Element { return  (<span>About My Brand</span>) }
    fields(): Views.OfFields.Plugin[] { return [{
        render: () => 
          <Database.AsEditor peerId="ViewPerson.AsWizard" pollInterval={{ ms: 15000 }} render={zone => <_Wizard language="en_US" binding={binding}/>}/>
    }] }
}), Modes.AnyMode);
Views.All.push(AsWizard);

function _After(props: {
    language: string,
    binding: ViewPerson.Binding,
}) {
    const navigation = React.useContext(Navigation.Context);
    const { zone } = React.useContext(Database.Context);
    if(zone === undefined) throw new Error('Missing Context');

    const { hasName, hasURL, hasAvatar, hasSocialMedia } = useData({ zone, binding: props.binding });

    const errors = {
        missingName: !hasName.value,
        missingURL: !hasURL.value,
        missingAvatar: !hasAvatar.value
    };
    if(errors.missingName || errors.missingURL || errors.missingAvatar) {
        return <Interview.Waypoint buttonStyle={{ width: '100%' }} progress={{
            key: "after-brand-fail",
            status: () => <span>"About My Brand" is Incomplete</span>,
            details: () => [
                <Styler.Body style={{ display: errors.missingName ? undefined : 'none' }}>You did not state the name of the brand you represent.</Styler.Body>,
                <Styler.Body style={{ display: errors.missingURL ? undefined : 'none' }}>You did not provide a URL for your brand's website.</Styler.Body>,
                <Styler.Body style={{ display: errors.missingAvatar ? undefined : 'none' }}>You did not upload a logo image for your brand.</Styler.Body>,
                <Styler.Body>Your "About My Brand" interview is incomplete, so your account cannot be admitted to the app.</Styler.Body>,
                <Styler.Body>Please click on the "Back to My Brand" button to go back and complete any missing sections.</Styler.Body>,
            ],
            options: [
                {
                    class: Views.OfControls.Direction.Forward,
                    id: 'return-brand',
                    label: () => <span>Back to About My Brand</span>,
                    icon: ({mode}) => <HeroIcons.ArrowCircle variant='outline' direction='left'/>,
                    onClick: () => {
                        navigation.forward(AsWizard.asPath().to(props.binding))
                        return Promise.resolve();
                    }
                }
            ]
        }}/>
    } else {
        return <Interview.Waypoint buttonStyle={{ width: '100%' }} progress={{
            key: "after-brand-pass",
            status: () => <span>"About My Brand" Complete</span>,
            details: () => [
                <Styler.Body>You have completed the "About My Brand" interview. All of your answers have been saved securely to the cloud.</Styler.Body>,
                <Styler.Body>Your profile is now ready for review. Swivell admins will provide feedback or approval typically within 24 hours.</Styler.Body>,
            ],
            options: [
                {
                    class: Views.OfControls.Direction.Forward,
                    id: 'to-profile',
                    label: () => <span>To My Profile</span>,
                    icon: ({mode}) => <HeroIcons.ArrowCircle variant='outline' direction='right'/>,
                    onClick: () => {
                        navigation.forward('/profile');
                        return Promise.resolve();
                    }
                }
            ]
        }}/>        
    }
}

export const AsAfter = new Views.Factory<ViewPerson.Binding, Model.Authority.Tags.ValueType>(['persons', 'after', 'brand'], ['person'], ((binding, factory) => new class implements Views.OfGoals.Plugin {
    key(separator: string) { return factory.asPath().key(separator) }
    route() { return factory.asPath().to(binding) }    
    controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin, options?: { ref?: React.RefObject<HTMLButtonElement> }) => JSX.Element) { return <React.Fragment></React.Fragment> }
    icon(): JSX.Element { return <NounProject.Talent/> }
    image(pixelDimensions: { width: number, height: number }, imageProps: React.CSSProperties) { return undefined; }
    label(truncate?: { maxChars?: number }): JSX.Element { return  (<span>Revise</span>) }
    summary(options: { onClick: (editMode: boolean) => void, zeroPlaceholder?: () => JSX.Element }): JSX.Element { return  (<span>About My Brand</span>) }
    fields(): Views.OfFields.Plugin[] { return [{
        render: () => 
          <Database.AsEditor peerId="ViewBrandOnboarding.AsAfter" pollInterval={{ ms: 15000 }} render={zone => <_After language="en_US" binding={binding}/>}/>
    }] }
}), Modes.AnyMode);
Views.All.push(AsAfter);