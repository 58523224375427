import React from 'react';
import { BrowserRouter, Route, Routes, useLocation, useNavigate, useNavigationType, useParams, NavigationType } from 'react-router-dom';
import Div100vh from 'react-div-100vh';

import './App.css';
import * as rxjs from 'rxjs';

import CssBaseline from '@material-ui/core/CssBaseline';

import * as XLayout from '@pitaman71/react-explicit-layout';
import { Database, Debug, MaterialUi, Navigation, Views } from '@pitaman71/omniglot-live-react';
import { Dependencies, Objects } from '@pitaman71/omniglot-live-data';

import * as Pages from './Pages';
import * as Persons from './Controls/Persons';
import * as Projects from './Controls/Projects';
import * as Media from './Controls/Media';
import * as Logos from './Logos';
import * as Notifications from './Notifications';
import * as Themes from './Themes';
import * as Whoami from './Whoami';

import config from './config.json';

const baseURI = process.env.SWIVELL_SERVICES || ( process.env.NODE_ENV !== 'production' ? 'http://localhost:8080/dev' : config.services['app.swivell'].backend.baseURI );

const __moduleName__ = 'omniglot-live-react.App';

Dependencies.makeSubject = (() => new rxjs.Subject());

function _PersonsHasAvatarView() {
  const params = useParams();
  const binding = { 
    person: Objects.Binding.from_bound(params.person?.toString() || "")
  };
  return <Database.AsEditor peerId="_PersonsHasAvatarView" pollInterval={{ ms: 15000 }} render={(zone) => <Pages.AsCover view={new Persons.ViewHasAvatar.View(zone, binding)}/>}/>
}

function _PersonsHasAvatarUpload() {
  const params = useParams();
  const binding = { 
    person: Objects.Binding.from_bound(params.person?.toString() || "")
  };
  return <Database.AsEditor peerId="_PersonsHasAvatarUpload" pollInterval={{ ms: 15000 }} render={(zone) => <Pages.AsCover view={new Persons.ViewHasAvatar.Upload(zone, binding)}/>}/>
}

function _ProjectsHasAvatarView() {
  const params = useParams();
  const binding = { 
    project: Objects.Binding.from_bound(params.project?.toString() || "")
  };
  return <Database.AsEditor peerId="_ProjectsHasAvatarView" pollInterval={{ ms: 15000 }} render={(zone) => <Pages.AsCover view={new Projects.ViewHasAvatar.View(zone, binding)}/>}/>
}

function _ProjectsHasAvatarUpload() {
  const params = useParams();
  const binding = { 
    project: Objects.Binding.from_bound(params.project?.toString() || "")
  };
  return <Database.AsEditor peerId="_ProjectsHasAvatarUpload" pollInterval={{ ms: 15000 }} render={(zone) => <Pages.AsCover view={new Projects.ViewHasAvatar.Upload(zone, binding)}/>}/>
}

function _ViewOfObject(props: { 
  factory: Views.Factory<any, any>
}) {
  const params=useParams();
  const binding = props.factory.asParameters().from(params);
  const view = props.factory.make(binding);
  if(view.route().startsWith('/persons/gallery/item') || view.route().startsWith('/projects/mood/item')) {
    return <Pages.AsCover view={view}/>
  }
  return <Pages.AsRegular view={view}/>
}

/** _AutomaticRoutes
 * This React FC is responsible for registering all of the
 * page routes in the application.
 * 
 * It must be used inside of react-router-dom.BrowserRouter
 * 
 * This React FC pulls every instance of Views.Factory and for 
 * each one, adds a <Route>.
 * 
 * Most page roues in this application are automatically generated,
 * with only a few added explicitly in manual code. Manual routes
 * are deprecated, and eventually each of them should be
 * refactored as a Views.Factory.
 * 
 * After the automatic routes have been added, the manual routes
 * are added.
 */
function _AutomaticRoutes(props: {
}) {
    const location = useLocation();
    const whoami = React.useContext(Whoami.Context);
    return (
      <Routes>
        {
          Views.All.filter(factory => factory.permitted(location.pathname, whoami.hasTag)).map((factory, index) => (
            <Route path={factory.asPath().pattern} key={`routes-${index}`} element={ <_ViewOfObject factory={factory}/> }/>
          ))
        }
        <Route path="/persons/hasAvatar/view/:person" element={<_PersonsHasAvatarView/>}/>
        <Route path="/persons/hasAvatar/upload/:person" element={<_PersonsHasAvatarUpload/>}/>
        <Route path="/projects/hasAvatar/view/:project" element={<_ProjectsHasAvatarView/>}/>
        <Route path="/projects/hasAvatar/upload/:project" element={<_ProjectsHasAvatarUpload/>}/>
      </Routes>
    );
}

function _App() {
  // These hooks can only be called inside of <BrowserRouter>
  const location = useLocation();
  const navigationType = useNavigationType();

  // Navigation.Provider needs to be notified when the app's route changes due to an
  // external event (such as when the user opens the app) rather than due to internal app logic
  const [ observer, setObserver ] = React.useState<undefined|rxjs.Observer<string>>(undefined);
  React.useEffect(() => {    
    if(observer && navigationType !== NavigationType.Push) {
      observer.next(location.pathname);
    }
  }, [observer, location, navigationType ]);  
  // Navigation.Provider - Setup a React state + context that manages the navigation state of the app
  return (
    <Navigation.Provider useNavigate={useNavigate as any} useLocation={useLocation} watchNavigate={setObserver}>
      <div className="App">
        <_AutomaticRoutes/>
      </div>
    </Navigation.Provider>
  );
}

function App() {
  const asyncStorage = {
    getItem: (key: string): Promise<string|null> => {
      return Promise.resolve(localStorage.getItem(key));
    }, setItem: (key: string, value: string): Promise<any> => {
      localStorage.setItem(key, value);
      return Promise.resolve();
    }, removeItem: (key:string): Promise<any> => {
      localStorage.removeItem(key);
      return Promise.resolve();
    }
  };
  return (
    <Debug.Provide log={(text, id) => <div id={id} style={{ display:"none"}}>{text}</div>}>
      <Media.Provide>
        { /* ensure root DOM element expands to cover the entire screen */ }
        <Div100vh>
        {/* add a React Context provider for the Material UI theme */}
        <MaterialUi.Styler.Screen prefix="talent-marketplace-app" theme={Themes.Dark}>
          {/* include a debug trace in the DOM tree that can be seen in the Chrome Devtools Elements tab */}
          <Debug.Boundary name={`${__moduleName__}`}>
            {/* Setup a React Context that stores and manages the authorization status and session lifetime */}
            <MaterialUi.ViewAuthentication.Session config={config} storage={asyncStorage} masquerade={process.env.NODE_ENV !== 'development' ? undefined : {
              common: {
                name: process.env.SWIVELL_TEST_NAME || 'Test User',
                email: process.env.SWIVELL_TEST_USER || 'test-user@swivell.app'
              }
            }}>
              {/* Material UI CSS defaults */}
              <CssBaseline/>
                {/* On first page fetch, regardless of route, show the React Logo splash screen */}
                <MaterialUi.ViewLogo.AsSplash logo={<Logos.Square type="Dark" pixelSize={300}/>} duration={{ attackMs: 300, sustainMs: 1500, decayMs: 300 }}>
                  {/* All pages below this level in the DOM require authentication to access ... signal to ViewAuthentication to ask that the user login before proceeding */}
                  <MaterialUi.ViewAuthentication.Protect config={config} label={{
                    reason: () => (
                      <XLayout.Stack.South>
                        <XLayout.Center.Horizontal style={{ marginBottom: "4em" }}>
                          <Logos.Square type="Dark" pixelSize={280} />
                        </XLayout.Center.Horizontal>
                        <MaterialUi.Styler.Heading align='center' paragraph={true}>
                          Welcome to Swivell
                        </MaterialUi.Styler.Heading>
                        <MaterialUi.Styler.Body align='center' paragraph={true}>
                          Sign in or create your account
                        </MaterialUi.Styler.Body>
                      </XLayout.Stack.South>
                    ), login: () => <span>Login</span>, logout: () => <span>Logout</span>, cancel: () => <span>Cancel</span> }}>
                    {/* Setup a React Context that provides a handle to access the Omniglot persistent store */}
                    <Database.Provide persistServiceURI={baseURI+'/persist'}>
                      <BrowserRouter>
                        {/* After ViewAuthentication has authenticated the user, lookup their account info in the Omniglot persistent store */}
                        <Whoami.Provide>
                          <Notifications.Provide>
                            <_App/>
                          </Notifications.Provide>
                        </Whoami.Provide>
                      </BrowserRouter>
                    </Database.Provide>
                  </MaterialUi.ViewAuthentication.Protect>
                </MaterialUi.ViewLogo.AsSplash>
            </MaterialUi.ViewAuthentication.Session>
          </Debug.Boundary>
        </MaterialUi.Styler.Screen>
        </Div100vh>
      </Media.Provide>
    </Debug.Provide>
  );
}

export default App;
