import faker from 'faker';
import React from 'react';

import { Button, Chip, Typography } from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';

import { VerticalTimeline }  from 'react-vertical-timeline-component';

import * as XLayout from '@pitaman71/react-explicit-layout';
import { Objects, Stores } from '@pitaman71/omniglot-live-data';
import { Database, MaterialUi, Navigation, Propose, Views } from '@pitaman71/omniglot-live-react';

import * as Model from 'models/autosrc/swivell/talent_marketplace';

import { ViewHasDetails } from '../../Controls/Events';
import { AsModifiers } from '../../Controls/ViewRelation';

import * as NounProject from '../../NounProject';

export let pollInterval = { ms: 30000 };

const classes = {
    content: {
      display: 'flex',
      justifyContent: 'center',
      '& > *': {
        margin: "0.5em",
      },
    },
    root: {
      width: 500,
      '& > * + *': {
        marginTop: "3em",
      },
    },
    chip: {
      margin: "0.25em 0.5em 0.25em 0.5em",
    }
};
  
export function AsChips(props: {
  zone: Stores.Zone,
  binding: { project: Objects.Binding<string> },
  zeroPlaceholder?: () => JSX.Element
}): JSX.Element { 
  const navigation = React.useContext(Navigation.Context);
  const hasEvents = props.zone.streams().relation(Model.Projects.HasEvents.Descriptor.bindAnchor(props.binding));
  const onClick = () => {
    navigation.forward(BrowseEvents.asPath().to(props.binding))
  }
  return (
    <Propose.RelationEntries
        stream={hasEvents}
        render={
          (entries, client) => {
            const editMode = !!client;
            if(!!props.zeroPlaceholder && entries.length == 0) return <div onClick={onClick}><Chip color="primary" style={classes.chip} icon={<NounProject.Talent/>} label={props.zeroPlaceholder()}/></div>;
            return (
              <div onClick={onClick}>{entries.map(entry => <ViewHasDetails.AsChip zone={props.zone} binding={{ event: entry.event }} />)}</div>
            );
          }
        }
    />
  );
}

export function AsTimeline(props: {
  zone: Stores.Zone,
  binding: { project: Objects.Binding<string> }
}) {
  const navigation = React.useContext(Navigation.Context);
  const stream = props.zone.streams().relation(Model.Projects.HasEvents.Descriptor.bindAnchor({ project: props.binding.project }));
  return (
    <div style={{ height: "100%" }}>
      <Propose.RelationEntries stream={stream} render={
        (entries, client) => (
          entries.length === 0 ? (
            <XLayout.Center.Both>
              <MaterialUi.Styler.Heading>This project has no events defined yet</MaterialUi.Styler.Heading>
            </XLayout.Center.Both>
          ) : (
          <VerticalTimeline>
            { entries.map(entry => (
                <ViewHasDetails.AsTimelineElement 
                  zone={props.zone} 
                  binding={{ event: entry.event }}
                  on={{ 
                    delete: () => navigation.forward(`/projects/hasEvents/delete/${entry.project.objectId || "?"}/${entry.event.objectId || "?"}`),
                    edit: () => navigation.forward(`/projects/hasEvents/edit/${entry.project.objectId || "?"}/${entry.event.objectId || "?"}`)
                  }}
                />
              )) 
            }
          </VerticalTimeline>
          )
        )
      }/>    
    <XLayout.Partition top={0.85} bottom={0.95} left={0.2} right={0.8}>
      <Button variant="outlined" style={{ 
                                 backdropFilter: 'blur(8px)',
                                 WebkitBackdropFilter: 'blur(8px)',
                                 backgroundColor: 'rgba(45, 45, 45, 0.1)'
      }} startIcon={<MuiIcons.AddOutlined/>} onClick={() => navigation.forward(ModifyEvent.add.asPath().to({...props.binding, event:Objects.Binding.from_bound(faker.datatype.uuid()) }))}>Add Locations/Dates</Button>
    </XLayout.Partition>
    </div>
  );
}
 
const BrowseEvents = new Views.Factory<{ project: Objects.Binding<string>}, Model.Authority.Tags.ValueType>([ 'projects', 'hasEvents', 'browse'], ['project'], (binding, factory) => new class implements Views.OfGoals.Plugin {
  key(separator: string) { return factory.asPath().key(separator) }
  route() { return factory.asPath().to(binding) }    
  controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin, options?: { ref?: React.RefObject<HTMLButtonElement> }) => JSX.Element) { return <React.Fragment></React.Fragment> }
  icon() { return <NounProject.Availability/> }
  image(pixelDimensions: { width: number, height: number }, imageProps: React.CSSProperties) {
      return undefined
  }
  label(truncate?: { maxChars?: number }) {
      return <span>Locations and Dates</span>
  }
  summary(options: { onClick: (editMode:boolean) => void, zeroPlaceholder?: () => JSX.Element }) {
      return <div onClick={() => options.onClick(false)}>{this.label()}</div>
  }
  fields() {
    return [ { 
      render: () =>  (
        <Database.AsViewer render={(zone) => <AsTimeline zone={zone} binding={binding}/>}/>        
      )
    } ];
  }
});

const AsInspector = (zone:Stores.Zone, binding: Model.Projects.HasEvents.BindingType, editMode: boolean, controls: Views.OfControls.Plugin[]) => {
  return ( <React.Fragment>
    <ViewHasDetails.AsForm zone={zone} binding={ binding }  editMode={editMode}/>
    <Views.OfControls.AsRow controls={controls} render={controlProps => <Views.OfControls.AsButton {...controlProps} variant="outlined" />}/>
  </React.Fragment> );
};

const ModifyEvent = AsModifiers(
  [ 'projects', 'hasEvents'], Model.Projects.HasEvents.Descriptor, 
  AsInspector
);

Views.All.push(BrowseEvents, ModifyEvent.add, ModifyEvent.edit, ModifyEvent.remove);
