import faker from 'faker';
import { Dialogues, Definitions, Objects, Properties, Relations, Values } from '@pitaman71/omniglot-live-data';
import { Domains, Modules, WrapFaker } from '@pitaman71/omniglot-live-domains';
import * as Elevated from '@pitaman71/omniglot-introspect';

export const directory = new Definitions.Directory();
// aggregate Events.TimeAndLocation

export type Value = {when: Elevated.getValueType<typeof Domains.Logistics.WhenDomain>,where: Elevated.getValueType<typeof Domains.Logistics.WhereDomain>,radius: Elevated.getValueType<typeof Domains.Logistics.RadiusDomain>};
        

export const Domain = new Elevated.Aggregate<Value>({when: Domains.Logistics.WhenDomain,where: Domains.Logistics.WhereDomain,radius: Domains.Logistics.RadiusDomain});
        
