import React from 'react';

import * as MuiIcons from '@material-ui/icons';

import { Objects, Relations, Stores } from '@pitaman71/omniglot-live-data';
import { Database, Navigation, Propose, Views } from '@pitaman71/omniglot-live-react';

import * as NounProject from '../NounProject';

import * as Model from 'models/autosrc/swivell/talent_marketplace';

export let pollInterval = { ms: 10000 };

export function AsModifiers<BindingType extends Objects.BindingType>(
    prefix: string[], 
    descriptor: Relations.Descriptor<BindingType>,
inspector: (zone: Stores.Zone, binding: BindingType, editMode: boolean, controls: Views.OfControls.Plugin[]) => JSX.Element,
) { 
    const parameterNames = descriptor.getSymbols({ from: true, to: true });
    const add = new Views.Factory<BindingType, Model.Authority.Tags.ValueType>([ ...prefix, 'add' ], parameterNames, (binding, factory) => new class implements Views.OfGoals.Plugin {
        key(separator: string) { return factory.asPath().key(separator) }
        route() { return factory.asPath().to(binding) }    
        controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin, options?: { ref?: React.RefObject<HTMLButtonElement> }) => JSX.Element) { return <React.Fragment></React.Fragment> }
        icon() { return <NounProject.Availability/> }
        image(pixelDimensions: { width: number, height: number }, imageProps: React.CSSProperties) {
            return undefined
        }
        label(truncate?: { maxChars?: number }) {
            return <span>Add Availablility</span>
        }
        summary(options: { onClick: (editMode:boolean) => void, zeroPlaceholder?: () => JSX.Element }) {
            return <div onClick={() => options.onClick(false)}>{this.label()}</div>
        }
        fields() { return [ { 
            render: (controller: Navigation.Controller) =>  (
                <Database.AsEditor peerId="ViewRelation.AsModifiers.add" pollInterval={pollInterval} render={zone => {
                    const stream = zone.streams().relation(descriptor.bindAnchor(binding));
                    return <Propose.RelationEntries stream={stream} render={
                        (entries, client) => (            
                            inspector(zone, binding, true, [{
                                class: Views.OfControls.Direction.Backward,
                                id: 'cancel',
                                label: () => 'Cancel',
                                icon: () => <MuiIcons.CancelOutlined/>,
                                onClick: () => {
                                    controller.backward && controller.backward();
                                    return Promise.resolve();
                                }
                            }, {
                                class: Views.OfControls.Direction.Forward,
                                id: 'add',
                                label: () => 'Add',
                                icon: () => <MuiIcons.CheckOutlined/>,
                                onClick: () => {
                                    client?.insert(binding);
                                    return zone.commitAll().then(() => {
                                        controller.backward && controller.backward();
                                    });
                                }
                            }])
                        )
                    }/>;
                }}/>
            )
        }] }
    });
    const edit = new Views.Factory<BindingType, Model.Authority.Tags.ValueType>([ ...prefix, 'edit' ], parameterNames, (binding, factory) => new class implements Views.OfGoals.Plugin {
        key(separator: string) { return factory.asPath().key(separator) }
        route() { return factory.asPath().to(binding) }    
        controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin, options?: { ref?: React.RefObject<HTMLButtonElement> }) => JSX.Element) { return <React.Fragment></React.Fragment> }
        icon() { return <NounProject.Availability/> }
        image(pixelDimensions: { width: number, height: number }, imageProps: React.CSSProperties) {
            return undefined
        }
        label(truncate?: { maxChars?: number }) {
            return <span>Edit</span>
        }
        summary(options: { onClick: (editMode:boolean) => void, zeroPlaceholder?: () => JSX.Element }) {
            return <div onClick={() => options.onClick(false)}>{this.label()}</div>
        }
        fields() {
          return [ { 
            render: (controller: Navigation.Controller) =>  (
                <Database.AsEditor peerId="ViewRelation.AsModifiers.edit" pollInterval={pollInterval} render={zone => inspector(zone, binding, true, [
                    {
                        class: Views.OfControls.Direction.Backward,
                        id: 'cancel',
                        label: () => 'Cancel',
                        icon: () => <MuiIcons.CancelOutlined/>,
                        onClick: () => {
                            controller.backward && controller.backward();
                            return Promise.resolve();
                        }
                    }, {
                        class: Views.OfControls.Direction.Forward,
                        id: 'save',
                        label: () => 'Save',
                        icon: () => <MuiIcons.CheckOutlined/>,
                        onClick: () => {
                            return zone.commitAll().then(() => {
                                controller.backward && controller.backward();
                            });
                        }
                    }
                ])}/>
            )
        }] };
    });
    const remove = new Views.Factory<BindingType, Model.Authority.Tags.ValueType>([ ...prefix, 'remove' ], parameterNames, (binding, factory) => new class implements Views.OfGoals.Plugin {
        key(separator: string) { return factory.asPath().key(separator) }
        route() { return factory.asPath().to(binding) }    
        controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin, options?: { ref?: React.RefObject<HTMLButtonElement> }) => JSX.Element) { return <React.Fragment></React.Fragment> }
        icon() { return <NounProject.Availability/> }
        image(pixelDimensions: { width: number, height: number }, imageProps: React.CSSProperties) {
            return undefined
        }
        label(truncate?: { maxChars?: number }) {
            return <span>Add Availablility</span>
        }
        summary(options: { onClick: (editMode:boolean) => void, zeroPlaceholder?: () => JSX.Element }) {
            return <div onClick={() => options.onClick(false)}>{this.label()}</div>
        }
        fields() { return [ { 
            render: (controller: Navigation.Controller) =>  (
                <Database.AsEditor peerId="ViewRelation.AsModifiers.remove" pollInterval={pollInterval} render={zone => {
                    const stream = zone.streams().relation(descriptor.bindAnchor(binding));
                    return <Propose.RelationEntries stream={stream} render={
                        (entries, client) => (            
                            inspector(zone, binding, false, [{
                                class: Views.OfControls.Direction.Backward,
                                id: 'cancel',
                                label: () => 'Cancel',
                                icon: () => <MuiIcons.CancelOutlined/>,
                                onClick: () => {
                                    controller.backward && controller.backward();
                                    return Promise.resolve();
                                }
                            }, {
                                class: Views.OfControls.Direction.Forward,
                                id: 'delete',
                                label: () => 'Delete',
                                icon: () => <MuiIcons.DeleteOutlined/>,
                                onClick: () => {
                                    client?.assign(entries.filter(entry_ => !Objects.cmp_objects(entry_, binding)));
                                    return zone.commitAll().then(() => {
                                        controller.backward && controller.backward();
                                    });
                                }
                            }])
                        )
                    }/>;
                }}/>
            )
        }] }
    });

    return { add, edit, remove };
}
