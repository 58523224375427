import React from 'react';

import { Dot } from 'react-animated-dots';

import * as Model from 'models/autosrc/swivell/talent_marketplace';
import * as Whoami from '../../Whoami';

import * as XLayout from '@pitaman71/react-explicit-layout';
import { Stores } from '@pitaman71/omniglot-live-data';
import { Database, Interview, Navigation, Propose, Styler, Views } from '@pitaman71/omniglot-live-react';

import * as HeroIcons from '../../HeroIcons';

import * as Payments from '../../Payments';

import { ViewHasRoles, ViewProfile, ViewProject } from '.'
import { Controller as ProjectController } from 'models/src/Projects';
import { Access } from 'models/src/Projects';

export function _Check(props: {
    zone: Stores.Zone;
    binding: ViewProject.Binding
}) {
    const whoami = React.useContext(Whoami.Context);
    const navigation = React.useContext(Navigation.Context);
    if(!navigation || !whoami.person) throw new Error('Missing Context');
    const me = whoami.person;

    const hasStatus = Propose.useScalarProperty(Model.Projects.HasStatus.Descriptor.stream(props.zone, props.binding).scalar);
    if(hasStatus.status.initializing) {
        return <XLayout.Center.Both>
            <XLayout.Stack.South>
                <Styler.Heading>Checking Project Status<Dot>.</Dot><Dot>.</Dot><Dot>.</Dot></Styler.Heading>
            </XLayout.Stack.South>
        </XLayout.Center.Both>
    }
    const options: Views.OfControls.Plugin[] = [];
    const details: JSX.Element[] = [];
    if(hasStatus.value === 'Draft') {
        options.push({
            class: Views.OfControls.Direction.Forward,
            id: 'project-activate',
            label: () => <span>Activate this Project</span>,
            icon: ({mode}) => <HeroIcons.ArrowCircle variant='outline' direction='right'/>,
            onClick: () => {
                ProjectController.activateProject(props.zone, { me, project: props.binding.project }).exec();
                return props.zone.commitAll()
                .then(() => navigation.forward(ViewProfile.AsProfile.asPath().to({ project: props.binding.project })));
            }
        });
        details.push(<Styler.Body>In order for others to see your project, you must activate it.</Styler.Body>);
    } else {
        options.push({
            class: Views.OfControls.Direction.Forward,
            id: 'project-deactivate',
            label: () => <span>Deactivate this Project</span>,
            icon: ({mode}) => <HeroIcons.ArrowCircle variant='outline' direction='right'/>,
            onClick: () => {
                ProjectController.deactivateProject(props.zone, { me, project: props.binding.project }).exec();
                return props.zone.commitAll()
                .then(() => navigation.forward(ViewProfile.AsProfile.asPath().to({ project: props.binding.project })));
            }
    });
        details.push(
            <Styler.Body>You may put this project back into Draft state by deactivating it.</Styler.Body>,
            <Styler.Body>In Draft state, no one else can see your project.</Styler.Body>,
        )
    }
    options.push({
        class: Views.OfControls.Direction.Lateral,
        id: 'to-team',
        label: () => <span>To Team Diagram</span>,
        icon: ({mode}) => <HeroIcons.ArrowCircle variant='outline' direction='right'/>,
        onClick: () => {
            navigation.forward(ViewHasRoles.AsTeamDiagram.asPath().to({ project: props.binding.project }));
            return Promise.resolve();
        }
    },{
        class: Views.OfControls.Direction.Lateral,
        id: 'to-profile',
        label: () => <span>To Project Profile</span>,
        icon: ({mode}) => <HeroIcons.ArrowCircle variant='outline' direction='right'/>,
        onClick: () => {
            navigation.forward(ViewProfile.AsProfile.asPath().to({ project: props.binding.project }));
            return Promise.resolve();
        }
    });
    return <Interview.Waypoint buttonStyle={{ width: '100%' }} progress={{
        key: "project-status-check",
        status: () => <span>Manage Project Status</span>,
        details: () => [
            <Styler.Body>This project is currently in {hasStatus.value} status.</Styler.Body>
        ],
        options
    }}/>        
}

export const AsCheck = new Views.Factory<ViewProject.Binding, Model.Authority.Tags.ValueType>(['projects', 'check' ], ['project'], ((binding, factory) => new class implements Views.OfGoals.Plugin {
    key(separator: string) { return factory.asPath().key(separator) }
    route() { return factory.asPath().to(binding) }    
    controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin, options?: { ref?: React.RefObject<HTMLButtonElement> }) => JSX.Element) { return <React.Fragment></React.Fragment> }
    icon(props: { mode: Views.OfControls.Modes }): JSX.Element { return <HeroIcons.Power variant={ props.mode === Views.OfControls.Modes.Selected ? "solid" : "outline" }/> }
    image(pixelDimensions: { width: number, height: number }, imageProps: React.CSSProperties) {
        return undefined;
    }
    label(truncate?: { maxChars?: number }) {
        return <span>Status</span>
    }
    summary(options: { onClick: (editMode: boolean) => void, zeroPlaceholder?: () => JSX.Element }): JSX.Element { return  (<span>Manage Project Status</span>) }
    fields() {
        return [ {
            render: () => {
                return <Database.AsEditor peerId="ViewProject.AsCheckStatus" pollInterval={{ ms: 15000 }} render={(zone ) => <_Check zone={zone} binding={binding}/> }/>;
            }
        }];
    }
} ));
Views.All.push(AsCheck);

