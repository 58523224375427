import faker from 'faker';
import { Dialogues, Definitions, Objects, Properties, Relations, Values } from '@pitaman71/omniglot-live-data';
import { Domains, Modules, WrapFaker } from '@pitaman71/omniglot-live-domains';
import * as Elevated from '@pitaman71/omniglot-introspect';

export const directory = new Definitions.Directory();
// tag Talents.Tags

export type ValueType = string|'Brand Management'|'Project Management'|'Recruiting'|'Talent Representation'|'Photography'|'Creative Direction'|'Influencer'|'Set Design'|'Graphic Design'|'Makeup'|'Hairstyling'|'Producer'|'Modeling'|'Videography'|'Wardrobe Styling'|'Content Creation'|'Art'|'Editing'|'Production Assistant'|'Marketing Management'|'Marketing Coordination'|'Marketing Direction'|'Copy Writing'|'Art Direction'|'Campaign Management'|'Event Planning'|'Music';
export const Domain = new Values.TagDomain('Brand Management','Project Management','Recruiting','Talent Representation','Photography','Creative Direction','Influencer','Set Design','Graphic Design','Makeup','Hairstyling','Producer','Modeling','Videography','Wardrobe Styling','Content Creation','Art','Editing','Production Assistant','Marketing Management','Marketing Coordination','Marketing Direction','Copy Writing','Art Direction','Campaign Management','Event Planning','Music');
        
