import faker from 'faker';
import { Dialogues, Definitions, Objects, Properties, Relations, Values } from '@pitaman71/omniglot-live-data';
import { Domains, Modules, WrapFaker } from '@pitaman71/omniglot-live-domains';
import * as Elevated from '@pitaman71/omniglot-introspect';

export const directory = new Definitions.Directory();
// property Locations.HasGeoPoint

const Domain: Elevated.Domain<{  name: string, googlePlaceId?: string, geo?: { point: { asString?: { lat: string, lng: string }, asNumber?: { lat: number, lng: number } } } }> = WrapFaker.OfJSON('Locations.HasGeoPoint', () => ({ name: faker.address.city() })).domain();
export type TypeParams = {
    Binding: { location: Objects.Binding<string> },
    Value: {  name: string, googlePlaceId?: string, geo?: { point: { asString?: { lat: string, lng: string }, asNumber?: { lat: number, lng: number } } } },
    Domain: typeof Domain
}
export const Descriptor = new class _Descriptor extends Properties.Descriptor<TypeParams> {
    canonicalName = 'generated.Locations.HasGeoPoint';
    build(builder: Properties.Builder<TypeParams>): void {
        builder.object('location');
        builder.measure(WrapFaker.OfJSON('Locations.HasGeoPoint', () => ({ name: faker.address.city() })).domain());
        builder.scalar();
    }
};

directory.descriptors.properties.set(Descriptor.canonicalName, Descriptor);
        
