import React from 'react';

import { useLocation, useNavigate } from 'react-router';
import faker from 'faker';
import { Dot } from 'react-animated-dots';

import { Button, Typography } from '@material-ui/core';

import { Authentication, Objects, Stores } from '@pitaman71/omniglot-live-data';
import { Database, Interview, MaterialUi, Propose, Styler, Views } from '@pitaman71/omniglot-live-react';
import * as XLayout from '@pitaman71/react-explicit-layout';

import { ViewPerson } from './Controls/Persons';
import { Controller as PersonController } from 'models/src/Persons';
import * as HeroIcons from './HeroIcons';
import * as Modes from './Modes';
import * as Themes from './Themes';

import * as Model from 'models/autosrc/swivell/talent_marketplace';

export const Context = React.createContext<{ 
    person?: Objects.Binding<string>, 
    mode?: 'lobby'|'normal'|'control',
    hasTag: (tag:Model.Authority.Tags.ValueType) => boolean 
}>({ hasTag: () => false });

function _Ready(props: {
    email: string,
    anchor: {
        email: Objects.Binding<any>;
    },
    zone: Stores.Zone,
    proposeZone: Stores.Zone,
    setWhoami: React.Dispatch<React.SetStateAction<Objects.Binding<string>|undefined>>,
    verifying: JSX.Element,
    children: JSX.Element[]|JSX.Element
}) {
    const location = useLocation();
    const navigate = useNavigate();
    const authContext = React.useContext(MaterialUi.ViewAuthentication.AuthContext);
    const byEmail = Propose.useRelation(Model.Persons.ByEmail.Descriptor.stream(props.zone, props.anchor))

    const makeNewAccount = (proposeZone: Stores.Zone, anchor: any, credentials: Partial<Authentication.Credentials> ) => {
        const person = Objects.Binding.from_bound(faker.datatype.uuid());
        if(credentials.common?.name) {
            proposeZone.streams().property(Model.Persons.HasName.Descriptor.bind({person})).scalar?.stateful()?.assign(credentials.common.name);
        }
        if(credentials.common?.email) {
            const email = Objects.Binding.from_bound(credentials.common?.email);
            proposeZone.streams().property(Model.Persons.HasEmail.Descriptor.bind({person})).set?.stateful()?.assign(new Set([ credentials.common.email ]));
            proposeZone.streams().relation(Model.Persons.ByEmail.Descriptor.bindAnchor({email})).stateful()?.assign([{person, email }]);
        }
        PersonController.Create(proposeZone, { me: person, subject: person }).reset();
        return proposeZone.commitAll().then(() => { 
            navigate(ViewPerson.AsSignup.asPath().to({ person })); 
        });
    }

    const person = byEmail.entries.length > 0 ? byEmail.entries[0].person : undefined;
    return !authContext || authContext.mode() === 'check' || byEmail.status.initializing === true
        ? <XLayout.Center.Both>
            <Styler.Heading>Checking credentials with the server <Dot>.</Dot><Dot>.</Dot><Dot>.</Dot></Styler.Heading>
        </XLayout.Center.Both>
        : !person ? <Context.Provider value={{ hasTag: () => false}}>
            <XLayout.Center.Both>
                <Interview.Waypoint buttonStyle={{ width: '100%' }} progress={{
                    key: "create-new-account",
                    status: () => <span>Hello,</span>,
                    details: () => [
                        <Styler.Body>It looks like you're new here.</Styler.Body>,
                        <Styler.Body>To get the most out of Swivell, I'll ask you a few simple questions.</Styler.Body>,
                    ],
                    options: [
                        {
                            class: Views.OfControls.Direction.Forward,
                            id: 'create-account',
                            label: () => <span>Get started</span>,
                            icon: ({mode}) => <HeroIcons.ArrowCircle variant='outline' direction='right'/>,
                            onClick: () => 
                                makeNewAccount(props.proposeZone, props.anchor, authContext.credentials())                    
                        }
                    ]
                }}/>                                
            </XLayout.Center.Both>
        </Context.Provider> 
    : <Propose.PropertyData
            stream={props.zone.streams().property(Model.Persons.HasAuthority.Descriptor.bind({ person }))}
            render={{
                set: (values, client) =>                       
                    {
                        const hasTag = (tag: Model.Authority.Tags.ValueType) => values.has(tag);
                        const mode = Modes.LobbyMode.permitted(location.pathname, hasTag) ? 'lobby'
                        : Modes.NormalMode.permitted(location.pathname, hasTag) ? 'normal' : undefined;
                        return <Context.Provider value={{ mode, person, hasTag }}>
                            {props.children}
                        </Context.Provider>
                    }
            }}
        />
    ;
}

function _Provide(props: {
    zone: Stores.Zone,
    children: JSX.Element[]|JSX.Element
}) {
    const authContext = React.useContext(MaterialUi.ViewAuthentication.AuthContext);
    const [ whoami, setWhoami ] = React.useState<Objects.Binding<string>|undefined>();
    const verifying = <XLayout.Center.Both><p><MaterialUi.Styler.Heading>Verifying your Swivell account <Dot>.</Dot><Dot>.</Dot><Dot>.</Dot></MaterialUi.Styler.Heading></p></XLayout.Center.Both>;
    
    if(!authContext) {
      return verifying;
    } else {
      const email = authContext.credentials().common?.email;
      if(email) {
            const anchor = { email: Objects.Binding.from_bound(email)};
            const byEmail = Model.Persons.ByEmail.Descriptor.bindAnchor(anchor);
            return (
                Propose.withProposal('Whoami._Provide', props.zone, { ms: 60000}, (proposeZone) => (
                    <_Ready {...props} email={email} setWhoami={setWhoami} anchor={anchor} proposeZone={proposeZone} verifying={verifying}/>
                ))
            );
      }
      return <Context.Provider value={{ person: whoami, hasTag: () => false }}>
            <MaterialUi.Styler.Card prefix="whoami-login-waiting" theme={Themes.Light}>
                <MaterialUi.Styler.Body paragraph={true}>
                    Waiting for Login
                </MaterialUi.Styler.Body>
            </MaterialUi.Styler.Card>
        </Context.Provider>
    }
  }

export function Provide(props: {
    children: JSX.Element[]|JSX.Element
}) {
    const { zone } = React.useContext(Database.Context);

    if(zone) return <_Provide zone={zone}>{props.children}</_Provide>
    return <React.Fragment>{props.children}</React.Fragment>
}

export function IsAdmin(props: {
    children: JSX.Element|JSX.Element[]
}) {
    const context = React.useContext(Context);
    return <React.Fragment>{!context?.hasTag('admin') ? [] : props.children }</React.Fragment> 
}
