import faker from 'faker';
import { Dialogues, Definitions, Objects, Properties, Relations, Values } from '@pitaman71/omniglot-live-data';
import { Domains, Modules, WrapFaker } from '@pitaman71/omniglot-live-domains';
import * as Elevated from '@pitaman71/omniglot-introspect';

export const directory = new Definitions.Directory();
// tag Products.Categories.Tags

export type ValueType = string|'adventure'|'apparel'|'appliances'|'athletic'|'beauty'|'business'|'casual'|'childrens'|'cosplay'|'footwear'|'formal'|'gear'|'gym'|'health'|'housewares'|'lingerie'|'lgbtq'|'makeup'|'mens'|'outdoor'|'snow'|'swim'|'teens'|'tools'|'toys'|'tweens'|'underwear'|'womens';
export const Domain = new Values.TagDomain('adventure','apparel','appliances','athletic','beauty','business','casual','childrens','cosplay','footwear','formal','gear','gym','health','housewares','lingerie','lgbtq','makeup','mens','outdoor','snow','swim','teens','tools','toys','tweens','underwear','womens');
        
