import React from 'react';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import * as NounProject from '../../NounProject';

import * as XLayout from '@pitaman71/react-explicit-layout';
import { Objects } from '@pitaman71/omniglot-live-data';
import { Database, MaterialUi, Propose, Styler, Views } from '@pitaman71/omniglot-live-react';
import * as ViewPerson from './ViewPerson';
import * as ViewProject from '../Projects/ViewProject';

import * as Whoami from '../../Whoami';

import * as Model from 'models/autosrc/swivell/talent_marketplace';

import * as SocialAccess from 'models/src/Social/Access';
import * as SocialController from 'models/src/Social/Controller';

function _Add(props: {
    binding: { 
        from: Objects.Binding<string>, 
        person?: Objects.Binding<string>,
        project?: Objects.Binding<string>
    }
}) {
    const { zone } = React.useContext(Database.Context);
    const whoami = React.useContext(Whoami.Context);
    if(!zone || !whoami.person) throw new Error('Missing Context');
    const me = whoami.person;
    const recommend = SocialController.Add(zone, { me, ...props.binding });
    return (
        <XLayout.Stack.East>
            <Styler.Button onClick={() => {
                recommend.reset();
                zone.commitAll();
            }}>Recommend</Styler.Button>
        </XLayout.Stack.East>
    )
}

function _Remove(props: {
    binding: { 
        from: Objects.Binding<string>, 
        person?: Objects.Binding<string>,
        project?: Objects.Binding<string>
    }
}) {
    const { zone } = React.useContext(Database.Context);
    const whoami = React.useContext(Whoami.Context);
    if(!zone || !whoami.person) throw new Error('Missing Context');
    const me = whoami.person;
    const recommend = SocialController.Remove(zone, { me, ...props.binding });
    return (
        <XLayout.Stack.East>
            <Styler.Button onClick={() => {
                recommend.reset();
                zone.commitAll();
            }}>Remove</Styler.Button>
        </XLayout.Stack.East>
    )
}

function _AsStatus(props: {
    binding: { 
        from: Objects.Binding<string>, 
        person?: Objects.Binding<string>,
        project?: Objects.Binding<string>
    }
}) {
    const { zone } = React.useContext(Database.Context);
    const whoami = React.useContext(Whoami.Context);
    if(!zone || !whoami.person) throw new Error('Missing Context');
    const me = whoami.person;
    const hasConnectionStatus = Propose.useSetProperty(React.useMemo(() => SocialAccess.hasStatus(zone, { from: props.binding.from, to: props.binding.person || props.binding.project || Objects.Binding.from_free() }), []));
    return <React.Fragment>
        {
            hasConnectionStatus.values.has('new') ? <span>New</span>
            : hasConnectionStatus.values.has('rejected') || hasConnectionStatus.values.has('rejecting') ? <span>Rejected</span> 
            : hasConnectionStatus.values.has('invited') || hasConnectionStatus.values.has('inviting') ? <span>Invited</span> 
            : hasConnectionStatus.values.has('connected') ? <span>Connected</span> 
            : <span>Undecided</span>
        }
    </React.Fragment>
}

function isPerson(item: {
    person?: Objects.Binding<string>,
    project?: Objects.Binding<string>
}): item is { person: Objects.Binding<string> } {
    return item.person !== undefined;
}

function isProject(item: {
    person?: Objects.Binding<string>,
    project?: Objects.Binding<string>
}): item is { project: Objects.Binding<string> } {
    return item.project !== undefined;
}

export function AsTable(props: {
    language: string,
    binding: { person: Objects.Binding<string> }
}) {
    const { zone } = React.useContext(Database.Context);
    if(zone === undefined) throw new Error('Missing Context');
    const hasDiscoveryFeed = Propose.useSequenceProperty(Model.Persons.HasDiscoveryFeed.Descriptor.stream(zone, props.binding).sequence);
    const persons = hasDiscoveryFeed.values.filter(isPerson).map(item => item.person);
    const projects = hasDiscoveryFeed.values.filter(isProject).map(item => item.project);
    return (
        <XLayout.Stack.East style={{ maxHeight: '100%', maxWidth: '100%' }}>
            <Styler.Section prefix="persons-manage-connections-me" style={{ flex: '1 1 0', minWidth: '0' }}>
                <XLayout.Center.Both>
                    <XLayout.Stack.South style={{ maxWidth: '100%' }}>
                        <Styler.Heading>Manage Discovery Feed</Styler.Heading>
                        <ViewPerson.AsName zone={zone} binding={props.binding}/>
                    </XLayout.Stack.South>
                </XLayout.Center.Both>
            </Styler.Section>
            <Styler.Section prefix="persons-manage-connections-others"  style={{ flex: '4 4 0', minWidth: '0' }}>
            <XLayout.Stack.South id="connections-browser" scroll={true} style={{ height: '100%', width: "100%" }}>
                <Table style={{ tableLayout: 'fixed' }}>
                    <TableBody>
                        { 
                            hasDiscoveryFeed.values.map((item, index) => 
                                !!item.project ? <ViewProject.AsTableRow
                                    key={`feed-${index}`}
                                    zone={zone}
                                    binding={{ project: item.project }}
                                    show={{ cover: true, header: true }}
                                >
                                    <span>Recommended</span>
                                    <_Remove binding={{
                                        from: props.binding.person,
                                        project: item.project
                                    }}/>
                                </ViewProject.AsTableRow> : !!item.person ? <ViewPerson.AsTableRow
                                    key={`feed-${index}`}
                                    zone={zone}
                                    binding={{ person: item.person }}
                                    show={{ avatar: true, header: true, email: false }}
                                >
                                    <span>Recommended</span>
                                    <_Remove binding={{
                                        from: props.binding.person,
                                        person: item.person
                                    }}/>
                                </ViewPerson.AsTableRow> : <React.Fragment></React.Fragment>
                            )
                        }
                        <ViewProject.AsTableRows
                            prefix="person-manage-discovery-projects" 
                            except={projects} 
                            show={{ cover: true, header: true }}
                            extras={[
                                binding => <_AsStatus binding={{ from: props.binding.person, project: binding.project }}/>,
                                binding => <_Add binding={{ from: props.binding.person, project: binding.project }}/>
                            ]}
                        />
                        <ViewPerson.AsTableRows
                            prefix="person-manage-discovery-person" 
                            except={persons} 
                            show={{ avatar: true, header: true, email: false }}
                            extras={[
                                binding => <_AsStatus binding={{ from: props.binding.person, person: binding.person }}/>,
                                binding => <_Add binding={{ from: props.binding.person, person: binding.person }}/>
                            ]}
                        />
                    </TableBody>
                </Table>
            </XLayout.Stack.South>
            </Styler.Section>
        </XLayout.Stack.East>
    )
}
