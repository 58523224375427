import faker from 'faker';
import { Dialogues, Definitions, Objects, Properties, Relations, Values } from '@pitaman71/omniglot-live-data';
import { Domains, Modules, WrapFaker } from '@pitaman71/omniglot-live-domains';
import * as Elevated from '@pitaman71/omniglot-introspect';

export const directory = new Definitions.Directory();
// property Persons.HasDiscoveryFeed

const Domain: Elevated.Domain<{ person?: Objects.Binding<string>, project?: Objects.Binding<string>, role?: Objects.Binding<string>, post?: Objects.Binding<string>, swipe?: 'left'|'right' }> = WrapFaker.OfJSON('Persons.HasDisoveryFeed', () => ({})).domain();
export type TypeParams = {
    Binding: { person: Objects.Binding<string> },
    Value: { person?: Objects.Binding<string>, project?: Objects.Binding<string>, role?: Objects.Binding<string>, post?: Objects.Binding<string>, swipe?: 'left'|'right' },
    Domain: typeof Domain
}
export const Descriptor = new class _Descriptor extends Properties.Descriptor<TypeParams> {
    canonicalName = 'generated.Persons.HasDiscoveryFeed';
    build(builder: Properties.Builder<TypeParams>): void {
        builder.object('person');
        builder.measure(WrapFaker.OfJSON('Persons.HasDisoveryFeed', () => ({})).domain());
        builder.sequence();
    }
};

directory.descriptors.properties.set(Descriptor.canonicalName, Descriptor);
        
