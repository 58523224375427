import React from 'react';
import { kilomega } from 'kilomega';
import * as HeroIcons from '../../HeroIcons';

import { Dot } from 'react-animated-dots';

import {  makeStyles, Button, Tab, Tabs  } from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import * as NounProject from '../../NounProject';

import * as XLayout from '@pitaman71/react-explicit-layout';
import { Objects, Stores } from '@pitaman71/omniglot-live-data';
import { Database, MaterialUi, Navigation, Propose, Styler, Views } from '@pitaman71/omniglot-live-react';

import * as Model from 'models/autosrc/swivell/talent_marketplace';
import * as Inspectors from '../../Inspectors';
import * as Modes from '../../Modes';
import * as Themes from '../../Themes';
import * as Whoami from '../../Whoami';

import { ViewIncludes } from '../Content';
import { ViewSubject } from '../Discussion';

import { ViewAccount, ViewGallery, ViewOnboarding } from '.';

import { Authorize, AsActions, AsDelete, AsHeader, useAccess } from './ViewPerson';

import config from '../../config.json'

const baseURI = process.env.SWIVELL_SERVICES || ( process.env.NODE_ENV !== 'production' ? 'http://localhost:8080/dev' : config.services['app.swivell'].backend.baseURI );

export interface Binding extends Objects.BindingType<string> {
    person: Objects.Binding<string>
}
export let pollInterval = { ms: 30000 };

function _Count(props: {
    value: undefined|number
}) {
    if(props.value === undefined) return <Styler.Body><Dot>.</Dot><Dot>.</Dot><Dot>.</Dot></Styler.Body>;
    const asKM = kilomega(props.value);
    return <div>{Math.floor((asKM.amount * 10)) / 10}{asKM.symbol}</div>
}

export type ProfileTabValues = 'qualifications'|'portfolio'|'availability';

function _Actions(props: {
    binding: Binding    
}) {
    const navigation = React.useContext(Navigation.Context);
    const whoami = React.useContext(Whoami.Context);
    const me = whoami?.person;
    if(!me) throw new Error('Missing Context');
    const isMe = me?.objectId === props.binding.person.objectId;
    const access = useAccess(props.binding);

    const toDelete = navigation === undefined ? undefined : () => navigation.forward(AsDelete.asPath().to({ person: props.binding.person }));
    const toMessage = navigation === undefined ? undefined : () => navigation.forward(ViewSubject.AsHistory.asPath().to({ subject: ViewSubject.groupToSubject(me, props.binding.person)}));
    const toEditProfile = navigation === undefined ? undefined : 
        isMe ? () => navigation.forward(ViewOnboarding.AsMine.asPath().to({ person: props.binding.person }))
        : () => navigation.forward(ViewOnboarding.AsOther.asPath().to({ person: props.binding.person }));
    const toManageAccount = navigation === undefined ? undefined : 
        isMe ? () => navigation.forward(ViewAccount.AsMine.asPath().to({ person: props.binding.person }))
        : () => navigation.forward(ViewAccount.AsOther.asPath().to({ person: props.binding.person }));
    return (
        <XLayout.Stack.West>
        {
            !!me && !isMe && !!toMessage ?
            <Button variant="outlined" onClick={toMessage} style={{ height: '90%', margin: '2%', width: '6em' }}><MuiIcons.MessageOutlined/></Button>
            : <React.Fragment/>
        }
        {
            access.edit.value === true && !!toEditProfile ?
            <Button variant='outlined' onClick={toEditProfile} style={{ height: '90%', margin: '2%', width: '6em' }}><MuiIcons.EditOutlined/></Button>
            : <React.Fragment/>
        }
        {
            access.edit.value === true && !!toManageAccount ?
            <Button variant='outlined' onClick={toManageAccount} style={{ height: '90%', margin: '2%', width: '6em' }}><MuiIcons.SettingsOutlined/></Button>
            : <React.Fragment/>
        }
        {
            access.edit.value === true && !!toDelete ?
            <Button variant='outlined' onClick={toDelete} style={{ height: '90%', margin: '2%', width: '6em' }}><MuiIcons.DeleteOutlined/></Button>
            : <React.Fragment></React.Fragment>
        }
    </XLayout.Stack.West>
    )
}

function _Sections(props: {
    binding: Binding,
    zone: Stores.Zone,
    allowGalleryEdit: boolean,
    isInvitation: boolean,
    mode?: ProfileTabValues
}) {
    const navigation = React.useContext(Navigation.Context);
    const whoami = React.useContext(Whoami.Context);
    const me = whoami?.person;
    if(!me) throw new Error('Missing Context');
    const isMe = me?.objectId === props.binding.person.objectId;
    const access = useAccess(props.binding);

    const hasName = Propose.useScalarProperty(Model.Persons.HasName.Descriptor.stream(props.zone, props.binding).scalar);
    const hasAvatar = Propose.useScalarProperty(Model.Persons.HasAvatar.Descriptor.stream(props.zone, { person: props.binding.person }).scalar);
    const hasAbout = Propose.useScalarProperty(Model.Persons.HasAbout.Descriptor.stream(props.zone, { person: props.binding.person }).scalar);
    const hasHomeLocation = Propose.useScalarProperty(Model.Persons.HasHomeLocation.Descriptor.stream(props.zone, props.binding).scalar);
    const offersAudienceTargeting = Propose.useSetProperty(Model.Creator.OffersAudienceTargeting.Descriptor.stream(props.zone, { creator: props.binding.person }).set);
    const offersBlendedRate = Propose.useScalarProperty(Model.Creator.OffersBlendedRate.Descriptor.stream(props.zone, { creator: props.binding.person }).scalar);
    const hasSkills = Propose.useSetProperty(Model.Creator.HasSkills.Descriptor.stream(props.zone, { creator: props.binding.person }).set);
    const offersContentTypes = Propose.useSetProperty(Model.Creator.OffersContentTypes.Descriptor.stream(props.zone, { creator: props.binding.person }).set);
    const offersProductCategories = Propose.useSetProperty(Model.Creator.OffersProductCategories.Descriptor.stream(props.zone, { creator: props.binding.person }).set);

    const toGallery = () => navigation.forward(ViewGallery.AsGallery.asPath().to({ person: props.binding.person }));
    
    return (<Authorize access={access} mode='view'>
        <XLayout.Stack.South style={{ height: '100%'}}>
            <div style={{ flex: '0 0 auto', padding: '1em'}}>
                <AsHeader binding={props.binding} title="Profile" placeholder="Name Here"/>
                <AsActions binding={props.binding}/>
            </div>
            <XLayout.Stack.South id="person-profile" style={{ flex: '1', padding: '1em'}} scroll={true}>
                <MaterialUi.Media.ViewAsset.AsDownloader
                    baseURI={baseURI}
                    style={{ width: "100%" }} 
                    value={hasAvatar.value || {}} 
                />
                <Styler.Section prefix="persons-viewprofile-about" style={{ padding: '1em' }}>
                    <Styler.Body align='left'>
                        <p>{ hasAbout.value }</p>
                    </Styler.Body>
                </Styler.Section>
                <div style={{ width: '100%', minHeight: '60%'}}>
                    <ViewIncludes.AsGrid
                        binding={{ topic: props.binding.person }}
                        shape={{ rows: 1, columns: 2 }}
                        major='horizontal'
                        onClick={toGallery}
                    />
                </div>
                <Styler.Section prefix="persons-viewprofile-skills" style={{ display: hasSkills.values.size === 0 ? 'none' : undefined, padding: '1em' }}>
                    <Styler.Heading align="left">Skills</Styler.Heading>
                    <Styler.Body align="left">
                        <MaterialUi.ViewSet.AsChips
                            id="person-skills-chips"
                            domain={Model.Talents.Tags.Domain}
                            values={hasSkills.values} 
                            theme={Themes.Gold}
                            size="small"
                        />
                    </Styler.Body>
                    <Styler.Value align="left">
                        { offersBlendedRate.value?.text }
                    </Styler.Value>
                </Styler.Section>
                <Styler.Section prefix="persons-viewprofile-audience-targeting" style={{ display: offersProductCategories.values.size === 0 ? 'none' : undefined, padding: '1em' }}>
                    <Styler.Heading align="left">Product Categories</Styler.Heading>
                    <Styler.Body align="left">
                        <MaterialUi.ViewSet.AsChips
                            id="person-categories-input"
                            domain={Model.Products.Categories.Tags.Domain}
                            values={offersProductCategories.values} 
                            theme={Themes.Gold}
                            size="small"
                        />
                    </Styler.Body>
                </Styler.Section>
                <Styler.Section prefix="persons-viewprofile-content-types" style={{ display: offersContentTypes.values.size === 0 ? 'none' : undefined, padding: '1em' }}>
                    <Styler.Heading align="left">Content Types</Styler.Heading>
                    <Styler.Body align="left">
                        <MaterialUi.ViewSet.AsChips
                            id="person-content-input"
                            domain={Model.SocialMedia.ContentTypes.Tags.Domain}
                            values={offersContentTypes.values} 
                            theme={Themes.Gold}
                            size="small"
                        />
                    </Styler.Body>
                </Styler.Section>
                <div style={{ width: '100%', minHeight: '60%'}}>
                    <ViewIncludes.AsGrid
                        binding={{ topic: props.binding.person }}
                        shape={{ rows: 1, columns: 2 }}
                        major='horizontal'
                        onClick={toGallery}
                        skip={2}
                    />
                </div>
            </XLayout.Stack.South>
        </XLayout.Stack.South>
        </Authorize>
    );
}

function _Card(props: {
    prefix: string,
    binding: Binding,
    zone: Stores.Zone,
    allowGalleryEdit: boolean,
    isInvitation: boolean,
    mode?: ProfileTabValues
}) {
    return <div style={{height: '100%', width: '100%', padding: '1em' }}>
        <_Sections {...props}/>
    </div>;
}

export function MyProfile(props: {
    zone: Stores.Zone,
    mode?: ProfileTabValues
}) {
    const whoami = React.useContext(Whoami.Context);
    const person = whoami?.person;
    if(!person) throw new Error('Missing Context');
    return <_Card prefix='my-profile' {...props} binding={{ person }} allowGalleryEdit={true} isInvitation={false}/>;
}

export const AsProfile = new Views.Factory<Binding, Model.Authority.Tags.ValueType>(['persons', 'profile'], ['person'], ((binding, factory) => new class implements Views.OfGoals.Plugin {
    key(separator: string) { return factory.asPath().key(separator) }
    route() { return factory.asPath().to(binding) }    
    controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin, options?: { ref?: React.RefObject<HTMLButtonElement> }) => JSX.Element) { return <React.Fragment></React.Fragment> }
    icon(props: {mode: Views.OfControls.Modes }): JSX.Element { return <HeroIcons.Identification variant={ props.mode === Views.OfControls.Modes.Selected ? "solid" : "outline" }/> }
    image(pixelDimensions: { width: number, height: number }, imageProps: React.CSSProperties) { return undefined; }
    label(truncate?: { maxChars?: number }): JSX.Element { return  (<span>Profile</span>) }
    summary(options: { onClick: (editMode: boolean) => void, zeroPlaceholder?: () => JSX.Element }): JSX.Element { return  (<span>Preview Profile</span>) }
    fields(): Views.OfFields.Plugin[] { return [{
        render: () => <Database.AsEditor peerId="Persons.ViewProfile.AsProfile" pollInterval={pollInterval} render={zone => <_Card prefix="person-profile" zone={zone} binding={binding} allowGalleryEdit={true} isInvitation={false} mode={'portfolio'}/>} />
    }] }
}), Modes.AnyMode);


Views.All.push(AsProfile);
