import faker from 'faker';
import React from 'react';

import { Button, createMuiTheme } from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';

import * as XLayout from '@pitaman71/react-explicit-layout';
import { Objects } from '@pitaman71/omniglot-live-data';
import { Database, MaterialUi, Navigation, Propose, Views } from '@pitaman71/omniglot-live-react';
import { ViewAuthentication } from '@pitaman71/omniglot-live-react/lib/MaterialUi';

import * as HeroIcons from './HeroIcons';
import * as Modes from './Modes';
import * as NounProject from './NounProject';
import * as Controls from './Controls';
import * as Inspectors from './Inspectors';
import * as Whoami from './Whoami';

import * as Model from 'models/autosrc/swivell/talent_marketplace';

import './Tabs/Discover';
import './Tabs/Landing';

function _Lobby(props: {
}) {
    const { zone } = React.useContext(Database.Context);
    const whoami = React.useContext(Whoami.Context);
    if(!zone || !whoami.person) throw new Error('Missing Context');
    const person = whoami?.person;

    return <XLayout.Center.Both>
        <XLayout.Stack.South style={{ height: '100%', width: '80%' }}>
            <Controls.Media.AsDownloader
                pixelDimensions={{ height: 150, width: 200 }}
                style={{ width: '100%', height: '100%', flex: '1', minHeight: '0' }}
                value={{
                    pixelDimensions: { width: 1545, height: 2000 },
                    mime: 'image/jpeg',
                    uri: '/images/lobby-background.jpeg'
                }}
            />
            <MaterialUi.Styler.Heading  style={{ flex: '0 0 auto' }}>You are in the Lobby.</MaterialUi.Styler.Heading>
            <MaterialUi.Styler.Body  style={{ flex: '0 0 auto' }}>
                <p>Your profile is under review for full app access.</p>
                <p>On approval you will be able to discover and match with top brands and creative talent, explore opportunities, and expand your creative network.</p>
                <p>Click on the "Account" tab at the bottom right corner of the page to continue building your profile.</p>
            </MaterialUi.Styler.Body>
        </XLayout.Stack.South>
        </XLayout.Center.Both>
}

const LobbyTab = new Views.Factory([''], [], ((binding, factory) => new class implements Views.OfGoals.Plugin {
    key(separator: string) { return factory.asPath().key(separator) }
    route() { return factory.asPath().to(binding) }    
    controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin, options?: { ref?: React.RefObject<HTMLButtonElement> }) => JSX.Element) { return <React.Fragment></React.Fragment> }
    icon(): JSX.Element { return <NounProject.Countdown/> }
    image(pixelDimensions: { width: number, height: number }, imageProps: React.CSSProperties) { return undefined; }
    label(truncate?: { maxChars?: number }): JSX.Element { return  (<span>LOBBY</span>) }
    summary(options: { onClick: (editMode: boolean) => void, zeroPlaceholder?: () => JSX.Element }): JSX.Element { return  (<span>Lobby Mode</span>) }
    fields(): Views.OfFields.Plugin[] { return [{
        render: () => <Database.AsViewer render={zone => <_Lobby/>}/>
    }] }
}()), Modes.LobbyMode);

function _MyProjects() {
    const whoami = React.useContext(Whoami.Context);
    const person = whoami?.person;
    return !!person ? <Database.AsViewer render={(zone) => <Controls.Projects.ViewByMember.AsTimeline binding={{ person }} zone={zone}/>}/> : <React.Fragment>Error: missing context</React.Fragment>
}

const UpcomingTab = new Views.Factory(['upcoming'], [], ((binding, factory) => new class implements Views.OfGoals.Plugin {
    key(separator: string) { return factory.asPath().key(separator) }
    route() { return factory.asPath().to(binding) }    
    controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin, options?: { ref?: React.RefObject<HTMLButtonElement> }) => JSX.Element) { return <React.Fragment></React.Fragment> }
    icon(props: { mode: Views.OfControls.Modes }): JSX.Element { return <HeroIcons.Clipboard variant={ props.mode === Views.OfControls.Modes.Selected ? "solid" : "outline" }/> }
    image(pixelDimensions: { width: number, height: number }, imageProps: React.CSSProperties) { return undefined; }
    label(truncate?: { maxChars?: number }): JSX.Element { return  (<span>PROJECTS</span>) }
    summary(options: { onClick: (editMode: boolean) => void, zeroPlaceholder?: () => JSX.Element }): JSX.Element { return  (<span>Projects</span>) }
    fields(): Views.OfFields.Plugin[] { return [{
        render: () => <_MyProjects/>
    }] }
}()), Modes.NormalMode);

function _Connect(props: {
}) {
    const whoami = React.useContext(Whoami.Context);
    const person = whoami?.person;
    return !!person ? <Database.AsViewer render={(zone) => <Controls.Discussion.ViewConnections.AsAvatarBrowser binding={{ person }} zone={zone}/>}/> : <React.Fragment>Error: missing context</React.Fragment>
}

const ConnectTab = new Views.Factory(['connect'], [], ((binding, factory) => new class implements Views.OfGoals.Plugin {
    key(separator: string) { return factory.asPath().key(separator) }
    route() { return factory.asPath().to(binding) }    
    controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin, options?: { ref?: React.RefObject<HTMLButtonElement> }) => JSX.Element) { return <React.Fragment></React.Fragment> }
    icon(props: { mode: Views.OfControls.Modes }): JSX.Element { return <HeroIcons.ChatBubbleOvalLeftEllipsis variant={ props.mode === Views.OfControls.Modes.Selected ? "solid" : "outline" }/> }
    image(pixelDimensions: { width: number, height: number }, imageProps: React.CSSProperties) { return undefined; }
    label(truncate?: { maxChars?: number }): JSX.Element { return  (<span>MESSAGES</span>) }
    summary(options: { onClick: (editMode: boolean) => void, zeroPlaceholder?: () => JSX.Element }): JSX.Element { return  (<span>Messages</span>) }
    fields(): Views.OfFields.Plugin[] { return [{
        render: () => <_Connect/>
    }] }
}()), Modes.AnyMode);

const MyProfileTab = new Views.Factory(['profile'], [], ((binding, factory) => new class implements Views.OfGoals.Plugin {
    key(separator: string) { return factory.asPath().key(separator) }
    route() { return factory.asPath().to(binding) }    
    controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin, options?: { ref?: React.RefObject<HTMLButtonElement> }) => JSX.Element) { return <React.Fragment></React.Fragment> }
    icon(props: { mode: Views.OfControls.Modes }): JSX.Element { return props.mode === Views.OfControls.Modes.Selected ? <MuiIcons.Person/> : <MuiIcons.PersonOutlined/> }
    image(pixelDimensions: { width: number, height: number }, imageProps: React.CSSProperties) { return undefined; }
    label(truncate?: { maxChars?: number }): JSX.Element { return  (<span>PROFILE</span>) }
    summary(options: { onClick: (editMode: boolean) => void, zeroPlaceholder?: () => JSX.Element }): JSX.Element { return  (<span>My Profile</span>) }
    fields(): Views.OfFields.Plugin[] { return [{
        render: () => <Database.AsEditor peerId='TopViews.MyProfileTab' pollInterval={Controls.Persons.ViewPerson.pollInterval} render={(zone) =><Controls.Persons.ViewProfile.MyProfile zone={zone}/>}/>
    }] }
}()), {
    relevant: (route, hasTag) => route !== '/profile',
    position: () => Views.Position.Primary
});

function _Logout(props: {
}) {
    const authentication = React.useContext(ViewAuthentication.AuthContext);
    const navigation = React.useContext(Navigation.Context);
    return (
        <React.Fragment>
            <p>
                <MaterialUi.Styler.Heading>Logout of the Swivell App?</MaterialUi.Styler.Heading>
            </p>
            <XLayout.Center.Horizontal>
                <Button variant="outlined" endIcon={<MuiIcons.CancelOutlined/>} onClick={() => navigation.backward && navigation.backward()}>
                    Cancel
                </Button>
                <Button variant="outlined" endIcon={<NounProject.Logout/>} onClick={() => {
                    authentication?.confirmLogout();
                    navigation.forward('/');
                }}>
                    Logout
                </Button>
            </XLayout.Center.Horizontal>
        </React.Fragment>
    )
}

const LogoutTab = new Views.Factory(['logout'], [], ((binding, factory) => new class implements Views.OfGoals.Plugin {
    key(separator: string) { return factory.asPath().key(separator) }
    route() { return factory.asPath().to(binding) }    
    controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin, options?: { ref?: React.RefObject<HTMLButtonElement> }) => JSX.Element) { return <React.Fragment></React.Fragment> }
    icon(props: { mode: Views.OfControls.Modes }): JSX.Element { return <HeroIcons.Power variant={ props.mode === Views.OfControls.Modes.Selected ? "solid" : "outline" }/> }
    image(pixelDimensions: { width: number, height: number }, imageProps: React.CSSProperties) { return undefined; }
    label(truncate?: { maxChars?: number }): JSX.Element { return  (<span>LOGOUT</span>) }
    summary(options: { onClick: (editMode: boolean) => void, zeroPlaceholder?: () => JSX.Element }): JSX.Element { return  (<span>Log Out of Swivell</span>) }
    fields(): Views.OfFields.Plugin[] { return [{
        render: () => <_Logout/>
    }] }
}()), {
    relevant: (route, hasTag) => route === '/profile',
    position: () => Views.Position.Primary
});

Views.All.push( LobbyTab, UpcomingTab, ConnectTab, MyProfileTab, LogoutTab );
