import { Clients, Controller, Objects, Stores, Values } from '@pitaman71/omniglot-live-data';

import { Access } from '.';

import * as Model from '../../autosrc/swivell/talent_marketplace';

export function usePerson(zone: Stores.Zone, binding: { person: Objects.Binding<string> }) {
    const community = Objects.Binding.from_bound("the");
    const hasPerson = Model.Community.HasPerson.Descriptor.stream(zone, { community });
    const hasProvenance = Model.Identity.Provenance.Descriptor.stream(zone, { the: binding.person }).scalar;
    const hasSkills = Model.Creator.HasSkills.Descriptor.stream(zone, { creator: binding.person }).set;
    const hasAuthority = Model.Persons.HasAuthority.Descriptor.stream(zone, { person: binding.person }).set;
    const hasName = Model.Persons.HasName.Descriptor.stream(zone, { person: binding.person }).scalar;
    const hasEmail = Model.Persons.HasEmail.Descriptor.stream(zone, { person: binding.person }).set;
    const hasOpenToTravel = Model.Persons.HasOpenToTravel.Descriptor.stream(zone, { person: binding.person }).scalar;
    const hasWebLink = Model.Persons.HasWebLink.Descriptor.stream(zone, { person: binding.person }).set;
    const hasTitle = Model.Persons.HasTitle.Descriptor.stream(zone, { person: binding.person}).scalar;
    const hasAbout = Model.Persons.HasAbout.Descriptor.stream(zone, { person: binding.person }).scalar;
    const hasTalentStats = Model.Persons.HasTalentStats.Descriptor.stream(zone, { person: binding.person }).scalar;
    const hasAvatar = Model.Persons.HasAvatar.Descriptor.stream(zone, { person: binding.person }).scalar;
    const hasHomeLocation = Model.Persons.HasHomeLocation.Descriptor.stream(zone, { person: binding.person }).scalar;
    const hasAvailability = Model.Persons.HasAvailability.Descriptor.stream(zone, { person: binding.person });
    const includes = Model.Content.Includes.Descriptor.stream(zone, { topic: binding.person });
    const byMember = Model.Projects.ByMember.Descriptor.stream(zone, { person: binding.person } );

    return { hasPerson, hasProvenance, 
        hasSkills, hasAuthority, hasName, hasEmail, 
        hasOpenToTravel, hasWebLink, hasTitle, hasAbout, hasTalentStats, hasAvatar, hasHomeLocation,
        hasAvailability, includes, byMember
    };
}

export function Create(zone: Stores.Zone, binding: { me: Objects.Binding<string>, subject: Objects.Binding<string> }): Controller.OfObject<any> {
    return new class _Create {
        _neighbors = {
            community: Objects.Binding.from_bound('the')
        }
        _aspects = {
            allow: Clients.ScalarClient.Eager<boolean>('allow'),
            hasProvenance: Model.Identity.Provenance.Descriptor.bind({ the: binding.subject }),
            hasPerson: Model.Community.HasPerson.Descriptor.bindAnchor({...this._neighbors})
        }
        allow() {
            return this._aspects.allow.stream(Values.TheBooleanDomain)
        }
        streams() {
            return {
                hasProvenance: zone.streams().property(this._aspects.hasProvenance).scalar,
                hasPerson: zone.streams().relation(this._aspects.hasPerson)
            };
        }
        clients()  {
            return {
                hasProvenance: this.streams().hasProvenance?.stateful(),
                hasPerson: this.streams().hasPerson?.stateful()
            }
        }
        reset() {
            this._aspects.allow.set(true);
            this.clients().hasProvenance?.assign({ claimed: false, owner: binding.me });
            this.clients().hasPerson?.insert({ ...this._neighbors, person: binding.subject });
        }
        pre() {
        }
        post() {
        }
    }();
}

export function Remove(zone: Stores.Zone, binding: { me: Objects.Binding<string>, subject: Objects.Binding<string> }): Controller.OfObject<any> {
    return new class _Remove {
        _neighbors = {
            community: Objects.Binding.from_bound('the')
        }
        _aspects = {
            hasPerson: Model.Community.HasPerson.Descriptor.bindAnchor({...this._neighbors}),
            hasEmail: Model.Persons.HasEmail.Descriptor.bind({ person: binding.subject }),
        }
        allow() {
            return Access.allowRemove(zone, { me: binding.me, person: binding.subject });
        }
        streams() {
            return {
                hasPerson: zone.streams().relation(this._aspects.hasPerson),
                hasEmail: zone.streams().property(this._aspects.hasEmail).set,
            }
        }
        clients() {
            return {
                hasPerson: this.streams().hasPerson?.stateful(),
                hasEmail: this.streams().hasEmail?.stateful()
            };
        }
        reset() {
            this.clients().hasPerson?.remove({ ...this._neighbors, person: binding.subject });
            const hasEmail = Clients.SetClient.Eager<string>('byEmail');
            hasEmail.watch({
                next: (status) => {
                    Array.from(status.values).forEach(email => {
                        zone.streams().relation(Model.Persons.ByEmail.Descriptor.bindAnchor({ email: Objects.Binding.from_bound(email)})).stateful().clear();
                    })
                }, error: (err) => {},
                complete: () => {}
            })
            hasEmail.upstream(this.streams().hasEmail);
            this.clients().hasEmail?.clear();
        }
        pre() {
        }
        post() {
        }
    }();
}
