import React from 'react';

import { Badge, Button, Divider, Tooltip } from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';

import * as XLayout from '@pitaman71/react-explicit-layout';

import * as Whoami from '../../Whoami';

import { Objects, Stores } from '@pitaman71/omniglot-live-data';
import { Conditional, Database, MaterialUi, Navigation, Propose, Views } from '@pitaman71/omniglot-live-react';
import * as ViewComment from './ViewComment';
import { ViewProfile } from '../Persons';
import { ViewCandidate } from '../Candidates';
import { ViewConnections } from '.';
import { AsAvatar } from '../Persons/ViewPerson';

import * as Model from 'models/autosrc/swivell/talent_marketplace';
import { Access, Controller } from 'models/src/Social'
import { Controller as DiscussionController } from 'models/src/Discussion';

export interface Binding extends Objects.BindingType<string> {
    subject: Objects.Binding<string>
}

function _Connection(props: {
    zone: Stores.Zone,
    binding: { from: Objects.Binding<string>, to: Objects.Binding<string> }
}) {
    const navigation = React.useContext(Navigation.Context);
    const whoami = React.useContext(Whoami.Context);
    if(whoami.person === undefined) throw new Error("Missing Context");
    const me = whoami.person;
    const hasConnectionStatus = Propose.useSetProperty(React.useMemo(() => Access.hasStatus(props.zone, props.binding), [ me, props.binding.from, props.binding.to ]))
    //const onClick = () => navigation.forward(ViewProfile.AsProfile.asPath().to({ person: props.binding.to }));
    const onClick = () => navigation.forward(ViewConnections.AsCheckPerson.asPath().to({ person: props.binding.to }));
    const makeAvatar = () => <AsAvatar 
        zone={props.zone} 
        binding={{ person: props.binding.to }} 
        pixelDimensions={{ width: 48, height: 48 }} 
        onClick={onClick}
    />;    

    if(hasConnectionStatus.values.size > 0 && !hasConnectionStatus.values.has('connected')) {
        return <Badge
            overlap="circular"
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            onClick={onClick}
            badgeContent={ 
                hasConnectionStatus.values.has('rejected') || hasConnectionStatus.values.has('rejecting') 
                ? <MuiIcons.Cancel color="secondary"/>
                : <MuiIcons.Warning color="secondary"/>
            }
        >
            { makeAvatar() }
        </Badge>
    }
    return makeAvatar();
}

function _AsStatus(props: {
    binding: { from: Objects.Binding<string>, to: Objects.Binding<string> },
}) {
    const { zone } = React.useContext(Database.Context);
    const navigation = React.useContext(Navigation.Context);
    const whoami = React.useContext(Whoami.Context);

    if(zone === undefined) throw new Error("Missing Context");
    if(whoami.person === undefined) throw new Error("Missing Context");

    const hasConnectionStatus = Propose.useSetProperty(React.useMemo(() => Access.hasStatus(zone, props.binding), [zone, whoami.person, props.binding.from, props.binding.to ]));

    const me = whoami.person;
    const isAdmin = whoami.hasTag('admin');

    return <React.Fragment>
        <Conditional show={hasConnectionStatus.status.initializing || hasConnectionStatus.values.size === 0}>
            <XLayout.Center.Both>
                <p><MaterialUi.Styler.Body>Confirming your authorization to participate in this conversation</MaterialUi.Styler.Body></p>
            </XLayout.Center.Both>
        </Conditional>
        <Conditional show={hasConnectionStatus.values.has('rejected') && !isAdmin}>
            <XLayout.Center.Both>
                <p><MaterialUi.Styler.Body>They have declined this connection</MaterialUi.Styler.Body></p>
            </XLayout.Center.Both>
        </Conditional>
        <Conditional show={hasConnectionStatus.values.has('rejecting') && !isAdmin}>
            <XLayout.Center.Both>
                <p><MaterialUi.Styler.Body>You have declined this connection</MaterialUi.Styler.Body></p>
            </XLayout.Center.Both>
        </Conditional>
        <Conditional show={hasConnectionStatus.values.has('invited') && !isAdmin}>
            <XLayout.Center.Both>
                <p><MaterialUi.Styler.Body>You have not yet accepted this connection</MaterialUi.Styler.Body></p>
            </XLayout.Center.Both>
        </Conditional>
        <Conditional show={hasConnectionStatus.values.has('inviting') && !isAdmin}>
            <XLayout.Center.Both>
                <p><MaterialUi.Styler.Body>They have not yet accepted this connection</MaterialUi.Styler.Body></p>
            </XLayout.Center.Both>
        </Conditional>
        <Conditional show={hasConnectionStatus.values.has('new') && !isAdmin}>
            <XLayout.Center.Both>
                <p><MaterialUi.Styler.Body>This connection is new</MaterialUi.Styler.Body></p>
            </XLayout.Center.Both>
        </Conditional>
    </React.Fragment>;
}

function _AsHistory(props: {
    zone: Stores.Zone,
    binding: Binding
}) {
    const { zone } = React.useContext(Database.Context);
    const navigation = React.useContext(Navigation.Context);
    const whoami = React.useContext(Whoami.Context);
    if(zone === undefined) throw new Error("Missing Context");
    if(whoami.person === undefined) throw new Error("Missing Context");
    const [ docVisible, setDocVisible ] = React.useState(true);
    const viewport = React.useRef<HTMLDivElement>(null);

    const me = whoami.person;
    const isAdmin = whoami.hasTag('admin');

    React.useEffect(() => {
        document.addEventListener('visibilitychange', event => {
            const isVisible = document.visibilityState !== 'hidden';
            DiscussionController.viewSubject(zone, { subject: props.binding.subject, viewer: me }).exec();
            setDocVisible(isVisible);
            zone.commitAll();
        })
    }, [] );

    const hasComments = zone.streams().relation(Model.Discussion.HasComment.Descriptor.bindAnchor(props.binding));
    const peers = subjectToGroup(props.binding.subject, me);
    const offer = () => {
        navigation.forward(ViewCandidate.AsWizard.asPath().to({ person: peers[0] }))
    };
    const disconnect = () => {
        if(me) {
            const controller = Controller.Reject(zone, { me, from: me, person: peers[0] });
            controller.reset();
            zone.commitAll();
        }
    }
    return (
        <React.Fragment>
            <div style={{ display: "flex", flexDirection: "column", height: "100%", maxWidth: "100%", overflow: "hidden", margin: "0 1em" }}>
                <div style={{ display: "flex", flex: "0 0 auto", justifyContent:'center', flexDirection: 'column' }}>
                    <XLayout.Stack.East id="discussion-avatars" scroll={true} style={{ justifyContent: 'center' }}>
                    {
                        peers.map(person => <_Connection zone={props.zone} binding={{ from: me, to: peers.length === 1 ? peers[0] : props.binding.subject }}/>)
                    }
                    </XLayout.Stack.East>
                    <Divider></Divider>
                </div>
                <div ref={viewport} style={{ display: "flex", flex: "1", overflow: "hidden", margin: "0.5em 0" }}>
                    <XLayout.Stack.North id="discussion-history" scroll={true} style={{ width: '100%' }}>
                        <Propose.RelationEntries stream={hasComments} render={(entries, client) => 
                                <React.Fragment>
                                {
                                    entries.length === 0 && 
                                        <i style={{ display: "flex", alignItems: "end"}}>Your conversation history is empty</i>
                                }
                                {
                                    [...entries].reverse().map(entry => 
                                        <ViewComment.AsHistoryEntry viewport={!docVisible ? undefined : viewport} key={entry.comment.objectId||"?"} zone={zone} binding={{ comment: entry.comment }}/>
                                    )
                                }
                                </React.Fragment>
                        }/>
                    </XLayout.Stack.North>
                </div>
                <div style={{ display: "flex", flex: "0 0 auto", border: 'solid', borderRadius: '2em', padding: '0.5em' }}>
                    <ViewComment.AsAppend zone={zone} binding={{ subject: props.binding.subject }} recipients={peers} />
                </div>
                <div style={{ display: "flex", flex: "0 0 auto" }}>
                    <XLayout.Center.Horizontal>
                        <Button variant="outlined" style={{ margin: "0.5em", width: "12em" }} onClick={(e) => {
                            e.preventDefault();
                            offer();
                        }}>Make an Offer
                        </Button>
                        <Button variant="outlined" style={{ margin: "0.5em", width: "12em" }} onClick={(e) => {
                            e.preventDefault();
                            disconnect();
                        }}>Disconnect
                        </Button>
                    </XLayout.Center.Horizontal>
                </div>
            </div>
        </React.Fragment>
    );
}

export function groupToSubject(...peers: Objects.Binding<string>[]) {
    const objectIds = peers.map(peer => peer.objectId || "?").sort((a,b) => a < b ? -1 : a > b ? 1 : 0);
    return Objects.Binding.from_bound(objectIds.join(","));
}

export function subjectToGroup(
    subject: Objects.Binding<string>,
    except?: Objects.Binding<string>
) {
    if(!subject.objectId) return [];
    return subject.objectId.split(',')
    .map(word => Objects.Binding.from_bound(word))
    .filter(peer => except === undefined || Objects.Binding.cmp(except, peer) !== 0);
}

export const AsHistory = new Views.Factory<Binding, Model.Authority.Tags.ValueType>(['messages' ], ['subject'], ((binding, factory) => new class implements Views.OfGoals.Plugin {
    key(separator: string) { return factory.asPath().key(separator) }
    route() { return factory.asPath().to(binding) }    
    controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin, options?: { ref?: React.RefObject<HTMLButtonElement> }) => JSX.Element) { return <React.Fragment></React.Fragment> }
    icon(): JSX.Element { return <MuiIcons.MessageOutlined/> }
    image(pixelDimensions: { width: number, height: number }, imageProps: React.CSSProperties) { return undefined; }
    label(truncate?: { maxChars?: number }): JSX.Element { return  (<span>Conversation</span>) }
    summary(options: { onClick: (editMode: boolean) => void, zeroPlaceholder?: () => JSX.Element }): JSX.Element { return  (<span>Messages</span>) }
    fields(): Views.OfFields.Plugin[] { return [{
        render: () => <Database.AsEditor peerId="/messages/subject" pollInterval={{ ms: 5000 }} render={zone => <_AsHistory zone={zone} binding={binding}/>} />
    }] }
}));

Views.All.push(AsHistory);
