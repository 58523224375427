import React from 'react';
import { Dot } from 'react-animated-dots';
import useInterval from 'react-useinterval';

import * as HeroIconsOutlined from '../HeroIconsOutlined';
import * as HeroIcons from '../HeroIcons';

import { Objects, Stores } from '@pitaman71/omniglot-live-data';
import { Database, Interview, Navigation, Styler, Views } from '@pitaman71/omniglot-live-react';
import * as XLayout from '@pitaman71/react-explicit-layout';

import { AsTab } from './Discover';
import * as Modes from '../Modes';
import * as Whoami from '../Whoami';

import { Controller as DiscoveryController } from 'models/src/Discovery';

import * as Themes from '../Themes';

import * as Model from 'models/autosrc/swivell/talent_marketplace';

export let logEnable = false;

export let pollInterval = { ms: 30000 };

function _Admitted(props: {
    zone: Stores.Zone
}) {
    const navigation = React.useContext(Navigation.Context);
    if(!navigation) throw new Error('Missing Context')
    return <Interview.Waypoint progress={{
        key: "landing-admitted",
        status: () => <span>You've Been Admitted</span>,
        details: () => [
            <Styler.Body>Your profile has been approved and your account has been admitted.</Styler.Body>,
            <Styler.Body>Note that access to some features will still require a paid subscription.</Styler.Body>,
        ],
        options: [
            {
                class: Views.OfControls.Direction.Forward,
                id: 'to-discovery',
                label: () => <span>Discover talent and projects</span>,
                icon: ({mode}) => <HeroIcons.ArrowCircle variant='outline' direction='right'/>,
                onClick: () => {
                    navigation.forward(AsTab.asPath().to({}))
                    return Promise.resolve();
                }
            }
        ]
    }}>

    </Interview.Waypoint>
}

const AsAdmitted = new Views.Factory(['landing', 'admitted'], [], ((binding, factory) => new class implements Views.OfGoals.Plugin {
    key(separator: string) { return factory.asPath().key(separator) }
    route() { return factory.asPath().to(binding) }    
    controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin, options?: { ref?: React.RefObject<HTMLButtonElement> }) => JSX.Element) { 
        return <React.Fragment></React.Fragment>;
    }
    icon(props: { mode: Views.OfControls.Modes }): JSX.Element { return <HeroIcons.GlobeAlt variant={ props.mode === Views.OfControls.Modes.Selected ? "solid" : "outline" }/> }
    image(pixelDimensions: { width: number, height: number }, imageProps: React.CSSProperties) { return undefined; }
    label(truncate?: { maxChars?: number }): JSX.Element { return  (<span>DISCOVER</span>) }
    summary(options: { onClick: (editMode: boolean) => void, zeroPlaceholder?: () => JSX.Element }): JSX.Element { return  (<span>Discover talent, projects, events</span>) }
    fields(): Views.OfFields.Plugin[] { return [{
        render: (controller) => {
            return <Database.AsEditor peerId="landing-admitted" pollInterval={{ ms: 150000 }} render={zone => <_Admitted zone={zone}/>}/>
        }
    }] }
}), Modes.NormalMode);

Views.All.push(AsAdmitted);
