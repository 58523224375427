import React from 'react';

import { Avatar, IconButton, MenuItem, Select, TableRow, TableCell } from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';

import { Objects, Stores } from '@pitaman71/omniglot-live-data';
import { Database, MaterialUi, Navigation, Propose, Styler, Views } from '@pitaman71/omniglot-live-react';

import * as XLayout from '@pitaman71/react-explicit-layout';
import * as Model from 'models/autosrc/swivell/talent_marketplace';

import * as NounProject from '../../NounProject';
import * as ViewHasCandidates from './ViewHasCandidates';
import * as ViewHasRate from './ViewHasRate';
import { ViewCandidate, ViewHasOfferStatus } from '../Candidates';
import { ViewHasEvents, ViewHasRoles, ViewProject } from '../Projects';
import * as Payments from '../../Payments';
import * as Themes from '../../Themes';
import * as Whoami from '../../Whoami';

import * as ProjectController from 'models/src/Projects/Controller';

export let pollInterval = { ms: 30000 };

export interface Binding {
    project: Objects.Binding<string>;
    role: Objects.Binding<string>;
};

export function AsTitle(props: {
    zone: Stores.Zone,
    binding: { role: Objects.Binding<string> },
    onClick?: () => void
}) {
    const hasTitle = Propose.useScalarProperty(Model.Roles.HasTitle.Descriptor.stream(props.zone, { role: props.binding.role }).scalar);
    return <span onClick={props.onClick}>{ hasTitle.value }</span>;
}

export function AsTitleOrSkills(props: {
    zone: Stores.Zone,
    binding: { role: Objects.Binding<string> },
    onClick?: () => void
}) {
    const hasTitle = Propose.useScalarProperty(Model.Roles.HasTitle.Descriptor.stream(props.zone, { role: props.binding.role }).scalar);
    const hasSkills = Propose.useSetProperty(Model.Roles.HasSkills.Descriptor.stream(props.zone, { role: props.binding.role }).set);
    return <span onClick={props.onClick}>{ hasTitle.value || Array.from(hasSkills.values).join(",") }</span>;
}

export function AsCount(props: {
    zone: Stores.Zone,
    binding: { role: Objects.Binding<string> },
    onClick?: () => void
}) {
    const hasCount = Propose.useScalarProperty(Model.Roles.HasCount.Descriptor.stream(props.zone, { role: props.binding.role }).scalar);
    return <span onClick={props.onClick}>{ hasCount.value !== undefined ? `(${hasCount.value})` : null }</span>;
}

export function AsTally(props: {
    zone: Stores.Zone,
    binding: { role: Objects.Binding<string> }
}) {
    const hasCount = Propose.useScalarProperty(Model.Roles.HasCount.Descriptor.stream(props.zone, { role: props.binding.role }).scalar);
    return <span>/{hasCount.value || 0}</span>;
}

export function AsRate(props: {
    zone: Stores.Zone,
    binding: { role: Objects.Binding<string> },
    onClick?: () => void
}) {
    const navigation = React.useContext(Navigation.Context);
    const hasRate = Propose.useScalarProperty(Model.Roles.HasRate.Descriptor.stream(props.zone, { role: props.binding.role }).scalar);
    return <div onClick={props.onClick}>{hasRate.value?.text || ""}</div>;
}

export function AsSummary(props: {
    zone: Stores.Zone,
    binding: { role: Objects.Binding<string> }
}) {
    return <XLayout.Stack.East>
        <AsTitleOrSkills {...props}/>
        <AsCount {...props}/>        
        <AsRate {...props}/>
    </XLayout.Stack.East>;
}

export function AsNames(props: {
    zone: Stores.Zone,
    binding: { role: Objects.Binding<string> }
}) {
    const hasCandidates = Propose.useRelation(Model.Roles.HasCandidates.Descriptor.stream(props.zone, { role: props.binding.role }));
    return <React.Fragment>
        {
            hasCandidates.entries.map(entry => (
                <ViewHasOfferStatus.If zone={props.zone} binding={{ candidate: entry.candidate }} predicate={(hasBrandStatus, hasTalentStatus) => hasBrandStatus === 'Accept' && hasTalentStatus === 'Accept' }>
                    <ViewCandidate.AsName zone={props.zone} binding={{candidate: entry.candidate }}/>
                </ViewHasOfferStatus.If>    
            ))
        }
    </React.Fragment>
}

export function AsAvatars(props: {
    zone: Stores.Zone,
    binding: { role: Objects.Binding<string> },
    pixelDimensions: { height: number, width: number },
}) {
    const hasCandidates = Propose.useRelation(Model.Roles.HasCandidates.Descriptor.stream(props.zone, { role: props.binding.role }));
    return <React.Fragment>
        {
            hasCandidates.entries.length === 0 ?
                <Avatar style={props.pixelDimensions}>
                    <MuiIcons.PersonOutlined/>
                </Avatar>
            : hasCandidates.entries.map(entry => (
                <ViewHasOfferStatus.If zone={props.zone} binding={{ candidate: entry.candidate }} predicate={(hasBrandStatus, hasTalentStatus) => hasBrandStatus === 'Accept' && hasTalentStatus === 'Accept' }>
                    <Avatar style={props.pixelDimensions}>
                        <ViewCandidate.AsAvatarImage zone={props.zone} binding={entry} pixelDimensions={props.pixelDimensions}/>
                    </Avatar>
                </ViewHasOfferStatus.If>    
            ))
        }
    </React.Fragment>
}

export function AsPicker(props: {
    zone: Stores.Zone,
    binding: { project: Objects.Binding<string> }
    role: Objects.Binding<string>|undefined,
    setRole: React.Dispatch<React.SetStateAction<Objects.Binding<string> | undefined>>
}): JSX.Element {
    const hasRoles = props.zone.streams().relation(Model.Projects.HasRoles.Descriptor.bindAnchor(props.binding));
    return (
            <Propose.RelationEntries stream={hasRoles} render={(entries, client) => {
                return <Select 
                    fullWidth={true}
                    placeholder="Choose a role for the project"
                    value={props.role?.objectId} 
                    onChange={(event) => {
                        if(!event.target.value)
                            props.setRole(undefined);
                        else
                            props.setRole(Objects.Binding.from_bound(event.target.value as string))
                    }}>
                    {
                        entries.map(entry => {
                            return <MenuItem value={entry.role.objectId}><AsTitle zone={props.zone} binding={{ role: entry.role }}/></MenuItem>
                        })
                    }
                </Select>
            }}/>
    );
}

export function AsOfferCard(props: {
    zone: Stores.Zone,
    binding: { project: Objects.Binding<string>, role: Objects.Binding<string>, candidate: Objects.Binding<string> },
    pixelDimensions: { height: number, width: number },
  }) {
    const hasTitle = props.zone.streams().property(Model.Roles.HasTitle.Descriptor.bind({ role: props.binding.role }));
    const pixelDimensions = { width: props.pixelDimensions.width, height: props.pixelDimensions.width };
    return (
        <XLayout.Stack.South>
            <ViewProject.AsTitle zone={props.zone} binding={props.binding}/>
            <XLayout.Center.Horizontal>
                <Avatar style={pixelDimensions}>
                    <ViewCandidate.AsAvatarImage zone={props.zone} binding={props.binding} pixelDimensions={pixelDimensions}/>
                </Avatar>
            </XLayout.Center.Horizontal>
            <Propose.PropertyData stream={hasTitle} render={{ scalar: (value, client) => <b>{value || ""}</b> }}/>
            <ViewCandidate.AsName zone={props.zone} binding={props.binding}/>
            <ViewHasEvents.AsChips zone={props.zone} binding={props.binding}/>
        </XLayout.Stack.South>
    );
}


export function AsTile(props: {
    zone: Stores.Zone,
    binding: { project: Objects.Binding<string>, role: Objects.Binding<string> },
    viewport?: React.RefObject<HTMLDivElement>,
    pixelDimensions: { height: number, width: number },
    onClick?: () => void
  }) {
    const pixelDimensions = { width: props.pixelDimensions.width, height: props.pixelDimensions.width };
    return (
        <Styler.Card prefix="project-card" viewport={props.viewport} theme={Themes.Light} style={{ ...props.pixelDimensions, display: 'inline-block', verticalAlign: 'top' }}
            onClick={props.onClick}
        >
        <XLayout.Stack.South style={props.pixelDimensions}>
            <XLayout.Center.Horizontal><AsAvatars {...props} binding={props.binding} pixelDimensions={{ width: pixelDimensions.width * 0.9, height: pixelDimensions.height * 0.9 }}/></XLayout.Center.Horizontal>
            <Styler.Control prefix="team-role-title"><Styler.Heading><AsTitleOrSkills {...props}/><AsCount {...props}/></Styler.Heading></Styler.Control>
        </XLayout.Stack.South>
        </Styler.Card>
    );
}

export function AsProfile(props: {
    zone: Stores.Zone,
    binding: Binding,
    on: {
        add?: () => Promise<any>,
        cancel?: () => Promise<any>
    }
}) {
    const navigation = React.useContext(Navigation.Context);
    return (
        <XLayout.Stack.South style={{ width: '100%', height: '100%' }}>
            <Payments.CheckSubscription why={<span>"A subscription is required to book talent"</span>}>
                <Styler.Heading style={{ flex: '0 0 auto' }}>Book Talent</Styler.Heading>
                <Styler.Section prefix="team-role-title">
                    <Styler.Heading><ViewProject.AsBrandName zone={props.zone} binding={{ project: props.binding.project }}/></Styler.Heading>
                    <Styler.Heading><ViewProject.AsTitle zone={props.zone} binding={{ project: props.binding.project }}/></Styler.Heading>
                    <Styler.Body><AsTitleOrSkills {...props}/><AsCount {...props}/></Styler.Body>
                </Styler.Section>
                <Styler.Section prefix="role-candidates" style={{ width: '100%', flex: '1' }}>
                    <ViewHasCandidates.AsTable zone={props.zone} binding={{ role: props.binding.role }}/>
                </Styler.Section>
                { !props.on.add || !props.on.cancel ? [] : <XLayout.Center.Horizontal><XLayout.Stack.East>
                    <Views.OfControls.AsRow style={{ marginBottom: "1em" }} controls={[
                            {
                                class: Views.OfControls.Direction.Backward,
                                id: 'discard',
                                label: () => 'Discard',
                                icon: () => <MuiIcons.ArrowLeftOutlined/>,
                                onClick: props.on.cancel
                            },{
                                class: Views.OfControls.Direction.Forward,
                                id: 'add',
                                label: () => 'Add Role',
                                icon: () => <MuiIcons.CheckOutlined/>,
                                onClick: props.on.add
                            }
                        ]} render={controlProps => <Views.OfControls.AsButton {...controlProps}  variant="outlined"/>} />
                    </XLayout.Stack.East></XLayout.Center.Horizontal>
                }
                { !!props.on.add ? [] : <XLayout.Center.Horizontal><XLayout.Stack.East>
                    <Views.OfControls.AsRow style={{ marginBottom: "1em" }} controls={[
                            {
                                class: Views.OfControls.Direction.Forward,
                                id: 'delete',
                                label: () => 'Delete Role',
                                icon: () => <MuiIcons.DeleteOutlined/>,
                                onClick: () => {
                                    navigation.openDialog(ViewHasRoles.Delete.make({ project: props.binding.project, role: props.binding.role }));
                                    return Promise.resolve();
                                }
                            }
                        ]} render={controlProps => <Views.OfControls.AsButton {...controlProps} variant="outlined"/>} />
                    </XLayout.Stack.East></XLayout.Center.Horizontal>
                }
            </Payments.CheckSubscription>
        </XLayout.Stack.South>
    );
}

export const Inspect = new Views.Factory<{ role: Objects.Binding<string>, project: Objects.Binding<string> }, Model.Authority.Tags.ValueType>([ 'role', 'inspect' ], ['role', 'project'], (binding, factory) => new class implements Views.OfGoals.Plugin {
    key(separator: string) { return factory.asPath().key(separator) }
    route() { return factory.asPath().to(binding) }    
    controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin, options?: { ref?: React.RefObject<HTMLButtonElement> }) => JSX.Element) { return <React.Fragment></React.Fragment> }
    icon() { return <NounProject.Availability/> }
    image(pixelDimensions: { width: number, height: number }, imageProps: React.CSSProperties) {
        return undefined
    }
    label(truncate?: { maxChars?: number }) {
        return <span>View Project Role</span>
    }
    summary(options: { onClick: (editMode:boolean) => void, zeroPlaceholder?: () => JSX.Element }) {
        return <div onClick={() => options.onClick(false)}>{this.label()}</div>
    }
    fields() { return [ { 
        render: () =>  (
            <Database.AsEditor peerId="ViewRole.Inspect" pollInterval={pollInterval} render={zone => <AsProfile zone={zone} binding={binding} on={{}}/>} />
        )
    }] }
});

Views.All.push(Inspect);

export function AsTableRow(props: {
    binding: {
        project: Objects.Binding<string>,
        role: Objects.Binding<string>
    }
}) {
    const { zone } = React.useContext(Database.Context);
    const whoami = React.useContext(Whoami.Context);
    if(!zone || !whoami.person) throw new Error('Missing Context');
    const me = whoami.person;

    const hasTitle = Propose.useScalarProperty(Model.Roles.HasTitle.Descriptor.stream(zone, { role: props.binding.role }).scalar);
    const hasSkills = Propose.useSetProperty(Model.Roles.HasSkills.Descriptor.stream(zone, { role: props.binding.role }).set);
    const hasCount = Propose.useScalarProperty(Model.Roles.HasCount.Descriptor.stream(zone, { role: props.binding.role }).scalar);
    const asEnumeration = hasCount.domain?.asEnumeration(100);
    return (
        <TableRow key={props.binding.role.objectId}>
            <TableCell>
                <MaterialUi.ViewScalar.AsChip id="role-title-chip" {...hasTitle}/>
                <MaterialUi.ViewSet.AsChips id="role-skills-chips" {...hasSkills}/>
            </TableCell>
            <TableCell>
                <MaterialUi.ViewScalar.AsSelect 
                    id="role-count-select"
                    {...hasCount} 
                    variant="outlined"
                    style={{ borderRadius: '3em', height: '5em' }}
                    order={asEnumeration ? Array.from(asEnumeration.forward()) :[0,1,2,3]}
                />
            </TableCell>
            <TableCell>
                <IconButton onClick={() => {                    
                    ProjectController.removeRole(zone, { ...props.binding, me}).exec();
                }}><MuiIcons.DeleteOutlined/></IconButton>
            </TableCell>
        </TableRow>
    )
}
