import { ThemeOptions, createTheme } from '@material-ui/core';

const typography: ThemeOptions['typography'] = {
    fontWeightLight: 200,
    fontWeightMedium: 300,
    fontWeightRegular: 400,
    fontWeightBold: 700,
    h1: {
        fontSize: '2.2rem',
        fontWeight: 'bold',
        fontFamily: 'Bebas Neue'
    }, h2: {
        fontSize: '2rem',
        fontWeight: 900,
        fontFamily: 'Libre Franklin'
    }, h3: {
        fontSize: '1.5rem',
        fontWeight: 'bold',
        fontFamily: 'Libre Franklin'
    }, h4: {
        fontSize: '1.1rem',
        fontWeight: 'bold',
        fontFamily: 'Libre Franklin'
    }, h5: {
        fontSize: '1.05rem',
        fontWeight: 'bold',
        fontFamily: 'Libre Franklin'
    }, body1: {
        fontSize: '1rem',
        fontFamily: 'Libre Franklin'
    }, body2: {
        fontSize: '0.9rem',
        fontFamily: 'Libre Franklin'
    }
};

export const Dark = createTheme({
  palette: {
    background: {
      default: '#000000',
      paper: '#0F0F0F'
    }, primary: {
      light: '#BFBFBF',
      main: '#DFDFDF',
      dark: '#FFFFFF'
    }, secondary: {
      light: '#FF0016',
      main: '#FF0016',
      dark: '#FFCCD0'
    }, text: {
      primary: '#FFFFFF',
      secondary: '#BFBFBF'
    }
  },
  typography
});

export const Light = createTheme({
  palette: {
    background: {
      default: '#eee3d1',
      paper: '#eee3d1'
    }, primary: {
      light: '#404040',
      main: '#202020',
      dark: '#000000'
    }, secondary: {
      light: '#FF0016',
      main: '#FF0016',
      dark: '#330004'
    }, text: {
      primary: '#000000',
      secondary: '#404040'
    }
  },
  typography
});

export const Gold = createTheme({
  palette: {
    background: {
      default: '#D8BB8D',
      paper: '#D8BB8D'
    }, primary: {
      light: 'BE8D41',
      main: '#261C0D',
      dark: '261C0D'
    }, secondary: {
      light: '330004',
      main: '#99000D',
      dark: 'FF0016'
    }, text: {
      primary: '#261C0D',
      secondary: '#FF0016'
    }
  },
  typography
});

export const Interview = createTheme({
  palette: {
    background: {
      default: '#000000',
      paper: '#080808'
    }, primary: {
      light: 'BE8D41',
      main: '#F2E8D9',
      dark: '#F2E8D9'
    }, secondary: {
      light: '330004',
      main: '#99000D',
      dark: 'FF0016'
    }, text: {
      primary: '#F2E8D9',
      secondary: '#725527'
    }
  },
  typography
});

export const Gray = createTheme({
  palette: {
    background: {
      default: '#808080',
      paper: '#808080'
    }, primary: {
      light: '#808080',
      main: '#BFBFBF',
      dark: '#F0F0F0'
    }, secondary: {
      light: '#FF0016',
      main: '#FF6673',
      dark: '#FFCCD0'
    }, text: {
      primary: '#808080',
      secondary: '#BFBFBF'
    }
  },
  typography
});

export const Red = createTheme({
  palette: {
    background: {
      default: '#FF0016',
      paper: '#FF0016'
    }, primary: {
      light: '#1F1F1F',
      main: '#1F1F1F',
      dark: '#1E1E1E'
    }, secondary: {
      light: '#FFFFFF',
      main: '#FFFFFF',
      dark: '#FFFFFF'
    }, text: {
      primary: '#000000',
      secondary: '#1E1E1E'
    }
  },
  typography
});

export const BlackOnRed = createTheme({
  palette: {
    background: {
      default: '#000000',
      paper: '#0F0F0F'
    }, primary: {
      light: '#FF0016',
      main: '#FF0016',
      dark: '#FF0016'
    }, secondary: {
      light: '#000000',
      main: '#000000',
      dark: '#000000'
    }, text: {
      primary: '#FF0016',
      secondary: '#FF0016'
    }
  },
  typography
});
