import React from 'react';

import { Avatar } from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';

import { Clients, Stores, Streams } from '@pitaman71/omniglot-live-data';
import { Navigation, Propose, Views } from '@pitaman71/omniglot-live-react';

import * as Model from 'models/autosrc/swivell/talent_marketplace';
import { Media } from '..';
import { AsProfile } from './ViewProfile';
import { ViewProject } from '.';

export type ImageGenerator = (height?: number, width?: number, randomize?: boolean) => string;

type Binding = Model.Projects.HasAvatar.TypeParams['Binding'];
export let pollInterval = { ms: 30000 };
const coverDimensions = { width: 960, height: 960 };

class Inspect {
    _zone: Stores.Zone;
    _binding: Binding;
    _streams: {
        hasAvatar: Streams.PropertyStream<Model.Projects.HasAvatar.TypeParams>
    }

    constructor(
        zone: Stores.Zone,
        binding: Binding,
    ) {
        this._zone = zone;
        this._binding = { ... binding };  
        this._streams = {
            hasAvatar: zone.streams().property(Model.Projects.HasAvatar.Descriptor.bind(binding))
        }
    }

    icon(): JSX.Element { return <MuiIcons.DynamicFeed/> }
}

export class View extends Inspect {
    key(separator: string) { return ['projects', 'hasAvatar', 'view'].join(separator) }
    route() { return '/projects/hasAvatar/view/'+(this._binding.project.objectId?.toString() || "?") }
    controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin) => JSX.Element) { return <Views.OfControls.Generic filter={filter} render={render} on={{
        upload: (controller) => {
            controller.forward('/projects/hasAvatar/upload/'+(this._binding.project.objectId?.toString()||'?'));
            return Promise.resolve();
        },
        cancel: (controller) => {
            controller.forward(AsProfile.asPath().to({ project: this._binding.project }));
            return Promise.resolve();
        }
    }}/>}
    icon(): JSX.Element { return <MuiIcons.PhotoOutlined/> }
    image(pixelDimensions: { width: number, height: number }, style: React.CSSProperties) {
        return <Propose.PropertyData
            stream={this._streams.hasAvatar}
            render={{
                scalar: (value, client) => {
                    return !value ? <MuiIcons.ImageOutlined style={{ ...pixelDimensions, maxWidth: '100%', ...style }}/> : <Media.AsDownloader pixelDimensions={pixelDimensions} style={style} value={value} render={(pixelDimensions, style, src) => (
                        <img src={src} style={{ ...pixelDimensions, maxWidth: '100%', ...style, objectFit: 'cover', objectPosition: 'center' }}/>
                    )}/>
                }
            }}
        />;
    }
    label(truncate?: { maxChars?: number }): JSX.Element { 
        return  (<span>View Project Cover Photo</span>);
    }
    summary(options: { onClick: (editMode:boolean) => void, zeroPlaceholder?: () => JSX.Element }): JSX.Element { 
        return <span>View Project Cover Photo</span>
    }
    fields(): Views.OfFields.Plugin[] { 
        return [ ]
    }
}

function _AsUploader(props: {
    view: Views.OfGoals.Plugin,
    zone: Stores.Zone,
    binding: Model.Projects.HasAvatar.TypeParams['Binding'],
    client: Clients.ScalarClient<Model.Projects.HasAvatar.TypeParams['Value']>,
    pixelDimensions: { width: number, height: number }, 
    style: React.CSSProperties
}) {
    const controller = React.useContext(Navigation.Context);
    const on = {
        assign: (value_: Model.Projects.HasAvatar.TypeParams['Value']) => {
            props.client.assign(value_);
            return Promise.resolve();
        },
        clear: () => {
            props.client.clear();
            return Promise.resolve();
        },
        confirm: () => { 
            const promise = new Promise((resolve, reject) => {
                props.zone.commitAll().then(resolve).catch(reject);
            });
            return promise.then(() => {
                !!controller.backward ? controller.backward() : controller.forward(AsProfile.asPath().to({ project: props.binding.project }));
                return Promise.resolve();
            });
        }, cancel: () => { 
            !!controller.backward ? controller.backward() : controller.forward(AsProfile.asPath().to({ project: props.binding.project }));
            return Promise.resolve();
        }
    };
    return <Media.AsUploader {...props} style={props.style} on={on}/>    
}

export class Upload extends View {
    key(separator: string) { return ['projects', 'hasAvatar', 'upload'].join(separator) }
    route() { return '/projects/hasAvatar/upload/'+(this._binding.project.objectId?.toString() || "?") }
    controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin, options?: { ref?: React.RefObject<HTMLButtonElement> }) => JSX.Element) { return <React.Fragment></React.Fragment> }
    label(truncate?: { maxChars?: number }): JSX.Element { 
        return  (<span>Upload Cover Photo</span>);
    }
    summary(options: { onClick: (editMode:boolean) => void, zeroPlaceholder?: () => JSX.Element }): JSX.Element { 
        return <span>Upload Project Cover Photo</span>
    }
    image(pixelDimensions: { width: number, height: number }, style: React.CSSProperties) {
        return <Propose.PropertyData
            stream={this._streams.hasAvatar}
            render={{
                scalar: (value, client) => {
                    if(!client) return <React.Fragment></React.Fragment>;
                    return <div style={style}><_AsUploader view={this} pixelDimensions={coverDimensions} style={style} zone={this._zone} binding={this._binding} client={client}/></div>
                }
            }}
        />;
    }
}

