import React from 'react';
import { Avatar, TableCell, TableRow } from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';

import * as XLayout from '@pitaman71/react-explicit-layout';
import { Objects, Stores } from '@pitaman71/omniglot-live-data';
import { Database, MaterialUi, Navigation, Propose, Styler, Views } from '@pitaman71/omniglot-live-react';

import * as Model from 'models/autosrc/swivell/talent_marketplace';
import { Access } from 'models/src/Persons';

import * as Inspectors from '../../Inspectors';
import * as Modes from '../../Modes';
import * as Themes from '../../Themes';
import * as Whoami from '../../Whoami';

import * as Manage from './Manage';

import { ViewConnections } from '../Discussion';
import { ViewAccount, ViewBrandOnboarding, ViewCreatorOnboarding, ViewCommonOnboarding, ViewGallery, ViewHasAvatar, ViewOnboarding, ViewProfile } from '.';
import { Media } from '../';
import { Controller as PersonController } from 'models/src/Persons';

export interface Binding extends Objects.BindingType<string> {
    person: Objects.Binding<string>
}
export let pollInterval = { ms: 30000 };

export function useAccess(binding: Binding) {
    const { zone } = React.useContext(Database.Context);
    const whoami = React.useContext(Whoami.Context);
    if(zone === undefined) throw new Error("Missing Context");
    if(whoami.person === undefined) throw new Error("Missing Context");
    const me = whoami.person;

    const view = Propose.useScalarProperty(React.useMemo(() => Access.allowView(zone, { me, person: binding.person }), [zone, whoami.person, binding.person ]));
    const edit = Propose.useScalarProperty(React.useMemo(() => Access.allowEdit(zone, { me, person: binding.person }), [zone, whoami.person, binding.person ]));
    return { view, edit };
}

export function Authorize(props: {
    mode: 'view'|'edit',
    access: ReturnType<typeof useAccess>,
    children: JSX.Element|JSX.Element[]
}) {
    /* if(props.mode === 'view' && props.access.view.value === undefined) {
        return (
            <XLayout.Center.Both>
                <Styler.Heading>Checking your authorization to view this talent profile.</Styler.Heading>
            </XLayout.Center.Both>
        )
    } else if(props.mode === 'view' && props.access.view.value === false) {
        return (
            <XLayout.Center.Both>
                <Styler.Heading>You do not have authorization to view this talent profile.</Styler.Heading>
            </XLayout.Center.Both>
        )
    } else */ if(props.mode === 'edit' && props.access.edit.value === undefined) {
        return (
            <XLayout.Center.Both>
                <Styler.Heading>Checking your authorization to edit this talent profile.</Styler.Heading>
            </XLayout.Center.Both>
        )
    } else if(props.mode === 'edit' && props.access.edit.value === false) {
        return (
            <XLayout.Center.Both>
                <Styler.Heading>You do not have authorization to edit this talent profile.</Styler.Heading>
            </XLayout.Center.Both>
        )
    } else if(Array.isArray(props.children)) {
        return <React.Fragment>{props.children}</React.Fragment>;
    } else {
        return props.children;
    }
}

export function AsBrand(props: {
    zone: Stores.Zone,
    binding: Binding
}) {
    const hasName = props.zone.streams().property(Model.Affiliations.HasName.Descriptor.bind({ affiliated: props.binding.person }));
    return <Propose.PropertyData stream={hasName} render={{
        scalar: (value, client): JSX.Element => {
            return <span>{value === undefined ? "" : value}</span>
        }}}/>;
}

export function AsName(props: {
    zone: Stores.Zone,
    binding: Binding
}) {
    const hasName = props.zone.streams().property(Model.Persons.HasName.Descriptor.bind(props.binding));
    return <Propose.PropertyData stream={hasName} render={{
        scalar: (value, client): JSX.Element => {
            return <span>{value === undefined ? "" : value}</span>
        }}}/>;
}

export function AsTitle(props: {
    zone: Stores.Zone,
    binding: Binding
}) {
    const hasTitle = props.zone.streams().property(Model.Persons.HasTitle.Descriptor.bind(props.binding));
    return <Propose.PropertyData stream={hasTitle} render={{
        scalar: (value, client): JSX.Element => {
            return <span>{value === undefined ? "" : value}</span>
        }}}/>;
}

export function AsEmails(props: {
    zone: Stores.Zone,
    binding: Binding
}) {
    const hasEmail = props.zone.streams().property(Model.Persons.HasEmail.Descriptor.bind(props.binding));
    return <Propose.PropertyData stream={hasEmail} render={{
        set: (values, client): JSX.Element => {
            return <MaterialUi.ViewSet.AsChips 
                id="person-emails-chips"
                values={values} 
                domain={Model.Persons.HasEmail.Descriptor.getDomain()} 
                icon={() => <MuiIcons.EmailOutlined/>}
                onClick={(email) => {
                    window.location.href=`mailto:${email}`;
                }}
            />
        }}}/>;
}

function _SwivellArc(props: {
    binding: {
        person: Objects.Binding<string>
    },
    pixelDimensions: {
        width: number,
        height: number
    },
    stroke: number
}) {
    const { zone } = React.useContext(Database.Context);
    const center = {
        x: props.pixelDimensions.width / 2,
        y: props.pixelDimensions.height / 2
    };
    const radius = {
        x: (props.pixelDimensions.width - (1 + props.stroke)) / 2,
        y: (props.pixelDimensions.height - (1 + props.stroke)) / 2
    }
    const hasAuthority = zone?.streams().property(Model.Persons.HasAuthority.Descriptor.bind(props.binding));
    return !hasAuthority ? <React.Fragment></React.Fragment> : <Propose.PropertyData
        stream={hasAuthority}
        render={{
            set: (value, client) => (
                !value?.has('admin') ? <React.Fragment></React.Fragment> : <div style={{ display: 'flex', position: 'absolute', height: 'auto', width: 'auto' }}>
                    <svg width={props.pixelDimensions.width} height={props.pixelDimensions.height} version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <g transform={`rotate(-10 ${center.x} ${center.y})`}>
                            <path stroke="red" stroke-width={props.stroke} fill="transparent" d={`M ${center.x + radius.x} ${center.y} A ${radius.x} ${radius.y} 0 0 0 ${center.x} ${center.y - radius.y}`}/>
                            <path stroke="red" stroke-width={props.stroke} fill="transparent" d={`M ${center.x - radius.x} ${center.y} A ${radius.x} ${radius.y} 0 0 0 ${center.x} ${center.y + radius.y}`}/>
                        </g>
                    </svg>
                </div>                                
            )
        }}
    />
}

export function AsAvatar(props: {
    zone: Stores.Zone,
    binding: Binding,
    pixelDimensions: {
        width: number,
        height: number
    },
    style?: React.CSSProperties,
    onClick?: () => void
}) {
    const hasAvatar = Propose.useScalarProperty(Model.Persons.HasAvatar.Descriptor.stream(props.zone, { person: props.binding.person }).scalar);
    const diameter = Math.min(props.pixelDimensions.width, props.pixelDimensions.height);
    const stroke = Math.ceil(diameter * 0.02); // stroke width and gap width
    return !hasAvatar.value ? <Avatar style={{
        width: diameter,
        height: diameter,
        ...props.style
    }}><MuiIcons.PersonOutlined/></Avatar> 
        : <Media.AsDownloader pixelDimensions={{ width: diameter, height: diameter }} style={props.style || {}} value={hasAvatar.value} render={(pixelDimensions, style, src) => (
            <div style={{ ...pixelDimensions, display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }} onClick={props.onClick}>
                <Avatar src={src} style={{ width: pixelDimensions.width - (1 + stroke * 4), height: pixelDimensions.height - (1 + stroke * 4), objectFit: 'cover', backgroundColor: 'transparent' }}/>
                <_SwivellArc binding={props.binding} stroke={stroke} pixelDimensions={pixelDimensions}/>
            </div>
        )}/>
}

export function AsThumbnail(props: {
    zone: Stores.Zone,
    binding: Binding,
    pixelDimensions: {
        width: number,
        height: number
    },
    style?: React.CSSProperties,
    onClick?: () => void
}) {
    const hasAvatar = Propose.useScalarProperty(Model.Persons.HasAvatar.Descriptor.stream(props.zone, { person: props.binding.person }).scalar);
    return !hasAvatar.value ? <div style={{
        ...props.pixelDimensions,
        ...props.style
    }}><MuiIcons.PersonOutlined/></div> 
        : <Media.AsDownloader pixelDimensions={props.pixelDimensions} style={props.style || {}} value={hasAvatar.value} render={(pixelDimensions, style, src) => (
            <img src={src} style={{ ...props.pixelDimensions, ...props.style, objectFit: 'cover', backgroundColor: 'transparent' }} alt="Avatar"/>
        )
    }/>
}

export function AsTile(props: {
    zone: Stores.Zone,
    binding: Binding,
    viewport?: React.RefObject<HTMLDivElement>,
    pixelDimensions: { width: number, height: number },
    onClick?: () => void
}): JSX.Element {
    const navigation = React.useContext(Navigation.Context);
    const whoami = React.useContext(Whoami.Context);

    const me = whoami?.person;
    const access = useAccess(props.binding);

    const hasAvatar = new ViewHasAvatar.View(
        props.zone,
        props.binding
    );
    
    return (
        <Authorize access={access} mode='view'>
            <Styler.Card prefix='person-tile' viewport={props.viewport} theme={Themes.Light} style={{ ...props.pixelDimensions, display: 'inline-block', verticalAlign: 'top' }}
                onClick={props.onClick}
            >
                <XLayout.Stack.South>
                    <XLayout.Center.Horizontal>
                        <Inspectors.AsBackground pixelDimensions={{ width: props.pixelDimensions.width, height: props.pixelDimensions.width }} style={{}} view={hasAvatar}/>
                    </XLayout.Center.Horizontal>
                    <b>Talent</b>
                    <AsName zone={props.zone} binding={props.binding}/>
                    {
                        !whoami.hasTag('admin') ? <React.Fragment></React.Fragment> : <AsEmails zone={props.zone} binding={props.binding}/>
                    }
                </XLayout.Stack.South>
            </Styler.Card>    
        </Authorize>
    );
}

export function AsHeader(props: {
    binding: Binding,
    title?: string,
    placeholder?: string
}) {
    const { zone } = React.useContext(Database.Context);
    const navigation = React.useContext(Navigation.Context);
    const whoami = React.useContext(Whoami.Context);
    if(!zone || !whoami.person) throw new Error('Missing Context');
    const me = whoami.person;
    const isMe = Objects.Binding.cmp(me, props.binding.person) === 0;

    const hasName = Propose.useScalarProperty(Model.Persons.HasName.Descriptor.stream(zone, props.binding).scalar);
    const hasHomeLocation = Propose.useScalarProperty(Model.Persons.HasHomeLocation.Descriptor.stream(zone, props.binding).scalar);
    const hasSkills = Propose.useSetProperty(Model.Creator.HasSkills.Descriptor.stream(zone, { creator: props.binding.person }).set);
    const allowEdit = Propose.useScalarProperty(React.useMemo(() => Access.allowEdit(zone, { me, ...props.binding }), [ me, props.binding.person ]));

    return (
        <React.Fragment>
            <Styler.Heading align='left'>
                { props.title ? props.title+': ' : ''}{ hasName.value ? hasName.value : <i>{props.placeholder}</i> || "?" }
            </Styler.Heading>
            <Styler.Body align='left'>
                { [ hasHomeLocation.value?.text, ...Array.from(hasSkills.values)].reduce<(JSX.Element|string)[]>((accum, value) => value === undefined ? accum : accum.length === 0 ? [value] : [...accum, <Styler.Bullet/>, value], []) }
            </Styler.Body>
        </React.Fragment>
    )
}

export function AsActions(props: {
    binding: Binding
}) {
    const { zone } = React.useContext(Database.Context);
    const navigation = React.useContext(Navigation.Context);
    const whoami = React.useContext(Whoami.Context);
    if(!zone || !whoami.person) throw new Error('Missing Context');
    const me = whoami.person;
    const isMe = Objects.Binding.cmp(me, props.binding.person) === 0;

    const allowEdit = Propose.useScalarProperty(React.useMemo(() => Access.allowEdit(zone, { me, ...props.binding }), [ me, props.binding.person ]));

    return (
        <XLayout.Center.Horizontal>
            <XLayout.Stack.East style={{ width: '100%' }}>
                { !allowEdit ? <React.Fragment></React.Fragment> : <Styler.Button variant='outlined' id="viewperson-edit-button" style={{ 
                    display: !allowEdit.value ? 'none' : undefined,
                    flex: '1',
                    margin: '0.5rem'
                }} onClick={() => navigation.forward(isMe ? ViewOnboarding.AsMine.asPath().to(props.binding) : ViewOnboarding.AsOther.asPath().to(props.binding))}>Edit Profile</Styler.Button>
                }
                { !allowEdit ? <React.Fragment></React.Fragment> : <Styler.Button variant='outlined' id="viewperson-manage-button" style={{ 
                    display: !allowEdit.value ? 'none' : undefined,
                    flex: '1',
                    margin: '0.5rem'
                }} onClick={() => navigation.forward(isMe ? ViewAccount.AsMine.asPath().to(props.binding) : ViewAccount.AsOther.asPath().to(props.binding))}>ManageAccount</Styler.Button>
                }
                { isMe ? <React.Fragment></React.Fragment> : <Styler.Button variant='outlined' id="viewperson-connection-button" style={{ 
                    display: isMe ? 'none' : undefined,
                    flex: '1',
                    margin: '0.5rem'
                }} onClick={() => navigation.forward(ViewConnections.AsCheckPerson.asPath().to(props.binding))}>Connection</Styler.Button>
                }
            </XLayout.Stack.East>
        </XLayout.Center.Horizontal>
    )
}

export const AsInvitation = new Views.Factory<Binding, Model.Authority.Tags.ValueType>(['persons', 'invite'], ['person'], ((binding, factory) => new class implements Views.OfGoals.Plugin {
    key(separator: string) { return factory.asPath().key(separator) }
    route() { return factory.asPath().to(binding) }    
    controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin, options?: { ref?: React.RefObject<HTMLButtonElement> }) => JSX.Element) { return <React.Fragment></React.Fragment> }
    icon(): JSX.Element { return <MuiIcons.Person/> }
    image(pixelDimensions: { width: number, height: number }, imageProps: React.CSSProperties) { return undefined; }
    label(truncate?: { maxChars?: number }): JSX.Element { return  (<span>Invite</span>) }
    summary(options: { onClick: (editMode: boolean) => void, zeroPlaceholder?: () => JSX.Element }): JSX.Element { return  (<span>Invite Someone</span>) }
    fields(): Views.OfFields.Plugin[] { return [{
        render: () => 
          <Database.AsEditor peerId="ViewPerson.AsInvitation" pollInterval={{ ms: 15000 }} render={zone => <ViewCommonOnboarding._Wizard language="en_US" binding={binding} mode='invite'/>}/>
    }] }
}), Modes.AnyMode);
Views.All.push(AsInvitation);

export const AsSignup = new Views.Factory<Binding, Model.Authority.Tags.ValueType>(['persons', 'signup'], ['person'], ((binding, factory) => new class implements Views.OfGoals.Plugin {
    key(separator: string) { return factory.asPath().key(separator) }
    route() { return factory.asPath().to(binding) }    
    controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin, options?: { ref?: React.RefObject<HTMLButtonElement> }) => JSX.Element) { return <React.Fragment></React.Fragment> }
    icon(): JSX.Element { return <MuiIcons.Person/> }
    image(pixelDimensions: { width: number, height: number }, imageProps: React.CSSProperties) { return undefined; }
    label(truncate?: { maxChars?: number }): JSX.Element { return  (<span>Sign Up</span>) }
    summary(options: { onClick: (editMode: boolean) => void, zeroPlaceholder?: () => JSX.Element }): JSX.Element { return  (<span>Signup for the Swivell App</span>) }
    fields(): Views.OfFields.Plugin[] { return [{
        render: () => 
          <Database.AsEditor peerId="ViewPerson.AsSignup" pollInterval={{ ms: 15000 }} render={zone => <ViewCommonOnboarding._Wizard language="en_US" binding={binding} mode='signup'/>}/>
    }] }
}), Modes.AnyMode);
Views.All.push(AsSignup);

function _Delete(props: {
    language: string,
    binding: Binding
}) {
    const navigation = React.useContext(Navigation.Context);
    const whoami = React.useContext(Whoami.Context);
    const { zone } = React.useContext(Database.Context);
    if(zone === undefined) throw new Error("Missing Context");
    if(whoami.person === undefined) throw new Error("Missing Context");
    const me = whoami.person;

    const access = useAccess(props.binding);

    return (
        <Authorize access={access} mode='edit'>
            <XLayout.Center.Both>
            <XLayout.Stack.South style={{ height: '50%' }}>
                <Styler.Heading style={{ flex: 1 }}>Delete this person?</Styler.Heading>
                <XLayout.Center.Horizontal style={{ flex: 1 }}>
                    <AsTile {...props} zone={zone} pixelDimensions={{ width: 105, height: 162 }}/>
                </XLayout.Center.Horizontal>
                <Views.OfControls.AsRow style={{ flex: 1 }} controls={[
                    {
                        class: Views.OfControls.Direction.Backward,
                        onClick: () => {
                            navigation.backward();
                            return Promise.resolve();
                        },
                        id: 'keep',
                        label: () => 'Keep Person',
                        icon: () => <MuiIcons.CancelOutlined/>
                    }, {
                        class: Views.OfControls.Direction.Backward,
                        onClick: () => {
                            PersonController.Remove(zone, { me, subject: props.binding.person }).reset();
                            return zone.commitAll().then(() => navigation.backward(2));
                        },
                        id: 'delete',
                        label: () => 'Delete Person',
                        icon: () => <MuiIcons.DeleteOutlined/>
                    }                            
                ]} render={controlProps => <Views.OfControls.AsIconButton {...controlProps}/>}/>
            </XLayout.Stack.South>
            </XLayout.Center.Both>
        </Authorize>
    )
}

export const AsDelete = new Views.Factory<Binding, Model.Authority.Tags.ValueType>(['persons', 'delete'], ['person'], ((binding, factory) => new class implements Views.OfGoals.Plugin {
    key(separator: string) { return factory.asPath().key(separator) }
    route() { return factory.asPath().to(binding) }    
    controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin, options?: { ref?: React.RefObject<HTMLButtonElement> }) => JSX.Element) { return <React.Fragment></React.Fragment> }
    icon(): JSX.Element { return <MuiIcons.DeleteOutlined/> }
    image(pixelDimensions: { width: number, height: number }, imageProps: React.CSSProperties) { return undefined; }
    label(truncate?: { maxChars?: number }): JSX.Element { return  (<span>Delete</span>) }
    summary(options: { onClick: (editMode: boolean) => void, zeroPlaceholder?: () => JSX.Element }): JSX.Element { return  (<span>Delete Account</span>) }
    fields(): Views.OfFields.Plugin[] { return [{
        render: () => 
          <Database.AsEditor peerId="ViewPerson.AsDelete" pollInterval={{ ms: 15000 }} render={zone => <_Delete language="en_US" binding={binding}/>}/>
    }] }
}), Modes.AnyMode);
Views.All.push(AsDelete);

export function AsGrid(props: {
    prefix: string,
    style?: React.CSSProperties
}) {
    const navigation = React.useContext(Navigation.Context);
    const { zone } = React.useContext(Database.Context);
    const whoami = React.useContext(Whoami.Context);
    const viewport = React.useRef<HTMLDivElement>(null);
    const community = Objects.Binding.from_bound("the");
    if(!navigation || !zone) throw new Error('Missing Context');
    const hasPerson = Propose.useRelation(Model.Community.HasPerson.Descriptor.stream(zone, { community }));
    return (
        <XLayout.Grid.Vertical id={`${props.prefix}-grid`} divRef={viewport} shape={{}} scroll={true} style={{ height: '100%', width: "100%" }}>
            { hasPerson.entries.map(entry => 
                <AsTile 
                    zone={zone} 
                    binding={{person: entry.person }} 
                    pixelDimensions={{ width: 160, height: 240 }}
                    viewport={viewport}
                    onClick={ 
                        whoami.hasTag('admin') 
                        ? () => navigation.forward(ViewAccount.AsOther.asPath().to({ person: entry.person }))
                        : () => navigation.forward(ViewProfile.AsProfile.asPath().to({ person: entry.person }))
                    }
                />) 
            }
        </XLayout.Grid.Vertical>
    );
}

export function AsTableRow(props: React.PropsWithChildren<{
    zone: Stores.Zone,
    binding: Binding,
    show?: {
        avatar: boolean,
        header: boolean,
        email: boolean        
    }
}>): JSX.Element {
    const whoami = React.useContext(Whoami.Context);
return (
        <TableRow>            
            { props.show?.avatar === false ? <React.Fragment/> : <TableCell><AsAvatar {...props} pixelDimensions={{ width: 160, height: 160 }}/></TableCell> }
            { props.show?.header === false ? <React.Fragment/> : <TableCell><AsHeader {...props}/></TableCell> }                        
            { props.show?.email === false ? <React.Fragment/> : <TableCell><AsEmails {...props}/></TableCell> }            
            { React.Children.map(props.children, child => (<TableCell>{child}</TableCell>)) }
        </TableRow>
    )
}

export function AsTableRows(props: {
    prefix: string,
    except?: Array<Objects.Binding<string>>,
    show?: {
        avatar: boolean,
        header: boolean,
        email: boolean        
    },
    extras?: ((binding: Binding) => JSX.Element)[]
}) {
    const navigation = React.useContext(Navigation.Context);
    const { zone } = React.useContext(Database.Context);
    const whoami = React.useContext(Whoami.Context);
    const community = Objects.Binding.from_bound("the");
    if(!navigation || !zone) throw new Error('Missing Context');
    const hasPerson = Propose.useRelation(Model.Community.HasPerson.Descriptor.stream(zone, { community }));
    return (<React.Fragment> {
        hasPerson.entries.filter(entry => (
            !props.except || !Objects.contains(props.except.map(person => ({person})), { person: entry.person })
        )).map(entry => 
            <AsTableRow
                key={entry.person.objectId}
                zone={zone} 
                binding={{person: entry.person }} 
                show={props.show}
            >
                { props.extras?.map(cb => cb({person: entry.person })) }
            </AsTableRow>)                 
    } </React.Fragment>);
}
