import faker from 'faker';
import { Dialogues, Definitions, Objects, Properties, Relations, Values } from '@pitaman71/omniglot-live-data';
import { Domains, Modules, WrapFaker } from '@pitaman71/omniglot-live-domains';
import * as Elevated from '@pitaman71/omniglot-introspect';

export const directory = new Definitions.Directory();
// property Events.ProximalTo

const Domain: Elevated.Domain<Domains.Geospatial._Distance> = Domains.Geospatial.makeDistanceDomain('mi', 0,40,5);
export type TypeParams = {
    Binding: { event: Objects.Binding<string>; location: Objects.Binding<string> },
    Value: Domains.Geospatial._Distance,
    Domain: typeof Domain
}
export const Descriptor = new class _Descriptor extends Properties.Descriptor<TypeParams> {
    canonicalName = 'generated.Events.ProximalTo';
    build(builder: Properties.Builder<TypeParams>): void {
        builder.object('event');
builder.object('location');
        builder.measure(Domains.Geospatial.makeDistanceDomain('mi', 0,40,5));
        builder.scalar();
    }
};

directory.descriptors.properties.set(Descriptor.canonicalName, Descriptor);
        
