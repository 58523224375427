import faker from 'faker';
import { Dialogues, Definitions, Objects, Properties, Relations, Values } from '@pitaman71/omniglot-live-data';
import { Domains, Modules, WrapFaker } from '@pitaman71/omniglot-live-domains';
import * as Elevated from '@pitaman71/omniglot-introspect';

export const directory = new Definitions.Directory();
// property Identity.Provenance

const Domain: Elevated.Domain<{ synthetic?: boolean, inferred?: boolean, invited?: boolean, claimed?: boolean, verified?: boolean, owner?: Objects.Binding<string> }> = WrapFaker.OfJSON('Identity.Provenance', () => ({ synthetic: true })).domain();
export type TypeParams = {
    Binding: { the: Objects.Binding<string> },
    Value: { synthetic?: boolean, inferred?: boolean, invited?: boolean, claimed?: boolean, verified?: boolean, owner?: Objects.Binding<string> },
    Domain: typeof Domain
}
export const Descriptor = new class _Descriptor extends Properties.Descriptor<TypeParams> {
    canonicalName = 'generated.Identity.Provenance';
    build(builder: Properties.Builder<TypeParams>): void {
        builder.object('the');
        builder.measure(WrapFaker.OfJSON('Identity.Provenance', () => ({ synthetic: true })).domain());
        builder.scalar();
    }
};

directory.descriptors.properties.set(Descriptor.canonicalName, Descriptor);
        
