import React from 'react';

import { Dot } from 'react-animated-dots';

import {  FormControlLabel, Radio, RadioGroup, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';

import { Stores } from '@pitaman71/omniglot-live-data';
import { Database, Interview, MaterialUi, Navigation, Propose, Styler, Views } from '@pitaman71/omniglot-live-react';

import * as Model from 'models/autosrc/swivell/talent_marketplace';
import * as Modes from '../../Modes';
import * as Themes from '../../Themes';
import * as Whoami from '../../Whoami';

import * as HeroIcons from '../../HeroIcons';
import { ViewBrandOnboarding, ViewGallery, ViewPerson } from '.';
import { Controller as PersonController } from 'models/src/Persons';

import config from '../../config.json'

const baseURI = process.env.SWIVELL_SERVICES || ( process.env.NODE_ENV !== 'production' ? 'http://localhost:8080/dev' : config.services['app.swivell'].backend.baseURI );

function useData(props: {
    zone: Stores.Zone,
    binding: ViewPerson.Binding
}) {
    return {
        hasName: Propose.useScalarProperty(Model.Persons.HasName.Descriptor.stream(props.zone, { person: props.binding.person }).scalar),
        hasAvatar: Propose.useScalarProperty(Model.Persons.HasAvatar.Descriptor.stream(props.zone, { person: props.binding.person }).scalar),
        hasEmail: Propose.useSetProperty(Model.Persons.HasEmail.Descriptor.stream(props.zone, { person: props.binding.person }).set),
        hasAuthority: Propose.useSetProperty(Model.Persons.HasAuthority.Descriptor.stream(props.zone, { person: props.binding.person }).set)
    };
}

export function _Wizard(props: {
    language: string,
    binding: ViewPerson.Binding,
    mode: 'invite'|'signup'|'edit'
}) {
    const navigation = React.useContext(Navigation.Context);
    const whoami = React.useContext(Whoami.Context);
    const { zone } = React.useContext(Database.Context);
    if(zone === undefined) throw new Error("Missing Context");
    if(whoami.person === undefined) throw new Error("Missing Context");
    const me = whoami.person;
    const isMe = me?.objectId === props.binding.person.objectId;

    const access = ViewPerson.useAccess(props.binding);
    const { hasName, hasAvatar, hasEmail, hasAuthority } = useData({ zone, binding: props.binding });

    React.useEffect(() => {
        if(props.mode === 'invite' || props.mode === 'signup') {
            const controller = PersonController.Create(zone, { me, subject: props.binding.person });
            controller.reset();
        }
    }, [ props.binding.person, props.mode ])

    const questions: Interview.Question[] = [{
        key: 'person-name',
        pre: (language) => [isMe ? "What's your full name?" : "Who are you inviting?" ],
        ask: (language, focus) => {
            return <MaterialUi.ViewScalar.AsInput inputProps={{ 
                id: "person-name-input",
                type: "text",
                autoFocus: focus,
                multiline: false,
                placeholder: isMe ? "your name" : "their name",
            }}
                domain={Model.Persons.HasName.Descriptor.getDomain()}
                value={hasName.value} 
                client={hasName.client}
            />
        },
        tell: (language) => isMe ? <span>Your name is {hasName.value}</span> : <span>Their name is {hasName.value}</span>,
        post: (language) => [ "This will be visible to other users.", "You may use an alias here if you like, but real names are encouraged in the Swivell Community. "]
    }, {
        key: 'person-email',
        pre: (language) => [ isMe ? "What's your email address?" : "What is their email address?" ],
        ask: (language, focus,) => {
            return <MaterialUi.ViewSet.AsAutocomplete
                id="person-email-input"
                placeholder="email addresses" 
                domain={Model.Persons.HasEmail.Descriptor.getDomain()}
                values={hasEmail.values} 
                client={hasEmail.client}
            />
        },
        tell: (language) => isMe ? <span>Your email addresses: {Array.from(hasEmail.values)[0]}</span> : <span>Their email addresses: {Array.from(hasEmail.values)[0]}</span>,
        post: (language) => [
            "You may enter as many email addresses as you like.",
            "Be sure to hit Enter or Return after each email.",
            "Swivell does not sell or share your contact information."
        ]
    }, {
        key: 'person-avatar',
        pre: (language) => [ isMe ? "What photo should we use as your avatar in the app?" : "What photo should we use as their avatar in the app?" ],
        ask: (language, focus,) => {
            return <MaterialUi.Media.ViewAsset.AsUploader
                baseURI={baseURI}
                style={{ width: "80% "}} 
                value={hasAvatar.value} 
                client={hasAvatar.client}
            />
        },
        tell: (language) => !!hasAvatar.value ? <span>You have uploaded an avatar photo</span> : <span>You have not uploaded an avatar photo</span>,
        post: (language) => [
        ]
    }, {
        key: 'person-type',
        pre: (language) => [ isMe ? "Are you a content creator or brand representative?" : "Are they a content creator or brand representative?" ],
        ask: (language, focus,) => {
            return (
                <RadioGroup autoFocus={focus} name="common-type-group" value={hasAuthority.values.has('creator') ? 'creator' : hasAuthority.values.has('brand') ? 'brand' : ''} onChange={(event) => {
                    if(event.target.value === 'creator') {
                        hasAuthority.client?.insert('creator');
                        hasAuthority.client?.remove('brand');
                    } else if(event.target.value === 'brand') {
                        hasAuthority.client?.remove('creator');
                        hasAuthority.client?.insert('brand');
                    } else {
                        hasAuthority.client?.remove('creator');
                        hasAuthority.client?.remove('brand');
                    } 
                }}>
                    <FormControlLabel id="common-type-creator" value="creator" control={<Radio />} label="Creator" />
                    <FormControlLabel id="common-type-brand" value="brand" control={<Radio />} label="Brand" />
                    <FormControlLabel id="common-type-other" value="other" control={<Radio />} label="Other" />
                </RadioGroup>
            );            
        },
        tell: (language) => {
            if(hasAuthority.values.has('creator')) 
                return isMe ? <span>You are a content creator</span> : <span>They are a content creator</span>
            else if(hasAuthority.values.has('brand'))
                return isMe ? <span>You are a brand representative</span> : <span>They are a brand representative</span>
            return <React.Fragment></React.Fragment>
        },
    }];

    return (
        <Interview.Wizard language={props.language} questions={questions} allow={{ view: access.view, edit: access.edit }} label={{
            checkingAuth: () => <span>Confirming your authorization to edit this profile <Dot>.</Dot><Dot>.</Dot><Dot>.</Dot></span>,
            failedAuth: () => <span>You don't have authorization to edit this profile.</span>,
            cancel: () => 
                props.mode === 'invite' ? <span>Discard Invitation</span> 
                : props.mode === 'signup' ? <span>Exit Signup</span>
                : <span>Discard Changes</span>
                ,
            confirm: () => 
                props.mode === 'invite' ? <span>Invite Person</span> 
                : props.mode === 'signup' ? <span>Next</span>
                : <span>Save Changes</span>
        }} on={{ 
            cancel: () => { navigation.backward(); return Promise.resolve() },
            commit: () => {
                return zone.commitAll().then(() => {
                    navigation.forward(AsAfter.asPath().to(props.binding))
                })                    
            },
        }} theme={{ card: Themes.Interview, buttons: Themes.Gold }}/>
    )
}

export const AsWizard = new Views.Factory<ViewPerson.Binding, Model.Authority.Tags.ValueType>(['persons', 'onboarding', 'common'], ['person'], ((binding, factory) => new class implements Views.OfGoals.Plugin {
    key(separator: string) { return factory.asPath().key(separator) }
    route() { return factory.asPath().to(binding) }    
    controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin, options?: { ref?: React.RefObject<HTMLButtonElement> }) => JSX.Element) { return <React.Fragment></React.Fragment> }
    icon(): JSX.Element { return <MuiIcons.Person/> }
    image(pixelDimensions: { width: number, height: number }, imageProps: React.CSSProperties) { return undefined; }
    label(truncate?: { maxChars?: number }): JSX.Element { return  (<span>Revise</span>) }
    summary(options: { onClick: (editMode: boolean) => void, zeroPlaceholder?: () => JSX.Element }): JSX.Element { return  (<span>About Me</span>) }
    fields(): Views.OfFields.Plugin[] { return [{
        render: () => 
          <Database.AsEditor peerId="ViewPerson.ViewCommonOnboarding.AsWizard" pollInterval={{ ms: 15000 }} render={zone => <_Wizard language="en_US" binding={binding} mode='edit'/>}/>
    }] }
}), Modes.AnyMode);
Views.All.push(AsWizard);

function _After(props: {
    language: string,
    binding: ViewPerson.Binding,
}) {
    const navigation = React.useContext(Navigation.Context);
    const { zone } = React.useContext(Database.Context);
    if(zone === undefined) throw new Error('Missing Context');

    const { hasName, hasAvatar, hasEmail, hasAuthority } = useData({ zone, binding: props.binding });
    const isCreator = hasAuthority.values.has('creator');
    const isBrand = hasAuthority.values.has('brand');
    const errors = {
        missingName: !hasName.value,
        missingAvatar: !hasAvatar.value,
        missingEmail: hasEmail.values.size === 0,
        uncategorized: !isBrand && !isCreator
    };
    if(errors.missingName || errors.missingAvatar || errors.missingEmail || errors.uncategorized) {
        return <Interview.Waypoint buttonStyle={{ width: '100%' }} progress={{
            key: "after-common-fail",
            status: () => <span>"About Me" is Incomplete</span>,
            details: () => [
                <Styler.Body style={{ display: errors.missingName ? undefined : 'none' }}>You did not state your name.</Styler.Body>,
                <Styler.Body style={{ display: errors.missingAvatar ? undefined : 'none' }}>You did not upload an avatar photo.</Styler.Body>,
                <Styler.Body style={{ display: errors.missingEmail ? undefined : 'none' }}>You did not provide at least one email address.</Styler.Body>,
                <Styler.Body style={{ display: errors.uncategorized ? undefined : 'none' }}>You did not specify if you are a brand or creator.</Styler.Body>,
                <Styler.Body>Your "About Me" interview is incomplete, so your account cannot be admitted to the app.</Styler.Body>,
                <Styler.Body>Please click on the "Back to About Me" button to go back and complete any missing sections.</Styler.Body>,
            ],
            options: [
                {
                    class: Views.OfControls.Direction.Forward,
                    id: 'return-common',
                    label: () => <span>Back to About Me</span>,
                    icon: ({mode}) => <HeroIcons.ArrowCircle variant='outline' direction='left'/>,
                    onClick: () => {
                        navigation.forward(AsWizard.asPath().to(props.binding))
                        return Promise.resolve();
                    }
                }
            ]
        }}/>
    } else {
        return <Interview.Waypoint buttonStyle={{ width: '100%' }} progress={{
            key: "after-common-pass",
            status: () => <span>"About Me" Complete</span>,
            details: () => [
                <Styler.Body>You have completed the "About Me" interview. All of your answers have been saved securely to the cloud.</Styler.Body>,
                <Styler.Body style={{ display: isBrand ? undefined : 'none' }}>In order for your brand account to be approved for full app access, we will need to ask you a few more questions about the brand you represent.</Styler.Body>,
                <Styler.Body style={{ display: isBrand ? undefined : 'none' }}>Please click on the "Next" button to begin the "About My Brand" interview.</Styler.Body>,
                <Styler.Body style={{ display: isCreator ? undefined : 'none' }}>In order for your creator account to be approved for full app access, we will need to ask you a few more questions about your work as a creator.</Styler.Body>,
                <Styler.Body style={{ display: isCreator ? undefined : 'none' }}>Please click on the "Next" button to begin the "About My Work" interview.</Styler.Body>,
            ],
            options: [
                {
                    class: Views.OfControls.Direction.Forward,
                    id: 'next',
                    label: () => <span>Next</span>,
                    icon: ({mode}) => <HeroIcons.ArrowCircle variant='outline' direction='right'/>,
                    onClick: () => {
                        if(isBrand) navigation.forward(ViewBrandOnboarding.AsWizard.asPath().to(props.binding))
                        else if(isCreator) navigation.forward(ViewGallery.AsUploader.asPath().to(props.binding))
                        return Promise.resolve();
                    }
                },
                {
                    class: Views.OfControls.Direction.Backward,
                    id: 'return-common',
                    label: () => <span>Back to About Me</span>,
                    icon: ({mode}) => <HeroIcons.ArrowCircle variant='outline' direction='left'/>,
                    onClick: () => {
                        navigation.forward(AsWizard.asPath().to(props.binding))
                        return Promise.resolve();
                    }
                }
            ]
        }}/>        
    }
}

export const AsAfter = new Views.Factory<ViewPerson.Binding, Model.Authority.Tags.ValueType>(['persons', 'after', 'common'], ['person'], ((binding, factory) => new class implements Views.OfGoals.Plugin {
    key(separator: string) { return factory.asPath().key(separator) }
    route() { return factory.asPath().to(binding) }    
    controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin, options?: { ref?: React.RefObject<HTMLButtonElement> }) => JSX.Element) { return <React.Fragment></React.Fragment> }
    icon(): JSX.Element { return <MuiIcons.Person/> }
    image(pixelDimensions: { width: number, height: number }, imageProps: React.CSSProperties) { return undefined; }
    label(truncate?: { maxChars?: number }): JSX.Element { return  (<span>Revise</span>) }
    summary(options: { onClick: (editMode: boolean) => void, zeroPlaceholder?: () => JSX.Element }): JSX.Element { return  (<span>About Me</span>) }
    fields(): Views.OfFields.Plugin[] { return [{
        render: () => 
          <Database.AsEditor peerId="ViewCommonOnboarding.AsAfter" pollInterval={{ ms: 15000 }} render={zone => <_After language="en_US" binding={binding}/>}/>
    }] }
}), Modes.AnyMode);
Views.All.push(AsAfter);
