import React from 'react';

import { Dot } from 'react-animated-dots';

import { Stores } from '@pitaman71/omniglot-live-data';
import { Domains } from '@pitaman71/omniglot-live-domains';
import { Database, Interview, MaterialUi, Navigation, Propose, Styler, Views } from '@pitaman71/omniglot-live-react';

import * as Model from 'models/autosrc/swivell/talent_marketplace';
import * as Modes from '../../Modes';
import * as NounProject from '../../NounProject';
import * as Themes from '../../Themes';
import * as Whoami from '../../Whoami';

import * as HeroIcons from '../../HeroIcons';
import { ViewPerson } from '.';

function useData(props: {
    zone: Stores.Zone,
    binding: ViewPerson.Binding
}) {
    return {
        hasTitle: Propose.useScalarProperty(Model.Persons.HasTitle.Descriptor.stream(props.zone, { person: props.binding.person }).scalar),
        hasAbout: Propose.useScalarProperty(Model.Persons.HasAbout.Descriptor.stream(props.zone, { person: props.binding.person }).scalar),
        hasHomeLocation: Propose.useScalarProperty(Model.Persons.HasHomeLocation.Descriptor.stream(props.zone, { person: props.binding.person }).scalar),
        hasSocialMedia: Propose.useSetProperty(Model.SocialMedia.HasAccount.Descriptor.stream(props.zone, { the: props.binding.person }).set),
        hasPastBrandAssociations: Propose.useSetProperty(Model.Creator.HasPastBrandAssociations.Descriptor.stream(props.zone, { creator: props.binding.person }).set),
        hasSkills: Propose.useSetProperty(Model.Creator.HasSkills.Descriptor.stream(props.zone, { creator: props.binding.person }).set),
        offersProductCategories: Propose.useSetProperty(Model.Creator.OffersProductCategories.Descriptor.stream(props.zone, { creator: props.binding.person }).set),
        offersAudienceTargeting: Propose.useSetProperty(Model.Creator.OffersAudienceTargeting.Descriptor.stream(props.zone, { creator: props.binding.person }).set),
        offersBlendedRate: Propose.useScalarProperty(Model.Creator.OffersBlendedRate.Descriptor.stream(props.zone, { creator: props.binding.person }).scalar)
    }
}

function _Wizard(props: {
    language: string,
    binding: ViewPerson.Binding
}) {
    const navigation = React.useContext(Navigation.Context);
    const whoami = React.useContext(Whoami.Context);
    const { zone } = React.useContext(Database.Context);
    if(zone === undefined) throw new Error("Missing Context");
    if(whoami.person === undefined) throw new Error("Missing Context");
    const me = whoami.person;

    const access = ViewPerson.useAccess(props.binding);

    const { hasTitle, hasAbout, hasHomeLocation, 
            hasSocialMedia, hasPastBrandAssociations, hasSkills, 
            offersProductCategories, offersAudienceTargeting, offersBlendedRate } = useData({ zone, binding: props.binding });
    const questions: Interview.Question[] = [{
        key: 'creator-title',
        pre: (language) => [ "What is your professional title?" ],
        ask: (language, focus,) => {
            return <MaterialUi.ViewScalar.AsInput inputProps={{ 
                id: "creator-title-input",
                type: "text",
                autoFocus: focus,
                multiline: false,
                placeholder: "your title" 
            }}
                domain={Model.Persons.HasTitle.Descriptor.getDomain()}
                value={hasTitle.value} 
                client={hasTitle.client}
            />
        },
        tell: (language) => <span>Your title is {hasTitle.value}</span>,
        post: (language) => [ "This will be visible on your profile to other users."]
    }, {
        key: 'creator-bio',
        pre: (language) => [ "What else would you like others to know about you?" ],
        ask: (language, focus,) => {
            return <MaterialUi.ViewScalar.AsInput inputProps={{ 
                id: "creator-bio-input",
                type: "text",
                autoFocus: focus,
                multiline: true,
                placeholder: "your bio" 
            }}
                domain={Model.Persons.HasAbout.Descriptor.getDomain()}
                value={hasAbout.value} 
                client={hasAbout.client}
            />
        },
        tell: (language) => <span>Your bio: {hasAbout.value}</span>,
        post: (language) => [ 
            "This is a good place to talk about your qualifications, work history, credentials, etc.",
            "This will be visible on your profile to other users."
        ]
    }, {
        key: 'creator-home',
        pre: (language) => [ "Where are you located?" ],
        ask: (language, focus,) => {
            return <MaterialUi.ViewLogistics.AsLocationInput 
                domain={Model.Persons.HasHomeLocation.Descriptor.getDomain()}
                value={hasHomeLocation.value} 
                client={hasHomeLocation.client}
                inputProps={{ placeholder: 'home city' }}
            />
        },
        tell: (language) => <span>Your home location: {hasHomeLocation.value?.text}</span>,
        post: (language) => [ 
            "If you are looking for on-site projects, it is important to provide this information so you will be matched with projects in your home location.",
            "Please do not provide a physical or mailing address - only the name of your city (and state/province/country as applicable)",
            "You can return to this page and change your location to match your travel plans.",
            "This will be visible on your profile to other users."
        ]
    },{
        key: 'creator-socials',
        pre: (language) => [ "Which social media accounts do you use?" ],
        ask: (language, focus,) => {
            return <MaterialUi.ViewSocialMedia.AsTable
                prefix="creator"
                serviceNames={[
                    Domains.SocialMedia.ServiceNames.FACEBOOK,
                    Domains.SocialMedia.ServiceNames.INSTAGRAM,
                    Domains.SocialMedia.ServiceNames.LINKEDIN,
                    Domains.SocialMedia.ServiceNames.TIKTOK,
                ]}
                style={{ width: "80% "}} 
                values={hasSocialMedia.values} 
                client={hasSocialMedia.client}
            />
        },
        tell: (language) => {
            return hasSocialMedia.values.size === 0
            ? <span>You are not using social media handles</span>
            : <span>Your social media handles are: {Array.from(hasSocialMedia.values).map(item => item.text).join(', ')}</span>
        },
        post: (language) => [ "Social media stats (e.g. follower counts) are visible to other users in your brand profile" ]
    }, {
        key: 'creator-brands',
        pre: (language) => [ "For which brands have you done paid work?" ],
        ask: (language, focus,) => {
            return <MaterialUi.ViewSet.AsAutocomplete
                id="creator-brands-input"
                domain={Model.Products.Brands.Tags.Domain}
                values={hasPastBrandAssociations.values} 
                client={hasPastBrandAssociations.client}
            />
        },
        tell: (language) => {
            return hasPastBrandAssociations.values.size === 0 
            ? <span>You have not worked with brands in the past</span>
            : <span>You have done (or are doing) paid work for the following brands: {Array.from(hasPastBrandAssociations.values).join(', ')}</span>;
        },
        post: (language) => [ 
            "You're allowed to list past, recent, or currently booked work.",
            "You may write in your own brand names/tags.",
            "This information will be searchable and visible on your profile."
        ]
    }, {
        key: 'creator-skills',
        pre: (language) => [ "What skills do you offer?" ],
        ask: (language, focus,) => {
            return <MaterialUi.ViewSet.AsAutocomplete
                id="creator-skills-input"
                domain={Model.Talents.Tags.Domain}
                values={hasSkills.values} 
                client={hasSkills.client}
            />
        },
        tell: (language) => {
            return hasSkills.values.size === 0
            ? <span>You do not have any skills to offer</span>
            : <span>Your skills include: {Array.from(hasSkills.values).join(', ')}</span>;
        },
        post: (language) => [ "Your skills set will be visible to others on your profile" ]
    }, {
        key: 'creator-products',
        pre: (language) => [ "What types of products do you work with?" ],
        ask: (language, focus,) => {
            return <MaterialUi.ViewSet.AsAutocomplete
                id="creator-products-input"
                domain={Model.Products.Categories.Tags.Domain}
                values={offersProductCategories.values} 
                client={offersProductCategories.client}
            />
        },
        tell: (language) => {
            return offersProductCategories.values.size === 0
            ? <span>You do not work with any specific product categories</span>
            : <span>You work with these product categories: {Array.from(offersProductCategories.values).join(', ')}</span>;
        },
        post: (language) => [ "Product categories will be visible to others on your profile" ]
    }, {
        key: 'creator-audiences',
        pre: (language) => [ "What kind of audiences are you targeting?" ],
        ask: (language, focus,) => {
            return <MaterialUi.ViewSet.AsAutocomplete
                id="creator-audiencees-input"
                domain={Model.Creator.AudienceTargeting.Tags.Domain}
                values={offersAudienceTargeting.values} 
                client={offersAudienceTargeting.client}
            />
        },
        tell: (language) => {
            return offersAudienceTargeting.values.size === 0 
            ? <span>You have no particular audience focus</span>
            : <span>Your audience focus is: {Array.from(offersAudienceTargeting.values).join(', ')}</span>;
        },
        post: (language) => [ 
            "Demographic, psychographic, and behavior categories are encouraged here.",
            "You may select more than one target group to describe your audience, but be careful not to over-represent your presence.",
            "You may write in your own group names/tags here.",
            "This information will be searchable and visible on your profile."
        ]
    }, {
        key: 'creator-rate',
        pre: (language) => [ "Do you offer a blended rate?" ],
        ask: (language, focus,) => {
            return <MaterialUi.ViewFinancial.AsPayRange
                value={offersBlendedRate.value} 
                client={offersBlendedRate.client}
            />
        },
        tell: (language) => {
            const asString = Domains.Financial.PayRangeDomain.asString();
            if(offersBlendedRate.value === undefined)
                return <span>You do not offer a blended rate</span>
            else
                return <span>You offer the following blended rate: {asString.to(offersBlendedRate.value)}</span>                
        },
        post: (language) => [ "A blended rate means that you accept a single pay rate across all skills or services that you provide" ]
    }];

    return (
        <Interview.Wizard language={props.language} questions={questions} allow={{ view: access.view, edit: access.edit }} label={{
            checkingAuth: () => <span>Confirming your authorization to edit this profile <Dot>.</Dot><Dot>.</Dot><Dot>.</Dot></span>,
            failedAuth: () => <span>You don't have authorization to edit this profile.</span>,
            cancel: () => <span>Discard Changes</span>,
            confirm: () => <span>Save Changes</span>
        }} on={{ 
            cancel: () => { navigation.backward(); return Promise.resolve() },
            commit: () => {
                return zone.commitAll().then(() => {
                    navigation.forward(AsAfter.asPath().to(props.binding))
                })                    
            },
        }} theme={{ card: Themes.Interview, buttons: Themes.Gold }}/>
    )
}

export const AsWizard = new Views.Factory<ViewPerson.Binding, Model.Authority.Tags.ValueType>(['persons', 'onboarding', 'creator'], ['person'], ((binding, factory) => new class implements Views.OfGoals.Plugin {
    key(separator: string) { return factory.asPath().key(separator) }
    route() { return factory.asPath().to(binding) }    
    controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin, options?: { ref?: React.RefObject<HTMLButtonElement> }) => JSX.Element) { return <React.Fragment></React.Fragment> }
    icon(): JSX.Element { return <NounProject.Talent/> }
    image(pixelDimensions: { width: number, height: number }, imageProps: React.CSSProperties) { return undefined; }
    label(truncate?: { maxChars?: number }): JSX.Element { return  (<span>Revise</span>) }
    summary(options: { onClick: (editMode: boolean) => void, zeroPlaceholder?: () => JSX.Element }): JSX.Element { return  (<span>About My Work</span>) }
    fields(): Views.OfFields.Plugin[] { return [{
        render: () => 
          <Database.AsEditor peerId="ViewPerson.AsWizard" pollInterval={{ ms: 15000 }} render={zone => <_Wizard language="en_US" binding={binding}/>}/>
    }] }
}), Modes.AnyMode);
Views.All.push(AsWizard);

function _After(props: {
    language: string,
    binding: ViewPerson.Binding,
}) {
    const navigation = React.useContext(Navigation.Context);
    const { zone } = React.useContext(Database.Context);
    if(zone === undefined) throw new Error('Missing Context');

    const { hasTitle, hasAbout, hasHomeLocation, 
        hasSocialMedia, hasPastBrandAssociations, hasSkills, 
        offersProductCategories, offersAudienceTargeting, offersBlendedRate } = useData({ zone, binding: props.binding });

    const errors = {
        missingTitle: !hasTitle.value,
        missingAbout: !hasAbout.value,
        missingSkills: hasSkills.values.size === 0
    };
    if(errors.missingTitle || errors.missingAbout || errors.missingSkills) {
        return <Interview.Waypoint buttonStyle={{ width: '100%' }} progress={{
            key: "after-creator-fail",
            status: () => <span>Creator Interview is Incomplete</span>,
            details: () => [
                <Styler.Body style={{ display: errors.missingTitle ? undefined : 'none' }}>You did not state your professional title.</Styler.Body>,
                <Styler.Body style={{ display: errors.missingAbout ? undefined : 'none' }}>You did not fill out your bio.</Styler.Body>,
                <Styler.Body style={{ display: errors.missingSkills ? undefined : 'none' }}>You did not provide offer least one creator skill.</Styler.Body>,
                <Styler.Body>Your "About My Work" interview is incomplete, so your account cannot be admitted to the app.</Styler.Body>,
                <Styler.Body>Please click on the "Back to My Work" button to go back and complete any missing sections.</Styler.Body>,
            ],
            options: [
                {
                    class: Views.OfControls.Direction.Forward,
                    id: 'return-creator',
                    label: () => <span>Back to About My Work</span>,
                    icon: ({mode}) => <HeroIcons.ArrowCircle variant='outline' direction='left'/>,
                    onClick: () => {
                        navigation.forward(AsWizard.asPath().to(props.binding))
                        return Promise.resolve();
                    }
                }
            ]
        }}/>
    } else {
        return <Interview.Waypoint buttonStyle={{ width: '100%' }} progress={{
            key: "after-creator-pass",
            status: () => <span>"About My Work" Complete</span>,
            details: () => [
                <Styler.Body>You have completed the "About My Work" interview. All of your answers have been saved securely to the cloud.</Styler.Body>,
                <Styler.Body>Your profile is now ready for review. Swivell admins will provide feedback or approval typically within 24 hours.</Styler.Body>,
            ],
            options: [
                {
                    class: Views.OfControls.Direction.Forward,
                    id: 'next',
                    label: () => <span>To My Profile</span>,
                    icon: ({mode}) => <HeroIcons.ArrowCircle variant='outline' direction='right'/>,
                    onClick: () => {
                        navigation.forward('/profile');
                        return Promise.resolve();
                    }
                }
            ]
        }}/>        
    }
}
export const AsAfter = new Views.Factory<ViewPerson.Binding, Model.Authority.Tags.ValueType>(['persons', 'after', 'creator'], ['person'], ((binding, factory) => new class implements Views.OfGoals.Plugin {
    key(separator: string) { return factory.asPath().key(separator) }
    route() { return factory.asPath().to(binding) }    
    controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin, options?: { ref?: React.RefObject<HTMLButtonElement> }) => JSX.Element) { return <React.Fragment></React.Fragment> }
    icon(): JSX.Element { return <NounProject.Talent/> }
    image(pixelDimensions: { width: number, height: number }, imageProps: React.CSSProperties) { return undefined; }
    label(truncate?: { maxChars?: number }): JSX.Element { return  (<span>Revise</span>) }
    summary(options: { onClick: (editMode: boolean) => void, zeroPlaceholder?: () => JSX.Element }): JSX.Element { return  (<span>About My Work</span>) }
    fields(): Views.OfFields.Plugin[] { return [{
        render: () => 
          <Database.AsEditor peerId="ViewCreatorOnboarding.AsAfter" pollInterval={{ ms: 15000 }} render={zone => <_After language="en_US" binding={binding}/>}/>
    }] }
}), Modes.AnyMode);
Views.All.push(AsAfter);