import React from 'react';
import { useTheme, SvgIcon, SvgIconProps } from '@material-ui/core';

export function ArrowCircle(props: SvgIconProps & {
    direction: 'left'|'right',
    variant: 'solid'|'outline'
}) {
    return props.variant === 'solid' ? (
        <SvgIcon {...props}>
            {
                props.direction === 'left' ?
                <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2.25C6.61522 2.25 2.25 6.61522 2.25 12C2.25 17.3848 6.61522 21.75 12 21.75C17.3848 21.75 21.75 17.3848 21.75 12C21.75 6.61522 17.3848 2.25 12 2.25ZM7.71967 11.4697C7.57902 11.6103 7.5 11.8011 7.5 12C7.5 12.1989 7.57902 12.3897 7.71967 12.5303L10.7197 15.5303C11.0126 15.8232 11.4874 15.8232 11.7803 15.5303C12.0732 15.2374 12.0732 14.7626 11.7803 14.4697L10.0607 12.75H15.75C16.1642 12.75 16.5 12.4142 16.5 12C16.5 11.5858 16.1642 11.25 15.75 11.25L10.0607 11.25L11.7803 9.53033C12.0732 9.23744 12.0732 8.76256 11.7803 8.46967C11.4874 8.17678 11.0126 8.17678 10.7197 8.46967L7.71967 11.4697Z"/>
                </svg> : 
                <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2.25C6.61522 2.25 2.25 6.61522 2.25 12C2.25 17.3848 6.61522 21.75 12 21.75C17.3848 21.75 21.75 17.3848 21.75 12C21.75 6.61522 17.3848 2.25 12 2.25ZM16.2803 12.5303C16.421 12.3897 16.5 12.1989 16.5 12C16.5 11.8011 16.421 11.6103 16.2803 11.4697L13.2803 8.46967C12.9874 8.17678 12.5126 8.17678 12.2197 8.46967C11.9268 8.76256 11.9268 9.23744 12.2197 9.53033L13.9393 11.25L8.25 11.25C7.83579 11.25 7.5 11.5858 7.5 12C7.5 12.4142 7.83579 12.75 8.25 12.75L13.9393 12.75L12.2197 14.4697C11.9268 14.7626 11.9268 15.2374 12.2197 15.5303C12.5126 15.8232 12.9874 15.8232 13.2803 15.5303L16.2803 12.5303Z"/>
                </svg>

            }
        </SvgIcon>
    ) : <SvgIcon {...props}>
        {
            props.direction === 'left' ?
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.25 9L8.25 12M8.25 12L11.25 15M8.25 12H15.75M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke-linecap="round" stroke-linejoin="round"/>
            </svg> :
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.75 15L15.75 12M15.75 12L12.75 9M15.75 12L8.25 12M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
    }
    </SvgIcon>
    ;
}

export function ChatBubbleOvalLeftEllipsis(props: SvgIconProps & {
    variant: 'solid'|'outline'
}) {
    return props.variant === 'solid' ? (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.80365 21.6442C4.9793 21.6757 5.15732 21.7003 5.33691 21.7178C5.55516 21.7391 5.77647 21.75 6 21.75C7.3153 21.75 8.54447 21.3731 9.58317 20.7213C10.3569 20.9034 11.1668 21 12 21C17.322 21 21.75 17.0307 21.75 12C21.75 6.96934 17.322 3 12 3C6.67799 3 2.25 6.96934 2.25 12C2.25 14.4086 3.2746 16.5871 4.92371 18.1923C5.15571 18.4182 5.20107 18.6196 5.17822 18.7349C5.05254 19.3685 4.76687 19.9451 4.36357 20.4211C4.19016 20.6258 4.13927 20.9075 4.23008 21.1599C4.3209 21.4123 4.5396 21.597 4.80365 21.6442ZM8.25 10.875C7.62868 10.875 7.125 11.3787 7.125 12C7.125 12.6213 7.62868 13.125 8.25 13.125C8.87132 13.125 9.375 12.6213 9.375 12C9.375 11.3787 8.87132 10.875 8.25 10.875ZM10.875 12C10.875 11.3787 11.3787 10.875 12 10.875C12.6213 10.875 13.125 11.3787 13.125 12C13.125 12.6213 12.6213 13.125 12 13.125C11.3787 13.125 10.875 12.6213 10.875 12ZM15.75 10.875C15.1287 10.875 14.625 11.3787 14.625 12C14.625 12.6213 15.1287 13.125 15.75 13.125C16.3713 13.125 16.875 12.6213 16.875 12C16.875 11.3787 16.3713 10.875 15.75 10.875Z"/>
            </svg>
        </SvgIcon>
    ) : <SvgIcon {...props}>
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.875 12C8.875 12.2071 8.70711 12.375 8.5 12.375C8.29289 12.375 8.125 12.2071 8.125 12C8.125 11.7929 8.29289 11.625 8.5 11.625C8.70711 11.625 8.875 11.7929 8.875 12ZM8.875 12H8.5M12.625 12C12.625 12.2071 12.4571 12.375 12.25 12.375C12.0429 12.375 11.875 12.2071 11.875 12C11.875 11.7929 12.0429 11.625 12.25 11.625C12.4571 11.625 12.625 11.7929 12.625 12ZM12.625 12H12.25M16.375 12C16.375 12.2071 16.2071 12.375 16 12.375C15.7929 12.375 15.625 12.2071 15.625 12C15.625 11.7929 15.7929 11.625 16 11.625C16.2071 11.625 16.375 11.7929 16.375 12ZM16.375 12H16M21.25 12C21.25 16.5563 17.2206 20.25 12.25 20.25C11.3625 20.25 10.5051 20.1323 9.69517 19.9129C8.72016 20.5979 7.53201 21 6.25 21C6.05078 21 5.85376 20.9903 5.65967 20.9713C5.5 20.9558 5.3418 20.9339 5.18579 20.906C5.66932 20.3353 6.01277 19.6427 6.16389 18.8808C6.25454 18.4238 6.0307 17.9799 5.69684 17.6549C4.1797 16.1782 3.25 14.1886 3.25 12C3.25 7.44365 7.27944 3.75 12.25 3.75C17.2206 3.75 21.25 7.44365 21.25 12Z" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    </SvgIcon>
    ;
}

export function CheckCircle(props: SvgIconProps & {
    variant: 'solid'|'outline'
}) {
    return props.variant === 'solid' ? (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.25 12C2.25 6.61522 6.61522 2.25 12 2.25C17.3848 2.25 21.75 6.61522 21.75 12C21.75 17.3848 17.3848 21.75 12 21.75C6.61522 21.75 2.25 17.3848 2.25 12ZM15.6103 10.1859C15.8511 9.84887 15.773 9.38046 15.4359 9.1397C15.0989 8.89894 14.6305 8.97701 14.3897 9.31407L11.1543 13.8436L9.53033 12.2197C9.23744 11.9268 8.76256 11.9268 8.46967 12.2197C8.17678 12.5126 8.17678 12.9874 8.46967 13.2803L10.7197 15.5303C10.8756 15.6862 11.0921 15.7656 11.3119 15.7474C11.5316 15.7293 11.7322 15.6153 11.8603 15.4359L15.6103 10.1859Z"/>
            </svg>
        </SvgIcon>
    ) : (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 12.75L11.25 15L15 9.75M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </SvgIcon>
    );
}

export function Clipboard(props: SvgIconProps & {
    variant: 'solid'|'outline'
}) {
    return props.variant === 'solid' ? (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 3C9.81411 3 9.23394 3.46099 9.0561 4.09149C9.01971 4.2205 9 4.35733 9 4.5H15C15 4.35733 14.9803 4.2205 14.9439 4.09149C14.7661 3.46099 14.1859 3 13.5 3H10.5ZM7.80654 3.17789C8.29511 2.18436 9.31692 1.5 10.5 1.5H13.5C14.6831 1.5 15.7049 2.18436 16.1935 3.17789C16.6911 3.22029 17.1865 3.27017 17.6798 3.32741C19.1772 3.50119 20.25 4.78722 20.25 6.25699V19.5C20.25 21.1569 18.9069 22.5 17.25 22.5H6.75C5.09315 22.5 3.75 21.1569 3.75 19.5V6.25699C3.75 4.78722 4.82283 3.50119 6.32022 3.32741C6.81347 3.27017 7.30894 3.22029 7.80654 3.17789Z"/>
            </svg>
        </SvgIcon>
    ): (
        <SvgIcon {...props}>
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.4157 3.88789C16.1491 2.94272 15.2805 2.25 14.25 2.25H11.25C10.2195 2.25 9.35087 2.94272 9.08426 3.88789M16.4157 3.88789C16.4706 4.0825 16.5 4.28782 16.5 4.5V4.5C16.5 4.91421 16.1642 5.25 15.75 5.25H9.75C9.33579 5.25 9 4.91421 9 4.5V4.5C9 4.28782 9.02937 4.0825 9.08426 3.88789M16.4157 3.88789C17.0619 3.93668 17.7045 3.99828 18.3433 4.07241C19.4439 4.20014 20.25 5.149 20.25 6.25699V19.5C20.25 20.7426 19.2426 21.75 18 21.75H7.5C6.25736 21.75 5.25 20.7426 5.25 19.5V6.25699C5.25 5.149 6.05608 4.20014 7.15668 4.07241C7.79547 3.99828 8.43808 3.93668 9.08426 3.88789" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </SvgIcon>
    );
}

export function GlobeAlt(props: SvgIconProps & {
    variant: 'solid'|'outline'
}) {
    return props.variant === 'solid' ? (
        <SvgIcon {...props}>
            <svg width="25" height="24" viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.4714 12.7518C22.4904 12.5037 22.5 12.253 22.5 12C22.5 10.4758 22.1503 9.03335 21.5267 7.74843C20.2896 8.92277 18.8171 9.85153 17.1874 10.4566C17.2289 10.9656 17.25 11.4803 17.25 12C17.25 13.1012 17.1551 14.1802 16.9729 15.2293C18.9663 14.7278 20.8217 13.8793 22.4714 12.7518Z"/>
            <path d="M15.3843 15.5501C15.624 14.4044 15.75 13.2169 15.75 12C15.75 11.6316 15.7385 11.2659 15.7157 10.9033C14.7641 11.13 13.771 11.25 12.75 11.25C11.729 11.25 10.7359 11.13 9.7843 10.9033C9.76155 11.2659 9.75 11.6316 9.75 12C9.75 13.2169 9.87601 14.4044 10.1157 15.5501C10.9746 15.6817 11.8543 15.75 12.75 15.75C13.6457 15.75 14.5254 15.6817 15.3843 15.5501Z"/>
            <path d="M10.5222 17.119C11.2528 17.2055 11.9962 17.25 12.75 17.25C13.5038 17.25 14.2472 17.2055 14.9778 17.119C14.464 18.7747 13.7075 20.3236 12.75 21.7241C11.7925 20.3236 11.036 18.7747 10.5222 17.119Z"/>
            <path d="M8.52705 15.2293C8.34493 14.1802 8.25 13.1012 8.25 12C8.25 11.4803 8.27114 10.9656 8.31261 10.4566C6.68286 9.85153 5.21039 8.92277 3.97333 7.74842C3.34973 9.03335 3 10.4758 3 12C3 12.253 3.00964 12.5037 3.02856 12.7518C4.67826 13.8793 6.53374 14.7278 8.52705 15.2293Z"/>
            <path d="M22.1061 14.7525C21.0904 18.2105 18.2097 20.8706 14.6276 21.5694C15.494 20.1124 16.1685 18.5279 16.6164 16.8509C18.5763 16.4401 20.4236 15.7232 22.1061 14.7525Z"/>
            <path d="M3.39395 14.7525C5.07642 15.7232 6.92372 16.4401 8.88356 16.8509C9.33146 18.5279 10.006 20.1124 10.8724 21.5694C7.29027 20.8706 4.40964 18.2105 3.39395 14.7525Z"/>
            <path d="M14.6276 2.43063C17.1491 2.92253 19.3231 4.38628 20.7437 6.41607C19.6851 7.48492 18.4137 8.34259 16.9983 8.92024C16.612 6.5829 15.7935 4.3914 14.6276 2.43063Z"/>
            <path d="M12.75 2.27596C14.1787 4.36555 15.1597 6.78545 15.555 9.39752C14.6583 9.62764 13.7184 9.75001 12.75 9.75001C11.7816 9.75001 10.8417 9.62764 9.94503 9.39752C10.3403 6.78545 11.3213 4.36555 12.75 2.27596Z"/>
            <path d="M10.8724 2.43063C9.70648 4.3914 8.88795 6.5829 8.50171 8.92024C7.08629 8.34259 5.81489 7.48492 4.75635 6.41607C6.17689 4.38628 8.35085 2.92252 10.8724 2.43063Z"/>
            </svg>
        </SvgIcon>
    ) : (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 21C16.1926 21 19.7156 18.1332 20.7157 14.2529M12 21C7.80742 21 4.28442 18.1332 3.2843 14.2529M12 21C14.4853 21 16.5 16.9706 16.5 12C16.5 7.02944 14.4853 3 12 3M12 21C9.51472 21 7.5 16.9706 7.5 12C7.5 7.02944 9.51472 3 12 3M12 3C15.3652 3 18.299 4.84694 19.8431 7.58245M12 3C8.63481 3 5.70099 4.84694 4.15692 7.58245M19.8431 7.58245C17.7397 9.40039 14.9983 10.5 12 10.5C9.00172 10.5 6.26027 9.40039 4.15692 7.58245M19.8431 7.58245C20.5797 8.88743 21 10.3946 21 12C21 12.778 20.9013 13.5329 20.7157 14.2529M20.7157 14.2529C18.1334 15.6847 15.1619 16.5 12 16.5C8.8381 16.5 5.86662 15.6847 3.2843 14.2529M3.2843 14.2529C3.09871 13.5329 3 12.778 3 12C3 10.3946 3.42032 8.88743 4.15692 7.58245" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </SvgIcon>
    );
}

export function Identification(props: SvgIconProps & {
    variant: 'solid'|'outline'
}) {
    return props.variant === 'solid' ? (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.5 3.75C2.84315 3.75 1.5 5.09315 1.5 6.75V17.25C1.5 18.9069 2.84315 20.25 4.5 20.25H19.5C21.1569 20.25 22.5 18.9069 22.5 17.25V6.75C22.5 5.09315 21.1569 3.75 19.5 3.75H4.5ZM8.625 6.75C7.38236 6.75 6.375 7.75736 6.375 9C6.375 10.2426 7.38236 11.25 8.625 11.25C9.86764 11.25 10.875 10.2426 10.875 9C10.875 7.75736 9.86764 6.75 8.625 6.75ZM4.75191 15.4528C5.3309 13.8765 6.84542 12.75 8.62496 12.75C10.4045 12.75 11.919 13.8765 12.498 15.4528C12.6271 15.8043 12.4771 16.1972 12.1466 16.3733C11.0958 16.9331 9.89627 17.25 8.62496 17.25C7.35364 17.25 6.15413 16.9331 5.10331 16.3733C4.77278 16.1972 4.62279 15.8043 4.75191 15.4528ZM15 8.25C14.5858 8.25 14.25 8.58579 14.25 9C14.25 9.41421 14.5858 9.75 15 9.75H18.75C19.1642 9.75 19.5 9.41421 19.5 9C19.5 8.58579 19.1642 8.25 18.75 8.25H15ZM14.25 12C14.25 11.5858 14.5858 11.25 15 11.25H18.75C19.1642 11.25 19.5 11.5858 19.5 12C19.5 12.4142 19.1642 12.75 18.75 12.75H15C14.5858 12.75 14.25 12.4142 14.25 12ZM15 14.25C14.5858 14.25 14.25 14.5858 14.25 15C14.25 15.4142 14.5858 15.75 15 15.75H18.75C19.1642 15.75 19.5 15.4142 19.5 15C19.5 14.5858 19.1642 14.25 18.75 14.25H15Z" />
            </svg>
        </SvgIcon>
    ) : (
        <SvgIcon {...props}>
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.25 9H19M15.25 12H19M15.25 15H19M4.75 19.5H19.75C20.9926 19.5 22 18.4926 22 17.25V6.75C22 5.50736 20.9926 4.5 19.75 4.5H4.75C3.50736 4.5 2.5 5.50736 2.5 6.75V17.25C2.5 18.4926 3.50736 19.5 4.75 19.5ZM10.75 9.375C10.75 10.4105 9.91053 11.25 8.875 11.25C7.83947 11.25 7 10.4105 7 9.375C7 8.33947 7.83947 7.5 8.875 7.5C9.91053 7.5 10.75 8.33947 10.75 9.375ZM12.0439 15.7114C11.0989 16.2147 10.0202 16.5 8.87484 16.5C7.72948 16.5 6.65074 16.2147 5.70581 15.7114C6.17986 14.4207 7.41983 13.5 8.87484 13.5C10.3299 13.5 11.5698 14.4207 12.0439 15.7114Z" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </SvgIcon>
    );
}

export function PlusCircle(props: SvgIconProps & {
    variant: 'solid'|'outline'
}) {
    return props.variant === 'solid' ? (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2.25C6.61522 2.25 2.25 6.61522 2.25 12C2.25 17.3848 6.61522 21.75 12 21.75C17.3848 21.75 21.75 17.3848 21.75 12C21.75 6.61522 17.3848 2.25 12 2.25ZM12.75 9C12.75 8.58579 12.4142 8.25 12 8.25C11.5858 8.25 11.25 8.58579 11.25 9V11.25H9C8.58579 11.25 8.25 11.5858 8.25 12C8.25 12.4142 8.58579 12.75 9 12.75H11.25V15C11.25 15.4142 11.5858 15.75 12 15.75C12.4142 15.75 12.75 15.4142 12.75 15V12.75H15C15.4142 12.75 15.75 12.4142 15.75 12C15.75 11.5858 15.4142 11.25 15 11.25H12.75V9Z"/>
            </svg>
        </SvgIcon>
    ): (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 9V15M15 12H9M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </SvgIcon>
    );
}

export function Photo(props: SvgIconProps & {
    variant: 'solid'|'outline'
}) {
    return props.variant === 'solid' ? (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 6C1.5 4.75736 2.50736 3.75 3.75 3.75H20.25C21.4926 3.75 22.5 4.75736 22.5 6V18C22.5 19.2426 21.4926 20.25 20.25 20.25H3.75C2.50736 20.25 1.5 19.2426 1.5 18V6ZM3 16.0607V18C3 18.4142 3.33579 18.75 3.75 18.75H20.25C20.6642 18.75 21 18.4142 21 18V16.0607L18.3107 13.3713C17.7249 12.7855 16.7751 12.7855 16.1893 13.3713L15.3107 14.25L16.2803 15.2197C16.5732 15.5126 16.5732 15.9874 16.2803 16.2803C15.9874 16.5732 15.5126 16.5732 15.2197 16.2803L10.0607 11.1213C9.47487 10.5355 8.52513 10.5355 7.93934 11.1213L3 16.0607ZM13.125 8.25C13.125 7.62868 13.6287 7.125 14.25 7.125C14.8713 7.125 15.375 7.62868 15.375 8.25C15.375 8.87132 14.8713 9.375 14.25 9.375C13.6287 9.375 13.125 8.87132 13.125 8.25Z" fill="currentColor"/>
            </svg>
        </SvgIcon>
    ): (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.25 15.75L7.40901 10.591C8.28769 9.71231 9.71231 9.71231 10.591 10.591L15.75 15.75M14.25 14.25L15.659 12.841C16.5377 11.9623 17.9623 11.9623 18.841 12.841L21.75 15.75M3.75 19.5H20.25C21.0784 19.5 21.75 18.8284 21.75 18V6C21.75 5.17157 21.0784 4.5 20.25 4.5H3.75C2.92157 4.5 2.25 5.17157 2.25 6V18C2.25 18.8284 2.92157 19.5 3.75 19.5ZM14.25 8.25H14.2575V8.2575H14.25V8.25ZM14.625 8.25C14.625 8.45711 14.4571 8.625 14.25 8.625C14.0429 8.625 13.875 8.45711 13.875 8.25C13.875 8.04289 14.0429 7.875 14.25 7.875C14.4571 7.875 14.625 8.04289 14.625 8.25Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </SvgIcon>
    );
}

export function Power(props: SvgIconProps & {
    variant: 'solid'|'outline'
}) {
    return props.variant === 'solid' ? (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2.25C12.4142 2.25 12.75 2.58579 12.75 3V12C12.75 12.4142 12.4142 12.75 12 12.75C11.5858 12.75 11.25 12.4142 11.25 12V3C11.25 2.58579 11.5858 2.25 12 2.25ZM6.16637 5.10571C6.45926 5.3986 6.45926 5.87348 6.16637 6.16637C2.94454 9.38819 2.94454 14.6118 6.16637 17.8336C9.38819 21.0555 14.6118 21.0555 17.8336 17.8336C21.0555 14.6118 21.0555 9.38819 17.8336 6.16637C17.5407 5.87348 17.5407 5.3986 17.8336 5.10571C18.1265 4.81282 18.6014 4.81282 18.8943 5.10571C22.7019 8.91332 22.7019 15.0867 18.8943 18.8943C15.0867 22.7019 8.91332 22.7019 5.10571 18.8943C1.2981 15.0867 1.2981 8.91332 5.10571 5.10571C5.3986 4.81282 5.87348 4.81282 6.16637 5.10571Z"/>
            </svg>
        </SvgIcon>
    ): (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.63604 5.63604C2.12132 9.15076 2.12132 14.8492 5.63604 18.364C9.15076 21.8787 14.8492 21.8787 18.364 18.364C21.8787 14.8492 21.8787 9.15076 18.364 5.63604M12 3V12" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </SvgIcon>
    );
}

export function XCircle(props: SvgIconProps & {
    variant: 'solid'|'outline'
}) {
    return props.variant === 'solid' ? (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2.25C6.61522 2.25 2.25 6.61522 2.25 12C2.25 17.3848 6.61522 21.75 12 21.75C17.3848 21.75 21.75 17.3848 21.75 12C21.75 6.61522 17.3848 2.25 12 2.25ZM10.2803 9.21967C9.98744 8.92678 9.51256 8.92678 9.21967 9.21967C8.92678 9.51256 8.92678 9.98744 9.21967 10.2803L10.9393 12L9.21967 13.7197C8.92678 14.0126 8.92678 14.4874 9.21967 14.7803C9.51256 15.0732 9.98744 15.0732 10.2803 14.7803L12 13.0607L13.7197 14.7803C14.0126 15.0732 14.4874 15.0732 14.7803 14.7803C15.0732 14.4874 15.0732 14.0126 14.7803 13.7197L13.0607 12L14.7803 10.2803C15.0732 9.98744 15.0732 9.51256 14.7803 9.21967C14.4874 8.92678 14.0126 8.92678 13.7197 9.21967L12 10.9393L10.2803 9.21967Z"/>
            </svg>
        </SvgIcon>
    ) : (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.75 9.75L14.25 14.25M14.25 9.75L9.75 14.25M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </SvgIcon>
    );
}
