import React from 'react';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import * as NounProject from '../../NounProject';

import * as XLayout from '@pitaman71/react-explicit-layout';
import { Objects } from '@pitaman71/omniglot-live-data';
import { Database, MaterialUi, Propose, Styler, Views } from '@pitaman71/omniglot-live-react';
import * as ViewPerson from './ViewPerson';
import * as ViewConnections from './ViewConnections';
import * as ViewDiscovery from './ViewDiscovery';

import * as Modes from '../../Modes';
import * as Whoami from '../../Whoami';

import * as Model from 'models/autosrc/swivell/talent_marketplace';

import * as SocialAccess from 'models/src/Social/Access';
import * as SocialController from 'models/src/Social/Controller';

interface Binding extends Objects.BindingType<string> { person: Objects.Binding<string> };

function _Membership(props: {
    language: string,
    binding: Binding
}) {
    const { zone } = React.useContext(Database.Context);
    if(!zone) throw new Error('Missing Context');
    const hasAuthority = Propose.useSetProperty(Model.Persons.HasAuthority.Descriptor.stream(zone, props.binding).set);
    const hasAdmission = Propose.useScalarProperty(Model.Agents.HasAdmission.Descriptor.stream(zone, props.binding).scalar);
    return <MaterialUi.ViewSet.AsCheckbox id="person-authority-checkboxes" {...hasAuthority} onClick={!hasAdmission.client ? undefined : (value, checked) => {
        if(value === 'admitted' && checked) {
            hasAdmission.client?.assign(value);
        }
        zone.commitAll();
     } }/>
}

export const AsMembership = new Views.Factory<Binding, Model.Authority.Tags.ValueType>(['persons', 'manage', 'membership'], ['person'], ((binding, factory) => new class implements Views.OfGoals.Plugin {
    key(separator: string) { return factory.asPath().key(separator) }
    route() { return factory.asPath().to(binding) }    
    controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin, options?: { ref?: React.RefObject<HTMLButtonElement> }) => JSX.Element) { return <React.Fragment></React.Fragment> }
    icon(): JSX.Element { return <MuiIcons.SettingsOutlined/> }
    image(pixelDimensions: { width: number, height: number }, imageProps: React.CSSProperties) { return undefined; }
    label(truncate?: { maxChars?: number }): JSX.Element { return  (<span>Manage</span>) }
    summary(options: { onClick: (editMode: boolean) => void, zeroPlaceholder?: () => JSX.Element }): JSX.Element { return  (<span>Manage Membership</span>) }
    fields(): Views.OfFields.Plugin[] { return [{
        render: () => 
          <Database.AsEditor peerId="Persons.Manage.Membership" pollInterval={{ ms: 15000 }} render={zone => <_Membership language="en_US" binding={binding}/>}/>
    }] }
}), Modes.AnyMode);
Views.All.push(AsMembership);


export const AsConnections = new Views.Factory<Binding, Model.Authority.Tags.ValueType>(['persons', 'manage', 'connections'], ['person'], ((binding, factory) => new class implements Views.OfGoals.Plugin {
    key(separator: string) { return factory.asPath().key(separator) }
    route() { return factory.asPath().to(binding) }    
    controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin, options?: { ref?: React.RefObject<HTMLButtonElement> }) => JSX.Element) { return <React.Fragment></React.Fragment> }
    icon(): JSX.Element { return <NounProject.Connections/> }
    image(pixelDimensions: { width: number, height: number }, imageProps: React.CSSProperties) { return undefined; }
    label(truncate?: { maxChars?: number }): JSX.Element { return  (<span>Manage</span>) }
    summary(options: { onClick: (editMode: boolean) => void, zeroPlaceholder?: () => JSX.Element }): JSX.Element { return  (<span>Manage Connections</span>) }
    fields(): Views.OfFields.Plugin[] { return [{
        render: () => 
          <Database.AsEditor peerId="Persons.Manage.Connections" pollInterval={{ ms: 15000 }} render={zone => <ViewConnections.AsTable language="en_US" binding={binding}/>}/>
    }] }
}), Modes.AnyMode);
Views.All.push(AsConnections);

export const AsDiscovery = new Views.Factory<Binding, Model.Authority.Tags.ValueType>(['persons', 'manage', 'discovery'], ['person'], ((binding, factory) => new class implements Views.OfGoals.Plugin {
    key(separator: string) { return factory.asPath().key(separator) }
    route() { return factory.asPath().to(binding) }    
    controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin, options?: { ref?: React.RefObject<HTMLButtonElement> }) => JSX.Element) { return <React.Fragment></React.Fragment> }
    icon(): JSX.Element { return <NounProject.Discovery/> }
    image(pixelDimensions: { width: number, height: number }, imageProps: React.CSSProperties) { return undefined; }
    label(truncate?: { maxChars?: number }): JSX.Element { return  (<span>Manage</span>) }
    summary(options: { onClick: (editMode: boolean) => void, zeroPlaceholder?: () => JSX.Element }): JSX.Element { return  (<span>Manage Discovery Feed</span>) }
    fields(): Views.OfFields.Plugin[] { return [{
        render: () => 
          <Database.AsEditor peerId="Persons.Manage.Discovery" pollInterval={{ ms: 15000 }} render={zone => <ViewDiscovery.AsTable language="en_US" binding={binding}/>}/>
    }] }
}), Modes.AnyMode);
Views.All.push(AsDiscovery);
