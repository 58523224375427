import { Definitions } from '@pitaman71/omniglot-live-data';

import * as HasPerson from './HasPerson';
import * as HasRole from './HasRole';
import * as HasScore from './HasScore';
import * as HasProject from './HasProject';
import * as Status from './Status';
import * as HasTalentStatus from './HasTalentStatus';
import * as HasBrandStatus from './HasBrandStatus';
import * as HasOfferStatus from './HasOfferStatus';
import * as HasOfferRate from './HasOfferRate';
const directory = Definitions.Directory.from(HasPerson.directory,HasRole.directory,HasScore.directory,HasProject.directory,Status.directory,HasTalentStatus.directory,HasBrandStatus.directory,HasOfferStatus.directory,HasOfferRate.directory)
export { directory, HasPerson, HasRole, HasScore, HasProject, Status, HasTalentStatus, HasBrandStatus, HasOfferStatus, HasOfferRate }; 
