import * as Elevated from '@pitaman71/omniglot-introspect';
import { Clients, Objects, Operators, Stores, Streams, Values } from '@pitaman71/omniglot-live-data';

import * as Model from '../../autosrc/swivell/talent_marketplace';

export const StatusDomain = new Values.TagDomain('connected', 'invited', 'inviting', 'rejected', 'rejecting', 'new');

export function hasStatus(
    before: Stores.Zone, 
    binding: { from: Objects.Binding<string>, to: Objects.Binding<string> }
): Streams.SetStream<string> {
    const result = Clients.SetClient.Eager<string>('hasStatus');

    const outStatus = Model.Social.HasInvitationStatus.Descriptor.eager(before, binding).scalar();
    const inStatus = Model.Social.HasInvitationStatus.Descriptor.eager(before, { from: binding.to, to: binding.from }).scalar();

    const onAnyUpdate = {
        next: () => {
            const values_: string[] = [];
            if(inStatus.value()?.rejectCount > 0) values_.push('rejected');
            if(outStatus.value()?.rejectCount > 0) values_.push('rejecting');
            if(inStatus.value()?.acceptCount > 0 && outStatus.value()?.acceptCount > 0)
                values_.push('connected');
            else if(inStatus.value()?.acceptCount > 0 && (!outStatus.value() || outStatus.value()?.acceptCount === 0))
                values_.push('invited');
            else if((!inStatus.value() || inStatus.value()?.acceptCount === 0) && outStatus.value()?.acceptCount > 0)
                values_.push('inviting');
            else
                values_.push('new');
            result.set(new Set(values_));
        },
        error: (err) => { console.error(err) },
        complete: () => {}
    };
    onAnyUpdate.next();

    outStatus.watch(onAnyUpdate);
    inStatus.watch(onAnyUpdate)

    return result.stream(StatusDomain);
}

export function allowInvite(
    before: Stores.Zone, 
    binding: { me: Objects.Binding<string>, from: Objects.Binding<string>, to: Objects.Binding<string> }
): Streams.ScalarStream<boolean> {
    const isSelf = Objects.Binding.cmp(binding.me, binding.from) === 0;

    const meHasAuthority = Model.Persons.HasAuthority.Descriptor.stream(before, { person: binding.me });
    const isAdmin = Operators.ApplySet('isAdmin', Values.TheBooleanDomain, meHasAuthority_ => meHasAuthority_.has('admin'), meHasAuthority);
    return isAdmin.scalar;
}
