import React from 'react';
import { Dot } from 'react-animated-dots';
import useInterval from 'react-useinterval';

import * as HeroIcons from '../HeroIcons';

import { Objects, Stores, Streams } from '@pitaman71/omniglot-live-data';
import { Database, Interview, Navigation, Propose, Styler, Views } from '@pitaman71/omniglot-live-react';
import * as XLayout from '@pitaman71/react-explicit-layout';

import * as Logos from '../Logos';
import * as Modes from '../Modes';
import * as NounProject from '../NounProject';
import * as Whoami from '../Whoami';

import { Card as PersonCard } from '../Controls/Persons/ViewPreview';
import { Card as ProjectCard } from '../Controls/Projects/ViewPreview';
import * as ViewConnections from '../Controls/Discussion/ViewConnections';

import { AsDownloader } from '../Controls/Media';
import { Controller } from 'models/src/Social';

import * as Payments from '../Payments';
import * as Themes from '../Themes';

import * as Model from 'models/autosrc/swivell/talent_marketplace';

export let logEnable = false;

export let pollInterval = { ms: 30000 };

function _Filters(props: {    
    style?: React.CSSProperties
}) {
    return (<React.Fragment></React.Fragment>
/*         <Styler.Control prefix="preview-filters" theme={Themes.Light} style={props.style}>
            <XLayout.Stack.East>
                <Styler.Chip label={<XLayout.Stack.East style={{ alignItems: 'center '}}><Styler.Heading>All projects</Styler.Heading><HeroIconsOutlined.ChevronDown color="primary"/></XLayout.Stack.East>} style={{ margin: "0.5em" }}/>
                <Styler.Chip label={<XLayout.Stack.East style={{ alignItems: 'center '}}><Styler.Heading>All roles</Styler.Heading><HeroIconsOutlined.ChevronDown htmlColor="primary"/></XLayout.Stack.East>} style={{ margin: "0.5em" }}/>
            </XLayout.Stack.East>
        </Styler.Control>
 */    )
}

function _Select(props: {
    zone: Stores.Zone
}) {
    const whoami = React.useContext(Whoami.Context);
    const me = whoami.person;
    if(!me) throw new Error('Missing Context');

    const hasDiscoveryFeed = Propose.useSequenceProperty(Model.Persons.HasDiscoveryFeed.Descriptor.stream(props.zone, { person: me }).sequence);
    return <_Browse zone={props.zone} values={hasDiscoveryFeed.values} status={hasDiscoveryFeed.status}/>
}

function _Blur(props: React.PropsWithChildren<{
    index: number,
    viewport: React.RefObject<HTMLDivElement>
}>) {
    const [ blurPixels, setBlurPixels ] = React.useState(props.index < 3 ? 0.0 : 1.0);
    const [ pending, setPending ] = React.useState(true);

    const card = React.useRef<HTMLDivElement>(null);
    React.useEffect(() => {
        if(props.viewport.current && card.current) {
            const observer_ = (new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if(entry.intersectionRatio < 0.5) {
                        setBlurPixels(8 * (1 - entry.intersectionRatio/0.5));
                    } else {
                        setBlurPixels(0);
                    }
                })
            }, {
                root: props.viewport.current,
                rootMargin: "50%",
                threshold: [
                    0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1
                ],
            }));
            observer_.observe(card.current);
            return () => observer_.disconnect();
        }
    }, [ props.viewport.current, card.current ]);
    React.useEffect(() => {
        if(pending && blurPixels < 0.5) setPending(false);
    }, [ blurPixels ]);

    return (
        <React.Fragment>
            <div ref={card} style={{ 
                minHeight: '90%', 
                width: '100%',
                filter: `blur(${blurPixels}px)`,
                WebkitFilter: `blur(${blurPixels}px)`,
                scrollSnapAlign: 'start',
                scrollSnapStop: 'always'
            }}>
                { !pending || blurPixels < 0.5 ? props.children : <React.Fragment></React.Fragment> }
            </div>
        </React.Fragment>
    );
}

function _Person(props: {    
    binding: Model.Persons.HasDiscoveryFeed.TypeParams['Value'] & {
        person: Objects.Binding<string>
    },
    style?: React.CSSProperties
}) {
    const navigation = React.useContext(Navigation.Context);
    const whoami = React.useContext(Whoami.Context);
    const { zone } = React.useContext(Database.Context);
    const me = whoami?.person;
    if(!zone || !me) throw new Error('Missing Context');
    return (
        <XLayout.Stack.East {...props}>
            <Styler.Control prefix="discover-actions-pass" theme={Themes.Light} style={{ flex: '1' }}>
                <Styler.Button variant='outlined' endIcon={<HeroIcons.XCircle variant="solid" htmlColor="primary"/>} style={{ width: "100%" }} onClick={event => {
                    event.preventDefault();
                    navigation.forward(ViewConnections.AsRejectPerson.asPath().to({ from: me, person: props.binding.person }))
                }}>Pass</Styler.Button>
            </Styler.Control>
            <Styler.Control prefix="discover-actions-connect" theme={Themes.Light} style={{ flex: '1' }}>
                <Styler.Button variant='contained' endIcon={<HeroIcons.PlusCircle variant="solid" htmlColor="primary"/>} style={{ width: "100%" }} onClick={event => {
                    event.preventDefault();
                    navigation.forward(ViewConnections.AsConfirmPerson.asPath().to({ from: me, person: props.binding.person }))
                }}>Connect</Styler.Button>
            </Styler.Control>
        </XLayout.Stack.East>
    )
}

function _Project(props: {    
    binding: Model.Persons.HasDiscoveryFeed.TypeParams['Value'] & {
        project: Objects.Binding<string>
    },
    style?: React.CSSProperties
}) {
    const navigation = React.useContext(Navigation.Context);
    const whoami = React.useContext(Whoami.Context);
    const { zone } = React.useContext(Database.Context);
    const me = whoami?.person;
    if(!zone || !me) throw new Error('Missing Context');
    return (
        <XLayout.Stack.East {...props}>
            <Styler.Control prefix="discover-actions-pass" theme={Themes.Light} style={{ flex: '1' }}>
                <Styler.Button variant='outlined' endIcon={<HeroIcons.XCircle variant="solid" htmlColor="primary"/>} style={{ width: "100%" }} onClick={event => {
                    event.preventDefault();
                    navigation.forward(ViewConnections.AsRejectProject.asPath().to({ from: me, project: props.binding.project }))
                }}>Pass</Styler.Button>
            </Styler.Control>
            <Styler.Control prefix="discover-actions-connect" theme={Themes.Dark} style={{ flex: '1' }}>
                <Styler.Button variant='contained' endIcon={<HeroIcons.PlusCircle variant="solid" htmlColor="primary"/>} style={{ width: "100%" }} onClick={event => {
                    event.preventDefault();
                    navigation.forward(ViewConnections.AsConfirmProject.asPath().to({ from: me, project: props.binding.project }))
                }}>Connect</Styler.Button>
            </Styler.Control>
        </XLayout.Stack.East>
    )
}

function _Browse(props: {
    zone: Stores.Zone,
    values: Model.Persons.HasDiscoveryFeed.TypeParams['Value'][],
    status: Streams.Status
}) {
    const whoami = React.useContext(Whoami.Context);
    const viewport = React.useRef<HTMLDivElement>(null);
    if(!whoami.person) throw new Error('Missing Context');

    const hasAuthority = Propose.useSetProperty(props.zone.streams().property(Model.Persons.HasAuthority.Descriptor.bind({ person: whoami.person })).set);
    return <Database.AsEditor peerId="Discover.Browse" pollInterval={pollInterval} render={zone => (
        <Payments.CheckSubscription
            skip={ hasAuthority.values.has('admin') ||  hasAuthority.values.has('creator') }
            why={<span>"A subscription is required to discover connections"</span>}
        >
            <_Filters style={{ flex: "0 0 auto", width: '100%'}}/>
            <XLayout.Stack.South id="discover-browse" divRef={viewport} scroll={true} style={{ width: '100%', height: '100%', minHeight: '0', flex: '1 1 auto', scrollSnapType: 'y mandatory' }}>
                {
                    props.status.initializing === true 
                    ? <XLayout.Center.Both>
                        <Styler.Heading>Loading your Discovery Feed <Dot>.</Dot><Dot>.</Dot><Dot>.</Dot></Styler.Heading>
                    </XLayout.Center.Both>
                    : props.values.length === 0 
                    ? <XLayout.Center.Both>
                        <XLayout.Stack.South style={{ flex: '1', maxHeight: '100%' }}>
                            <AsDownloader
                                pixelDimensions={{ height: 150, width: 200 }}
                                style={{ flex: '1', minHeight: '0', height: 'auto', width: 'auto' }}
                                value={{
                                    pixelDimensions: { width: 1545, height: 2000 },
                                    mime: 'image/jpeg',
                                    uri: '/images/lobby-background.jpeg'
                                }}
                            />
                            <Styler.Heading style={{ flex: "0 0 auto" }}>Your discovery feed is empty</Styler.Heading>
                            <Styler.Body style={{ flex: "0 0 auto" }}>Come back tomorrow for more fresh talent and projects</Styler.Body>
                        </XLayout.Stack.South>
                    </XLayout.Center.Both>
                    : props.values.map((value, index) => (
                            !!value.person ? 
                                <_Blur index={index} viewport={viewport}><PersonCard prefix='discover-browse-person' binding={{ person: value.person }} zone={props.zone}><_Person binding={{ person: value.person }}/></PersonCard></_Blur>
                            : !!value.project ?
                                <_Blur index={index} viewport={viewport}><ProjectCard prefix='discover-browse-project' binding={{ project: value.project }} zone={props.zone}><_Project binding={{ project: value.project }}/></ProjectCard></_Blur>
                            : <React.Fragment></React.Fragment>
                    ))
                }
            </XLayout.Stack.South>
        </Payments.CheckSubscription>
    )}/>;
}

function _Reload(props: {
    zone: Stores.Zone,
    me: Objects.Binding<string>
}) {
    const [ controller, ]= React.useState(() => {
        const controller_ = Controller.Suggest(props.zone, { me: props.me, from: props.me })
        controller_.reset();
        return controller_;
    });
    useInterval(() => {
        props.zone.commitAll();
    }, 4000);
    return (
        <Styler.Heading>Finding matches and content <Dot>.</Dot><Dot>.</Dot><Dot>.</Dot></Styler.Heading>
    );
}

function _Suggest(props: {
    zone: Stores.Zone,
    me: Objects.Binding<string>
}) {
    return <Database.AsEditor peerId="Discover.AsTab" pollInterval={pollInterval} render={zone => (
        <_Reload {...props} zone={zone}/>
    )}/>;
}

export const AsTab = new Views.Factory([''], [], ((binding, factory) => new class implements Views.OfGoals.Plugin {
    key(separator: string) { return factory.asPath().key(separator) }
    route() { return factory.asPath().to(binding) }    
    controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin, options?: { ref?: React.RefObject<HTMLButtonElement> }) => JSX.Element) { 
        return <React.Fragment></React.Fragment>;
    }
    icon(props: { mode: Views.OfControls.Modes }): JSX.Element { return <HeroIcons.GlobeAlt variant={ props.mode === Views.OfControls.Modes.Selected ? "solid" : "outline" }/> }
    image(pixelDimensions: { width: number, height: number }, imageProps: React.CSSProperties) { return undefined; }
    label(truncate?: { maxChars?: number }): JSX.Element { return  (<span>DISCOVER</span>) }
    summary(options: { onClick: (editMode: boolean) => void, zeroPlaceholder?: () => JSX.Element }): JSX.Element { return  (<span>Discover talent, projects, events</span>) }
    fields(): Views.OfFields.Plugin[] { return [{
        render: () => {
            return <Database.AsViewer render={zone => <_Select zone={zone}/>}/>
        }
    }] }
}), Modes.NormalMode);

Views.All.push(AsTab);
