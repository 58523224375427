import faker from 'faker';
import { Dialogues, Definitions, Objects, Properties, Relations, Values } from '@pitaman71/omniglot-live-data';
import { Domains, Modules, WrapFaker } from '@pitaman71/omniglot-live-domains';
import * as Elevated from '@pitaman71/omniglot-introspect';

export const directory = new Definitions.Directory();
// tag Products.Brands.Tags

export type ValueType = string|'Nike'|'Louis Vuitton'|'Hermes'|'Gucci'|'Zalando'|'Adidas'|'Tiffany & Co.'|'Zara'|'H&M'|'Cartier'|'Lululemon'|'Moncler'|'Chanel'|'Rolex'|'Patek Philippe'|'Prada'|'Uniqlo'|'Chow Tai Fook'|'Swarovski'|'Burerry'|'Polo Ralph Lauren'|'Tom Ford'|'The North Face'|'Levis'|'Victorias Secret'|'Next'|'New Balance'|'Michael Kors'|'Skechers'|'TJ Maxx'|'ASOS'|'Under Armour'|'Coach'|'Nordstrom'|'C&A'|'Chopard'|'Dolce & Gabbana'|'Christian Louboutin'|'Omega'|'Foot Locker, Inc.'|'Ray Ban'|'Macys'|'Asics'|'Vera Wang'|'Dior'|'Puma'|'Steve Madden'|'Brunello Cucinelli'|'American Eagle Outfitters'|'Armani'|'Nine West'|'Fendi'|'Urban Outfitters'|'Salvatore Farragamo'|'Hugo Boss'|'Old Navy'|'IWC Schaffhausen'|'Primark'|'Max Mara'|'Manolo Blahnik'|'Audemars Piguet'|'Diesel'|'Calvin Klein'|'Net-a-Porter'|'Furla'|'GAP'|'Longines'|'Forever 21'|'Stuart Weitzman'|'Longchamp'|'Sisley'|'Lao Feng Xiang'|'TODs'|'Tissot'|'Tommy Hilfiger'|'Tory Burch'|'Lacoste'|'Topshop'|'G-star'|'Aldo'|'Oakley';
export const Domain = new Values.TagDomain('Nike','Louis Vuitton','Hermes','Gucci','Zalando','Adidas','Tiffany & Co.','Zara','H&M','Cartier','Lululemon','Moncler','Chanel','Rolex','Patek Philippe','Prada','Uniqlo','Chow Tai Fook','Swarovski','Burerry','Polo Ralph Lauren','Tom Ford','The North Face','Levis','Victorias Secret','Next','New Balance','Michael Kors','Skechers','TJ Maxx','ASOS','Under Armour','Coach','Nordstrom','C&A','Chopard','Dolce & Gabbana','Christian Louboutin','Omega','Foot Locker, Inc.','Ray Ban','Macys','Asics','Vera Wang','Dior','Puma','Steve Madden','Brunello Cucinelli','American Eagle Outfitters','Armani','Nine West','Fendi','Urban Outfitters','Salvatore Farragamo','Hugo Boss','Old Navy','IWC Schaffhausen','Primark','Max Mara','Manolo Blahnik','Audemars Piguet','Diesel','Calvin Klein','Net-a-Porter','Furla','GAP','Longines','Forever 21','Stuart Weitzman','Longchamp','Sisley','Lao Feng Xiang','TODs','Tissot','Tommy Hilfiger','Tory Burch','Lacoste','Topshop','G-star','Aldo','Oakley');
        
