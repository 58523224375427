import { Definitions } from '@pitaman71/omniglot-live-data';

import * as Occurs from './Occurs';
import * as ProximalTo from './ProximalTo';
import * as HasWhen from './HasWhen';
import * as HasDetails from './HasDetails';
import * as HasWhere from './HasWhere';
import * as TimeAndLocation from './TimeAndLocation';
const directory = Definitions.Directory.from(Occurs.directory,ProximalTo.directory,HasWhen.directory,HasDetails.directory,HasWhere.directory,TimeAndLocation.directory)
export { directory, Occurs, ProximalTo, HasWhen, HasDetails, HasWhere, TimeAndLocation }; 
