import faker from 'faker';
import React from 'react';

import { Button, Chip } from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';

import { VerticalTimeline }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import * as XLayout from '@pitaman71/react-explicit-layout';
import { Objects, Stores, Streams } from '@pitaman71/omniglot-live-data';
import { Database, MaterialUi, Navigation, Propose, Views } from '@pitaman71/omniglot-live-react';

import * as Model from 'models/autosrc/swivell/talent_marketplace';

import { ViewHasDetails } from '../../Controls/Events';
import { AsModifiers } from '../../Controls/ViewRelation';

import * as NounProject from '../../NounProject';

export let pollInterval = { ms: 30000};

const classes = {
    content: {
      display: 'flex',
      justifyContent: 'center',
      '& > *': {
        margin: "0.5em",
      },
    },
    root: {
      width: 500,
      '& > * + *': {
        marginTop: "3em",
      },
    },
    chip: {
      margin: "0.25em 0.5em 0.25em 0.5em",
    }
};
  
export function AsChips(props: {
  zone: Stores.Zone,
  hasAvailability: Streams.RelationStream<Model.Persons.HasAvailability.BindingType>,
  zeroPlaceholder?: () => JSX.Element
}): JSX.Element { 
  const navigation = React.useContext(Navigation.Context);
  const onClick = () => {
    const person = props.hasAvailability.anchor.binding.person;
    !!person && navigation.forward(BrowseEvents.asPath().to({person}))
  }
  return (
    <Propose.RelationEntries
        stream={props.hasAvailability}
        render={
          (entries, client) => <Propose.SortEntries unsorted={entries} client={client} 
            compute={(unsorted, updateSortKey ) => {
              return (<React.Fragment>
                {unsorted.map(entry => {
                  return <ViewHasDetails.AsSortable<Model.Persons.HasAvailability.BindingType> zone={props.zone} entry={entry} binding={{ event: entry.event }} updateSortKey={updateSortKey}/>
                })}
              </React.Fragment>);
            }}
            render={(sorted, getSortKey, client ) => {
              if(!!props.zeroPlaceholder && sorted.length == 0) return <div onClick={onClick}><Chip color="primary" style={classes.chip} icon={<NounProject.Talent/>} label={props.zeroPlaceholder()}/></div>;
              return (
                <div onClick={onClick}>{sorted.map(entry => {
                  return <div key={JSON.stringify(entry)}>
                    <ViewHasDetails.AsChip zone={props.zone} binding={{ event: entry.event }} />
                  </div>;
                })}</div>
              );
            }}
          />
        }
    />
  );
}

export function AsTimeline(props: {
  zone: Stores.Zone,
  binding: { person: Objects.Binding<string> }
}) {
  const navigation = React.useContext(Navigation.Context);
  const stream = props.zone.streams().relation(Model.Persons.HasAvailability.Descriptor.bindAnchor({ person: props.binding.person }));
  return (
    <React.Fragment>
    <XLayout.Stack.South scroll={true} style={{ height: '100%', width: '100%'}}>      
        <Propose.RelationEntries stream={stream} render={
          (entries, client) => <Propose.SortEntries unsorted={entries} client={client} 
            compute={(unsorted, updateSortKey ) => {
                return <React.Fragment>
                { unsorted.map(entry => (<ViewHasDetails.AsSortable<Model.Persons.HasAvailability.BindingType> zone={props.zone} entry={entry} binding={{ event: entry.event }} updateSortKey={updateSortKey}/>
                )) }
              </React.Fragment>;
            }}
            render={(sorted, getSortKey, client ) => {
                return sorted.length === 0 ? (
                  <XLayout.Center.Both>
                    <MaterialUi.Styler.Heading>This person has no availability times/locations defined yet</MaterialUi.Styler.Heading>
                  </XLayout.Center.Both>
                ) : ( <VerticalTimeline>
                { sorted.map(entry => (
                    <ViewHasDetails.AsTimelineElement 
                      key={JSON.stringify(entry)}
                      zone={props.zone} 
                      binding={{ event: entry.event }}
                      icon={<NounProject.Availability/>}
                      on={{ 
                        delete: () => navigation.forward(ModifyEvent.remove.asPath().to(entry)),
                        edit: () => navigation.forward(ModifyEvent.edit.asPath().to(entry))
                      }}
                    />
                )) }
              </VerticalTimeline> );
            }}
          />          
        }/>

    </XLayout.Stack.South>
    <XLayout.Partition top={0.85} bottom={0.95} left={0.2} right={0.8}>
      <Button variant="outlined" style={{ 
                                 backdropFilter: 'blur(8px)',
                                 WebkitBackdropFilter: 'blur(8px)',
                                 backgroundColor: 'rgba(45, 45, 45, 0.1)'
      }} startIcon={<MuiIcons.AddOutlined/>} onClick={() => navigation.forward(ModifyEvent.add.asPath().to({...props.binding, event:Objects.Binding.from_bound(faker.datatype.uuid()) }))}>Add Availability</Button>
    </XLayout.Partition>
    </React.Fragment>
  );
}

const BrowseEvents = new Views.Factory<{ person: Objects.Binding<string>}, Model.Authority.Tags.ValueType>([ 'persons', 'hasAvailability', 'browse'], ['person'], (binding, factory) => new class implements Views.OfGoals.Plugin {
  key(separator: string) { return factory.asPath().key(separator) }
  route() { return factory.asPath().to(binding) }    
  controls(filter: (control: Views.OfControls.Plugin) => boolean, render: (control: Views.OfControls.Plugin, options?: { ref?: React.RefObject<HTMLButtonElement> }) => JSX.Element) { return <React.Fragment></React.Fragment> }
  icon() { return <NounProject.Availability/> }
  image(pixelDimensions: { width: number, height: number }, imageProps: React.CSSProperties) {
      return undefined
  }
  label(truncate?: { maxChars?: number }) {
      return <span>Browse Availability</span>
  }
  summary(options: { onClick: (editMode:boolean) => void, zeroPlaceholder?: () => JSX.Element }) {
      return <div onClick={() => options.onClick(false)}>{this.label()}</div>
  }
  fields() {
    return [ { 
      render: () =>  (
        <Database.AsViewer render={(zone) => <AsTimeline zone={zone} binding={binding}/>}/>        
      )
    } ];
  }
});

const AsInspector = (zone:Stores.Zone, binding: Model.Persons.HasAvailability.BindingType, editMode: boolean, controls: Views.OfControls.Plugin[]) => {
  return ( <React.Fragment>
    <ViewHasDetails.AsForm zone={zone} binding={ binding }  editMode={editMode}/>
    <Views.OfControls.AsRow controls={controls} render={controlProps => <Views.OfControls.AsButton {...controlProps} variant="outlined" />}/>
  </React.Fragment> );
};

const ModifyEvent = AsModifiers(
  [ 'persons', 'hasAvailability'], Model.Persons.HasAvailability.Descriptor, 
  AsInspector
);

Views.All.push(BrowseEvents, ModifyEvent.add, ModifyEvent.edit, ModifyEvent.remove);
